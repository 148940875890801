import React, { useEffect, useState } from 'react';
import MainLayout from '../layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { experimentalStyled as styled } from '@mui/material/styles';

const HomePagePF = () => {
  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#eeeff573',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    fontSize: '18px',
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    color: 'hsl(0, 0%, 21%)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#2e323a', // Cor de fundo ao passar o mouse
      color: 'white',
    },
    // backgroundColor: '#2e323a',
    // color: 'white',
    // cursor: 'pointer',
    // '&:hover': {
    //     backgroundColor: '#fff', // Cor de fundo ao passar o mouse
    //     color: 'hsl(0, 0%, 21%)',
    // },
  }));

  return (
    <>
      <MainLayout>
        <section className="section is-title-bar">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <ul>
                  <li>Portal de Fornecedores</li>
                  <li>Home</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="hero is-hero-bar">
          <div className="hero-body">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h1 className="title">Home</h1>
                </div>
              </div>
              <div className="level-right" style={{ display: 'none' }}>
                <div className="level-item"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-main-section">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-ballot"></i>
                </span>
              </p>
            </header>
            <div className="card-content">
              <div className="content">
                <Box
                  sx={{
                    flexGrow: 1,
                    padding: '15px 0px 40px',
                  }}
                >
                  <Grid
                    container
                    spacing={{ xs: 4, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid item xs={2} sm={4} md={4} key={1}>
                      <Item onClick={() => navigate('/ext/pedidos')}>
                        Pedidos
                      </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4} key={2}>
                      <Item onClick={() => navigate('/ext/home')}>
                        Propostas
                      </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4} key={3}>
                      <Item onClick={() => navigate('/ext/frete-home')}>
                        Frete
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
};

export default HomePagePF;
