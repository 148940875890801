import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MainLayout from '../layouts/MainLayout';
import './css/Formularios.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FornecedorFreteInclusionView from '../functional/FornecedorFreteInclusionView';
import FormModal from '../layouts/FormModal';

import ComentarioComponent from '../functional/comentarios/Comentario';
import AlertMessage from '../layouts/AlertMessage';
import { useUser } from '../../context/UserContext';

const AprovarFrete = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;
  const { tarefa, frete, route } = location.state;

  const [status, setStatus] = useState(tarefa.status);
  const [respostaSolicitante, setRespostaSolicitante] = useState('');
  const [responsavelID, setResponsavelID] = useState('');
  const [observacaoResponsavel, setObservacaoResponsavel] = useState('');
  const [packages, setPackages] = useState(frete.pacotes);
  const [listaFornecedores, setListaFornecedores] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [fornecedoresId, setFornecedoresId] = useState(frete.fornecedores);
  const [cfmdFornecedorId, setCfmdFornecedorId] = useState(frete.fornecedores);
  const [usersSuprimentos, setUsersSuprimentos] = useState([]);
  const [commentsData, setCommentsData] = useState([]);
  const [userComment, setUserComment] = useState('');
  const { idUsuario, setIdUsuario } = useUser();

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const statusMiddleList = ['ANDAMENTO', 'PENDENTE', 'ROTA', 'COTACAO'];

  const headers = {
    'Content-Type': 'application/json',
  };

  useEffect(() => {
    const getResponsavel = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/tarefas/responsavel/`,
          {
            params: {
              tarefa_id: tarefa.id,
            },
            headers: headers,
          }
        );

        if (response.data) {
          setResponsavelID(response.data.id);
        }
      } catch (error) {
        console.error('Erro ao buscar responsavel:', error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao buscar responsavel.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    const getUsersSuprimentos = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/users/setor/`,
          {
            params: {
              setor: 'suprimentos',
            },
            headers: headers,
          }
        );

        if (response.data && response.data.users) {
          setUsersSuprimentos(response.data.users);
        }
      } catch (error) {
        console.error('Erro ao buscar usuarios de suprimentos:', error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail ||
                'Erro ao buscar usuarios de suprimentos.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    getResponsavel();
    getUsersSuprimentos();
    if (
      respostaSolicitante === 'null' ||
      respostaSolicitante === null ||
      !respostaSolicitante
    ) {
      setRespostaSolicitante('');
    }
  }, [REACT_APP_BASE_API_URL]);

  useEffect(() => {
    if (idUsuario === null || idUsuario === 'null' || idUsuario === undefined) {
      setIdUsuario(idUsuario);
    }
  }, [idUsuario]);

  const handleFlux = async (status_val) => {
    const mensagem = `
    A sua solicitação de orçamento de frete foi atualizada para ${status_val}
    

    Detalhes: ${respostaSolicitante}
    `;
    let schema = {
      comentario: mensagem,
      tarefa_id: tarefa.id,
      email: tarefa.email_relator,
    };
    if (statusMiddleList.includes(status_val)) {
      try {
        const response = await axios.put(
          `${REACT_APP_BASE_API_URL}/tarefas/status/`,
          schema,
          {
            params: { status: status_val },
            headers,
          }
        );
        if (response.status === 200) {
          // alert('O status da solicitação foi alterado com sucesso.');
          setAlertMessage('O status da solicitação foi alterado com sucesso.');
          setAlertSeverity('success');
          setAlertOpen(true);
          setStatus(status_val);
          // navigate('/suprimentos/fila-tarefas', { state: { defaultButton: 'Frete' } });
          setNavigateTo('/suprimentos/fila-tarefas');
        }
      } catch (error) {
        // alert(error.response.data.detail)
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail ||
                'Erro ao alterar status da solicitação.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
        console.error(error);
      }
    } else if (status_val === 'RECUSADO') {
      if (respostaSolicitante === '' || respostaSolicitante === null) {
        // alert("É necessário explicar o motivo da recusa.")
        setAlertMessage('É necessário explicar o motivo da recusa.');
        setAlertSeverity('error');
        setAlertOpen(true);
        return;
      }
      try {
        const refuseResponse = await axios.put(
          `${REACT_APP_BASE_API_URL}/tarefas/done/`,
          schema,
          {
            params: { status: status_val },
            headers,
          }
        );
        if (refuseResponse.status === 200) {
          // alert("Solicitação recusada com sucesso.");
          setAlertMessage('Solicitação recusada com sucesso.');
          setAlertSeverity('success');
          setAlertOpen(true);
          setStatus(status_val);
          // navigate('/suprimentos/fila-tarefas', { state: { defaultButton: 'Frete' } });
          setNavigateTo('/suprimentos/fila-tarefas');
        }
      } catch (error) {
        // alert(error.response.data.detail)
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao recusar solicitação.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
        console.error(error);
      }
    } else if (status_val === 'CONCLUIDO') {
      try {
        const cadStatusResponse = await axios.put(
          `${REACT_APP_BASE_API_URL}/tarefas/done/`,
          schema,
          {
            params: { status: status_val },
            headers,
          }
        );
        if (cadStatusResponse.status === 200) {
          // alert("Solicitação concluida com sucesso.");
          setAlertMessage('Solicitação concluida com sucesso.');
          setAlertSeverity('success');
          setAlertOpen(true);
          setStatus(status_val);
          // navigate('/suprimentos/fila-tarefas', { state: { defaultButton: 'Frete' } });
          setNavigateTo('/suprimentos/fila-tarefas');
        }
      } catch (error) {
        console.error(error);
        // alert(error.response.data.detail)
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao concluir solicitação.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    }
  };

  // ------------------------- Fornecedor ------------------------- //

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setModalOpen(false);
  };

  const onAddHandler = (obj) => {
    if (!listaFornecedores.some((item) => item.id === obj.id)) {
      setListaFornecedores((prevObjs) => [...prevObjs, obj]);
      setFornecedoresId((prev_ids) => [...prev_ids, obj.id]);
    } else {
      // alert(`${obj.razao_social} já incluso.`)
      setAlertMessage(`${obj.razao_social} já incluso.`);
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleConfirmFornecedor = (obj) => {
    if (!cfmdFornecedorId.some((item) => item === obj.id)) {
      setCfmdFornecedorId((prev_ids) => [...prev_ids, obj.id]);
    }
  };

  const handleRemoveFornecedor = (obj) => {
    if (!cfmdFornecedorId.includes(obj.id)) {
      setListaFornecedores((prev_objs) =>
        prev_objs.filter((fornecedor) => fornecedor.id !== obj.id)
      );
    } else {
      setCfmdFornecedorId((prev_ids) => prev_ids.filter((id) => id !== obj.id));
    }
  };

  // ------------------------- Pacotes ------------------------- //

  function createEmptyPackage() {
    return {
      referencia: '',
      peso: 0.0,
      dim1: 0.0,
      dim2: 0.0,
      dim3: 0.0,
      valor: 0.0,
    };
  }

  const handleRemovePackage = (index) => {
    setPackages(packages.filter((_, pkgIndex) => pkgIndex !== index));
  };

  const handlePackageChange = (index, field, value) => {
    const numericValue = parseFloat(value);
    const updatedPackages = packages.map((pkg, pkgIndex) =>
      pkgIndex === index ? { ...pkg, [field]: numericValue } : pkg
    );
    setPackages(updatedPackages);
  };

  const handlePackageRefChange = (index, value) => {
    const updatedPackages = packages.map((pkg, pkgIndex) =>
      pkgIndex === index ? { ...pkg, referencia: value } : pkg
    );
    setPackages(updatedPackages);
  };

  const renderModalContent = (info) => {
    switch (modalContent) {
      case 'Incluir Fornecedor':
        return (
          <FornecedorFreteInclusionView
            onAddHandler={onAddHandler}
            info={info}
          />
        );
    }
  };

  const getFornecedores = async () => {
    try {
      if (cfmdFornecedorId.length > 0 && listaFornecedores.length === 0) {
        let list_ids = cfmdFornecedorId.join(',');
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/fornecedor/fornecedores-por-ids/`,
          {
            params: {
              list_ids: list_ids,
            },
            headers: headers,
          }
        );

        if (response.status === 200 && response.data) {
          const data = await response.data.fornecedores;
          setListaFornecedores(data);
        } else {
          setListaFornecedores([]);
        }
      }
    } catch (error) {
      console.error(error);
      // alert(error.response.data.detail);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar fornecedores.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getFornecedores();
  }, [cfmdFornecedorId, REACT_APP_BASE_API_URL]);

  // -------------------------  CADASTRAR ------------------------- //

  const handleResponsavel = async (idNovoResponsavel) => {
    const params = new URLSearchParams({
      tarefa_id: tarefa.id,
      responsavel_id: idNovoResponsavel,
    });
    try {
      const response = await axios.put(
        `${REACT_APP_BASE_API_URL}/tarefas/editar-responsavel-tarefa/?${params.toString()}`,
        {},
        { headers }
      );
      if (response.status === 200) {
        // alert("Responsavel atualizado.")
        setAlertMessage('Responsavel atualizado.');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      // alert(error.response.data.detail)
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao atualizar responsável.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedFrete = {
      ...frete.frete,
      observacaoResponsavel: observacaoResponsavel,
    };

    let requestObj = {
      fornecedores: cfmdFornecedorId,
      frete: updatedFrete,
      pacotes: packages,
    };

    try {
      const response = await axios.put(
        `${REACT_APP_BASE_API_URL}/frete/atualiza-frete/`,
        requestObj,
        {
          params: {
            tarefa_id: tarefa.id,
          },
          headers: headers,
        }
      );
      if (response.status === 200) {
        // alert("Email(s) enviado(s) ao(s) fornecedor(es)");
        setAlertMessage('Email(s) enviado(s) ao(s) fornecedor(es)');
        setAlertSeverity('success');
        setAlertOpen(true);
      } else {
        setAlertMessage('Erro ao enviar email(s) ao(s) fornecedor(es)');
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      // alert(error.response.data.detail);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail ||
              'Erro ao enviar email(s) ao(s) fornecedor(es)'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const postRootComment = async (e) => {
    e.preventDefault();
    let obj = {
      text: userComment,
      user_id: idUsuario,
      objeto: tarefa.id,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/comments/`,
        obj,
        { headers }
      );
      if (response.status === 201) {
        setUserComment('');
        getComments();
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || "Ocorreu um erro.")
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao enviar comentário.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getComments = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_API_URL}/comments/`, {
        params: { objeto_id: tarefa.id },
        headers,
      });
      if (response.status === 200) {
        setCommentsData(response.data);
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || "Ocoreu um erro.")
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar comentários.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getComments();
  }, []);

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo, { state: { defaultButton: 'Frete' } });
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo, { state: { defaultButton: 'Frete' } });
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    {/* <li>Setores</li> */}
                    <li>Suprimentos</li>
                    <li>Fila de Tarefas</li>
                    <li>Analisar Frete</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Analisar Frete</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <div className="buttons is-right">
                      <div className="div-select-box-status">
                        <Select
                          style={{
                            height: '42px',
                            marginBottom: '8px',
                          }}
                          className="select-status"
                          value={status}
                          onChange={(e) => {
                            handleFlux(e.target.value);
                          }}
                        >
                          <MenuItem value={'PENDENTE'}>PENDENTE</MenuItem>
                          <MenuItem value={'ANDAMENTO'}>EM ANDAMENTO</MenuItem>
                          <MenuItem value={'COTACAO'}>EM COTAÇÃO</MenuItem>
                          <MenuItem value={'ROTA'}>EM ROTA</MenuItem>
                          <MenuItem value={'RECUSADO'}>RECUSADO</MenuItem>
                          <MenuItem value={'CONCLUIDO'}>CONCLUIDO</MenuItem>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Detalhes da solicitação
                </p>
              </header>
              <div
                className="card-content"
                style={{
                  display: 'flex',
                  margin: '0px 0px 30px 0px',
                }}
              >
                <div className="atributos-form">
                  <div className="div-quadro-main-form">
                    <div className="div-quadro-material">
                      <div
                        className="field"
                        style={{
                          paddingBottom: '12px',
                          borderBottom: '1px solid #dbdbdb',
                        }}
                      >
                        <div className="row-field" style={{ fontSize: '20px' }}>
                          <label className="label-field">
                            {tarefa.nome_relator} criou esta solicitação
                          </label>
                        </div>
                      </div>

                      <div className="field">
                        <div className="row-field">
                          <label className="label-field">Id</label>
                          <span className="cell-value">{tarefa.id}</span>
                        </div>
                      </div>

                      {frete.frete.id && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Frete ID</label>
                            <span className="cell-value">{frete.frete.id}</span>
                          </div>
                        </div>
                      )}

                      {tarefa.titulo && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Resumo</label>
                            <span className="cell-value">{tarefa.titulo}</span>
                          </div>
                        </div>
                      )}

                      {frete.frete.referencia && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Referencia</label>
                            <span className="cell-value">
                              {frete.frete.referencia}
                            </span>
                          </div>
                        </div>
                      )}

                      {frete.frete.origem && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Origem</label>
                            <span className="cell-value">
                              {frete.frete.origem}
                            </span>
                          </div>
                        </div>
                      )}

                      {frete.frete.destino && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Destino</label>
                            <span className="cell-value">
                              {frete.frete.destino}
                            </span>
                          </div>
                        </div>
                      )}

                      {frete.frete.centro && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Centro</label>
                            <span className="cell-value">
                              {frete.frete.centro}
                            </span>
                          </div>
                        </div>
                      )}

                      {frete.frete.dataProntidao && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Data de Prontidão
                            </label>
                            <span className="cell-value">
                              {new Date(
                                frete.frete.dataProntidao
                              ).toLocaleString('pt-BR', {
                                day: '2-digit', // Dois dígitos para o dia
                                month: '2-digit', // Dois dígitos para o mês
                                year: 'numeric', // Quatro dígitos para o ano
                              })}
                            </span>
                          </div>
                        </div>
                      )}

                      {frete.frete.tipo !== null && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Tipo de Frete</label>
                            <span className="cell-value">
                              {frete.frete.tipo}
                            </span>
                          </div>
                        </div>
                      )}

                      {frete.frete.statusRC !== null && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Requisição de Compra aprovada de
                              equipamento/material?
                            </label>
                            <span className="cell-value">
                              {frete.frete.statusRC ? 'SIM' : 'NÃO'}
                            </span>
                          </div>
                        </div>
                      )}

                      {frete.frete.pedidoRC && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Pedido / RC</label>
                            <span className="cell-value">
                              {frete.frete.pedidoRC}
                            </span>
                          </div>
                        </div>
                      )}

                      {frete.frete.observacaoSolicitante && (
                        <div className="field">
                          <label className="label-field">
                            Observações do Solicitante
                          </label>
                          <div className="row-field">
                            <span className="cell-value-full">
                              {frete.frete.observacaoSolicitante}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* ---------------------------------  Quadros laterais ---------------------------------*/}
                  <div className="div-quadros-secundarios">
                    <div className="div-quadro-SLAs">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">
                          Adicionar detalhes ao status
                        </label>
                      </div>
                      <div className="div-quadro-content">
                        <div
                          className="row-field-message"
                          style={{ width: '100%' }}
                        >
                          <label
                            className="text"
                            style={{
                              color: '#171717',
                            }}
                          >
                            Informe ao solicitante detalhes sobre o status da
                            tarefa:
                          </label>
                          <div
                            className="cell-value"
                            style={{
                              paddingLeft: '0px',
                              paddingTop: '15px',
                            }}
                          >
                            <textarea
                              rows="4"
                              className="input"
                              value={respostaSolicitante}
                              style={{
                                height: '100px',
                                backgroundColor: '#f9f9f9',
                              }}
                              onChange={(e) =>
                                setRespostaSolicitante(e.target.value)
                              }
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="div-quadro-SLAs">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">SLAs</label>
                      </div>
                      <div className="div-quadro-content">
                        <div className="row-field">
                          <label className="label-field">Criado em</label>
                          <span className="cell-value">
                            {new Date(tarefa.criado_em).toLocaleString()}
                          </span>
                        </div>

                        <div className="row-field">
                          <label className="label-field">
                            Tempo para resolução
                          </label>
                          <span className="cell-value">
                            {new Date(tarefa.data_due).toLocaleString()}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="div-quadro-informacoes">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">
                          Informações
                        </label>
                      </div>
                      <div className="div-quadro-content">
                        <div className="row-field">
                          <label className="label-field">
                            Responsável Suprimentos
                          </label>
                          <span className="cell-value">
                            <Select
                              style={{
                                height: '35px',
                                width: '100%',
                                backgroundColor: 'rgb(249, 249, 249)',
                                color: 'black',
                                fontWeight: '300',
                              }}
                              className="select-status"
                              value={responsavelID}
                              onChange={(e) => {
                                setResponsavelID(e.target.value);
                                handleResponsavel(e.target.value);
                              }}
                            >
                              {usersSuprimentos.map((item) => (
                                <MenuItem key={item} value={item.id}>
                                  {item.nome + ' ' + item.sobrenome}
                                </MenuItem>
                              ))}
                            </Select>
                          </span>
                        </div>

                        <div className="row-field">
                          <label className="label-field">Relator</label>
                          <span className="cell-value">
                            {tarefa.nome_relator} {tarefa.sobrenome_relator}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ----------------------------------- INFORMAÇÕES DO PACOTE ---------------------------------------- */}
            <form name="frete form" onSubmit={handleSubmit}>
              <div className="card" style={{ marginBottom: '24px' }}>
                <header className="card-header">
                  <p className="card-header-title" style={{ width: '100%' }}>
                    <div style={{ width: '500px' }}>
                      <span className="icon">
                        <i className="mdi mdi-ballot"></i>
                      </span>
                      Adicionar informações do(s) pacote(s)
                    </div>
                  </p>
                </header>
                <div className="card-content">
                  <div
                    className="grid"
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      marginLeft: '10px',
                    }}
                  >
                    {packages &&
                      packages.map((pkg, index) => (
                        <div
                          className="card"
                          key={index}
                          style={{
                            margin: '0px 10px 10px 0px',
                          }}
                        >
                          <header
                            className="card-header"
                            style={{
                              backgroundColor: '#2e323a',
                              color: 'white',
                            }}
                          >
                            <p
                              className="card-header-title"
                              style={{
                                color: 'white',
                              }}
                            >
                              Pacote {index + 1}
                            </p>
                            <p
                              className="card-header-icon"
                              aria-label="more options"
                              onClick={() => handleRemovePackage(index)}
                            >
                              <span className="icon">
                                <i
                                  className="fa-solid fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </p>
                          </header>
                          <div className="card-content">
                            <div key={`card-${index}`}>
                              <div
                                className="field is-horizontal"
                                style={{
                                  padding: '0px',
                                }}
                              >
                                <div
                                  className="field-label is-normal"
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <label className="label">
                                    Referência
                                    <span className="required-symbol"> *</span>
                                  </label>
                                </div>
                                <div
                                  className="field-body"
                                  style={{
                                    maxWidth: '500px',
                                  }}
                                >
                                  <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                      <input
                                        className="input"
                                        type="text"
                                        required
                                        value={pkg.referencia}
                                        onChange={(e) =>
                                          handlePackageRefChange(
                                            index,
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span className="icon is-small is-left">
                                        <i className="fas fa-asterisk"></i>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="field is-horizontal"
                                style={{
                                  padding: '0px',
                                }}
                              >
                                <div
                                  className="field-label is-normal"
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <label className="label">
                                    Dimensão 1 [CM]
                                    <span className="required-symbol">*</span>
                                  </label>
                                </div>
                                <div
                                  className="field-body"
                                  style={{
                                    maxWidth: '500px',
                                  }}
                                >
                                  <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                      <input
                                        className="input"
                                        type="number"
                                        step="0.01"
                                        required
                                        value={pkg.dim1}
                                        onChange={(e) =>
                                          handlePackageChange(
                                            index,
                                            'dim1',
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span className="icon is-small is-left">
                                        <i className="fas fa-ruler"></i>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="field is-horizontal"
                                style={{
                                  padding: '0px',
                                }}
                              >
                                <div
                                  className="field-label is-normal"
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <label className="label">
                                    Dimensão 2 [CM]
                                    <span className="required-symbol">*</span>
                                  </label>
                                </div>
                                <div
                                  className="field-body"
                                  style={{
                                    maxWidth: '500px',
                                  }}
                                >
                                  <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                      <input
                                        className="input"
                                        type="number"
                                        step={'0.01'}
                                        required
                                        value={pkg.dim2}
                                        onChange={(e) =>
                                          handlePackageChange(
                                            index,
                                            'dim2',
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span className="icon is-small is-left">
                                        <i className="fas fa-ruler"></i>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="field is-horizontal"
                                style={{
                                  padding: '0px',
                                }}
                              >
                                <div
                                  className="field-label is-normal"
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <label className="label">
                                    Dimensão 3 [CM]
                                    <span className="required-symbol">*</span>
                                  </label>
                                </div>
                                <div
                                  className="field-body"
                                  style={{
                                    maxWidth: '500px',
                                  }}
                                >
                                  <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                      <input
                                        className="input"
                                        type="number"
                                        step={'0.01'}
                                        required
                                        value={pkg.dim3}
                                        onChange={(e) =>
                                          handlePackageChange(
                                            index,
                                            'dim3',
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span className="icon is-small is-left">
                                        <i className="fas fa-ruler"></i>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="field is-horizontal"
                                style={{
                                  padding: '0px',
                                }}
                              >
                                <div
                                  className="field-label is-normal"
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <label className="label">
                                    Dimensões [CM]
                                  </label>
                                </div>
                                <div
                                  className="field-body"
                                  style={{
                                    maxWidth: '500px',
                                  }}
                                >
                                  <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                      <input
                                        className="input"
                                        type="text"
                                        value={`(${pkg.dim1} x ${pkg.dim2} x ${pkg.dim3}) CM`}
                                        disabled
                                      />
                                      <span className="icon is-small is-left">
                                        <i className="fas fa-ruler-combined"></i>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="field is-horizontal"
                                style={{
                                  padding: '0px',
                                }}
                              >
                                <div
                                  className="field-label is-normal"
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <label className="label">
                                    Peso [KG]
                                    <span className="required-symbol">*</span>
                                  </label>
                                </div>
                                <div
                                  className="field-body"
                                  style={{
                                    maxWidth: '500px',
                                  }}
                                >
                                  <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                      <input
                                        className="input"
                                        type="number"
                                        step={'0.01'}
                                        required
                                        value={pkg.peso}
                                        onChange={(e) =>
                                          handlePackageChange(
                                            index,
                                            'peso',
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span className="icon is-small is-left">
                                        <i className="fas fa-scale-balanced"></i>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="field is-horizontal"
                                style={{
                                  padding: '0px',
                                }}
                              >
                                <div
                                  className="field-label is-normal"
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <label className="label">
                                    Valor do pacote [R$]
                                    <span className="required-symbol">*</span>
                                  </label>
                                </div>
                                <div
                                  className="field-body"
                                  style={{
                                    maxWidth: '500px',
                                  }}
                                >
                                  <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                      <input
                                        className="input"
                                        type="number"
                                        required
                                        step={'0.01'}
                                        value={pkg.valor}
                                        onChange={(e) =>
                                          handlePackageChange(
                                            index,
                                            'valor',
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span className="icon is-small is-left">
                                        <i className="fas fa-money-bill"></i>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="card" style={{ marginBottom: '24px' }}>
                <header className="card-header">
                  <p className="card-header-title" style={{ width: '100%' }}>
                    <div style={{ width: '300px' }}>
                      <span className="icon">
                        <i className="mdi mdi-ballot"></i>
                      </span>
                      Incluir fornecedor(es)
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end',
                        fontWeight: '300',
                      }}
                    >
                      <p
                        className="button is-secondary"
                        onClick={() => handleOpenModal('Incluir Fornecedor')}
                        style={{
                          borderColor: '#2B3BED',
                          color: '#2B3BED',
                        }}
                      >
                        <span className="icon">
                          <i className="fa-solid fa-add"></i>
                        </span>
                        <span style={{ fontSize: '16px' }}>
                          Incluir Fornecedor
                        </span>
                      </p>
                    </div>
                  </p>
                </header>
                <div className="card-content">
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Observação para o(s) fornecedor(es)
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                          <textarea
                            rows="4"
                            className="input"
                            value={observacaoResponsavel}
                            style={{
                              height: '100px',
                            }}
                            onChange={(e) =>
                              setObservacaoResponsavel(e.target.value)
                            }
                          ></textarea>
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div
                    className="grid"
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      gap: '20px',
                    }}
                  >
                    {listaFornecedores &&
                      listaFornecedores.map((item, i) => (
                        <div
                          className="card"
                          key={i}
                          style={
                            cfmdFornecedorId.includes(item.id)
                              ? {
                                  backgroundColor: 'lightcyan',
                                  marginBottom: '0px',
                                }
                              : {
                                  marginBottom: '0px',
                                }
                          }
                        >
                          <header
                            className="card-header"
                            style={{
                              backgroundColor: '#2e323a',
                              color: 'white',
                            }}
                          >
                            <p
                              className="card-header-title"
                              style={{
                                color: 'white',
                              }}
                            >
                              {item.razao_social}
                            </p>
                            <button
                              className="card-header-icon"
                              aria-label="more options"
                            >
                              <span
                                className="icon"
                                onClick={() => handleRemoveFornecedor(item)}
                              >
                                <i
                                  className="fa-solid fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </button>
                          </header>
                          <div className="card-content">
                            <div className="content">
                              {item.logradouro}, {item.numero} - {item.bairro}{' '}
                              <br />
                              {item.cidade} - {item.estado} <br />
                              {item.telefone1}
                              <br />
                            </div>
                          </div>
                          <footer className="card-footer">
                            <a
                              onClick={() => handleConfirmFornecedor(item)}
                              style={{
                                fontSize: '19px',
                                fontWeight: 'bold',
                              }}
                              className="card-footer-item"
                            >
                              Confirmar
                            </a>
                          </footer>
                        </div>
                      ))}
                  </div>
                  <hr></hr>
                  <div className="container" style={{ display: 'flex' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <button className="button is-primary" type="submit">
                        <span style={{ fontSize: '16px' }}>Enviar email</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Comentários
                </p>
              </header>
              <div className="card-content" style={{ width: '100%' }}>
                <form name="comment" onSubmit={postRootComment}>
                  <div className="field">
                    <div className="control">
                      <textarea
                        className="textarea"
                        placeholder="Comentário"
                        value={userComment}
                        onChange={(e) => setUserComment(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div
                    className="div"
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <button className="button is-primary" type="submit">
                      Comentar
                    </button>
                  </div>
                </form>
                <br />
                {commentsData &&
                  commentsData.map((comment) => (
                    <ComentarioComponent
                      key={comment.id}
                      comment={comment}
                      userId={idUsuario}
                      objeto={tarefa}
                      getCommentFunction={getComments}
                    />
                  ))}
              </div>
            </div>
          </section>
          <FormModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            modalTitle={modalContent}
          >
            {renderModalContent(info)}
          </FormModal>
        </div>
      </MainLayout>
    </>
  );
};

export default AprovarFrete;
