import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ModalPedidos = ({ mPedido, mItemPedido, mRoute }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const [prioridade, setPrioridade] = useState('');
  const [centro, setCentro] = useState('');
  const [tipoMaterial, setTipoMaterial] = useState('');
  const [ncm, setNcm] = useState('');
  const [finalidade, setFinalidade] = useState('');
  const [precoMedio, setPrecoMedio] = useState('');
  const [unidadeMedida, setUnidadeMedida] = useState('');
  const [norma, setNorma] = useState('');
  const [cfop, setCfop] = useState('');
  const [observacoesSolicitante, setObservacoesSolicitante] = useState('');
  const [allUnidades, setAllUnidades] = useState([]);

  const handleCad = async (e) => {
    // e.preventDefault();;
    // const precoMedioNumerico = parseFloat(precoMedio.replace(',', '.'));
    // const formData = {
    //   id: null,
    //   resumo: mTarget,
    //   // area_solicitante: areaSolicitante,
    //   prioridade_material: prioridade,
    //   unidade_medida: unidadeMedida,
    //   centro: centro,
    //   ncm: ncm,
    //   observacoes_solicitante: observacoesSolicitante,
    //   tipo_material: tipoMaterial,
    //   preco_medio: precoMedioNumerico,
    //   finalidade: finalidade,
    //   norma: norma,
    //   cfop: cfop,
    // };
    // try {
    //   let params = {};
    //   params = { material: mTarget };
    //   if (mRoute === "equips") {
    //     params = { equipamento: mTarget };
    //   }
    //   let requestObj = {
    //     cad_material: formData,
    //     schema: mDataPDM
    //   };
    //   const response = await axios.post(
    //     `${REACT_APP_BASE_API_URL}/${mRoute}/`,
    //     requestObj,
    //     { params, headers }
    //   );
    //   if (response.status === 201) {
    //     alert("Item cadastrado com sucesso!");
    //     // navigate('/meic')
    //     mRoute === "equips" ? navigate('/meic/equipamentos') : navigate('/meic/materiais')
    //   } else if (!response.status === 201) {
    //     alert("Ocorreu um erro.")
    //   };
    // } catch (error) {
    //   console.error(error);
    //   alert(error.response.data.detail)
    // };
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="container" style={{ width: '900px' }}>
        <form onSubmit={handleCad} className="modal-form">
          <div style={{ marginTop: '20px' }}>
            {mPedido.pedido_compra && (
              <div className="field">
                <div className="row-field">
                  <label
                    className="label-field"
                    style={{
                      color: 'white',
                      fontSize: '1.2rem',
                    }}
                  >
                    ID do Pedido
                  </label>
                  <span
                    className="cell-value"
                    style={{
                      color: 'white',
                      fontSize: '1.2rem',
                    }}
                  >
                    {mPedido.pedido_compra}
                  </span>
                </div>
              </div>
            )}

            {mPedido.nome_centro && (
              <div className="field">
                <div className="row-field">
                  <label
                    className="label-field"
                    style={{
                      color: 'white',
                      fontSize: '1.2rem',
                    }}
                  >
                    Destinatário
                  </label>
                  <span
                    className="cell-value"
                    style={{
                      color: 'white',
                      fontSize: '1.2rem',
                    }}
                  >
                    {mPedido.nome_centro}
                  </span>
                </div>
              </div>
            )}
          </div>

          {mPedido.fornecedor && (
            <div className="field">
              <div className="row-field">
                <label
                  className="label-field"
                  style={{
                    color: 'white',
                    fontSize: '1.2rem',
                  }}
                >
                  Fornecedor
                </label>
                <span
                  className="cell-value"
                  style={{
                    color: 'white',
                    fontSize: '1.2rem',
                  }}
                >
                  {mPedido.fornecedor}
                </span>
              </div>
            </div>
          )}

          {mItemPedido.item_pedido_compra && (
            <div className="field">
              <div className="row-field">
                <label
                  className="label-field"
                  style={{
                    color: 'white',
                    fontSize: '1.2rem',
                  }}
                >
                  Item ID
                </label>
                <span
                  className="cell-value"
                  style={{
                    color: 'white',
                    fontSize: '1.2rem',
                  }}
                >
                  {mItemPedido.item_pedido_compra}
                </span>
              </div>
            </div>
          )}

          {mItemPedido.texto_breve && (
            <div className="field">
              <div className="row-field">
                <label
                  className="label-field"
                  style={{
                    color: 'white',
                    fontSize: '1.2rem',
                  }}
                >
                  Descrição
                </label>
                <span
                  className="cell-value"
                  style={{
                    color: 'white',
                    fontSize: '1.2rem',
                  }}
                >
                  {mItemPedido.texto_breve}
                </span>
              </div>
            </div>
          )}

          {mItemPedido.data_entrega && (
            <div className="field">
              <div className="row-field">
                <label
                  className="label-field"
                  style={{
                    color: 'white',
                    fontSize: '1.2rem',
                  }}
                >
                  Data de Entrega
                </label>
                <span
                  className="cell-value"
                  style={{
                    color: 'white',
                    fontSize: '1.2rem',
                  }}
                >
                  {mItemPedido.data_entrega}
                </span>
              </div>
            </div>
          )}

          {mItemPedido.data_remessa && (
            <div className="field">
              <div className="row-field">
                <label
                  className="label-field"
                  style={{
                    color: 'white',
                    fontSize: '1.2rem',
                  }}
                >
                  Data Alinhada
                </label>
                <span
                  className="cell-value"
                  style={{
                    color: 'white',
                    fontSize: '1.2rem',
                  }}
                >
                  {mItemPedido.data_remessa}
                </span>
              </div>
            </div>
          )}

          {/* <div className="field-modal">
                    <label className="label-modal"> 
                      Centro   
                      <span className="required-symbol" style={{fontSize: '1.2rem'}}> *</span>                    
                    </label>                   
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="select-modal"
                      required
                      value={centro}
                      onChange={(e) => setCentro(e.target.value)}   
                    >                              
                      <MenuItem value={"5101 - Geo Energética"}>5101 - Geo Energética</MenuItem>
                      <MenuItem value={"5001 - Geo Elétrica"}>5001 - Geo Elétrica</MenuItem>
                      <MenuItem value={"2301 - Uisa Geo"}>2301 - Uisa Geo</MenuItem>
                      <MenuItem value={"5701 - Cri Geo"}>5701 - Cri Geo</MenuItem>
                    </Select>                     
                  </div> */}

          {/* <div className="field-modal">
                    <label className="label-modal"> 
                      Norma          
                      <span className="required-symbol" style={{fontSize: '1.2rem'}}> *</span>                         
                    </label>                      
                      <input className="input-modal"
                        type="text"
                        value={norma}                        
                        onChange={(e) => setNorma(e.target.value)}
                        required
                      />                                
                  </div>
                 */}
          <hr></hr>

          {/* <div className="modal-btn-footer" >
                      <button type="submit" className="button-modal btn-submit">Cadastrar</button>
                  </div> */}
        </form>
      </div>
    </>
  );
};

export default ModalPedidos;
