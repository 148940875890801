import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bulma/css/bulma.min.css';
import 'bulma/bulma.sass';
import './css/style.css';
import './font-awesome/css/font-awesome.css';
import './scss/main.scss';
import './bulma-divider/bulma-divider.min.css';

import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import Footer from './components/layouts/Footer';
import { UserProvider } from './context/UserContext';
import AuthProvider from './context/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <Router>
    <AuthProvider>
      <UserProvider>
        <App />
        <Footer />
      </UserProvider>
    </AuthProvider>
  </Router>
  // {/* </React.StrictMode> */}
);

// footer.render(
//   <Footer />
// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
