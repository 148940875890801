import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import AlertMessage from '../layouts/AlertMessage';

const ItemInclusionView = ({ mTarget, mSchema, onAddHandler, info }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [allInsumos, setAllInsumos] = useState([]);
  const [targetSearch, setTargetSearch] = useState('');

  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const headers = {
    'Content-Type': 'application/json',
  };

  const getInsumos = async () => {
    try {
      const sap = mSchema === 'material' || mSchema === 'equip';
      const url =
        mTarget === undefined
          ? `${REACT_APP_BASE_API_URL}/mats/t-insumos/?schema=${mSchema}&sap=${sap}`
          : `${REACT_APP_BASE_API_URL}/mats/insumos/?schema=${mSchema}&item=${mTarget}&sap=${sap}`;

      const response = await axios.get(url, { headers });
      const data = await response.data;
      setAllInsumos(data);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar insumos.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleAddClick = (obj) => {
    onAddHandler(obj);
    // alert(`${obj.did} incluido.`)
    setAlertMessage(`${obj.did} incluido.`);
    setAlertSeverity('success');
    setAlertOpen(true);
  };

  useEffect(() => {
    getInsumos();
  }, [mTarget, mSchema, onAddHandler]);

  const handleFilterMats = async (e) => {
    e.preventDefault();
    const headers = {
      'Content-Type': 'application/json',
    };

    const requestParams =
      mSchema === 'material' || mSchema === 'equip'
        ? {
            search_str: targetSearch,
            schema: mSchema,
            sap: true,
          }
        : {
            search_str: targetSearch,
            schema: mSchema,
          };

    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/mats/insumos/q`,
        {
          params: requestParams,
          headers,
        }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAllInsumos(data);
      }
    } catch (error) {
      console.error(error);
      // alert(error.data.response.detail)
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar insumos.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const columns = [
    { field: 'did', headerName: 'ID', flex: 1 },
    { field: 'descl', headerName: 'DESCRICAO', flex: 2 },
    { field: 'unidade', headerName: 'UNIDADE', flex: 1 },
    {
      field: 'acao',
      headerName: 'ACAO',
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>
          <div
            className="button is-small is-info"
            style={{ backgroundColor: '#2B3BED' }}
            onClick={(e) => {
              e.preventDefault();
              const insumo = allInsumos.find(
                (item) => item.did === params.row.did
              );
              if (insumo) {
                handleAddClick(insumo);
              } else {
                console.error('Insumo não encontrado.');
              }
            }}
          >
            Adicionar
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="container" style={{ width: '800px' }}>
        <div onClick={(event) => event.stopPropagation()}>
          {/* <p className="title is-4 has-text-white">{mSchema.toUpperCase()}</p> */}
          <form className="modal-form" onSubmit={handleFilterMats}>
            <div className="field-modal">
              <label className="label-modal">Buscar</label>
              <div className="flex-items">
                <div style={{ flex: '3' }}>
                  <input
                    className="input-modal"
                    type="text"
                    placeholder="Texto"
                    onChange={(e) => {
                      setTargetSearch(e.target.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    flex: '1',
                    display: 'flex',
                    justifyContent: 'center',
                    marginLeft: '16px',
                  }}
                >
                  <button className="button is-primary">Procurar</button>
                </div>
              </div>
              <p className="help-text">Separe os termos por vírgula</p>
            </div>
          </form>
        </div>

        <br />
        <div
          className="table-modal"
          style={{ margin: '16px', height: '400px' }}
        >
          <DataGrid
            rows={allInsumos}
            columns={columns}
            pageSize={5}
            disableSelectionOnClick
            getRowHeight={() => 'auto'}
            getColumnHeight={() => 'auto'}
            sx={{
              color: 'white',
              fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'flex-start', // Alinha o conteúdo no topo
                paddingTop: '4px', // Ajuste conforme necessário
                paddingBottom: '4px',
                border: '0px',
                fontFamily: '"Nunito", sans-serif',
                lineHeight: '1.5',
                fontSize: '13px',
              },
              '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle': {
                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                fontSize: '14px',
                color: '#4a4a4a',
                fontFamily: '"Nunito", sans-serif',
                lineHeight: '1.5',
              },
              '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders':
                {
                  backgroundColor: '#ffffff',
                },
              '& .MuiDataGrid-row': {
                borderLeft: '0px',
              },
              '& .css-wop1k0-MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                color: 'black',
              },
              '& .css-qvtrhg-MuiDataGrid-virtualScroller': {
                backgroundColor: 'white',
                color: 'black',
              },
            }}
          />

          {/* <table className="table is-fullwidth is-hoverable is-striped" >
                        <thead>
                            <tr>
                                <th><p className="has-text-centered is-size-7">ID</p></th>
                                <th><p className="has-text-centered is-size-7">DESCRIÇÃO</p></th>
                                <th><p className="has-text-centered is-size-7">UNIDADE</p></th>
                                <th><p className="has-text-centered is-size-7">AÇÃO</p></th>
                            </tr>
                        </thead>
                        <tbody>
                            {allInsumos.map((material, i) => (
                                <tr key={material.id}>
                                    <td><p className="has-text-centered is-size-7">{material.did}</p></td>
                                    <td className="has-test-justified"><p className="is-size-7">{material.descl}</p></td>
                                    <td><p className="has-text-centered is-size-7">{material.unidade}</p></td>
                                    <td className="has-text-centered"><div className="button is-small is-info" onClick={(e) => { e.preventDefault(); handleAddClick(material) }}>Adicionar</div></td>
                                </tr>
                            ))}
                        </tbody>
                    </table> */}
        </div>
      </div>
    </>
  );
};

export default ItemInclusionView;
