import React, { useState } from 'react';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/pt-br';
import AlertMessage from '../../layouts/AlertMessage';

dayjs.extend(localizedFormat);
dayjs.locale('pt-br'); // Define o idioma para português

const FreteForm = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [freteRef, setFreteRef] = useState('');
  const [freteOrigem, setFreteOrigem] = useState('');
  const [freteDestino, setFreteDestino] = useState('');
  const [freteTipo, setFreteTipo] = useState('');
  const [statusRC, setStatusRC] = useState(false);
  const [pedidoRC, setPedidoRC] = useState('');
  const [dataProntidao, setDataProntidao] = React.useState(null);
  const [packages, setPackages] = useState([
    {
      referencia: '',
      dim1: 0.0,
      dim2: 0.0,
      dim3: 0.0,
      peso: 0.0,
      valor: 0.0,
    },
  ]);
  const [observacaoSolicitante, setObservacaoSolicitante] = useState('');
  const [centro, setCentro] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  function createEmptyPackage() {
    return {
      referencia: '',
      peso: 0.0,
      dim1: 0.0,
      dim2: 0.0,
      dim3: 0.0,
      valor: 0.0,
    };
  }

  const addPackage = () => {
    setPackages([...packages, createEmptyPackage()]);
  };

  const handleRemovePackage = (index) => {
    setPackages(packages.filter((_, pkgIndex) => pkgIndex !== index));
  };

  const handlePackageChange = (index, field, value) => {
    const numericValue = parseFloat(value);
    const updatedPackages = packages.map((pkg, pkgIndex) =>
      pkgIndex === index ? { ...pkg, [field]: numericValue } : pkg
    );
    setPackages(updatedPackages);
  };

  const handlePackageRefChange = (index, value) => {
    const updatedPackages = packages.map((pkg, pkgIndex) =>
      pkgIndex === index ? { ...pkg, referencia: value } : pkg
    );
    setPackages(updatedPackages);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let freteObj = {
      referencia: freteRef,
      origem: freteOrigem,
      destino: freteDestino,
      pacotes: '',
      tipo: freteTipo,
      pedidoRC: pedidoRC.toString(),
      dataProntidao: dataProntidao
        ? dayjs(dataProntidao).format('YYYY-MM-DDTHH:mm:ssZ')
        : null,
      statusRC: statusRC,
      observacaoSolicitante: observacaoSolicitante,
      centro: centro,
    };

    const tarefa = {
      titulo: `Frete: ${freteRef}`,
      setor: 'suprimentos',
      tags: 'frete',
      status: 'PENDENTE',
      tempo_resolucao: 2,
      prioridade: 'NORMAL',
    };

    let requestObj = {
      // fornecedores: cfmdFornecedorId,
      frete: freteObj,
      pacotes: packages,
      tarefa: tarefa,
    };

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/frete/orcamento-frete`,
        requestObj,
        { headers }
      );
      if (response.status === 201) {
        // alert("Frete solicitado")
        setAlertMessage('Frete solicitado com sucesso');
        setAlertSeverity('success');
        setAlertOpen(true);

        setFreteRef('');
        setFreteOrigem('');
        setFreteDestino('');
        setCentro('');
        setObservacaoSolicitante('');
        setPedidoRC('');
        setDataProntidao(null);
        setStatusRC(false);
        setFreteTipo('');
        setPackages([
          {
            referencia: '',
            dim1: 0.0,
            dim2: 0.0,
            dim3: 0.0,
            peso: 0.0,
            valor: 0.0,
          },
        ]);
      } else {
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || "Ocorreu um erro.")
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao solicitar frete'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    {/* <li>Setores</li> */}
                    <li>Suprimentos</li>
                    <li>Cadastrar Frete</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Cadastrar Frete</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <form name="frete form" onSubmit={handleSubmit}>
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Formulário geral de frete
                  </p>
                </header>
                <div className="card-content">
                  <div className="sla">
                    {/* <div className="card-content"> */}
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          Referência
                          <span className="required-symbol"> *</span>
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <p className="control is-expanded has-icons-left">
                            <input
                              className="input"
                              type="text"
                              value={freteRef}
                              required
                              onChange={(e) => setFreteRef(e.target.value)}
                            />
                            <span className="icon is-small is-left">
                              <i className="fas fa-asterisk"></i>
                            </span>
                          </p>
                          <p
                            className="help"
                            style={{
                              fontSize: '0.85rem',
                            }}
                          >
                            Algum texto para facilitar a identificação.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div
                        className="field-label is-normal"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <label className="label">
                          Origem
                          <span className="required-symbol"> *</span>
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <p className="control is-expanded has-icons-left">
                            <input
                              className="input"
                              type="text"
                              value={freteOrigem}
                              required
                              onChange={(e) => setFreteOrigem(e.target.value)}
                            />
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-plane-departure"></i>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div
                        className="field-label is-normal"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <label className="label">
                          Destino
                          <span className="required-symbol"> *</span>
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <p className="control is-expanded has-icons-left">
                            <input
                              className="input"
                              type="text"
                              required
                              value={freteDestino}
                              onChange={(e) => setFreteDestino(e.target.value)}
                            />
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-plane-arrival"></i>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          Centro
                          <span className="required-symbol"> *</span>
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field is-narrow">
                          <div className="control has-icons-left">
                            <div className="select is-fullwidth">
                              <select
                                name="centro"
                                value={centro}
                                required
                                onChange={(e) => setCentro(e.target.value)}
                              >
                                <option value="" disabled></option>
                                <option value={'5101 - Geo Energética'}>
                                  5101 - Geo Energética
                                </option>
                                <option value={'5001 - Geo Elétrica'}>
                                  5001 - Geo Elétrica
                                </option>
                                <option value={'2301 - Uisa Geo'}>
                                  2301 - Uisa Geo
                                </option>
                                <option value={'5701 - Cri Geo'}>
                                  5701 - Cri Geo
                                </option>
                              </select>
                              <span className="icon is-small is-left">
                                <i className="fa-solid fa-location-dot"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div
                        className="field-label is-normal"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <label className="label">
                          Data de Prontidão
                          <span className="required-symbol"> *</span>
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <p className="control">
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale="pt-br"
                            >
                              <DemoContainer
                                sx={{
                                  padding: '0px',
                                  height: '40px',
                                }}
                                components={['DatePicker']}
                              >
                                <DatePicker
                                  label=""
                                  required
                                  sx={{
                                    '& .MuiInputBase-input': {
                                      padding: '8px',
                                      fontSize: '1rem',
                                    },
                                  }}
                                  value={dataProntidao}
                                  onChange={(newValue) =>
                                    setDataProntidao(newValue ? newValue : null)
                                  }
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div
                        className="field-label is-normal"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <label className="label">
                          Tipo de Frete
                          <span className="required-symbol"> *</span>
                        </label>
                      </div>
                      <div
                        className="field-body"
                        style={{
                          display: 'flex',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div className="field">
                          <div className="control">
                            <label className="radio">
                              <input
                                type="radio"
                                name="tipoFrete"
                                value="FRACIONADO"
                                required
                                onChange={(e) => setFreteTipo(e.target.value)}
                              />
                              &nbsp;FRACIONADO
                            </label>
                            <label className="radio">
                              <input
                                type="radio"
                                name="tipoFrete"
                                value="DEDICADO"
                                onChange={(e) => setFreteTipo(e.target.value)}
                              />
                              &nbsp;DEDICADO
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="field is-horizontal"
                      style={{ marginBottom: '20px' }}
                    >
                      <div className="field-label is-normal">
                        <label style={{ textAlign: 'end' }} className="label">
                          Requisição de Compra aprovada de equipamento/material?
                          <span className="required-symbol"> *</span>
                        </label>
                      </div>
                      <div
                        className="field-body"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingTop: '5px',
                        }}
                      >
                        <div className="field">
                          <div className="control">
                            <label className="radio">
                              <input
                                type="radio"
                                name="statusRC"
                                value="true"
                                required
                                onChange={(e) => setStatusRC(true)}
                              />
                              &nbsp;SIM
                            </label>
                            <label className="radio">
                              <input
                                type="radio"
                                name="statusRC"
                                value="false"
                                onChange={(e) => setStatusRC(false)}
                              />
                              &nbsp;NÃO
                            </label>
                          </div>
                          <p
                            className="help"
                            style={{
                              fontSize: '0.85rem',
                              marginTop: '0px',
                            }}
                          >
                            Selecione <strong>SIM</strong> se for um frete para
                            algum material/equipamento cujo a requisição de
                            compra ja foi aceita, <strong>NÃO</strong> caso
                            contrario.
                          </p>
                        </div>
                      </div>
                    </div>

                    {statusRC && (
                      <div className="field is-horizontal">
                        <div
                          className="field-label is-normal"
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <label className="label">Número RC</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <p className="control is-expanded has-icons-left">
                              <input
                                className="input"
                                type="number"
                                value={pedidoRC}
                                required
                                onChange={(e) => setPedidoRC(e.target.value)}
                              />
                              <span className="icon is-small is-left">
                                <i className="fa-solid fa-comments-dollar"></i>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Observação</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <p className="control ">
                            <textarea
                              rows="4"
                              className="input"
                              value={observacaoSolicitante}
                              style={{
                                height: '100px',
                              }}
                              onChange={(e) =>
                                setObservacaoSolicitante(e.target.value)
                              }
                            ></textarea>
                            {/* <span className="icon is-small is-left"><i className="fa-solid fa-comments"></i></span> */}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="card" style={{ marginBottom: '24px' }}>
                <header className="card-header">
                  <p className="card-header-title" style={{ width: '100%' }}>
                    <div style={{ width: '500px' }}>
                      <span className="icon">
                        <i className="mdi mdi-ballot"></i>
                      </span>
                      Adicionar informações do(s) pacote(s)
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end',
                        fontWeight: '300',
                        borderColor: '#2B3BED',
                      }}
                    >
                      <p
                        className="button is-secondary"
                        onClick={addPackage}
                        style={{
                          borderColor: '#2B3BED',
                          color: '#2B3BED',
                        }}
                      >
                        <span className="icon">
                          <i className="fa-solid fa-add"></i>
                        </span>
                        <span style={{ fontSize: '16px' }}>
                          Adicionar Pacote
                        </span>
                      </p>
                    </div>
                  </p>
                </header>
                <div className="card-content">
                  <div
                    className="grid"
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      marginLeft: '10px',
                    }}
                  >
                    {packages &&
                      packages.map((pkg, index) => (
                        <div
                          className="card"
                          key={index}
                          style={{
                            margin: '0px 10px 10px 0px',
                          }}
                        >
                          <header
                            className="card-header"
                            style={{
                              backgroundColor: '#2e323a',
                              color: 'white',
                            }}
                          >
                            <p
                              className="card-header-title"
                              style={{
                                color: 'white',
                              }}
                            >
                              Pacote {index + 1}
                            </p>
                            <p
                              className="card-header-icon"
                              aria-label="more options"
                              onClick={() => handleRemovePackage(index)}
                            >
                              <span className="icon">
                                <i
                                  className="fa-solid fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </p>
                          </header>
                          <div className="card-content">
                            <div key={`card-${index}`}>
                              <div
                                className="field is-horizontal"
                                style={{
                                  padding: '0px',
                                }}
                              >
                                <div
                                  className="field-label is-normal"
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <label className="label">
                                    Referência
                                    <span className="required-symbol"> *</span>
                                  </label>
                                </div>
                                <div
                                  className="field-body"
                                  style={{
                                    maxWidth: '70%',
                                  }}
                                >
                                  <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                      <input
                                        className="input"
                                        type="text"
                                        required
                                        value={pkg.referencia}
                                        onChange={(e) =>
                                          handlePackageRefChange(
                                            index,
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span className="icon is-small is-left">
                                        <i className="fas fa-asterisk"></i>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="field is-horizontal"
                                style={{
                                  padding: '0px',
                                }}
                              >
                                <div
                                  className="field-label is-normal"
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <label className="label">
                                    Dimensão 1 [CM]
                                  </label>
                                </div>
                                <div
                                  className="field-body"
                                  style={{
                                    maxWidth: '70%',
                                  }}
                                >
                                  <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                      <input
                                        className="input"
                                        type="number"
                                        step="0.01"
                                        value={pkg.dim1}
                                        onChange={(e) =>
                                          handlePackageChange(
                                            index,
                                            'dim1',
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span className="icon is-small is-left">
                                        <i className="fas fa-ruler"></i>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="field is-horizontal"
                                style={{
                                  padding: '0px',
                                }}
                              >
                                <div
                                  className="field-label is-normal"
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <label className="label">
                                    Dimensão 2 [CM]
                                  </label>
                                </div>
                                <div
                                  className="field-body"
                                  style={{
                                    maxWidth: '70%',
                                  }}
                                >
                                  <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                      <input
                                        className="input"
                                        type="number"
                                        step={'0.01'}
                                        value={pkg.dim2}
                                        onChange={(e) =>
                                          handlePackageChange(
                                            index,
                                            'dim2',
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span className="icon is-small is-left">
                                        <i className="fas fa-ruler"></i>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="field is-horizontal"
                                style={{
                                  padding: '0px',
                                }}
                              >
                                <div
                                  className="field-label is-normal"
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <label className="label">
                                    Dimensão 3 [CM]
                                  </label>
                                </div>
                                <div
                                  className="field-body"
                                  style={{
                                    maxWidth: '70%',
                                  }}
                                >
                                  <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                      <input
                                        className="input"
                                        type="number"
                                        step={'0.01'}
                                        value={pkg.dim3}
                                        onChange={(e) =>
                                          handlePackageChange(
                                            index,
                                            'dim3',
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span className="icon is-small is-left">
                                        <i className="fas fa-ruler"></i>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="field is-horizontal"
                                style={{
                                  padding: '0px',
                                }}
                              >
                                <div
                                  className="field-label is-normal"
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <label className="label">
                                    Dimensões [CM]
                                  </label>
                                </div>
                                <div
                                  className="field-body"
                                  style={{
                                    maxWidth: '70%',
                                  }}
                                >
                                  <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                      <input
                                        className="input"
                                        type="text"
                                        value={`(${pkg.dim1} x ${pkg.dim2} x ${pkg.dim3}) CM`}
                                        disabled
                                      />
                                      <span className="icon is-small is-left">
                                        <i className="fas fa-ruler-combined"></i>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="field is-horizontal"
                                style={{
                                  padding: '0px',
                                }}
                              >
                                <div
                                  className="field-label is-normal"
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <label className="label">Peso [KG]</label>
                                </div>
                                <div
                                  className="field-body"
                                  style={{
                                    maxWidth: '70%',
                                  }}
                                >
                                  <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                      <input
                                        className="input"
                                        type="number"
                                        step={'0.01'}
                                        value={pkg.peso}
                                        onChange={(e) =>
                                          handlePackageChange(
                                            index,
                                            'peso',
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span className="icon is-small is-left">
                                        <i className="fas fa-scale-balanced"></i>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="field is-horizontal"
                                style={{
                                  padding: '0px',
                                }}
                              >
                                <div
                                  className="field-label is-normal"
                                  style={{
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <label className="label">
                                    Valor do pacote [R$]
                                  </label>
                                </div>
                                <div
                                  className="field-body"
                                  style={{
                                    maxWidth: '70%',
                                  }}
                                >
                                  <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                      <input
                                        className="input"
                                        type="number"
                                        step={'0.01'}
                                        value={pkg.valor}
                                        onChange={(e) =>
                                          handlePackageChange(
                                            index,
                                            'valor',
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span className="icon is-small is-left">
                                        <i className="fas fa-money-bill"></i>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div
                    className="container"
                    style={{
                      display: 'flex',
                      marginTop: '10px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <button className="button is-primary" type="submit">
                        {/* <span className="icon"><i className="fa-solid fa-add"></i></span> */}
                        <span style={{ fontSize: '16px' }}>Cadastrar</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default FreteForm;
