import React, { useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SolicitacaoRCFormA from './SolicitacaoRCFormA';
import SolicitacaoRCFormV from './SolicitacaoRCFormV';

const SolicitacaoRCHomePage = () => {
  const [tipoRC, setTipoRC] = useState('');
  const [formVisivel, setFormVisivel] = useState(false);
  const navigate = useNavigate();

  const handleTipoChange = (e) => {
    setTipoRC(e.target.value);
    setFormVisivel(true);
  };

  return (
    <>
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    {/* <li>Setores</li> */}
                    <li>Engenharia</li>
                    <li>Requisição de compra</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Requisição de compra</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <label></label>
                    <div className="search-pdm">
                      <div style={{ display: 'flex' }}>
                        <div
                          className="div-select-search"
                          style={{
                            paddingRight: '15px',
                            width: '400px',
                          }}
                        >
                          <label className="label">
                            Tipo de requisição de compra
                          </label>

                          <div className="field-body">
                            <div
                              className="field is-narrow"
                              style={{
                                padding: '0px',
                              }}
                            >
                              <div className="control has-icons-left">
                                <div className="select is-fullwidth">
                                  <select
                                    value={tipoRC}
                                    className="custom-select-style"
                                    onChange={handleTipoChange}
                                  >
                                    <option value="rca">Avulsa</option>
                                    <option value="rcv">Vinculada</option>
                                  </select>
                                  <span className="icon is-small is-left">
                                    <i className="fa-solid fa-user-tie"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="section is-main-section"> */}

          <div>
            {tipoRC === 'rcv' && <SolicitacaoRCFormV />}

            {(tipoRC === 'rca' || tipoRC === '') && <SolicitacaoRCFormA />}
          </div>

          {/* </section> */}
        </div>
      </MainLayout>
    </>
  );
};

export default SolicitacaoRCHomePage;
