import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ListaMateriaisAvulsaDupe from './ListaMateriaisAvulsaDupe';
import ListaMateriaisAvulsaShare from './ListaMateriaisAvulsaShare';
import FormModal from '../../../layouts/FormModal';
import AlertMessage from '../../../layouts/AlertMessage';

const ListaMateriaisAvulsaView = ({ listaId, info }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [listaData, setListaData] = useState(null);
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const [isInEditMode, setInEditMode] = useState(false);

  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const headers = {
    'Content-Type': 'application/json',
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const handleOpenModal = (action) => {
    setModalContent(action);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setModalOpen(false);
  };

  const renderModalContent = (info) => {
    switch (modalContent) {
      case 'duplicar':
        return <ListaMateriaisAvulsaDupe info={info} listaId={listaId} />;
      case 'compartilhar':
        return <ListaMateriaisAvulsaShare info={info} listaId={listaId} />;
      default:
        return null;
    }
  };

  const readLista = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/listas/lmid/`,
        {
          params: { lista_id: listaId },
          headers,
        }
      );
      const data = await response.data;
      if (response.status === 200) {
        setListaData(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar lista'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleEdit = () => {
    navigate(`/lista-materiais/edit/${listaId}`);
  };

  useEffect(() => {
    readLista();
  }, []);

  return (
    <>
      <section className="main-content">
        <div className="container">
          <div className="card" style={{ backgroundColor: 'transparent' }}>
            <div className="card-content">
              {listaData && (
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label" style={{ color: 'white' }}>
                      Título
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          type="text"
                          placeholder=""
                          value={listaData.lista.titulo}
                          className="input"
                          disabled
                        />
                        <span className="icon is-small is-left">
                          <i className="fa-solid fa-heading"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {listaData && (
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label" style={{ color: 'white' }}>
                      Custo
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          type="text"
                          placeholder=""
                          value={formatPreco(listaData.lista.custo)}
                          className="input"
                          disabled
                        />
                        <span className="icon is-small is-left">
                          <i className="fa-solid fa-money-bill"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {listaData && (
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label" style={{ color: 'white' }}>
                      RC solicitada?
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <label className="radio" style={{ color: 'white' }}>
                          <input
                            type="radio"
                            name="statusRC"
                            InputProps={{
                              readOnly: true,
                            }}
                            checked={
                              listaData.lista.src_ref !== '' &&
                              listaData.lista.src_ref !== null
                            }
                          />
                          &nbsp;SIM
                        </label>
                        <label className="radio" style={{ color: 'white' }}>
                          <input
                            type="radio"
                            className="radio"
                            InputProps={{
                              readOnly: true,
                            }}
                            checked={
                              !(
                                listaData.lista.src_ref !== '' &&
                                listaData.lista.src_ref !== null
                              )
                            }
                          />{' '}
                          NÃO
                        </label>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="divider is-size-5">Itens</div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <TableContainer
                style={{
                  width: 'calc(100% - 40px)',
                  marginBottom: '20px',
                }}
              >
                <Table style={{ border: '1px solid transparent' }}>
                  <TableHead
                    style={{
                      backgroundColor: 'rgb(33, 37, 41)',
                    }}
                  >
                    <TableRow
                      style={{
                        display: 'flex',
                        width: '100%',
                        backgroundColor: '#2e323a',
                        borderTopLeftRadius: '7px',
                        borderTopRightRadius: '7px',
                      }}
                    >
                      <TableCell
                        style={{
                          flex: '7',
                          fontSize: '15px',
                          color: '#ffffff',
                          fontWeight: 'bold',
                          fontFamily: '"Nunito", sans-serif',
                        }}
                      >
                        ITEM
                      </TableCell>
                      <TableCell
                        style={{
                          flex: '1',
                          fontSize: '15px',
                          color: '#ffffff',
                          fontWeight: 'bold',
                          fontFamily: '"Nunito", sans-serif',
                          textAlign: 'center',
                        }}
                      >
                        UND.
                      </TableCell>
                      <TableCell
                        style={{
                          flex: '1',
                          fontSize: '15px',
                          color: '#ffffff',
                          fontWeight: 'bold',
                          fontFamily: '"Nunito", sans-serif',
                          textAlign: 'center',
                        }}
                      >
                        QTD.
                      </TableCell>
                      <TableCell
                        style={{
                          flex: '1',
                          fontSize: '15px',
                          color: '#ffffff',
                          fontWeight: 'bold',
                          fontFamily: '"Nunito", sans-serif',
                          textAlign: 'center',
                        }}
                      >
                        PREÇO
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listaData &&
                      listaData.items.map((item, idx) => (
                        <TableRow
                          key={idx}
                          style={{
                            display: 'flex',
                            width: '100%',
                            backgroundColor: '#f7f7f7',
                          }}
                        >
                          <TableCell
                            style={{
                              flex: '7',
                              fontSize: '13px',
                              fontWeight: '400',
                              border: '0px',
                              fontFamily: '"Nunito", sans-serif',
                              textAlign: 'left',
                            }}
                          >
                            {item.descl}
                          </TableCell>
                          <TableCell
                            style={{
                              flex: '1',
                              fontSize: '13px',
                              fontWeight: '400',
                              border: '0px',
                              fontFamily: '"Nunito", sans-serif',
                              textAlign: 'center',
                            }}
                          >
                            {item.unidade}
                          </TableCell>
                          <TableCell
                            style={{
                              flex: '1',
                              fontSize: '13px',
                              fontWeight: '400',
                              border: '0px',
                              fontFamily: '"Nunito", sans-serif',
                              textAlign: 'center',
                            }}
                          >
                            {item.quantidade}
                          </TableCell>
                          <TableCell
                            style={{
                              flex: '1',
                              fontSize: '13px',
                              fontWeight: '400',
                              border: '0px',
                              fontFamily: '"Nunito", sans-serif',
                              textAlign: 'center',
                            }}
                          >
                            {formatPreco(item.preco)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <footer className="card-footer">
              <button
                className="button card-footer-item"
                disabled={listaData && listaData.lista.src_ref !== null}
                onClick={handleEdit}
              >
                Editar
              </button>
              <button
                className="button card-footer-item"
                onClick={() => handleOpenModal('duplicar')}
              >
                Duplicar
              </button>
              <button
                className="button card-footer-item"
                onClick={() => handleOpenModal('compartilhar')}
              >
                Compartilhar
              </button>
            </footer>
          </div>
        </div>
      </section>
      <FormModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        modalTitle={modalContent ? modalContent.toUpperCase() : ''}
      >
        {renderModalContent(info)}
      </FormModal>
    </>
  );
};

export default ListaMateriaisAvulsaView;
