import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { experimentalStyled as styled } from '@mui/material/styles';

const Cadastros = () => {
  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#eeeff573',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    fontSize: '18px',
    textAlign: 'center',
    // color: theme.palette.text.secondary,
    color: '#171717',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#2e323a', // Cor de fundo ao passar o mouse
      color: 'white',
    },
    // backgroundColor: '#2e323a',
    // color: 'white',
    // cursor: 'pointer',
    // '&:hover': {
    //     backgroundColor: '#fff', // Cor de fundo ao passar o mouse
    //     color: 'hsl(0, 0%, 21%)',
    // },
  }));

  return (
    <>
      <MainLayout>
        <section className="section is-title-bar">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <ul>
                  <li>Geral</li>
                  <li>Cadastros</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="hero is-hero-bar">
          <div className="hero-body">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h1 className="title">Cadastros</h1>
                </div>
              </div>
              <div className="level-right" style={{ display: 'none' }}>
                <div className="level-item"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-main-section">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-ballot"></i>
                </span>
                Selecione o cadastro que deseja fazer
              </p>
            </header>
            <div className="card-content">
              <div className="content">
                <Box
                  sx={{
                    flexGrow: 1,
                    padding: '15px 0px 40px',
                  }}
                >
                  <Grid
                    container
                    spacing={{ xs: 4, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid item xs={2} sm={4} md={4} key={1}>
                      <Item onClick={() => navigate('/adm/empreendimentos')}>
                        Empreendimento
                      </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4} key={2}>
                      <Item onClick={() => navigate('/clientes/registrar')}>
                        Cliente
                      </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4} key={3}>
                      <Item onClick={() => navigate('/fretes/form')}>
                        Frete
                      </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4} key={4}>
                      <Item onClick={() => navigate('/fornecedores/registrar')}>
                        Fornecedor
                      </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4} key={5}>
                      <Item onClick={() => navigate('/meic/mis')}>MIS</Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4} key={6}>
                      <Item onClick={() => navigate('/meic/composicoes/geo')}>
                        Composição
                      </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4} key={7}>
                      <Item onClick={() => navigate('/meic/mo')}>
                        Mão de obra
                      </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4} key={8}>
                      <Item onClick={() => navigate('/meic/materiais')}>
                        Materiais
                      </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4} key={9}>
                      <Item onClick={() => navigate('/meic/equipamentos')}>
                        Equipamentos
                      </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4} key={10}>
                      <Item onClick={() => navigate('/srcv')}>
                        Requisição de compra vinculada
                      </Item>
                    </Grid>
                    <Grid item xs={2} sm={4} md={4} key={11}>
                      <Item onClick={() => navigate('/srca')}>
                        Requisição de compra avulsa
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
                {/* <ul style={{fontSize: '20px'}}>
                                <li><Link to="/adm/empreendimentos">Empreendimento</Link></li>
                                <li><Link to="/clientes/registrar">Cliente</Link></li>
                                <li><Link to="/fretes/form">Frete</Link></li>
                                <li><Link to="/fornecedores/registrar">Fornecedor</Link></li>
                                <li><Link to="/meic/mis">MIS</Link></li>
                                <li><Link to="/meic/composicoes/geo">Composição</Link></li>
                                <li><Link to="/meic/mo">Mão de obra</Link></li>
                                <li><Link to="/meic/materiais">Materiais</Link></li>
                                <li><Link to="/meic/equipamentos">Equipamentos</Link></li>
                                <li><Link to="/srcv">Requisição de compra vinculada</Link></li>
                                <li><Link to="/srca">Requisição de compra avulsa</Link></li>
                            </ul> */}
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
};

export default Cadastros;
