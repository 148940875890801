import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { ButtonGroup } from '@mui/material';
import AlertMessage from '../../layouts/AlertMessage';

const AdminRoleEdit = ({ roleId, info }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [role, setRole] = useState({});
  const [initialPoliticas, setInitialPoliticas] = useState([]);
  const [newPoliticas, setNewPoliticas] = useState([]);
  const [allResources, setAllResources] = useState([]);
  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const headers = {
    'Content-Type': 'application/json',
  };

  const getRole = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/roles/q/`,
        {
          params: { role_id: roleId },
          headers,
        }
      );
      const data = await response.data;
      if (response.status === 200) {
        setRole(data);
        const politics = data.politicas.split(' ');

        const politicasObjects = await Promise.all(
          politics.map(async (politica) => {
            const [resourceFunction, efeito] = politica.split(':');
            const [recurso, acao] = resourceFunction.split('|');
            const availableAcoes = await getAvailableAcoes(recurso);
            return { recurso, efeito, acao, availableAcoes };
          })
        );
        setInitialPoliticas(politicasObjects);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar detalhes da Role.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getAvailableAcoes = async (resource) => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/acoes/p/`,
        {
          params: { prefix: resource },
          headers,
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error.response.data.detail);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar ações.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
      return [];
    }
  };

  const getAcoesEResources = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/acoes/`,
        { headers }
      );
      const data = await response.data;
      if (response.status === 200) {
        const uniqueresources = Array.from(
          new Set(data.map((item) => item.prefix))
        );
        setAllResources(uniqueresources);
      }
    } catch (error) {
      console.error(error);
      // alert(error.response.data.detail)
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar recursos.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleChangeRoleDescricao = (val) => {
    setRole({ ...role, descricao: val });
  };

  const addPolitica = () => {
    setNewPoliticas([
      ...newPoliticas,
      { recurso: '', efeito: '', acao: '', availableAcoes: [] },
    ]);
  };

  const removePolitica = (index, isInitial) => {
    if (isInitial) {
      const newPoliticas = initialPoliticas.filter((_, idx) => idx != index);
      setInitialPoliticas(newPoliticas);
    } else {
      const updatedNewPoliticas = newPoliticas.filter(
        (_, idx) => idx !== index
      );
      setNewPoliticas(updatedNewPoliticas);
    }
  };

  const handlePoliticaChange = async (index, field, value, isInitial) => {
    if (isInitial) {
      const updatedRows = [...initialPoliticas];
      updatedRows[index] = { ...updatedRows[index], [field]: value };
      setInitialPoliticas(updatedRows);
    } else {
      const updatedRows = [...newPoliticas];
      updatedRows[index] = { ...updatedRows[index], [field]: value };
      setNewPoliticas(updatedRows);
      if (field === 'recurso') {
        const updatedRow = { ...updatedRows[index], recurso: value };
        updatedRows[index] = updatedRow;
        setNewPoliticas(updatedRows);

        const availableAcoes = await getAvailableAcoes(value);
        updatedRow.availableAcoes = availableAcoes;
        setNewPoliticas([
          ...updatedRows.slice(0, index),
          updatedRow,
          ...updatedRows.slice(index + 1),
        ]);
      }
    }
  };

  const putRole = async (e) => {
    e.preventDefault();
    const allPoliticas = [...initialPoliticas, ...newPoliticas];

    const filteredPoliticas = allPoliticas.filter(
      (politica) =>
        politica.recurso !== '' &&
        politica.acao !== '' &&
        politica.efeito !== ''
    );

    const politicasStrings = filteredPoliticas.map((politica) => {
      return `${politica.recurso}|${politica.acao}:${politica.efeito}`;
    });

    const updatedRole = {
      ...role,
      politicas: politicasStrings.join(' '),
    };

    try {
      const response = await axios.put(
        `${REACT_APP_BASE_API_URL}/admin/roles/`,
        updatedRole,
        { headers }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAlertMessage('Role atualizada com sucesso.');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao atualizar Role.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const deleteRole = async () => {
    try {
      const response = await axios.delete(
        `${REACT_APP_BASE_API_URL}/admin/roles/`,
        {
          params: { role_id: role.id },
          headers,
        }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAlertMessage('Role excluída com sucesso.');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao excluir Role.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getRole();
    getAcoesEResources();
  }, [roleId]);

  return (
    <>
      <div className="container" style={{ width: '900px' }}>
        <form name="updateRole" className="modal-form" onSubmit={putRole}>
          <div className="field-modal">
            <label className="label-modal">Nome</label>
            <input
              className="input-modal"
              style={{ color: '#7c8187' }}
              type="text"
              value={role.nome}
              disabled
            />
          </div>

          <div className="field-modal">
            <label className="label-modal">
              Descrição
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
            </label>
            <input
              className="input-modal"
              type="text"
              required
              value={role.descricao}
              onChange={(e) => handleChangeRoleDescricao(e.target.value)}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <table
              className="table is-fullwidth"
              style={{
                padding: '20px 20px',
                borderRadius: '5px',
                width: 'calc(100% - 40px)',
              }}
            >
              <thead>
                <tr key={'thead'}>
                  <th
                    style={{
                      borderBottom: '1px solid gray',
                    }}
                  >
                    Recurso
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid gray',
                    }}
                  >
                    Efeito
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid gray',
                    }}
                  >
                    Ações
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid gray',
                    }}
                  >
                    Excluir
                  </th>
                </tr>
              </thead>
              <tbody>
                {initialPoliticas &&
                  initialPoliticas.map((politica, idx) => (
                    <tr key={idx}>
                      <td>
                        <select value={politica.recurso} disabled>
                          <option value={''}>Selecione</option>
                          {allResources.map((resource) => (
                            <option key={resource} value={resource}>
                              {resource}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <select value={politica.efeito} disabled>
                          <option value="">Selecione</option>
                          <option value="allow">Permitir</option>
                          <option value="deny">Negar</option>
                        </select>
                      </td>
                      <td>
                        <select value={politica.acao} disabled>
                          <option value={''}>Selecione</option>
                          {(politica.availableAcoes || []).map((acao) => (
                            <option key={acao.id} value={acao.funcao}>
                              {acao.funcao}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td style={{ paddingLeft: '24px' }}>
                        <button
                          type="button"
                          className="button"
                          style={{
                            color: 'white',
                            backgroundColor: 'hsl(348deg 100% 50%)',
                            fontWeight: '600',
                            height: '27px',
                            fontWeight: 'bold',
                            padding: '0px 8px 2px',
                          }}
                          onClick={() => removePolitica(idx, true)}
                        >
                          x
                        </button>
                      </td>
                    </tr>
                  ))}
                {newPoliticas &&
                  newPoliticas.map((politica, idx) => (
                    <tr key={idx + initialPoliticas.length}>
                      <td>
                        <select
                          value={politica.recurso}
                          onChange={(e) =>
                            handlePoliticaChange(
                              idx,
                              'recurso',
                              e.target.value,
                              false
                            )
                          }
                        >
                          <option value="">Selecione</option>
                          {allResources.map((resource) => (
                            <option key={resource} value={resource}>
                              {resource}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <select
                          value={politica.efeito}
                          onChange={(e) =>
                            handlePoliticaChange(
                              idx,
                              'efeito',
                              e.target.value,
                              false
                            )
                          }
                        >
                          <option value="">Selecione</option>
                          <option value="allow">Permitir</option>
                          <option value="deny">Negar</option>
                        </select>
                      </td>
                      <td>
                        <select
                          value={politica.acao}
                          onChange={(e) =>
                            handlePoliticaChange(
                              idx,
                              'acao',
                              e.target.value,
                              false
                            )
                          }
                        >
                          <option value="">Selecione</option>
                          {(politica.availableAcoes || []).map((acao) => (
                            <option key={acao.id} value={acao.funcao}>
                              {acao.funcao}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td style={{ paddingLeft: '27px' }}>
                        <button
                          type="button"
                          className="button is-primary"
                          style={{
                            color: 'white',
                            backgroundColor: 'hsl(348deg 100% 50%)',
                            fontWeight: '600',
                            height: '27px',
                            fontWeight: 'bold',
                            padding: '0px 8px 2px',
                          }}
                          onClick={() => removePolitica(idx, false)}
                        >
                          x
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <hr></hr>
          <div
            style={{
              display: 'flex',
              margin: '20px 20px 0px',
              justifyContent: 'space-between',
            }}
          >
            <ButtonGroup aria-label="outlined is-primary button group">
              <Button
                style={{
                  color: '#2B3BED',
                  backgroundColor: 'white',
                  padding: '5px 30px',
                }}
                onClick={addPolitica}
                type="button"
              >
                Nova Política
              </Button>

              <Button
                style={{
                  color: '#2B3BED',
                  backgroundColor: 'white',
                  padding: '5px 30px',
                }}
                type="submit"
              >
                Atualizar
              </Button>
            </ButtonGroup>
            <button
              type="button"
              className="button is-danger"
              style={{
                backgroundColor: 'hsl(348deg 100% 50%)',
                fontWeight: '600',
              }}
              onClick={deleteRole}
            >
              Excluir
            </button>
          </div>
        </form>
        <br />
      </div>
    </>
  );
};

export default AdminRoleEdit;
