import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import AlertMessage from '../../layouts/AlertMessage';

const VisualizarSolicitacaoRC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;
  const { tarefa, req_comp, route } = location.state;

  const [status, setStatus] = useState(tarefa.status);
  const [respostaSolicitante, setRespostaSolicitante] = useState(
    req_comp.observacoes
  );
  const [lista, setLista] = useState([]);
  const [items, setItems] = useState([]);
  const [resumoLista, setResumoLista] = useState(null);
  const [reload, setReload] = useState(false);
  const [resumoMatsServ, setResumoMatsServ] = useState(null);
  const [idSap, setIdSap] = useState(req_comp.sap_rc);
  const [itemsOk, setItemsOk] = useState(null);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reloadWindow, setReloadWindow] = useState(false);

  const statusMiddleList = ['ANDAMENTO', 'PENDENTE'];

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const handleFlux = async (status_val) => {
    const mensagem = `
    A sua solicitação requisição de compra ${req_comp.titulo} foi atualizada para ${status_val}
    
    Detalhes: ${respostaSolicitante}
    `;
    let schema = {
      comentario: mensagem,
      tarefa_id: tarefa.id,
      email: tarefa.email_relator,
    };
    if (statusMiddleList.includes(status_val)) {
      try {
        const middleResponse = await axios.put(
          `${REACT_APP_BASE_API_URL}/solicitacao-rc/status/`,
          {},
          {
            params: { src_id: req_comp.id, status: status_val },
          }
        );
        if (middleResponse.status === 200) {
          const response = await axios.put(
            `${REACT_APP_BASE_API_URL}/tarefas/status/`,
            schema,
            {
              params: { status: status_val },
              headers,
            }
          );
          if (response.status === 200) {
            setAlertMessage(
              'O status da solicitação foi alterado com sucesso.'
            );
            setAlertSeverity('success');
            setAlertOpen(true);
            setNavigateTo('/plan/fila-tarefas-rc/');
            setStatus(status_val);
          }
        }
      } catch (error) {
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail ||
                'Erro ao atualizar status da solicitação'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
        console.error(error);
      }
    } else if (status_val === 'RECUSADO') {
      if (respostaSolicitante === '') {
        setAlertMessage('É necessário explicar o motivo da recusa.');
        setAlertSeverity('error');
        setAlertOpen(true);
        return;
      }
      try {
        const refuseResponse = await axios.put(
          `${REACT_APP_BASE_API_URL}/solicitacao-rc/liberar/`,
          {},
          {
            params: {
              src_id: req_comp.id,
              justificativa: respostaSolicitante,
            },
          }
        );
        if (refuseResponse.status === 200) {
          try {
            const refuseStatusResponse = await axios.put(
              `${REACT_APP_BASE_API_URL}/tarefas/done/`,
              schema,
              {
                params: { status: status_val },
                headers,
              }
            );
            if (refuseStatusResponse.status === 200) {
              setAlertMessage('Solicitação recusada com sucesso.');
              setAlertSeverity('success');
              setAlertOpen(true);
              setStatus(status_val);
              setNavigateTo('/plan/fila-tarefas-rc/');
            }
          } catch (error) {
            console.error(error);
            setAlertMessage(
              Array.isArray(error.response?.data?.detail)
                ? JSON.stringify(error.response?.data?.detail, null, 2)
                : error.response?.data?.detail || 'Erro ao recusar solicitação'
            );
            setAlertSeverity('error');
            setAlertOpen(true);
          }
        }
      } catch (error) {
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao recusar solicitação'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
        console.error(error);
      }
    } else if (status_val === 'CONCLUIDO') {
      if ((idSap === '') | (idSap === null) | (idSap === 'null')) {
        setAlertMessage(
          'É necessário informar o SAP ID para concluir essa solicitação.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
        return;
      }
      const updatedReqComp = {
        ...req_comp,
        sap_rc: idSap,
      };
      try {
        const cadResponse = await axios.put(
          `${REACT_APP_BASE_API_URL}/solicitacao-rc/sap/`,
          {},
          {
            params: { rc_id: req_comp.id, sap_rc: idSap },
            headers,
          }
        );
        if (cadResponse.status === 200) {
          try {
            const cadStatusResponse = await axios.put(
              `${REACT_APP_BASE_API_URL}/tarefas/done/`,
              schema,
              {
                params: { status: status_val },
                headers,
              }
            );
            if (cadStatusResponse.status === 200) {
              setAlertMessage('Solicitação concluida com sucesso.');
              setAlertSeverity('success');
              setAlertOpen(true);
              setStatus(status_val);
              setNavigateTo('/plan/fila-tarefas-rc/');
            }
          } catch (error) {
            console.error(error);
            setAlertMessage(
              Array.isArray(error.response?.data?.detail)
                ? JSON.stringify(error.response?.data?.detail, null, 2)
                : error.response?.data?.detail || 'Erro ao concluir solicitação'
            );
            setAlertSeverity('error');
            setAlertOpen(true);
          }
        }
      } catch (error) {
        console.error(error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao concluir solicitação'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    }
  };

  const handleUpdateItems = async () => {
    let itemsObj = items.map((item) => ({
      id: item.lm_item_id,
      lm_id: req_comp.lista_materiais,
      capex_linha_ref: item.capex_linha_ref,
      capex_linha_valor_ref: item.capex_linha_valor_ref,
      item_id: item.item_id,
      sap_id: item.sap_id,
      quantidade: item.quantidade,
      preco: item.preco,
      preco_cad: item.preco_cad,
      vinculada: item.vinculada,
    }));

    let objRequest = {
      items: itemsObj,
    };

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/solicitacao-rc/src-a/items/`,
        objRequest,
        {
          params: { srca_id: req_comp.id },
          headers,
        }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAlertMessage(data);
        setAlertSeverity('success');
        setAlertOpen(true);

        setReload(!reload);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao atualizar itens'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    const getListaMateriais = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/listas/lmid/`,
          {
            params: {
              lista_id: req_comp.lista_materiais,
            },
            headers: headers,
          }
        );

        if (response.data) {
          setLista(response.data.lista);
          setItems(response.data.items);

          const capexSumDict = response.data.items.reduce((acc, item) => {
            if (acc[item.capex_linha_ref]) {
              acc[item.capex_linha_ref] += item.preco * item.quantidade;
            } else {
              acc[item.capex_linha_ref] = item.preco * item.quantidade;
            }
            return acc;
          }, {});

          const capexSum = Object.keys(capexSumDict).map((key) => ({
            capex_ref: key,
            total_preco: capexSumDict[key],
          }));

          setResumoLista(capexSum);

          const resumoMats = response.data.items.reduce(
            (acc, item) => {
              if (
                item.schema === 'material' ||
                item.schema === 'equip' ||
                (item.schema === 'sinapi' && item.origem === 'insumo')
              ) {
                acc.material += item.preco * item.quantidade;
              } else {
                acc.servico += item.preco * item.quantidade;
              }
              return acc;
            },
            { material: 0, servico: 0 }
          );

          const resumoSum = Object.keys(resumoMats).map((key) => ({
            tipo_ref: key,
            total_preco: resumoMats[key],
          }));
          setResumoMatsServ(resumoSum);
        }
      } catch (error) {
        console.error('Erro ao buscar lista de materiais:', error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail ||
                'Erro ao buscar lista de materiais'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };
    getListaMateriais();
  }, [REACT_APP_BASE_API_URL, reload]);

  const handleItemRefChange = (e, id, field) => {
    const value = e.target.value;
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const columns = [
    { field: 'sap_id', headerName: 'SAP ID', flex: 0.5 },
    {
      field: 'capex_linha_ref',
      headerName: 'REF',
      flex: 0.6,
      renderCell: (params) => (
        <input
          className="input"
          value={params.row.capex_linha_ref}
          onChange={(e) =>
            handleItemRefChange(e, params.row.id, 'capex_linha_ref')
          }
        />
      ),
    },
    {
      field: 'capex_linha_valor_ref',
      headerName: 'Valor REF',
      renderCell: (params) => (
        <input
          className="input"
          value={params.row.capex_linha_valor_ref}
          onChange={(e) =>
            handleItemRefChange(e, params.row.id, 'capex_linha_valor_ref')
          }
        />
      ),
    },
    { field: 'descl', headerName: 'Descrição L', flex: 3 },
    { field: 'unidade', headerName: 'UN', flex: 0.4 },
    { field: 'schema', headerName: 'Origem', flex: 0.8 },
    { field: 'quantidade', headerName: 'QTD', flex: 0.6 },
    {
      field: 'preco',
      headerName: 'Preço',
      editable: true,
      flex: 0.8,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>{formatPreco(params.row.preco)}</div>
      ),
    },
    {
      field: 'preco_total',
      headerName: 'Total',
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>
          {formatPreco(params.row.preco * params.row.quantidade)}
        </div>
      ),
    },
  ];

  useEffect(() => {
    return () => {
      if (reloadWindow) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reloadWindow) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    {/* <li>Setores</li> */}
                    <li>Planejamento</li>
                    <li>Fila de Tarefas</li>
                    <li>Analizar solicitação de RC</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Analizar solicitação de RC</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <div className="buttons is-right">
                      <div className="div-select-box-status">
                        <Select
                          style={{
                            height: '42px',
                            marginBottom: '8px',
                          }}
                          className="select-status"
                          value={status}
                          onChange={(e) => {
                            handleFlux(e.target.value);
                          }}
                        >
                          <MenuItem value={'PENDENTE'}>PENDENTE</MenuItem>
                          <MenuItem value={'ANDAMENTO'}>EM ANDAMENTO</MenuItem>
                          <MenuItem
                            disabled={req_comp.status !== 'ANDAMENTO'}
                            value={'RECUSADO'}
                          >
                            RECUSADO
                          </MenuItem>
                          <MenuItem
                            disabled={req_comp.status !== 'ANDAMENTO'}
                            value={'CONCLUIDO'}
                          >
                            CONCLUIDO
                          </MenuItem>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Detalhes da solicitação
                </p>
              </header>
              <div
                className="card-content"
                style={{
                  display: 'flex',
                  margin: '0px 0px 30px 0px',
                }}
              >
                <div className="atributos-form">
                  <div className="div-quadro-main-form">
                    <div className="div-quadro-material">
                      <div
                        className="field"
                        style={{
                          paddingBottom: '12px',
                          borderBottom: '1px solid #dbdbdb',
                        }}
                      >
                        <div className="row-field" style={{ fontSize: '20px' }}>
                          <label className="label-field">
                            {tarefa.nome_relator} criou esta solicitação
                          </label>
                        </div>
                      </div>

                      <div className="field">
                        <div className="row-field">
                          <label className="label-field">Tarefa ID</label>
                          <span className="cell-value">{tarefa.id}</span>
                        </div>
                      </div>

                      <div className="field">
                        <div className="row-field">
                          <label className="label-field">RC ID</label>
                          <span className="cell-value">{req_comp.id}</span>
                        </div>
                      </div>

                      <div className="field">
                        <div className="row-field">
                          <label className="label-field">Solicitante</label>
                          <span className="cell-value">
                            {req_comp.solicitante}
                          </span>
                        </div>
                      </div>

                      <div className="field">
                        <div className="row-field">
                          <label className="label-field">Responsável</label>
                          <span className="cell-value">
                            {req_comp.responsavel}
                          </span>
                        </div>
                      </div>

                      {req_comp.sap_rc && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">RC SAP</label>
                            <span className="cell-value">
                              {req_comp.sap_rc}
                            </span>
                          </div>
                        </div>
                      )}

                      {tarefa.titulo && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Resumo</label>
                            <span className="cell-value">{tarefa.titulo}</span>
                          </div>
                        </div>
                      )}

                      {req_comp.titulo && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Título</label>
                            <span className="cell-value">
                              {req_comp.titulo}
                            </span>
                          </div>
                        </div>
                      )}

                      {req_comp.projeto && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Projeto</label>
                            <span className="cell-value">
                              {req_comp.projeto}
                            </span>
                          </div>
                        </div>
                      )}

                      {req_comp.empreendimento && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Empreendimento
                            </label>
                            <span className="cell-value">
                              {req_comp.empreendimento}
                            </span>
                          </div>
                        </div>
                      )}

                      {req_comp.prioridade && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Prioridade</label>
                            <span className="cell-value">
                              {req_comp.prioridade}
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="field">
                        <div className="row-field">
                          <label className="label-field">Custo</label>
                          <span className="cell-value">
                            {formatPreco(req_comp.custo)}
                          </span>
                        </div>
                      </div>

                      {req_comp.tipo && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Tipo</label>
                            <span className="cell-value">{req_comp.tipo}</span>
                          </div>
                        </div>
                      )}

                      {req_comp.data_necessidade_obra && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Data necessidade obra
                            </label>
                            <span className="cell-value">
                              {new Date(
                                req_comp.data_necessidade_obra
                              ).toLocaleDateString()}
                            </span>
                          </div>
                        </div>
                      )}

                      {req_comp.orcamento && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Orçamento</label>
                            <span className="cell-value">
                              {req_comp.orcamento}
                            </span>
                          </div>
                        </div>
                      )}

                      {req_comp.local_entrega && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Local Entrega</label>
                            <span className="cell-value">
                              {req_comp.local_entrega}
                            </span>
                          </div>
                        </div>
                      )}

                      {req_comp.fornecedor_entrega && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Fornecedor entrega
                            </label>
                            <span className="cell-value">
                              {req_comp.fornecedor_entrega}
                            </span>
                          </div>
                        </div>
                      )}

                      {req_comp.doc_ref && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Documento referência
                            </label>
                            <span className="cell-value">
                              {req_comp.doc_ref}
                            </span>
                          </div>
                        </div>
                      )}

                      {req_comp.tipo_compra && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Tipo de compra
                            </label>
                            <span className="cell-value">
                              {req_comp.tipo_compra}
                            </span>
                          </div>
                        </div>
                      )}

                      {req_comp.fornecimento_exclusivo && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Fornecimento exclusivo
                            </label>
                            <span className="cell-value">
                              {req_comp.fornecimento_exclusivo}
                            </span>
                          </div>
                        </div>
                      )}

                      {req_comp.justificativa_fornecimento_exclusivo && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Justificativa fornecimento exclusivo
                            </label>
                            <span className="cell-value">
                              {req_comp.justificativa_fornecimento_exclusivo}
                            </span>
                          </div>
                        </div>
                      )}

                      {req_comp.frete_incluso && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Frete incluso</label>
                            <span className="cell-value">
                              {req_comp.frete_incluso}
                            </span>
                          </div>
                        </div>
                      )}

                      {req_comp.compra_pacote && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Compra por pacote
                            </label>
                            <span className="cell-value">
                              {req_comp.compra_pacote}
                            </span>
                          </div>
                        </div>
                      )}

                      {req_comp.proposta && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Link da proposta
                            </label>
                            <span className="cell-value">
                              <a href={req_comp.proposta}>
                                {' '}
                                {req_comp.proposta}
                              </a>
                            </span>
                          </div>
                        </div>
                      )}

                      {req_comp.observacoes && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Observações</label>
                            <span className="cell-value">
                              {req_comp.observacoes}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* ---------------------------------  Quadros laterais ---------------------------------*/}
                  <div className="div-quadros-secundarios">
                    {/* ----------------------- RESPONDER AO SOLICITANTE ----------------------- */}
                    <div className="div-quadro-SLAs">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">SAP</label>
                      </div>
                      <div className="div-quadro-content">
                        <div
                          className="row-field-message"
                          style={{ width: '100%' }}
                        >
                          <label
                            className="text"
                            style={{
                              color: 'hsl(0, 0%, 21%)',
                            }}
                          >
                            Informe o SAP ID:
                          </label>
                          <div
                            className="cell-value"
                            style={{
                              paddingLeft: '0px',
                              paddingTop: '15px',
                            }}
                          >
                            <input
                              className="input"
                              value={idSap}
                              onChange={(e) => setIdSap(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="div-quadro-SLAs">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">
                          Adicionar detalhes ao status
                        </label>
                      </div>
                      <div className="div-quadro-content">
                        <div
                          className="row-field-message"
                          style={{ width: '100%' }}
                        >
                          <label
                            className="text"
                            style={{
                              color: '#171717',
                            }}
                          >
                            Informe ao solicitante detalhes sobre o status da
                            tarefa:
                          </label>
                          <div
                            className="cell-value"
                            style={{
                              paddingLeft: '0px',
                              paddingTop: '15px',
                            }}
                          >
                            <textarea
                              rows="4"
                              className="input"
                              value={respostaSolicitante}
                              style={{
                                height: '100px',
                                backgroundColor: '#f9f9f9',
                              }}
                              onChange={(e) =>
                                setRespostaSolicitante(e.target.value)
                              }
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="div-quadro-SLAs">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">SLAs</label>
                      </div>
                      <div className="div-quadro-content">
                        <div className="row-field">
                          <label className="label-field">Criado em</label>
                          <span className="cell-value">
                            {new Date(tarefa.criado_em).toLocaleString()}
                          </span>
                        </div>

                        <div className="row-field">
                          <label className="label-field">
                            Tempo para resolução
                          </label>
                          <span className="cell-value">
                            {new Date(tarefa.data_due).toLocaleString()}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="div-quadro-informacoes">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">
                          Informações
                        </label>
                      </div>
                      <div className="div-quadro-content">
                        <div className="row-field">
                          <label className="label-field">
                            Responsável Planejamento
                          </label>
                          <span className="cell-value">
                            {tarefa.responsavel}
                          </span>
                        </div>

                        <div className="row-field">
                          <label className="label-field">Relator</label>
                          <span className="cell-value">
                            {tarefa.nome_relator} {tarefa.sobrenome_relator}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="div-quadro-informacoes">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">
                          Resumo de custo das linhas
                        </label>
                      </div>
                      <div className="div-quadro-content">
                        <table className="table is-fullwidth">
                          <thead>
                            <tr key={'thead-resumo'}>
                              <th>REF</th>
                              <th>VALOR</th>
                            </tr>
                          </thead>
                          <tbody>
                            {resumoLista &&
                              resumoLista.map((ref, idx) => (
                                <tr key={`res${idx}`}>
                                  <td>{ref.capex_ref}</td>
                                  <td>{formatPreco(ref.total_preco)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="div-quadro-informacoes">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">
                          Resumo de custo por tipo
                        </label>
                      </div>
                      <div className="div-quadro-content">
                        <table className="table is-fullwidth">
                          <thead>
                            <tr key={'thead-resumo'}>
                              <th>TIPO</th>
                              <th>VALOR</th>
                            </tr>
                          </thead>
                          <tbody>
                            {resumoMatsServ &&
                              resumoMatsServ.map((ref, idx) => (
                                <tr key={`res${idx}`}>
                                  <td>{ref.tipo_ref.toUpperCase()}</td>
                                  <td>{formatPreco(ref.total_preco)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ----------------------- INFORMAÇÕES DOS ITEMS ----------------------- */}
            <div className="card" style={{ marginBottom: '24px' }}>
              <header className="card-header">
                <p className="card-header-title" style={{ width: '100%' }}>
                  <div>
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Informações do(s) item(s)
                  </div>
                </p>
              </header>
              <div className="card-content">
                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="FilaTarefas">
                      <div
                        style={{
                          height: 600,
                          width: '100%',
                        }}
                      >
                        <DataGrid
                          rows={items}
                          columns={columns}
                          pageSize={5}
                          disableSelectionOnClick
                          slots={{
                            toolbar: CustomToolbar,
                          }}
                          getRowHeight={() => 'auto'}
                          sx={{
                            fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
                            '& .MuiDataGrid-cell': {
                              display: 'flex',
                              alignItems: 'flex-start', // Alinha o conteúdo no topo
                              paddingTop: '4px', // Ajuste conforme necessário
                              paddingBottom: '4px',
                              border: '0px',
                              fontSize: '16px',
                            },
                            '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                              {
                                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                                fontSize: '16px',
                                color: '#171717',
                                lineHeight: '2.5',
                                borderLeft: '5px solid transparent',
                              },
                            '& .MuiDataGrid-virtualScroller': {
                              overflowX: 'hidden',
                            },
                            '& .MuiDataGrid-toolbarContainer': {
                              display: 'flex',
                              justifyContent: 'flex-end',
                            },
                            '& .MuiButton-root': {
                              width: '120px',
                              color: '#2db76d',
                              fontSize: '15px',
                              backgroundColor: 'white',
                              margin: '5px',
                              border: '1px solid #2db76d',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    margin: '20px 0px 0px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <button
                    className="button is-primary"
                    type="button"
                    onClick={handleUpdateItems}
                  >
                    CONFIRMAR
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default VisualizarSolicitacaoRC;
