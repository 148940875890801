import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import AlertMessage from '../../layouts/AlertMessage';
import { cepMask } from '../../functional/cepMask';
import axios from 'axios';

const FornecedoresFormInternacional = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [allTags, setAllTags] = useState([]);
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [email, setEmail] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState(0);
  const [bairro, setBairro] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [cep, setCep] = useState('');
  const [nif, setNif] = useState('');
  const [swift, setSwift] = useState('');
  const [iban, setIban] = useState('');
  const [nomeResponsavel, setNomeResponsavel] = useState('');
  const [cargoResponsavel, setCargoResponsavel] = useState('');
  const [telefone1, setTelefone1] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [pais, setPais] = useState('');
  const [tags, setTags] = useState([]);
  const [tagStr, setTagStr] = useState('');
  const [observacaoSolicitante, setObservacaoSolicitante] = useState('');
  const [centro, setCentro] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  const listaEstados = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  const getTags = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/tools/tag-fornecedores/`,
        { headers }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAllTags(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar as TAGs.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleTagChange = (e) => {
    const selected = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setTags(selected);
    setTagStr(selected.join(' '));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      id: null,
      razao_social: razaoSocial.toUpperCase(),
      nome_fantasia: nomeFantasia.toUpperCase(),
      email: email,
      observacao: observacaoSolicitante,
      centro: centro,
      logradouro: logradouro.toUpperCase(),
      numero: numero,
      bairro: bairro.toUpperCase(),
      complemento: complemento.toUpperCase(),
      cidade: cidade.toUpperCase(),
      estado: estado.toUpperCase(),
      pais: pais.toUpperCase(),
      cep: cep,
      cpf: null,
      cnpj: null,
      cnae: null,
      nif: nif,
      swift: swift,
      iban: iban,
      nome_responsavel: nomeResponsavel,
      cargo_responsavel: cargoResponsavel,
      telefone1: telefone1,
      telefone2: telefone2,
      tags: tagStr,
    };

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/fornecedor`,
        formData,
        { headers }
      );
      if (response.status === 201) {
        // alert("Solicitação de cadastro de fornecedor enviada.")
        setAlertMessage('Solicitação de cadastro de fornecedor enviada.');
        setAlertSeverity('success');
        setAlertOpen(true);
        setRazaoSocial('');
        setNomeFantasia('');
        setEmail('');
        setObservacaoSolicitante('');
        setCentro('');
        setNif('');
        setTelefone1('');
        setTelefone2('');
        setLogradouro('');
        setNumero(0);
        setBairro('');
        setComplemento('');
        setCidade('');
        setEstado('');
        setPais('');
        setCep('');

        setSwift('');
        setIban('');
        setNomeResponsavel('');
        setCargoResponsavel('');
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao cadastrar fornecedor.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    getTags();
  }, []);

  return (
    <>
      <AlertMessage
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Informações gerais
                </p>
              </header>
              <div className="card-content">
                <form name="cadastrarFornecedor" onSubmit={handleSubmit}>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Razão Social
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={razaoSocial}
                            onChange={(e) => setRazaoSocial(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-building"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Nome Fantasia
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={nomeFantasia}
                            onChange={(e) => setNomeFantasia(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-building"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Email Principal
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="email"
                            placeholder="exemplo@gmail.com"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-envelope"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        NIF
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder="XX000000000"
                            required
                            value={nif}
                            onChange={(e) => setNif(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-stamp"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Telefone principal
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder="+55 0XX 0000000"
                            required
                            value={telefone1}
                            onChange={(e) => setTelefone1(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-phone"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Telefone secundário</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder="+55 0XX 0000000"
                            value={telefone2}
                            onChange={(e) => setTelefone2(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-phone"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Empresa
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <select
                              name="centro"
                              value={centro}
                              required
                              onChange={(e) => setCentro(e.target.value)}
                            >
                              <option value="" disabled></option>
                              <option value={'5000 - GEO ELÉTRICA'}>
                                5000 - GEO ELÉTRICA
                              </option>
                              <option value={'5100 - GEO ENERGÉTICA'}>
                                5100 - GEO ENERGÉTICA
                              </option>
                              <option value={'5700 - CRI GEO'}>
                                5700 - CRI GEO
                              </option>
                            </select>
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Observação</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <textarea
                            rows="4"
                            className="input"
                            value={observacaoSolicitante}
                            style={{
                              height: '100px',
                            }}
                            onChange={(e) =>
                              setObservacaoSolicitante(e.target.value)
                            }
                          ></textarea>
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-comments"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        TAGs
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-multiple is-fullwidth">
                            <select
                              multiple
                              name="tags"
                              value={tags}
                              required
                              onChange={handleTagChange}
                              size={'4'}
                            >
                              <option value="" disabled></option>
                              {allTags &&
                                allTags.map((item) => (
                                  <option key={item.id} value={item.tag}>
                                    {item.tag.toUpperCase()}
                                  </option>
                                ))}
                            </select>
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-tag"></i>
                            </span>
                          </div>
                        </div>
                        <p
                          className="help"
                          style={{
                            fontSize: '0.80rem',
                            marginTop: '10px',
                          }}
                        >
                          <i>
                            Segure <strong>CTRL</strong> para selecionar mais de
                            uma TAG.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label"></label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            value={tagStr}
                            disabled
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-tag"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <header className="card-header">
                    <p
                      className="card-header-title"
                      style={{ paddingLeft: '0px' }}
                    >
                      <span className="icon">
                        <i className="mdi mdi-ballot"></i>
                      </span>
                      Endereço
                    </p>
                  </header>
                  <br />
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Logradouro
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={logradouro}
                            onChange={(e) => setLogradouro(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-house"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Complemento
                        {/* <span className="required-symbol"> *</span>  */}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            value={complemento}
                            onChange={(e) => setComplemento(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-house-chimney-medical"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Número
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="number"
                            placeholder=""
                            required
                            step={'1'}
                            value={numero}
                            onChange={(e) => setNumero(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-map-pin"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Bairro
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={bairro}
                            onChange={(e) => setBairro(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-location-dot"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Cidade
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={cidade}
                            onChange={(e) => setCidade(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-map-location-dot"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Estado
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={estado}
                            onChange={(e) => setEstado(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-city"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">CEP</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            value={cepMask(cep)}
                            onChange={(e) => setCep(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-city"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        País
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={pais}
                            onChange={(e) => setPais(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-globe"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <header className="card-header">
                    <p
                      className="card-header-title"
                      style={{ paddingLeft: '0px' }}
                    >
                      <span className="icon">
                        <i className="mdi mdi-ballot"></i>
                      </span>
                      Dados bancários
                    </p>
                  </header>
                  <br />

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">SWIFT</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder="XX000000000"
                            value={swift}
                            onChange={(e) => setSwift(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-stamp"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">IBAN</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            value={iban}
                            onChange={(e) => setIban(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-money-check-dollar"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      margin: '40px 0px 20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button className="button is-primary">Cadastrar</button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default FornecedoresFormInternacional;
