import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { CheckBox, Close, LinkSharp, Search } from '@mui/icons-material';
import AlertMessage from '../../layouts/AlertMessage';

const TabelaDocsEntregaveis = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const [filaDocEntregavel, setFilaDocEntregavel] = useState([]); // Estado para armazenar os dados da fila
  const [listaDocEntregavel, setListaDocEntregavel] = useState([]); // Estado para armazenar os dados da fila
  const [open, setOpen] = useState(false);
  const [editingRows, setEditingRows] = useState({}); // Object to track editing state by row ID
  const [editedPermalink, setEditedPermalink] = useState('');

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  useEffect(() => {
    const fetchFilaDocEntregavel = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/kanban/doc-entregavel-principal/`,
          {
            params: {},
            headers,
          }
        );

        if (response.data) {
          const docs = response.data.docs;

          const documentos = docs.map((docsE, index) => ({
            id: docsE.id,
            projeto: docsE.projeto_tag,
            disciplina: docsE.disciplina,
            tag: docsE.tag,
            nome_documento: docsE.nome_documento,
            permalink: docsE.permalink,
          }));

          setFilaDocEntregavel(documentos);
          setListaDocEntregavel(docs);
        }
      } catch (error) {
        console.error('Erro ao buscar fila de documentos entregaveis:', error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail ||
                'Erro ao buscar fila de documentos entregaveis.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    fetchFilaDocEntregavel();
  }, [REACT_APP_BASE_API_URL]);

  const handleSave = async (row_info) => {
    setEditingRows({ ...editingRows, [row_info.id]: false });

    try {
      const response = await axios.put(
        `${REACT_APP_BASE_API_URL}/kanban/doc-entregavel-principal/`,
        {},
        {
          params: {
            id: row_info.id,
            permalink: editedPermalink,
          },
          headers,
        }
      );

      if (response.status === 200) {
        const data = response.data;
        // alert("Permalink atualizado com sucesso!");
        setAlertMessage('Permalink atualizado com sucesso!');
        setAlertSeverity('success');
        setAlertOpen(true);
        setReload(true);
        // window.location.reload();
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao atualizar o permalink.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }

    setEditedPermalink(''); // Limpa o campo de texto após salvar
    setEditingRows({ ...editingRows, [row_info.id]: false }); // Desativa o modo de edição
  };

  const handleEditClick = (id, permalink) => {
    setEditingRows({ ...editingRows, [id]: true });
    if (permalink === null || permalink === '-') {
      permalink = '';
    }
    setEditedPermalink(permalink);
  };

  const columns = [
    // { field: 'id', headerName: 'ID', flex: 2},
    { field: 'projeto', headerName: 'Projeto', flex: 1 },
    { field: 'disciplina', headerName: 'Disciplina', flex: 1.5 },
    { field: 'tag', headerName: 'Tag', flex: 2 },
    { field: 'nome_documento', headerName: 'Nome documento', flex: 2 },
    {
      field: 'permalink',
      headerName: 'Permalink',
      flex: 1.5,
      renderCell: (params) => {
        const { id } = params.row;
        const isEditing = editingRows[id];

        return (
          <>
            {/* Se for um permalink !== de '-' ou nulo, deixe como se fosse um link */}
            {params.row.permalink !== '-' &&
            params.row.permalink !== null &&
            !isEditing ? (
              <a
                href={params.row.permalink}
                style={{
                  width: '100%',
                  wordBreak: 'break-all',
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {params.row.permalink}
              </a>
            ) : isEditing ? ( // Se estiver editando, exiba um campo de texto para editar o permalink
              <textarea
                value={editedPermalink}
                onChange={(e) => setEditedPermalink(e.target.value)}
                style={{
                  width: '100%',
                  wordBreak: 'break-all',
                  resize: 'none',
                }}
                onKeyDown={(event) => {
                  // Allow Ctrl A
                  if (event.ctrlKey && event.key === 'a') {
                    event.preventDefault();
                    event.target.select();
                    // Next shift with arrow keys
                  }

                  if (
                    event.key === 'ArrowLeft' ||
                    event.key === 'ArrowRight' ||
                    event.key === 'ArrowUp' ||
                    event.key === 'ArrowDown'
                  ) {
                    // Do nothing to allow default behavior
                    event.stopPropagation();
                  }
                }}
              />
            ) : (
              // Se não for um permalink, exiba o texto 'Não disponível'
              <p
                style={{
                  width: '100%',
                  wordBreak: 'break-all',
                }}
              >
                Não disponível
              </p>
            )}
            {params.row.permalink !== '-' &&
            params.row.permalink !== null &&
            !isEditing ? ( // Se for um permalink, exiba um botão para copiar o permalink
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(params.row.permalink);
                }}
                color="black"
              >
                <LinkSharp />
              </IconButton>
            ) : null}
            {!isEditing ? ( // Se não estiver editando, exiba um botão de editar
              <IconButton
                onClick={() => handleEditClick(id, params.row.permalink)}
                color="black"
              >
                <EditIcon />
              </IconButton>
            ) : (
              <>
                <IconButton
                  onClick={() => handleSave(params.row)}
                  color="black"
                >
                  <CheckBox />
                </IconButton>

                <IconButton
                  onClick={() =>
                    setEditingRows({
                      ...editingRows,
                      [id]: false,
                    })
                  }
                  color="black"
                >
                  <Close />
                </IconButton>
              </>
            )}
          </>
        );
      },
    },
    {
      field: 'acao',
      headerName: 'Versões',
      flex: 0.5,
      renderCell: (params) => (
        <div
          style={{
            width: '100%',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <IconButton
            onClick={() => {
              editDocEntregavel(params.row);
            }}
            color="black"
          >
            <Search />
          </IconButton>
        </div>
      ),
    },
  ];

  const editDocEntregavel = async (docEntregavel) => {
    // const docEntregavelObj = filaDocEntregavel.find(docEntregavel => docEntregavel.id === id_docEntregavel);

    // if(docEntregavelObj) {
    navigate('/doc-entregavel-versao', {
      state: { docEntregavel, route: '/doc-entregavel' },
    });
    // }
  };

  const handleCloseAlert = () => {
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleCloseAlert}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Planejamento</li>
                    <li>Documentos Entregáveis</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Documentos Entregáveis</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Lista dos Documentos Entregáveis
                </p>
              </header>
              <div className="card-content">
                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="FilaTarefas">
                      <div
                        style={{
                          height: 600,
                          width: '100%',
                        }}
                      >
                        <DataGrid
                          rows={filaDocEntregavel}
                          columns={columns}
                          pageSize={5}
                          disableSelectionOnClick
                          getRowHeight={() => 'auto'}
                          sx={{
                            fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
                            '& .MuiDataGrid-cell': {
                              display: 'flex',
                              alignItems: 'flex-start', // Alinha o conteúdo no topo
                              paddingTop: '4px', // Ajuste conforme necessário
                              paddingBottom: '4px',
                              border: '0px',
                              // lineHeight: '2.5',
                              fontSize: '16px',
                            },
                            '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                              {
                                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                                fontSize: '16px',
                                color: '#171717',
                                // fontFamily: '"Nunito", sans-serif',
                                lineHeight: '2.5',
                                borderLeft: '5px solid transparent',
                              },
                            '& .MuiDataGrid-virtualScroller': {
                              overflowX: 'hidden',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={!(filaDocEntregavel.length > 0)}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" size="5rem" />
                    </Backdrop>                  
                     */}
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default TabelaDocsEntregaveis;
