import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import AlertMessage from '../../layouts/AlertMessage';

const FreteHome = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const [allFretes, setAllFretes] = useState([]);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  const getAllFretes = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/frete/fretes`,
        { headers }
      );
      if (response.status == 200) {
        const data = await response.data;
        setAllFretes(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar fretes'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const inspectOrcamento = async (val) => {
    return navigate(`/ext/frete-orc/detail/${val}`);
  };

  useEffect(() => {
    getAllFretes();
  }, []);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Fretes</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button
                      className="button is-primary"
                      onClick={() => navigate('/fretes/form')}
                    >
                      Nova Solicitação
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
          <div className="container">
            <div
              className="grid box"
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                marginLeft: '30px',
              }}
            >
              {allFretes &&
                allFretes.map((item, i) => (
                  <div
                    className="card"
                    key={`${i}-card`}
                    style={{ margin: '0px 20px 20px 0px' }}
                  >
                    <header className="card-header">
                      <p className="card-header-title">
                        {item.frete.referencia}
                      </p>
                    </header>
                    <div className="card-content">
                      <p>
                        <strong>Origem:</strong> {item.frete.origem}
                      </p>
                      <p>
                        <strong>Destino:</strong> {item.frete.destino}
                      </p>
                      <p>
                        <strong>Tipo:</strong> {item.frete.tipo}
                      </p>
                      <br />
                      <table className="table is-striped is-fullwidth">
                        <thead>
                          <tr key={`card-${i}`}>
                            <th>FORNECEDOR</th>
                            <th>ORÇADO</th>
                            <th>AÇÃO</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.orcamentos &&
                            item.orcamentos.map((orc, orc_i) => (
                              <tr key={orc_i}>
                                <td>{orc.fornecedor}</td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={orc.orcado}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <div>
                                    <span
                                      className="icon"
                                      style={{
                                        margin: '0px 10px',
                                      }}
                                    >
                                      <i
                                        className="fa-solid fa-magnifying-glass"
                                        style={{
                                          cursor: 'pointer',
                                          fontSize: '20px',
                                          color: '#2B3BED',
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          inspectOrcamento(orc.id);
                                        }}
                                      ></i>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default FreteHome;
