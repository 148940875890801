import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AlertMessage from '../../layouts/AlertMessage';

const AdminUserGrupoManager = ({ userData, info }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [allGrupos, setAllGrupos] = useState([]);
  const [initialGrupos, setInitialGrupos] = useState([]);
  const [newGrupos, setNewGrupos] = useState([]);
  const [rows, setRows] = useState(
    userData.grupos.map((grupo) => ({
      id: grupo.grupo.id,
      nome: grupo.grupo.nome,
      descricao: grupo.grupo.descricao,
    }))
  );

  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const headers = {
    'Content-Type': 'application/json',
  };

  const getAllGrupos = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/grupos/com/`,
        { headers }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAllGrupos(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar grupos.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleGrupoChange = (idx, grupoId) => {
    const selectedGrupo = allGrupos.find((grupo) => grupo.id === grupoId);
    const updatedRows = rows.map((row, rowIdx) => {
      if (rowIdx === idx) {
        return {
          id: selectedGrupo.id,
          nome: selectedGrupo.nome,
          descricao: selectedGrupo.desc,
        };
      }
      return row;
    });
    setRows(updatedRows);
    if (!initialGrupos.includes(grupoId)) {
      setInitialGrupos([...initialGrupos, grupoId]);
    }
  };

  const putGrupos = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/users/grupos/`,
        {},
        {
          params: {
            user_id: userData.user.id,
            grupos: initialGrupos.join(' '),
          },
        }
      );
      const data = await response.data;
      if (response.status === 200) {
        // alert(`Grupos de ${userData.user.nome} atualizados.`)
        setAlertMessage(`Grupos de ${userData.user.nome} atualizados.`);
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao atualizar grupos.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const addRow = () => {
    setRows([...rows, { id: '', nome: '', descricao: '' }]);
  };

  const handleRemoveGrupo = (grupoId) => {
    const updatedGrupos = rows.filter((grupo) => grupo.id !== grupoId);
    setRows(updatedGrupos);

    const updatedInitial = initialGrupos.filter((id) => id !== grupoId);
    setInitialGrupos(updatedInitial);
  };

  useEffect(() => {
    getAllGrupos();
    setInitialGrupos(userData.grupos.map((grupo) => grupo.grupo.id));
  }, []);

  return (
    <>
      <div className="container">
        <div className="card" style={{ margin: 2 }}>
          <header className="card-header">
            <p className="card-header-title">Grupos de {userData.user.nome}</p>
          </header>
          <div className="card-content">
            <table className="table is-fullwidth">
              <thead>
                <tr key={'thead'}>
                  <th>Grupo</th>
                  <th>Descrição</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {rows.map((grupo, idx) => (
                  <tr key={idx}>
                    <td>
                      <select
                        value={grupo.id}
                        onChange={(e) => handleGrupoChange(idx, e.target.value)}
                      >
                        <option value={''}>Selecione</option>
                        {allGrupos.map((grupo) => (
                          <option key={grupo.id} value={grupo.id}>
                            {grupo.nome}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <span className="">{grupo.descricao}</span>
                    </td>
                    <td>
                      <span
                        className="button"
                        onClick={() => handleRemoveGrupo(grupo.id)}
                      >
                        X
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button type="button" className="button" onClick={addRow}>
              Adicionar grupo
            </button>
            <button type="button" className="button" onClick={putGrupos}>
              Atualizar usuário {userData.user.nome}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUserGrupoManager;
