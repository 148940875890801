import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import AlertMessage from '../../layouts/AlertMessage';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../context/UserContext';

const PasswordChange = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const [actualPass, setActualPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newPassConfirm, setNewPassConfirm] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const { idUsuario } = useUser();

  const headers = {
    'Content-Type': 'application/json',
  };

  const handlePassChange = async (e) => {
    e.preventDefault();
    let formData = {
      user_id: idUsuario,
      npas: newPass,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/users/change_pass`,
        formData,
        { headers }
      );
      if (response.status === 200) {
        setAlertMessage('Senha alterada com sucesso.');
        setAlertSeverity('success');
        setAlertOpen(true);
        setNavigateTo('/me/profile');
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Ocorreu um erro.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
        handleClose={handleClose}
      />
      <MainLayout>
        <section className="section main-content">
          <div className="container">
            <div className="card">
              <div className="card-content">
                <div className="content">
                  <form autoComplete="new-password" onSubmit={handlePassChange}>
                    <div className="field is-horizontal">
                      <div
                        className="field-label is-normal"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <label className="label">Senha atual</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <p className="control is-expanded has-icons-left">
                            <input
                              className="input is-rounded"
                              type="password"
                              value={actualPass}
                              onChange={(e) => setActualPass(e.target.value)}
                            />
                            <span className="icon is-small is-left">
                              <i className="fas fa-lock"></i>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div
                        className="field-label is-normal"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <label className="label">Nova senha</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <p className="control is-expanded has-icons-left">
                            <input
                              className="input is-rounded"
                              type="password"
                              value={newPass}
                              onChange={(e) => setNewPass(e.target.value)}
                            />
                            <span className="icon is-small is-left">
                              <i className="fas fa-lock"></i>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div
                        className="field-label is-normal"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <label className="label">Confirmar</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <p className="control is-expanded has-icons-left">
                            <input
                              className="input is-rounded"
                              type="password"
                              value={newPassConfirm}
                              onChange={(e) =>
                                setNewPassConfirm(e.target.value)
                              }
                            />
                            <span className="icon is-small is-left">
                              <i className="fas fa-lock"></i>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <button className="button is-primary" type="submit">
                      Salvar
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
};

export default PasswordChange;
