import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import axios from 'axios';
import Modal from '../../layouts/InsumosModal';
import SinapiCompDetail from './CompDetail';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AlertMessage from '../../layouts/AlertMessage';

const SinapiHome = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [targetSearch, setTargetSearch] = useState('');
  const [allRegs, setAllRegs] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    compCod: 0,
    compObj: {},
    compItems: [],
  });
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setModalOpen(false);
  };

  //Fecha modal apertando "ESC"
  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      handleCloseModal();
    }
  });

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const renderModalContent = (data) => {
    return {
      compCod: data.cod,
      compObj: data.composicao,
      compItems: data.items,
    };
  };

  const getRegs = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/mats/sinapi/`,
        { headers }
      );
      const data = await response.data;
      setAllRegs(data);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar entradas de sinapi'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleFilterMats = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/mats/insumos/q`,
        {
          params: {
            search_str: targetSearch,
            schema: 'sinapi',
          },
          headers: headers,
        }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAllRegs(data);
      }
    } catch (error) {
      console.error(error.response.data.detail);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar insumos'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleRowClick = async (id, tipo) => {
    if (tipo.toLowerCase() === 'composicao') {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/sinapi/composicoes`,
          {
            params: {
              comp_id: id,
            },
            headers: headers,
          }
        );
        const data = await response.data;

        handleOpenModal(modalContent);
        setModalContent(renderModalContent(data));
      } catch (error) {
        console.error(error.response.data.detail);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao buscar composição'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    }
  };

  useEffect(() => {
    getRegs();
  }, []);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Engenharia</li>
                    <li>Sinapi</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Sinapi</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header" style={{ paddingLeft: '24px' }}>
                <p
                  className="card-header-title"
                  style={{ padding: '12px 0px 12px 0px' }}
                >
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Tabela de sinapi
                </p>
              </header>
              <div className="card-content">
                {/* <form name="SinapiSearch" onSubmit={handleFilterMats}>
                                <div className="control">
                                    <input 
                                        className="input" 
                                        type="text"
                                        placeholder="Texto"
                                        onChange={(e) => setTargetSearch(e.target.value)}
                                    />
                                    <p className="help">Separe os termos por vírgula</p>
                                    <div className="control">
                                        <button className="button is-small mt-1">Procurar</button>
                                    </div>
                                </div>
                            </form> */}

                <div className="div-select-search">
                  <form name="SinapiSearch" onSubmit={handleFilterMats}>
                    <div
                      className="search-input"
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <div className="info-search">
                        <div className="field">
                          <label className="label">Buscar </label>
                          <input
                            className="input"
                            type="text"
                            onChange={(e) => setTargetSearch(e.target.value)}
                          />
                          {/* <p className="help">Separe os termos por vírgula</p>                                          */}
                        </div>
                      </div>
                      <div
                        className="button-search"
                        style={{
                          padding: '0px 15px 0px 15px',
                        }}
                      >
                        <button className="button is-primary">Buscar</button>
                      </div>
                    </div>
                  </form>
                </div>

                <br />

                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    {/* <table className="table is-hoverable is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>DESCRIÇÃO</th>
                                            <th>UND</th>
                                            <th>PREÇO</th>
                                            <th>TIPO</th>
                                        </tr>   
                                    </thead>
                                    <tbody>
                                        {allRegs.map((registro) => (
                                            <tr key={registro.id} onClick={() => handleRowClick(registro.id, registro.origem)}>
                                                <td><p className="is-size-7">{registro.did}</p></td>
                                                <td><p className="is-size-7">{registro.descl}</p></td>
                                                <td><p className="is-size-7">{registro.unidade}</p></td>
                                                <td><p className="is-size-7">{formatPreco(registro.preco)}</p></td>
                                                <td>
                                                    <p className={`is-size-7 ${registro.origem.charAt(0) === 'c' ? 'tag is-link' : 'tag is-warning'}`}>
                                                        {registro.origem.toUpperCase().charAt(0)}
                                                    </p>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table> */}

                    <TableContainer
                      component={Paper}
                      style={{
                        border: '1px solid lightgrey',
                        borderRadius: '5px',
                        marginBottom: '20px',
                      }}
                    >
                      <Table aria-label="all records table">
                        <TableHead>
                          <TableRow
                            style={{
                              display: 'flex',
                              width: '100%',
                            }}
                          >
                            <TableCell
                              style={{
                                flex: '1',
                                color: '#171717',
                                fontWeight: 'bold',
                                fontFamily: '"Nunito", sans-serif',
                              }}
                            >
                              ID
                            </TableCell>
                            <TableCell
                              style={{
                                flex: '3',
                                color: '#171717',
                                fontWeight: 'bold',
                                fontFamily: '"Nunito", sans-serif',
                              }}
                            >
                              DESCRIÇÃO
                            </TableCell>
                            <TableCell
                              style={{
                                flex: '1',
                                color: '#171717',
                                fontWeight: 'bold',
                                fontFamily: '"Nunito", sans-serif',
                              }}
                            >
                              UND
                            </TableCell>
                            <TableCell
                              style={{
                                flex: '1',
                                color: '#171717',
                                fontWeight: 'bold',
                                fontFamily: '"Nunito", sans-serif',
                              }}
                            >
                              PREÇO
                            </TableCell>
                            <TableCell
                              style={{
                                flex: '1',
                                color: '#171717',
                                fontWeight: 'bold',
                                fontFamily: '"Nunito", sans-serif',
                              }}
                            >
                              TIPO
                            </TableCell>
                            <TableCell
                              style={{
                                width: '80px',
                                color: '#171717',
                                fontWeight: 'bold',
                                fontFamily: '"Nunito", sans-serif',
                              }}
                            >
                              AÇÃO
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allRegs.map((registro) => (
                            <TableRow
                              key={registro.id}
                              style={{
                                display: 'flex',
                              }}
                            >
                              <TableCell
                                style={{
                                  flex: '1',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                {registro.did}
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '3',
                                  fontFamily: '"Nunito", sans-serif',
                                  textAlign: 'justify',
                                }}
                              >
                                {registro.descl}
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                {registro.unidade}
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                {formatPreco(registro.preco)}
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                <p
                                  className={`is-size-7 ${registro.origem.charAt(0) === 'c' ? 'tag is-link' : 'tag is-warning'}`}
                                >
                                  {registro.origem.toUpperCase().charAt(0)}
                                </p>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: '80px',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                <div
                                  onClick={() =>
                                    handleRowClick(registro.id, registro.origem)
                                  }
                                  style={{
                                    paddingLeft: '13px',
                                    fontSize: '25px',
                                  }}
                                >
                                  <span
                                    style={{
                                      cursor: `${registro.origem.charAt(0) === 'c' ? 'pointer' : 'default'}`,
                                      color: `${registro.origem.charAt(0) === 'c' ? '#2B3BED' : 'grey'}`,
                                    }}
                                  >
                                    <i className="fa-solid fa-file-lines"></i>
                                  </span>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
              {modalContent && (
                <SinapiCompDetail
                  compCod={modalContent.compCod}
                  compObj={modalContent.compObj}
                  compItems={modalContent.compItems}
                />
              )}
            </Modal>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default SinapiHome;
