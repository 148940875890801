import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import './css/ModalPDM.css';
import AlertMessage from '../layouts/AlertMessage';
import { useUser } from '../../context/UserContext';

const ModalPDM = ({ mTarget, mDataPDM, mRoute, info }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const [prioridade, setPrioridade] = useState('');
  const [centro, setCentro] = useState('');
  const [tipoMaterial, setTipoMaterial] = useState('');
  const [ncm, setNcm] = useState('');
  const [finalidade, setFinalidade] = useState('');
  const [precoMedio, setPrecoMedio] = useState('');
  const [unidadeMedida, setUnidadeMedida] = useState('');
  const [norma, setNorma] = useState('');
  const [cfop, setCfop] = useState('');
  const [observacoesSolicitante, setObservacoesSolicitante] = useState('');
  const [allUnidades, setAllUnidades] = useState([]);
  const {
    nomeUsuario,
    roleUsuario,
    setorUsuario,
    idUsuario,
    cargoUsuario,
    telefone1Usuario,
    telefone2Usuario,
  } = useUser();
  const [user, setUser] = useState({
    nome: nomeUsuario,
    role: roleUsuario,
    setor: setorUsuario,
    id: idUsuario,
    cargo: cargoUsuario,
    telefone1: telefone1Usuario,
    telefone2: telefone2Usuario,
  });

  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleNcmChange = (event) => {
    let inputValue = event.target.value.replace(/\D/g, ''); // Remove tudo que não é dígito
    if (inputValue.length > 4) {
      inputValue = inputValue.slice(0, 4) + '.' + inputValue.slice(4);
    }
    if (inputValue.length > 7) {
      inputValue = inputValue.slice(0, 7) + '.' + inputValue.slice(7, 9);
    }
    setNcm(inputValue);
  };

  const handlePrecoMedioChange = (event) => {
    const inputValue = event.target.value;
    // Permite números com até dois dígitos após a vírgula
    const validFormat = /^\d*(,\d{0,2})?$/;

    if (validFormat.test(inputValue)) {
      setPrecoMedio(inputValue);
    }
  };

  const getUnidadesMedida = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/tools/unsap`,
        { headers }
      );
      const data = await response.data;
      setAllUnidades(data);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar unidades de medida'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const iniciaEngenharia = async () => {
    if (['engenharia', 'planejamento', 'novosnegocios'].includes(user.setor)) {
      setCfop('0 - Material');
      setTipoMaterial('NLAG');
    }
  };

  const handleCad = async (e) => {
    e.preventDefault();

    const precoMedioNumerico = parseFloat(precoMedio.replace(',', '.'));
    const formData = {
      id: null,
      resumo: mTarget,
      // area_solicitante: areaSolicitante,
      prioridade_material: prioridade,
      unidade_medida: unidadeMedida,
      centro: centro,
      ncm: ncm,
      observacoes_solicitante: observacoesSolicitante,
      tipo_material: tipoMaterial,
      preco_medio: precoMedioNumerico,
      finalidade: finalidade,
      cfop: cfop,
    };

    try {
      let params = {};

      params = { material: mTarget };

      if (mRoute === 'equips') {
        params = { equipamento: mTarget };
      }

      let requestObj = {
        cad_material: formData,
        schema: mDataPDM,
      };

      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/${mRoute}/`,
        requestObj,
        { params, headers }
      );

      if (response.status === 201) {
        // alert("Item cadastrado com sucesso!");
        setAlertMessage('Item cadastrado com sucesso!');
        setAlertSeverity('success');
        setAlertOpen(true);
        // navigate('/meic')
        // mRoute === "equips" ? navigate('/meic/equipamentos') : navigate('/meic/materiais')
        mRoute === 'equips'
          ? setNavigateTo('/meic/equipamentos')
          : setNavigateTo('/meic/materiais');
      } else if (!response.status === 201) {
        // alert("Ocorreu um erro.")
        setAlertMessage('Ocorreu um erro.');
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      // alert(error.response.data.detail)
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao cadastrar item'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getUnidadesMedida();
    iniciaEngenharia();
  }, []);

  useEffect(() => {
    return () => {
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      {/* <div onClick={(event) => event.stopPropagation()}> */}
      {/* <p className="title is-4 has-text-white">Informações do Material</p> */}
      <div className="container" style={{ width: '800px' }}>
        <form onSubmit={handleCad} className="modal-form">
          <div className="field-modal">
            <label className="label-modal">
              Finalidade
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
              <Tooltip
                placement="right-end"
                title={
                  <div
                    style={{
                      textAlign: 'justify',
                      fontSize: '15px',
                    }}
                  >
                    <b>{'1- Projeto'}</b>{' '}
                    <p>
                      {
                        '- GEO irá  imobilizar e tomar crédito de todos impostos possíveis;'
                      }
                    </p>
                    <br></br>
                    <b>{'2- Substituição de Peças'}</b>{' '}
                    <p>
                      {
                        '- Fiscal irá entender se pode ou não ser imobilizado, por exemplo, troca por mal uso de uma máquina, classifico como desp. não tomo crédito e pago diferencial de alíquota independente do valor. e lançamos para despesa. Sendo para aumentar a vida útil do bem classifico como imobilizado e tomo crédito dos impostos. '
                      }
                    </p>
                    <br></br>
                    <b>{'3- Matéria-prima'}</b>{' '}
                    <p>
                      {
                        '- Soda que usamos no biodigestor, por exemplo ... tomamos crédito e não pago diferencial ( isso é bem importante)'
                      }
                    </p>
                    <br></br>
                    <b>{'4- Consumo Administrativo'}</b>{' '}
                    <p>{'- Apenas pago imposto aqui.'}</p>
                    <br></br>
                    <b>{'5- Laboratório'}</b> <p>{'- Items de laboratório.'}</p>
                    <br></br>
                  </div>
                }
                componentsProps={{
                  tooltip: { sx: { maxWidth: '35rem' } },
                }}
                arrow
              >
                <span
                  style={{
                    color: '#ff3e3e',
                    marginLeft: '6px',
                    padding: '0px 7px',
                    border: '1px solid #ff3e3e',
                    borderRadius: '15px',
                  }}
                >
                  ?
                </span>
              </Tooltip>
            </label>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="select-modal"
              required
              value={finalidade}
              onChange={(e) => setFinalidade(e.target.value)}
            >
              <MenuItem value={'Projeto'}>Projeto</MenuItem>
              <MenuItem value={'Substituição de peça'}>
                Substituição de peça
              </MenuItem>
              <MenuItem value={'Matéria-prima (produção)'}>
                Matéria-prima (produção)
              </MenuItem>
              <MenuItem value={'Consumo Administrativo'}>
                Consumo Administrativo
              </MenuItem>
              <MenuItem value={'Laboratório'}>Laboratório</MenuItem>
            </Select>
          </div>

          <div className="field-modal">
            <label className="label-modal">
              Prioridade
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
            </label>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="select-modal"
              required
              value={prioridade}
              onChange={(e) => setPrioridade(e.target.value)}
            >
              <MenuItem value={'1 - Urgente'}>1 - Urgente</MenuItem>
              <MenuItem value={'2 - Maq. Parada'}>2 - Maq. Parada</MenuItem>
              <MenuItem value={'3 - Para Lançamento de NF (Regularização)'}>
                3 -Para Lançamento de NF (Regularização){' '}
              </MenuItem>
              <MenuItem value={'4 - Capex'}>4 - Capex</MenuItem>
              <MenuItem value={'5 - Opex'}>5 - Opex</MenuItem>
              {/* <MenuItem value={"1 - Processo parado"}>1 - Processo parado</MenuItem>
                        <MenuItem value={"2 - Máquina parada"}>2 - Máquina parada</MenuItem>
                        <MenuItem value={"3 - Evento eminente"}>3 - Evento eminente</MenuItem>
                        <MenuItem value={"4 - Para evitar multas/prejuízos"}>4 - Para evitar multas/prejuízos</MenuItem>
                        <MenuItem value={"5 - Obrigação legal"}>5 - Obrigação legal</MenuItem>
                        <MenuItem value={"6 - Sem urgencia"}>6 - Sem urgencia</MenuItem>
                        <MenuItem value={"7 - Capex"}>7 - Capex</MenuItem> */}
            </Select>
          </div>

          <div className="field-modal">
            <label className="label-modal">
              Centro
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
            </label>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="select-modal"
              required
              value={centro}
              onChange={(e) => setCentro(e.target.value)}
            >
              <MenuItem value={'5101 - Geo Energética'}>
                5101 - Geo Energética
              </MenuItem>
              <MenuItem value={'5001 - Geo Elétrica'}>
                5001 - Geo Elétrica
              </MenuItem>
              <MenuItem value={'2301 - Uisa Geo'}>2301 - Uisa Geo</MenuItem>
              <MenuItem value={'5701 - Cri Geo'}>5701 - Cri Geo</MenuItem>
            </Select>
          </div>

          <div className="field-modal">
            <label className="label-modal">
              CFOP
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
            </label>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="select-modal"
              value={cfop}
              required
              onChange={(e) => setCfop(e.target.value)}
              // disabled
            >
              <MenuItem value={'0 - Material'}>
                0 - Material{' '}
                {['engenharia', 'planejamento', 'novosnegocios'].includes(
                  user.setor
                )
                  ? '(Padrão para engenharia)'
                  : ''}
              </MenuItem>
              <MenuItem value={'1 - Eletricidade'}>1 - Eletricidade</MenuItem>
              <MenuItem value={'2 - Comunicação'}>2 - Comunicação</MenuItem>
              <MenuItem value={'3 - Transportes'}>3 - Transportes</MenuItem>
              <MenuItem value={'4 - Animal'}>4 - Animal</MenuItem>
              <MenuItem value={'5 - Combustível'}>5 - Combustível</MenuItem>
            </Select>
          </div>

          <div className="field-modal">
            <label className="label-modal">
              Tipo de material
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
              <Tooltip
                placement="right-end"
                title={
                  <div
                    style={{
                      textAlign: 'justify',
                      fontSize: '15px',
                    }}
                  >
                    <b>{'1- HIBE'}</b>{' '}
                    <p>
                      {
                        ' – Utilizado para materiais auxiliares ou de consumo (ex. material de escritório / limpeza / embalagens / laboratório).'
                      }
                    </p>
                    <br></br>
                    <b>{'2- NLAG'}</b>{' '}
                    <p>
                      {
                        ' – Utilizado para materiais que não são mantidos em estoque (uso único ou imediato).'
                      }
                    </p>
                    <br></br>
                    <b>{'3- ERSA'}</b>{' '}
                    <p>
                      {
                        ' – Peças utilizados para manutenção e conserto de equipamentos.'
                      }
                    </p>
                    <br></br>
                    <b>{'4- DIEN'}</b>{' '}
                    <p>
                      {
                        ' – Usado para representar serviços prestados (serv. de manutenção / consultoria).'
                      }
                    </p>
                    <br></br>
                    <b>{'5- ROH'}</b>{' '}
                    <p>
                      {
                        ' – Utilizados para matérias primas necessárias para a fabricação de produtos acabados.'
                      }
                    </p>
                    <br></br>
                    <b>{'6- XLAG'}</b>{' '}
                    <p>
                      {
                        ' – Utilizável para cadastro de material de NF de regularização.'
                      }
                    </p>
                    <br></br>
                  </div>
                }
                componentsProps={{
                  tooltip: { sx: { maxWidth: '35rem' } },
                }}
                arrow
              >
                <span
                  style={{
                    color: '#ff3e3e',
                    marginLeft: '6px',
                    padding: '0px 7px',
                    border: '1px solid #ff3e3e',
                    borderRadius: '15px',
                  }}
                >
                  ?
                </span>
              </Tooltip>
            </label>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="select-modal"
              required
              value={tipoMaterial}
              onChange={(e) => setTipoMaterial(e.target.value)}
            >
              <MenuItem
                style={{
                  width: '700px',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  textAlign: 'justify',
                }}
                value={'HIBE'}
              >
                HIBE (Material aux. Consumo){' '}
              </MenuItem>
              <MenuItem
                style={{
                  width: '700px',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  textAlign: 'justify',
                }}
                value={'NLAG'}
              >
                NLAG (Material não estocável){' '}
                {['engenharia', 'planejamento', 'novosnegocios'].includes(
                  user.setor
                )
                  ? '(Padrão para engenharia)'
                  : ''}
              </MenuItem>
              <MenuItem
                style={{
                  width: '700px',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  textAlign: 'justify',
                }}
                value={'ERSA'}
              >
                ERSA (Peças de reposição){' '}
              </MenuItem>
              <MenuItem
                style={{
                  width: '700px',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  textAlign: 'justify',
                }}
                value={'DIEN'}
              >
                DIEN (Serviços){' '}
              </MenuItem>
              <MenuItem
                style={{
                  width: '700px',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  textAlign: 'justify',
                }}
                value={'ROH'}
              >
                ROH ( Matéria-prima){' '}
              </MenuItem>
              <MenuItem
                style={{
                  width: '700px',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  textAlign: 'justify',
                }}
                value={'XLAG'}
              >
                XLAG (Material não estocável – NF)
              </MenuItem>

              {/* <MenuItem value={"DIEN"}>DIEN - Prestação de serviços</MenuItem>
                        <MenuItem value={"ERSA"}>ERSA - Peças de reposição</MenuItem>
                        <MenuItem value={"FERT"}>FERT - Produto acabado</MenuItem>
                        <MenuItem value={"HALB"}>HALB - Produto semiacabado</MenuItem>
                        <MenuItem value={"HAWA"}>HAWA - Produto comercializável</MenuItem>
                        <MenuItem value={"HERS"}>HERS - Peça de fabricante</MenuItem>
                        <MenuItem value={"HIBE"}>HIBE - Material auxiliar de consumo</MenuItem>
                        <MenuItem value={"IBAU"}>IBAU - Conjunto de manutenção</MenuItem>
                        <MenuItem value={"LEIH"}>LEIH - Embalagem retornável</MenuItem>
                        <MenuItem value={"NLAG"}>NLAG - Material não estocável { ["engenharia", "planejamento", "novosnegocios"].includes(user.setor) ? "(Padrão para engenharia)" : "" }</MenuItem>
                        <MenuItem value={"ROH/ZROH"}>ROH/ZROH - Matérias-primas</MenuItem>
                        <MenuItem value={"UNBW/ZLEI"}>UNBW/ZLEI - Material não avaliado</MenuItem>
                        <MenuItem value={"VERP"}>VERP - Embalagem</MenuItem> */}
            </Select>
          </div>

          <div className="field-modal">
            <label className="label-modal">
              NCM
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
            </label>
            <input
              className="input-modal"
              type="text"
              value={ncm}
              onChange={handleNcmChange}
              maxLength={'10'}
              minLength={'10'}
              required
              placeholder="xxxx.xx.xx"
            />
          </div>

          {/* <div className="field-modal">
                    <label className="label-modal"> 
                      Norma          
                      <span className="required-symbol" style={{fontSize: '1.2rem'}}> *</span>                         
                    </label>                      
                      <input className="input-modal"
                        type="text"
                        value={norma}                        
                        onChange={(e) => setNorma(e.target.value)}
                        required
                      />                                
                  </div> */}

          <div className="field-modal">
            <label className="label-modal">
              Unidade de medida
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
            </label>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className="select-modal"
              required
              value={unidadeMedida}
              onChange={(e) => setUnidadeMedida(e.target.value)}
            >
              {allUnidades &&
                allUnidades.map((item, i) => (
                  <MenuItem value={item.com}>
                    {item.com} - {item.texto}
                  </MenuItem>
                ))}
            </Select>
          </div>

          <div className="field-modal">
            <label className="label-modal">
              Preço médio
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
            </label>
            <input
              className="input-modal"
              type="text"
              value={precoMedio}
              required
              onChange={handlePrecoMedioChange}
              placeholder="0,00"
            />
            <label className="label-descricao">
              Separe centavos por vírgula. Exemplo: 10,00
            </label>
          </div>

          <div className="field-modal">
            <label className="label-modal">
              Observações
              {/* <span className="required-simbol" aria-hidden="true">*</span> */}
            </label>
            <textarea
              className="input-modal"
              type="text"
              rows="4"
              style={{ height: '100px' }}
              value={observacoesSolicitante}
              onChange={(e) => setObservacoesSolicitante(e.target.value)}
            ></textarea>
          </div>

          <hr></hr>

          <div className="modal-btn-footer">
            <button type="submit" className="button-modal btn-submit">
              Cadastrar
            </button>
          </div>

          {/* <div className="control">
                      <button className="button is-info is-small mt-1">Cadastrar</button>
                  </div> */}
        </form>
      </div>
      {/* </div>

            <br/> */}
    </>
  );
};

export default ModalPDM;
