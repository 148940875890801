import { useEffect } from 'react';
import './Scrum.css';

const Row = ({ tarefas, etapa }) => {
  // Use reduce to count the amount of tarefas in each status
  let count = 0;

  for (let key in tarefas) {
    count += tarefas[key].length;
  }

  return (
    <>
      <div className="vertical-header-cell">
        {etapa} ({count})
      </div>{' '}
      {/* EMISSÃO, REVISÃO, APROVAÇÃO, ... */}
      <Cell tarefas={tarefas.pending} /> {/* PENDING */}
      <Cell tarefas={tarefas.todo} /> {/* TO DO */}
      <Cell tarefas={tarefas.doing} /> {/* DOING */}
    </>
  );
};

const Cell = ({ tarefas }) => {
  return (
    <div className="cell">
      {tarefas.length > 0 ? (
        <div className="task-count-badge">{tarefas.length}</div>
      ) : null}
      <div className="cell-content">
        {tarefas.map((tarefa, index) => (
          <Card key={index} tarefa={tarefa} />
        ))}
      </div>
    </div>
  );
};

const Card = ({ tarefa }) => {
  const conteudo = JSON.parse(tarefa.conteudo);

  return (
    <div className="card">
      <div
        className="card-title"
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {conteudo.tag}
      </div>
      <div
        className="card-description"
        style={{ wordWrap: 'break-word', overflow: 'hidden' }}
      >
        {conteudo.user_nome}
      </div>
    </div>
  );
};

const BoardScrum = ({ tarefas }) => {
  const headers = ['ETAPA/STATUS', 'PENDING', 'TO DO', 'DOING'];
  const vertical_headers = ['Emissão', 'Revisão', 'Análise/Aprovação'];
  let tarefasEmissao = {
    todo: [],
    doing: [],
    pending: [],
  };
  let tarefasRevisao = {
    todo: [],
    doing: [],
    pending: [],
  };
  let tarefasAprovacao = {
    todo: [],
    doing: [],
    pending: [],
  };

  tarefas.forEach((tarefa) => {
    if (tarefa.link_bw === null) {
      if (tarefa.status === 'PENDENTE') {
        tarefasEmissao.todo.push(tarefa);
      } else if (tarefa.status === 'INICIADA') {
        tarefasEmissao.doing.push(tarefa);
      } else if (tarefa.status === 'REVISAO') {
        tarefasRevisao.todo.push(tarefa);
      }
    } else if (tarefa.status === 'PENDENTE') {
      tarefasAprovacao.doing.push(tarefa);
    } else if (tarefa.status === 'PREDECESSORA') {
      tarefasAprovacao.pending.push(tarefa);
    } else {
      console.error('Tarefa com status inválido: ', tarefa);
    }
  });

  let countPending =
    tarefasEmissao.pending.length +
    tarefasRevisao.pending.length +
    tarefasAprovacao.pending.length;
  let countTodo =
    tarefasEmissao.todo.length +
    tarefasRevisao.todo.length +
    tarefasAprovacao.todo.length;
  let countDoing =
    tarefasEmissao.doing.length +
    tarefasRevisao.doing.length +
    tarefasAprovacao.doing.length;

  return (
    <>
      <div className="scrum-board-container">
        <div className="scrum-board">
          <div className="scrum-board-header">
            {headers.map((header, index) => {
              if (header === 'PENDING') {
                return (
                  <div key={index} className="header-cell">
                    {header} ({countPending})
                  </div>
                );
              } else if (header === 'TO DO') {
                return (
                  <div key={index} className="header-cell">
                    {header} ({countTodo})
                  </div>
                );
              } else if (header === 'DOING') {
                return (
                  <div key={index} className="header-cell">
                    {header} ({countDoing})
                  </div>
                );
              } else {
                return (
                  <div key={index} className="header-cell">
                    {header}
                  </div>
                );
              }
            })}
          </div>
          <div className="scrum-board-content">
            {vertical_headers.map((etapa, index) => (
              <div key={index} className="row">
                <Row
                  tarefas={
                    etapa === 'Emissão'
                      ? tarefasEmissao
                      : etapa === 'Revisão'
                        ? tarefasRevisao
                        : tarefasAprovacao
                  }
                  etapa={etapa}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BoardScrum;
