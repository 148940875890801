import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import FormModal from '../../layouts/FormModal';
import ModalPasswordForget from './ModalPasswordForget';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AlertMessage from '../../layouts/AlertMessage';
import { useUser } from '../../../context/UserContext';

const { REACT_APP_BASE_API_URL } = process.env;

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        ACE
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

const LoginForm = () => {
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const {
    setNomeUsuario,
    setRoleUsuario,
    setIdUsuario,
    setSetorUsuario,
    setGruposUsuario,
    setCargoUsuario,
    setTelefone1Usuario,
    setTelefone2Usuario,
    setUsuarioInterno,
  } = useUser();

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setModalOpen(false);
  };

  const renderModalContent = (info) => {
    return <ModalPasswordForget info={info} />;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      username: username,
      password: password,
    };

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/auth/token`,
        formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      if (response.status === 200) {
        const data = await response.data;
        setNomeUsuario(data.user_nome);
        setRoleUsuario(data.user_role);
        setIdUsuario(data.user_id);
        setSetorUsuario(data.user_setor);
        setGruposUsuario(data.user_grupos);
        setCargoUsuario(data.user_cargo);
        setTelefone1Usuario(data.user_telefone1);
        setTelefone2Usuario(data.user_telefone2);
        setUsuarioInterno(data.user_intern);

        if (data.user_intern) {
          navigate('/me/profileN');
        } else {
          navigate('/ext/home/');
        }
      }
    } catch (error) {
      setUsername('');
      setPassword('');
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao logar'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <ThemeProvider theme={defaultTheme}>
        <Grid
          container
          component="main"
          sx={{
            position: 'absolute',
            left: '0',
            top: '0',
            height: '100vh',
          }}
        >
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundColor: 'black',
            }}
          >
            <div
              style={{
                display: 'flex',
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src="/GEO_ACES_positivo-completo.svg"
                style={{ width: '50%' }}
                alt="Logo"
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '200px',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'black' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Login
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="E-mail"
                  name="email"
                  value={username}
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  value={password}
                  name="password"
                  label="Senha"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Lembre de mim"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: 'black',
                    '&:hover': {
                      backgroundColor: '#2e323a',
                      color: 'white', // Cor do texto quando o mouse está em cima
                    },
                    '&:active': {
                      backgroundColor: 'darkgray',
                      color: 'white', // Cor do texto quando o botão é clicado
                    },
                  }}
                >
                  Entrar
                </Button>
                <Grid container>
                  <Grid item xs>
                    <p
                      style={{
                        color: '#2B3BED',
                        cursor: 'pointer',
                        width: 'max-content',
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.textDecoration = 'underline')
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.textDecoration = 'none')
                      }
                      onClick={() => handleOpenModal('Cadastrar')}
                    >
                      Esqueceu a senha?
                    </p>
                  </Grid>
                  <Grid item>
                    <Link
                      to={'/registrar'}
                      variant="body2"
                      style={{ color: '#2B3BED' }}
                      onMouseEnter={(e) =>
                        (e.target.style.textDecoration = 'underline')
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.textDecoration = 'none')
                      }
                    >
                      {'Não tem uma conta? Cadastre-se'}
                    </Link>
                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <FormModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          modalTitle={'Reenviar senha'}
        >
          {renderModalContent(info)}
        </FormModal>
      </ThemeProvider>
    </>
  );
};

export default LoginForm;
