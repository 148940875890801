import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import { useNavigate, useLocation } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

import ComentarioComponent from '../../functional/comentarios/Comentario';
import AlertMessage from '../../layouts/AlertMessage';
import { useUser } from '../../../context/UserContext';

const FluxoDocsEntregaveis = () => {
  const location = useLocation();
  const { doc_ent_versao, route, tarefa } = location.state;
  const { REACT_APP_BASE_API_URL } = process.env;
  const [filaTarefas, setFilaTarefas] = useState([]); // Estado para armazenar os dados da fila
  const [filaDocEntregavelVersao, setFilaDocEntregavelVersao] = useState([
    doc_ent_versao,
  ]);
  const navigate = useNavigate();
  const { idUsuario } = useUser();
  const [commentsData, setCommentsData] = useState([]);
  const [userComment, setUserComment] = useState('');
  const [expandir, setExpandir] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  useEffect(() => {
    const fetchFilaDocEntregavelVersao = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/kanban/fluxo-aprovacao/`,
          {
            params: { fluxo_id: doc_ent_versao.id },
            headers,
          }
        );

        if (response.data) {
          setFilaTarefas(response.data.tarefas);
          puxarComentarios(response.data.tarefas);
        }
      } catch (error) {
        console.error('Erro ao buscar fluxo do documentos entregavel:', error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail ||
                'Erro ao buscar fluxo do documentos entregavel.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    const puxarComentarios = async (fila) => {
      let fluxo_ids = fila.map((tarefa) => tarefa.id);
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/comments/`,
          {
            params: { objeto_id: fluxo_ids[0] },
            headers,
          }
        );

        if (response.status === 200) {
          setCommentsData(response.data);
        }
      } catch (error) {
        console.error(error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao buscar comentários.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    fetchFilaDocEntregavelVersao();
  }, [REACT_APP_BASE_API_URL]);

  const columns = [
    { field: 'versao', headerName: 'Versão', flex: 1 },
    { field: 'projeto', headerName: 'Projeto', flex: 1 },
    { field: 'disciplina', headerName: 'Disciplina', flex: 1.05 },
    { field: 'tag', headerName: 'Tag', flex: 2 },
    { field: 'nome_documento', headerName: 'Nome documento', flex: 2 },
    {
      field: 'permalink',
      headerName: 'Permalink',
      flex: 2,
      renderCell: (params) => (
        <a
          href={params.row.permalink}
          style={{ width: '100%', wordBreak: 'break-all' }}
        >
          {params.row.permalink}
        </a>
      ),
    },
    {
      field: 'data_emissao',
      headerName: 'Data Emissao',
      flex: 2,
      renderCell: (params) => (
        <div>
          {params.row.data_emissao
            ? new Date(params.row.data_emissao).toLocaleString()
            : '-'}
        </div>
      ),
    },
  ];

  const postRootComment = async (e) => {
    e.preventDefault();
    let obj = {
      text: userComment,
      user_id: idUsuario,
      objeto: filaTarefas[filaTarefas.length - 1].id,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/comments/`,
        obj,
        { headers }
      );
      if (response.status === 201) {
        setUserComment('');
        getComments(filaTarefas[filaTarefas.length - 1].id);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao postar comentário.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getComments = async (id) => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_API_URL}/comments/`, {
        params: { objeto_id: id },
        headers,
      });
      if (response.status === 200) {
        setCommentsData(response.data);
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || "Ocoreu um erro.")
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar comentários.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Planejamento</li>
                    <li>Documentos Entregáveis</li>
                    <li>Versões</li>
                    <li>Fluxo</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Fluxo</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Fluxo de aprovação do documento entregável
                </p>
              </header>
              <div className="card-content">
                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="FilaTarefas">
                      <div
                        style={{
                          height: 'auto',
                          width: '100%',
                        }}
                      >
                        <div
                          style={{
                            marginBottom: '20px',
                          }}
                        >
                          <DataGrid
                            rows={filaDocEntregavelVersao}
                            columns={columns}
                            pageSize={5}
                            disableSelectionOnClick
                            getRowHeight={() => 'auto'}
                            sx={{
                              fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
                              '& .MuiDataGrid-cell': {
                                display: 'flex',
                                alignItems: 'flex-start', // Alinha o conteúdo no topo
                                paddingTop: '4px', // Ajuste conforme necessário
                                paddingBottom: '4px',
                                border: '0px',
                                // lineHeight: '2.5',
                                fontSize: '16px',
                              },
                              '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                                {
                                  fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                                  fontSize: '16px',
                                  color: '#171717',
                                  // fontFamily: '"Nunito", sans-serif',
                                  lineHeight: '2.5',
                                  borderLeft: '5px solid transparent',
                                },
                              '& .MuiDataGrid-virtualScroller': {
                                overflowX: 'hidden',
                              },

                              '& .MuiDataGrid-footerContainer': {
                                display: 'none',
                              },
                            }}
                          />
                        </div>

                        <div
                          style={{
                            width: '100%',
                            height: 'auto',
                            margin: '0px',
                          }}
                        >
                          {filaTarefas.map((card, index) => (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                              >
                                <Avatar
                                  sx={{
                                    backgroundColor: '#2B3BED',
                                  }}
                                >
                                  {JSON.parse(card.conteudo).user_nome[0]}
                                </Avatar>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <label
                                    style={{
                                      marginLeft: '10px',
                                    }}
                                  >
                                    {' '}
                                    {JSON.parse(card.conteudo).user_nome}
                                  </label>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div
                                  className="field"
                                  style={{
                                    paddingTop: '5px',
                                  }}
                                >
                                  <div
                                    className="row-field"
                                    style={{
                                      padding: '0px',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <label
                                      className="label-field"
                                      style={{
                                        color: 'hsl(0, 0%, 29%)',
                                        fontSize: '16px',
                                      }}
                                    >
                                      Nome
                                    </label>
                                    <span
                                      className="description"
                                      style={{
                                        color: 'hsl(0, 0%, 29%)',
                                        fontSize: '16px',
                                      }}
                                    >
                                      {JSON.parse(card.conteudo).user_nome}
                                    </span>
                                  </div>
                                </div>
                                <div className="field">
                                  <div
                                    className="row-field"
                                    style={{
                                      padding: '0px',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <label
                                      className="label-field"
                                      style={{
                                        color: 'hsl(0, 0%, 29%)',
                                        fontSize: '16px',
                                      }}
                                    >
                                      Disciplina
                                    </label>
                                    <span
                                      className="description"
                                      style={{
                                        color: 'hsl(0, 0%, 29%)',
                                        fontSize: '16px',
                                      }}
                                    >
                                      {JSON.parse(card.conteudo).disciplina}
                                    </span>
                                  </div>
                                </div>

                                <div className="field">
                                  <div
                                    className="row-field"
                                    style={{
                                      padding: '0px',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <label
                                      className="label-field"
                                      style={{
                                        color: 'hsl(0, 0%, 29%)',
                                        fontSize: '16px',
                                      }}
                                    >
                                      Status
                                    </label>
                                    <span
                                      className="description"
                                      style={{
                                        color: 'hsl(0, 0%, 29%)',
                                        fontSize: '16px',
                                      }}
                                    >
                                      {card.status === 'CONCLUIDA'
                                        ? 'EMITIDO'
                                        : card.status}{' '}
                                      {JSON.parse(card.conteudo).comentario
                                        ? ' COM COMENTARIO'
                                        : ''}
                                    </span>
                                  </div>
                                </div>

                                {JSON.parse(card.conteudo).comentario && (
                                  <div className="field">
                                    <div
                                      className="row-field"
                                      style={{
                                        padding: '0px',
                                        flexDirection: 'column',
                                      }}
                                    >
                                      <label
                                        className="label-field"
                                        style={{
                                          color: 'hsl(0, 0%, 29%)',
                                          fontSize: '16px',
                                        }}
                                      >
                                        Comentário
                                      </label>
                                      <span
                                        className="description"
                                        style={{
                                          color: 'hsl(0, 0%, 29%)',
                                          fontSize: '16px',
                                        }}
                                      >
                                        {JSON.parse(card.conteudo).comentario}
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {card.status !== 'PREDECESSORA' && (
                                  <div>
                                    <div className="field">
                                      <div
                                        className="row-field"
                                        style={{
                                          padding: '0px',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        <label
                                          className="label-field"
                                          style={{
                                            color: 'hsl(0, 0%, 29%)',
                                            fontSize: '16px',
                                          }}
                                        >
                                          Data Inicio
                                        </label>
                                        <span
                                          className="description"
                                          style={{
                                            color: 'hsl(0, 0%, 29%)',
                                            fontSize: '16px',
                                          }}
                                        >
                                          {new Date(
                                            card.data_inicio_efetivo
                                          ).toLocaleString()}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="field">
                                      <div
                                        className="row-field"
                                        style={{
                                          padding: '0px',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        <label
                                          className="label-field"
                                          style={{
                                            color: 'hsl(0, 0%, 29%)',
                                            fontSize: '16px',
                                          }}
                                        >
                                          Data Prevista
                                        </label>
                                        <span
                                          className="description"
                                          style={{
                                            color: 'hsl(0, 0%, 29%)',
                                            fontSize: '16px',
                                          }}
                                        >
                                          {new Date(
                                            card.data_due
                                          ).toLocaleString()}
                                        </span>
                                      </div>
                                    </div>

                                    {card.data_conclusao && (
                                      <div className="field">
                                        <div
                                          className="row-field"
                                          style={{
                                            padding: '0px',
                                            flexDirection: 'column',
                                          }}
                                        >
                                          <label
                                            className="label-field"
                                            style={{
                                              color: 'hsl(0, 0%, 29%)',
                                              fontSize: '16px',
                                            }}
                                          >
                                            Data Conclusão
                                          </label>
                                          <span
                                            className="description"
                                            style={{
                                              color: 'hsl(0, 0%, 29%)',
                                              fontSize: '16px',
                                            }}
                                          >
                                            {new Date(
                                              card.data_conclusao
                                            ).toLocaleString()}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </AccordionDetails>
                              {/* <AccordionActions>
                                                            <Button>Cancel</Button>
                                                            <Button>Agree</Button>
                                                        </AccordionActions> */}
                            </Accordion>
                          ))}
                        </div>

                        <div className="card">
                          <header className="card-header">
                            <p className="card-header-title">
                              <span className="icon">
                                <i className="mdi mdi-ballot"></i>
                              </span>
                              Comentários
                            </p>
                          </header>
                          <div
                            className="card-content"
                            style={{
                              width: '100%',
                            }}
                          >
                            <form name="comment" onSubmit={postRootComment}>
                              <div className="field">
                                <div className="control">
                                  <textarea
                                    className="textarea"
                                    placeholder="Comentário"
                                    value={userComment}
                                    onChange={(e) =>
                                      setUserComment(e.target.value)
                                    }
                                  ></textarea>
                                </div>
                              </div>
                              <div
                                className="div"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <button
                                  className="button is-primary"
                                  type="submit"
                                >
                                  Comentar
                                </button>
                              </div>
                            </form>
                            <br />
                            {commentsData &&
                              commentsData.map((comment) => (
                                <ComentarioComponent
                                  key={comment.id}
                                  comment={comment}
                                  userId={idUsuario}
                                  objeto={tarefa}
                                  getCommentFunction={getComments}
                                  reply={false}
                                />
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default FluxoDocsEntregaveis;
