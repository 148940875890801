import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const ModalMaterial = ({ materialSap, info }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const [centroAtual, setCentroAtual] = useState('');

  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const handleCad = async (e) => {
    e.preventDefault();

    try {
      const tarefa = {
        titulo: `Expandir material: ${materialSap[0].MATERIAL}`,
        setor: 'suprimentos',
        tags: 'expansao',
        status: 'PENDENTE',
        tempo_resolucao: 2,
        prioridade: 'NORMAL',
      };

      const conteudo = {
        sap_id: materialSap[0].MATERIAL,
        centros: materialSap.map((item) => item.CENTRO),
        centro_novo: centroAtual,
        texto_longo: materialSap[0].TEXTOLONGO,
        texto_breve: materialSap[0].TEXTOBREVE,
      };

      let params = { tarefa: tarefa, conteudo: conteudo };

      const headers = {
        'Content-Type': 'application/json',
      };

      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/tarefas/`,
        params,
        { headers }
      );

      if (response.status === 201) {
        // alert("Expansão de material solicitada com sucesso!");
        setAlertMessage('Expansão de material solicitada com sucesso!');
        setAlertSeverity('success');
        setAlertOpen(true);
        // navigate('/geral/minhas-solicitacoes')
        setNavigateTo('/geral/minhas-solicitacoes');
      } else if (!response.status === 201) {
        // alert("Ocorreu um erro.")
        setAlertMessage('Ocorreu um erro.');
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      // alert(error.response.data.detail)
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail ||
              'Erro ao solicitar expansão de material.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {}, [materialSap]);

  useEffect(() => {
    return () => {
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <div
        className="container"
        style={{
          maxWidth: '1200px',
          minWidth: '1000px',
          height: 'auto',
        }}
      >
        {materialSap.length === 0 && (
          <div
            style={{
              display: 'flex',
              height: '604px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="secondary" size="4rem" />
          </div>
        )}
        {materialSap && materialSap.length > 0 && (
          <form onSubmit={handleCad} className="modal-form">
            <div className="field-modal">
              <label className="label-modal">
                Selecione o centro que deseja expandir o material
                <span
                  className="required-symbol"
                  style={{ fontSize: '1.2rem' }}
                >
                  {' '}
                  *
                </span>
              </label>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="select-modal"
                required
                value={centroAtual}
                onChange={(e) => setCentroAtual(e.target.value)}
              >
                <MenuItem value={'5101 - Geo Energética'}>
                  5101 - Geo Energética
                </MenuItem>
                <MenuItem value={'5001 - Geo Elétrica'}>
                  5001 - Geo Elétrica
                </MenuItem>
                <MenuItem value={'2301 - Uisa Geo'}>2301 - Uisa Geo</MenuItem>
                <MenuItem value={'5701 - Cri Geo'}>5701 - Cri Geo</MenuItem>
              </Select>
            </div>

            <div className="divider is-size-5">Detalhes material</div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <TableContainer
                component={Paper}
                style={{
                  border: '1px solid lightgrey',
                  borderRadius: '5px',
                  margin: '16px',
                  height: 'auto',
                  width: '1000px',
                }}
              >
                <Table aria-label="Materiais">
                  <TableHead>
                    <TableRow
                      style={{
                        display: 'flex',
                        backgroundColor: '#2e323a',
                      }}
                    >
                      <TableCell
                        style={{
                          flex: '2',
                          fontSize: '16px',
                          color: '#ffffff',
                          fontWeight: 'bold',
                          fontFamily: '"Nunito", sans-serif',
                        }}
                      >
                        SAP ID
                      </TableCell>
                      <TableCell
                        style={{
                          flex: '1',
                          fontSize: '16px',
                          color: '#ffffff',
                          fontWeight: 'bold',
                          fontFamily: '"Nunito", sans-serif',
                        }}
                      >
                        Centro
                      </TableCell>
                      <TableCell
                        style={{
                          flex: '3',
                          fontSize: '16px',
                          color: '#ffffff',
                          fontWeight: 'bold',
                          fontFamily: '"Nunito", sans-serif',
                        }}
                      >
                        Texto breve
                      </TableCell>
                      <TableCell
                        style={{
                          flex: '3',
                          fontSize: '16px',
                          color: '#ffffff',
                          fontWeight: 'bold',
                          fontFamily: '"Nunito", sans-serif',
                        }}
                      >
                        Texto longo
                      </TableCell>
                      <TableCell
                        style={{
                          flex: '2',
                          fontSize: '16px',
                          color: '#ffffff',
                          fontWeight: 'bold',
                          fontFamily: '"Nunito", sans-serif',
                        }}
                      >
                        Descrição centro
                      </TableCell>
                      <TableCell
                        style={{
                          flex: '1',
                          fontSize: '16px',
                          color: '#ffffff',
                          fontWeight: 'bold',
                          fontFamily: '"Nunito", sans-serif',
                        }}
                      >
                        Preço
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {materialSap.map((item, index) => (
                      <TableRow
                        key={index}
                        style={{
                          display: 'flex',
                          backgroundColor: '#f7f7f7',
                        }}
                      >
                        <TableCell
                          style={{
                            flex: '2',
                            wordBreak: 'break-word',
                            textAlign: 'justify',
                            fontFamily: '"Nunito", sans-serif',
                            fontSize: '14px',
                          }}
                        >
                          {item.MATERIAL}
                        </TableCell>
                        <TableCell
                          style={{
                            flex: '1',
                            textAlign: 'justify',
                            fontFamily: '"Nunito", sans-serif',
                            fontSize: '14px',
                          }}
                        >
                          {item.CENTRO}
                        </TableCell>
                        <TableCell
                          style={{
                            flex: '3',
                            textAlign: 'justify',
                            fontFamily: '"Nunito", sans-serif',
                            fontSize: '14px',
                          }}
                        >
                          {item.TEXTOBREVE}
                        </TableCell>
                        <TableCell
                          style={{
                            flex: '3',
                            textAlign: 'justify',
                            fontFamily: '"Nunito", sans-serif',
                            fontSize: '14px',
                          }}
                        >
                          {item.TEXTOLONGO}
                        </TableCell>
                        <TableCell
                          style={{
                            flex: '2',
                            textAlign: 'justify',
                            fontFamily: '"Nunito", sans-serif',
                            fontSize: '14px',
                          }}
                        >
                          {item.DESCRICAO_CENTRO}
                        </TableCell>
                        <TableCell
                          style={{
                            flex: '1',
                            textAlign: 'justify',
                            fontFamily: '"Nunito", sans-serif',
                            fontSize: '14px',
                          }}
                        >
                          {item.PRECO}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <hr></hr>

            <div className="modal-btn-footer">
              <button type="submit" className="button-modal btn-submit">
                Expandir
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default ModalMaterial;
