import React, { useState } from 'react';
import axios from 'axios';
import AlertMessage from '../../layouts/AlertMessage';
import { useNavigate } from 'react-router-dom';

const ProjetosForm = ({ empreendimento_id, onProjCreated, info }) => {
  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;
  const [projTitulo, setProjTitulo] = useState('');
  const [projTag, setProjTag] = useState('');

  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const handleProjCad = async (e) => {
    e.preventDefault();
    const formData = {
      titulo: projTitulo,
      empreendimento_id: empreendimento_id,
      tag: projTag,
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/adm/projetos/`,
        formData,
        { headers }
      );
      const newProj = await response.data;
      if (response.status === 201) {
        setAlertMessage('Projeto iniciado com sucesso!');
        setAlertSeverity('success');
        setAlertOpen(true);
        onProjCreated();
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao cadastrar projeto'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  return (
    <>
      <div className="container" style={{ width: '500px' }}>
        <form className="modal-form" onSubmit={handleProjCad} name="ProjCad">
          <div className="field-modal">
            <label className="label-modal">
              Título
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
            </label>
            <input
              className="input-modal"
              type="text"
              required
              value={projTitulo}
              onChange={(e) => setProjTitulo(e.target.value)}
            />
          </div>

          <div className="field-modal">
            <label className="label-modal">
              TAG
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
            </label>
            <input
              className="input-modal"
              type="text"
              required
              value={projTag}
              onChange={(e) => setProjTag(e.target.value)}
            />
          </div>
          <hr></hr>
          <div className="modal-btn-footer">
            <button type="submit" className="button-modal btn-submit">
              Cadastrar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProjetosForm;
