import React, { useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import LocacaoForm from './LocacaoForm';
import MOForm from './MOForm';

const ServicoHomePage = () => {
  const [tipoServ, setTipoServ] = useState('');
  const [formVisivel, setFormVisivel] = useState(false);

  const navigate = useNavigate();

  const handleServicoChange = (e) => {
    setTipoServ(e.target.value);
    setFormVisivel(true);
  };

  return (
    <>
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    {/* <li>Setores</li> */}
                    <li>Engenharia</li>
                    <li>Serviço</li>
                    <li>
                      {tipoServ === 'mdo' || tipoServ === ''
                        ? 'Mão de obra'
                        : 'Locação'}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">
                      {tipoServ === 'mdo' || tipoServ === ''
                        ? 'Mão de obra'
                        : 'Locação'}
                    </h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <label></label>
                    <div className="search-pdm">
                      <div style={{ display: 'flex' }}>
                        <div
                          className="div-select-search"
                          style={{
                            paddingRight: '15px',
                            width: '400px',
                          }}
                        >
                          <label className="label">Selecione o serviço:</label>

                          <div className="field-body">
                            <div
                              className="field is-narrow"
                              style={{
                                padding: '0px',
                              }}
                            >
                              <div className="control has-icons-left">
                                <div className="select is-fullwidth">
                                  <select
                                    value={tipoServ}
                                    className="custom-select-style"
                                    onChange={handleServicoChange}
                                  >
                                    <option value="mdo">Mão de obra</option>
                                    <option value="loc">Locação</option>
                                  </select>
                                  <span className="icon is-small is-left">
                                    <i className="fa-solid fa-user-tie"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="section is-main-section"> */}

          <div>
            {tipoServ === 'loc' && <LocacaoForm />}

            {(tipoServ === 'mdo' || tipoServ === '') && <MOForm />}
          </div>

          {/* </section> */}
        </div>
      </MainLayout>
    </>
  );
};

export default ServicoHomePage;
