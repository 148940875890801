import React, { useEffect, useState } from 'react';
import MainLayout from '../../../layouts/MainLayout';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

import TarefaClienteView from './componentes/TarefaClienteView';
import TarefaFornecedorView from './componentes/TarefaFornecedorView';
import TarefaFreteView from './componentes/TarefaFreteView';
import TarefaMaterialView from './componentes/TarefaMaterialView';
import TarefaPDMView from './componentes/TarefaPDMView';
import TarefaSRCView from './componentes/TarefaSRCView';
import AlertMessage from '../../../layouts/AlertMessage';

const VisualizarTarefaUser = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const location = useLocation();
  const tarefaId = location.state.tarefa_id;
  const scope = location.state.scope;
  const [tarefaState, setTarefaState] = useState(null);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  function titleStr(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
      .join(' ');
  }

  const getTarefaDetail = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/tarefas/detail/`,
        {
          params: { tarefa_id: tarefaId },
          headers,
        }
      );
      if (response.status === 200) {
        setTarefaState(response.data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Ocorreu um erro.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleRender = ({ scope }) => {
    switch (scope) {
      case 'material':
        return <TarefaMaterialView tarefa={tarefaState} />;
      case 'fornecedor':
        return <TarefaFornecedorView tarefa={tarefaState} />;
      case 'cliente':
        return <TarefaClienteView tarefa={tarefaState} />;
      case 'frete':
        return <TarefaFreteView tarefa={tarefaState} />;
      case 'solicitacao_rc':
        return <TarefaSRCView tarefa={tarefaState} />;
      case 'pdm':
        return <TarefaPDMView tarefa={tarefaState} />;
    }
  };

  useEffect(() => {
    getTarefaDetail();
  }, []);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Visualizar tarefa</li>
                    <li>{titleStr(scope)}</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">{titleStr(scope)}</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            {handleRender(scope)}
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default VisualizarTarefaUser;
