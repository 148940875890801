// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Classe para estilizar as linhas/dados da tabela */
.MuiDataGrid-root .MuiDataGrid-cell {
  margin: 5px 0px 5px 0px;
}

.MuiDataGrid-row {
  border-left: 5px solid transparent;
}

.urgente {
  /* background-color: rgb(252, 174, 174) !important; */
  border-left: 5px solid red;
}

.ocultar {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/apps/usuarios/css/Filas.css"],"names":[],"mappings":"AAAA,oDAAoD;AACpD;EACE,uBAAuB;AACzB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,qDAAqD;EACrD,0BAA0B;AAC5B;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":["/* Classe para estilizar as linhas/dados da tabela */\n.MuiDataGrid-root .MuiDataGrid-cell {\n  margin: 5px 0px 5px 0px;\n}\n\n.MuiDataGrid-row {\n  border-left: 5px solid transparent;\n}\n\n.urgente {\n  /* background-color: rgb(252, 174, 174) !important; */\n  border-left: 5px solid red;\n}\n\n.ocultar {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
