import { createContext, useState, useContext, useEffect, useRef } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // Initialize state variables
  const userStates = {
    cargoUsuario: useState(null),
    gruposUsuario: useState(null),
    idUsuario: useState(null),
    nomeUsuario: useState(null),
    roleUsuario: useState(null),
    setorUsuario: useState(null),
    telefone1Usuario: useState(null),
    telefone2Usuario: useState(null),
    usuarioInterno: useState(null),
  };

  // Initialize refs explicitly for each state
  const userRefs = {
    cargoUsuarioRef: useRef(null),
    gruposUsuarioRef: useRef(null),
    idUsuarioRef: useRef(null),
    nomeUsuarioRef: useRef(null),
    roleUsuarioRef: useRef(null),
    setorUsuarioRef: useRef(null),
    telefone1UsuarioRef: useRef(null),
    telefone2UsuarioRef: useRef(null),
    usuarioInternoRef: useRef(null),
  };

  // Update refs when state changes
  useEffect(() => {
    Object.entries(userStates).forEach(([key, [state]]) => {
      const refName = `${key}Ref`; // Dynamically generate the ref name
      userRefs[refName].current = state; // Assign the state to the respective ref
    });
  }, [userStates]);

  // Prepare context value dynamically
  const contextValue = Object.fromEntries(
    Object.entries(userStates).flatMap(([key, [state, setState]]) => [
      [key, state],
      [`set${key.charAt(0).toUpperCase() + key.slice(1)}`, setState],
    ])
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
