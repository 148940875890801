import React, { useState } from 'react';
import axios from 'axios';
import MainLayout from '../layouts/MainLayout';
import './css/Formularios.css';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import { useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import AlertMessage from '../layouts/AlertMessage';

const FormCriarPDM = () => {
  const [fields, setFields] = useState([
    { key: '', values: [], obrigatorio: true },
  ]);
  const [pdmName, setPdmName] = useState('');
  const location = useLocation();
  // const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const { REACT_APP_BASE_API_URL } = process.env;

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const addFields = () => {
    const newFields = [...fields, { key: '', values: [''], obrigatorio: true }];
    setFields(newFields);
  };

  const removeField = (fieldIndex) => {
    const newFields = fields.filter((_, index) => index !== fieldIndex);
    setFields(newFields);
  };

  const unidecodeString = (str) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace('.', '')
      .replace(',', '')
      .toUpperCase();
  };

  const handleChangeKey = (index, event) => {
    const newFields = [...fields];
    newFields[index].key = unidecodeString(event.target.value);
    setFields(newFields);
  };

  const handleChangeValue = (fieldIndex, valueIndex, event) => {
    const newFields = [...fields];
    newFields[fieldIndex].values[valueIndex] = event.target.value;
    setFields(newFields);
  };

  const addValue = (fieldIndex) => {
    const newFields = [...fields];
    newFields[fieldIndex].values.push('');
    setFields(newFields);
  };

  const removeValue = (fieldIndex, valueIndex) => {
    // if (fields[fieldIndex].values.length > 1) {
    const newFields = [...fields];
    newFields[fieldIndex].values = newFields[fieldIndex].values.filter(
      (_, index) => index !== valueIndex
    );
    setFields(newFields);
    // }
  };

  const handleChangeObrigatorio = (fieldIndex, event) => {
    const newFields = [...fields];
    newFields[fieldIndex].obrigatorio = event.target.checked;
    setFields(newFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Filtrar campos onde a chave não é nula e a lista de valores não está vazia
    const filteredFields = fields.filter((field) => field.key);

    // const filteredFields = fields.filter(field => field.key && field.values.some(value => value.trim() !== ''));

    const data = filteredFields.reduce((acc, field) => {
      acc[field.key] = {
        Obrigatorio: field.obrigatorio,
        Valores: field.values.some((value) => value.trim() !== '')
          ? field.values.filter((value) => value.trim() !== '')
          : [],
      };
      return acc;
    }, {});

    const payload = { [pdmName]: data };

    // Verificar se há campos adicionais além do nome
    if (Object.keys(payload[pdmName]).length === 0) {
      // alert("Por favor, complete pelo menos um campo antes de enviar.");
      setAlertMessage(
        'Por favor, complete pelo menos um campo antes de enviar.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    let headers = {
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/pdms/criar-pdm`,
        payload,
        { headers }
      );
      if (response.status === 201) {
        // alert("Template de PDM enviado para análise.")
        setAlertMessage('Template de PDM enviado para análise.');
        setAlertSeverity('success');
        setAlertOpen(true);
        setPdmName('');
        setFields([{ key: '', values: [''], obrigatorio: false }]);
      }
    } catch (error) {
      console.error(error);
      // alert(error.response.data.detail);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao enviar template de PDM.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Engenharia</li>
                    {location.state && location.state.route === 'equips' && (
                      <li>Equipamentos</li>
                    )}
                    {location.state &&
                      (location.state.route === 'mats' ||
                        location.state.route === 'pdms') && <li>Materiais</li>}
                    <li>Criar PDM</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Criar PDM</h1>
                  </div>
                </div>
                <div className="level-right" style={{ display: 'none' }}>
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Criar Padrão de Descrição de Material (PDM)
                </p>
              </header>
              <div
                className="card-content"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <form onSubmit={handleSubmit}>
                  <div className="field" style={{ marginTop: '15px' }}>
                    <label className="label">
                      Nome do PDM
                      <span className="required-simbol" aria-hidden="true">
                        *
                      </span>
                    </label>
                    <div className="div-input">
                      <input
                        className="input"
                        type="text"
                        value={pdmName}
                        onChange={(e) =>
                          setPdmName(unidecodeString(e.target.value))
                        }
                        required
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div>

                  {fields.map((field, fieldIndex) => (
                    <div className="field" key={fieldIndex}>
                      <div
                        className="div-input-form-pdm"
                        style={{
                          paddingTop: '20px',
                          display: 'flex',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div
                          className="div-required"
                          style={{
                            margin: '0px 30px 0px 0px',
                          }}
                        >
                          <label className="label">Obrigatório</label>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Checkbox
                              checked={field.obrigatorio}
                              onChange={(e) =>
                                handleChangeObrigatorio(fieldIndex, e)
                              }
                              sx={{
                                '& .MuiSvgIcon-root': {
                                  fill: '#2B3BED',
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            marginRight: '15px',
                          }}
                        >
                          <label className="label">
                            Campo
                            <span
                              className="required-simbol"
                              aria-hidden="true"
                            >
                              *
                            </span>
                          </label>
                          <div className="div-input">
                            <input
                              type="text"
                              className="input"
                              placeholder="Campo"
                              required
                              value={field.key}
                              onChange={(e) => handleChangeKey(fieldIndex, e)}
                              style={{
                                width: '400px',
                              }}
                            />
                          </div>
                        </div>

                        <div style={{ width: '700px' }}>
                          {field.values.length > 0 && (
                            <label className="label">
                              Valores
                              <Tooltip
                                placement="right-end"
                                title={
                                  <div
                                    style={{
                                      textAlign: 'justify',
                                      fontSize: '15px',
                                    }}
                                  >
                                    <b>
                                      <p>
                                        {
                                          'Adicione opções de valores que deseje atribuir ao campo, caso queira limitar as opções para o usuário informar. Exemplo:'
                                        }
                                      </p>
                                    </b>
                                    <br></br>
                                    <div
                                      style={{
                                        display: 'flex',
                                        width: '100%',
                                      }}
                                    >
                                      <div
                                        style={{
                                          paddingRight: '10px',
                                          width: '100%',
                                        }}
                                      >
                                        <b>
                                          <p>Campo</p>
                                        </b>
                                        <input
                                          type="text"
                                          className="input"
                                          disabled
                                          value="Cor"
                                          style={{
                                            width: '100%',
                                          }}
                                        />
                                      </div>
                                      <br></br>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          width: '100%',
                                        }}
                                      >
                                        <b>
                                          <p>Valores</p>
                                        </b>
                                        <input
                                          type="text"
                                          className="input"
                                          disabled
                                          value="Azul"
                                          style={{
                                            width: '100%',
                                            marginBottom: '10px',
                                          }}
                                        />
                                        <input
                                          type="text"
                                          className="input"
                                          disabled
                                          value="Verde"
                                          style={{
                                            width: '100%',
                                          }}
                                        />
                                        <p
                                          style={{
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            fontSize: '15px',
                                          }}
                                        >
                                          ...
                                        </p>
                                        <br></br>
                                      </div>
                                    </div>

                                    <br></br>
                                    <b>
                                      <p>
                                        {
                                          'Ou deixe sem valores, caso queira um campo livre para o usuário preencher como desejar. Exemplo:'
                                        }
                                      </p>
                                    </b>
                                    <br></br>
                                    <b>
                                      <p>Campo</p>
                                    </b>
                                    <input
                                      type="text"
                                      className="input"
                                      disabled
                                      value="Largura"
                                      style={{
                                        width: '50%',
                                        marginBottom: '10px',
                                      }}
                                    />
                                  </div>
                                }
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      maxWidth: '35rem',
                                    },
                                  },
                                }}
                                arrow
                              >
                                <span
                                  style={{
                                    color: '#ff3e3e',
                                    marginLeft: '6px',
                                    padding: '2px 7px 0px',
                                    fontSize: '13px',
                                    border: '1px solid #ff3e3e',
                                    borderRadius: '15px',
                                  }}
                                >
                                  ?
                                </span>
                              </Tooltip>
                            </label>
                          )}
                          {field.values.map((value, valueIndex) => (
                            <React.Fragment key={valueIndex}>
                              <div
                                className="div-input"
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <input
                                  type="text"
                                  className="input"
                                  placeholder="Valor"
                                  value={value}
                                  onChange={(e) =>
                                    handleChangeValue(fieldIndex, valueIndex, e)
                                  }
                                  style={{
                                    marginBottom: '10px',
                                    width: '650px',
                                  }}
                                />
                                <div className="div-btn-delete-valor">
                                  <IconButton
                                    style={{
                                      padding: '10px 8px 0px 15px',
                                    }}
                                    color="secondary"
                                    onClick={() =>
                                      removeValue(fieldIndex, valueIndex)
                                    }
                                  >
                                    <ClearSharpIcon fontSize="small" />
                                  </IconButton>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                          {/* <div className='div-add-linha' style={{ width:'612px', display: 'flex', justifyContent: 'center'  }} >
                            <IconButton                            
                              onClick={() => addValue(fieldIndex)}
                              color="secondary"
                            >
                              <AddIcon />
                            </IconButton>
                            <label className="label-form" style={{fontSize: '10px', cursor: 'pointer', marginTop: '12px'}} >
                              Adicionar valor
                            </label>
                          </div> */}
                          <div
                            className="div-add-linha"
                            onClick={() => addValue(fieldIndex)}
                            style={{
                              width: '612px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'flex-end',
                            }}
                          >
                            <span
                              className="icon"
                              style={{
                                fontSize: '16px',
                                color: '#2B3BED',
                                cursor: 'pointer',
                              }}
                            >
                              <i className="fa-solid fa-plus"></i>
                            </span>
                            <label
                              className="label"
                              style={{
                                fontSize: '13px',
                                cursor: 'pointer',
                                marginTop: '12px',
                              }}
                            >
                              Adicionar valor
                            </label>
                          </div>
                        </div>
                        {/* <div className='div-delete-linha' style={{ margin: '37px 0px 0px 10px', width: '100px'}} >
                          <IconButton color="secondary" style={{ padding: '0px 8px 0px 8px', display: 'flex', flexDirection: 'column'  }} onClick={() => removeField(fieldIndex)}>
                            <DeleteIcon fontSize='small'  />
                              <label className='label-form' style={{fontSize: '10px', cursor: 'pointer', margin: '5px 0px 5px 0px' }}>
                                Deletar campo
                              </label>
                          </IconButton>
                        </div> */}
                        <div
                          className="div-delete-linha"
                          onClick={() => removeField(fieldIndex)}
                          style={{
                            margin: '37px 0px 0px 10px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <span
                            className="icon"
                            style={{
                              padding: '0px 8px 0px 8px',
                              fontSize: '16px',
                              color: '#2B3BED',
                              cursor: 'pointer',
                            }}
                          >
                            <i className="fa-solid fa-trash-can"></i>
                          </span>
                          <label
                            className="label"
                            style={{
                              fontSize: '13px',
                              cursor: 'pointer',
                              margin: '5px 0px 5px 0px',
                            }}
                          >
                            Deletar campo
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* <div className="btn-sup">
                    <button className="button-form" type="button" onClick={addFields}>Adicionar Campos</button>
                    <button className="button-form" type="submit" style={{ marginLeft: '10px' }}>Enviar</button>
                  </div> */}
                  <div
                    style={{
                      margin: '40px 0px 20px 0px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      className="button is-secondary"
                      onClick={addFields}
                      style={{
                        borderRadius: '20px',
                        marginBottom: '7px',
                      }}
                    >
                      <span className="icon">
                        <i className="fa-solid fa-plus"></i>
                      </span>
                    </button>
                    <span style={{ cursor: 'pointer' }} onClick={addFields}>
                      Adicionar Campo
                    </span>
                  </div>
                  <div
                    style={{
                      margin: '40px 0px 20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button
                      className="button is-primary"
                      type="submit"
                      style={{ width: '160px' }}
                    >
                      <span className="icon">
                        <i className="fa-solid fa-paper-plane"></i>
                      </span>
                      <span>Enviar</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default FormCriarPDM;
