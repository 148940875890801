import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import AlertMessage from '../layouts/AlertMessage';

const FornecedorFreteInclusionView = ({ onAddHandler, info }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [allFornecedores, setAllFornecedores] = useState([]);

  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleAddClick = (obj) => {
    onAddHandler(obj);
    // alert(`${obj.razao_social} incluido.`)
    setAlertMessage(`${obj.razao_social} incluido.`);
    setAlertSeverity('success');
    setAlertOpen(true);
  };

  const columns = [
    { field: 'razao_social', headerName: 'FORNECEDOR', flex: 3 },
    { field: 'email', headerName: 'EMAIL', flex: 2 },
    { field: 'cnae', headerName: 'CNAE', flex: 1 },
    { field: 'tags', headerName: 'TAGS', flex: 1 },
    {
      field: 'acao',
      headerName: 'AÇÃO',
      flex: 1,
      renderCell: (params) => {
        const obj = allFornecedores.find(
          (obj) => obj.email === params.row.email
        );

        return (
          <div>
            <span className="icon" style={{ margin: '0px 10px' }}>
              <i
                className="fa-solid fa-circle-plus"
                style={{
                  cursor: 'pointer',
                  fontSize: '20px',
                  color: '#2B3BED',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleAddClick(obj);
                }}
              ></i>
            </span>
          </div>
        );
      },
    },
  ];

  const getFornecedores = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_API_URL}/fornecedor`, {
        params: {
          tag: 'frete',
        },
        headers: headers,
      });
      if (response.status === 200) {
        const data = await response.data;
        setAllFornecedores(data.fornecedores);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar fornecedores.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getFornecedores();
  }, []);

  return (
    <>
      <div className="container" style={{ width: 800 }}>
        <div style={{ margin: '20px' }}>
          <DataGrid
            rows={allFornecedores}
            columns={columns}
            pageSize={5}
            disableSelectionOnClick
            getRowHeight={() => 'auto'}
            getColumnHeight={() => 'auto'}
            sx={{
              color: 'white',
              fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'flex-start', // Alinha o conteúdo no topo
                paddingTop: '4px', // Ajuste conforme necessário
                paddingBottom: '4px',
                border: '0px',
                fontFamily: '"Nunito", sans-serif',
                lineHeight: '1.5',
                fontSize: '13px',
              },
              '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle': {
                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                fontSize: '14px',
                color: '#4a4a4a',
                fontFamily: '"Nunito", sans-serif',
                lineHeight: '1.5',
              },
              '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders':
                {
                  backgroundColor: '#ffffff',
                },
              '& .MuiDataGrid-row': {
                borderLeft: '0px',
              },
              '& .css-wop1k0-MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                color: 'black',
              },
              '& .css-qvtrhg-MuiDataGrid-virtualScroller': {
                backgroundColor: 'white',
                color: 'black',
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default FornecedorFreteInclusionView;
