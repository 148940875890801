import React, { useState, useEffect } from 'react';
import MainLayout from '../../layouts/MainLayout';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import FormModal from '../../layouts/FormModal';
import ListaMateriaisAvulsaView from '../../apps/listas/ListaMateriais/ListaMateriaisAvulsaView';
import MISDetailView from './MISDetailView';
import AlertMessage from '../../layouts/AlertMessage';
import { useUser } from '../../../context/UserContext';

const MISHome = () => {
  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;
  const [allMis, setAllMis] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedMisId, setSelectedMisId] = useState(null);
  const { usuarioId } = useUser();
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const handleOpenModal = (misId) => {
    setSelectedMisId(misId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedMisId(null);
    setModalOpen(false);
  };

  const handleEditLista = (listaId) => {
    handleOpenModal(listaId);
  };

  const getListasAvulsas = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_API_URL}/mis/`, {
        params: { user_id: usuarioId },
        headers,
      });
      const data = await response.data;
      setAllMis(data.mis);
    } catch (error) {
      console.error(error);
      // alert("Erro ao buscar listas do usuário \n", error.response.data.detail)
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar listas do usuário'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  const columns = [
    { field: 'did', headerName: 'ID', flex: 1 },
    { field: 'sigla', headerName: 'SIGLA', flex: 1 },
    { field: 'area', headerName: 'AREA', flex: 1 },
    { field: 'subarea', headerName: 'S.AREA', flex: 1 },
    { field: 'meio', headerName: 'MEIO', flex: 1 },
    { field: 'display', headerName: 'DISPLAY', flex: 2 },
    { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 4 },
    {
      field: 'preco',
      headerName: 'PREÇO',
      flex: 1,
      renderCell: (params) => <div>{formatPreco(params.row.preco)}</div>,
    },
    {
      field: 'expandir',
      headerName: 'EXPANDIR',
      flex: 1,
      renderCell: (params) => (
        <div style={{ paddingLeft: '17px', width: '100%' }}>
          <IconButton
            onClick={() => {
              handleOpenModal(params.row.id);
            }}
            color="black"
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getListasAvulsas();
  }, []);

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Engenharia</li>
                    <li>Lista de MIS</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">MIS</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button
                      className="button is-primary"
                      onClick={() => navigate('/meic/mis/form/')}
                    >
                      Novo MIS
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Listas de MIS cadastrados
                </p>
              </header>
              <div className="card-content">
                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="TabelaMateriais">
                      <div style={{ width: '100%' }}>
                        <DataGrid
                          rows={allMis}
                          columns={columns}
                          pageSize={5}
                          disableSelectionOnClick
                          getRowHeight={() => 'auto'}
                          sx={{
                            fontFamily: '"Nunito", sans-serif',
                            '& .MuiDataGrid-cell': {
                              display: 'flex',
                              alignItems: 'flex-start',
                              paddingTop: '4px',
                              paddingBottom: '4px',
                              border: '0px',
                              fontFamily: '"Nunito", sans-serif',
                              lineHeight: '1.5',
                              fontSize: '16px',
                            },
                            '& .MuiDataGrid-row': {
                              borderLeft: '0px',
                            },
                            '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                              {
                                fontWeight: '600',
                                fontSize: '16px',
                                color: '#363636',
                                fontFamily: '"Nunito", sans-serif',
                                lineHeight: '1.5',
                              },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FormModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              modalTitle={'Visualizar Lista'}
            >
              {isModalOpen && selectedMisId && (
                <MISDetailView misId={selectedMisId} info={info} />
              )}
            </FormModal>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default MISHome;
