import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AlertMessage from '../../layouts/AlertMessage';

const CapexDetailModalView = ({ capex_id, onAddHandler }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [capexDetails, setCapexDetails] = useState({});
  const [capexItems, setCapexItems] = useState([]);
  const [targetCol, setTargetCol] = useState([]);
  const [targetSearch, setTargetSearch] = useState('');
  const [capexCusto, setCapexCusto] = useState(0.0);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  const getDetails = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/capex/detail/`,
        {
          params: {
            capex_id: capex_id,
          },
          headers: headers,
        }
      );
      const data = await response.data;
      setCapexDetails(data.capex);
      setCapexItems(data.linhas);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar detalhes do Capex'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleAddClick = (obj) => {
    onAddHandler(obj);
    // alert(`Linha - Ref: ${obj.ref} incluida`)
    setAlertMessage(`Linha - Ref: ${obj.ref} incluida`);
    setAlertSeverity('success');
    setAlertOpen(true);
  };

  const handleFilterLinhas = async (e) => {
    e.preventDefault();
    try {
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    getDetails();
  }, [capex_id]);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <table className="table is-fullwidth">
        <thead>
          <th>ID</th>
          <th>EMPREENDIMENTO</th>
          <th>PROJETO</th>
          <th>FASE</th>
          <th>REV</th>
          <th>VER</th>
          <th>CUSTO</th>
        </thead>
        <tbody>
          <tr>
            <td>{capexDetails.did}</td>
            <td>{capexDetails.empreendimento}</td>
            <td>{capexDetails.projeto}</td>
            <td>
              {capexDetails.fase && (
                <p
                  className={`${capexDetails.fase.charAt(0) === 'C' ? 'tag is-danger' : capexDetails.fase.charAt(0) === 'B' ? 'tag is-warning' : 'tag is-success'}`}
                >
                  {capexDetails.fase}
                </p>
              )}
            </td>
            <td>{capexDetails.rev}</td>
            <td>{capexDetails.ver}</td>
            <td>{formatPreco(capexDetails.custo)}</td>
          </tr>
        </tbody>
      </table>

      <div className="divider is-size-6">LINHAS</div>

      <div onClick={(event) => event.stopPropagation()}>
        <form onSubmit={handleFilterLinhas}>
          <div className="field has-addons">
            <p className="control">
              <span className="select">
                <select onChange={(e) => setTargetCol(e.target.value)}>
                  <option value={'rev'}>REV</option>
                  <option value={'grupo'}>GRUPO</option>
                  <option value={'disciplina'}>DISCIPLINA</option>
                  <option value={'area'}>AREA</option>
                  <option value={'mis'}>MIS</option>
                  <option value={'item'}>ITEM</option>
                </select>
              </span>
            </p>
            <p className="control is-expanded">
              <input
                className="input"
                type="text"
                placeholder="Texto"
                onChange={(e) => {
                  setTargetSearch(e.target.value);
                }}
              />
            </p>
            <p className="control">
              <button className="button is-primary" type="submit">
                Procurar
              </button>
            </p>
          </div>

          <p className="help has-text-white">Separe os termos por vírgula</p>
        </form>
      </div>

      <table className="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>ID</th>
            <th>REV</th>
            <th>GRUPO</th>
            <th>DISCIPLINA</th>
            <th>MIS</th>
            <th>ITEM</th>
            <th>UND</th>
            <th>QTD</th>
            <th>PREÇO</th>
            <th>PREÇO TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {capexItems.map((linha, i) => (
            <tr
              key={i}
              onClick={(e) => {
                e.preventDefault();
                handleAddClick(linha);
              }}
            >
              <td>
                <p className="is-size-7">{linha.did}</p>
              </td>
              <td>
                <p className="is-size-7">{linha.rev}</p>
              </td>
              <td>
                <p className="is-size-7">{linha.grupo}</p>
              </td>
              <td>
                <p className="is-size-7">{linha.disciplina}</p>
              </td>
              <td>
                <p className="is-size-7">{linha.mis}</p>
              </td>
              <td>
                <p className="is-size-7">{linha.descricao}</p>
              </td>
              <td>
                <p className="is-size-7">{linha.unidade}</p>
              </td>
              <td>
                <p className="is-size-7">{linha.quantidade}</p>
              </td>
              <td>
                <p className="is-size-7">{formatPreco(linha.preco_und)}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default CapexDetailModalView;
