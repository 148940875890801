import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';

import FormModal from '../../layouts/FormModal';
import { useNavigate } from 'react-router-dom';

import AdminSetorForm from './AdminSetorForm';
import AdminDisciplinaForm from './AdminDisciplinaForm';

import AlertMessage from '../../layouts/AlertMessage';

const AdminAdmTable = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const [allSetores, setAllSetores] = useState(null);
  const [allDisciplinas, setAllDisciplinas] = useState(null);
  const [modalType, setModalType] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleOpenModal = (type) => {
    setModalType(type);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalType('');
  };

  const disciplina_columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'disciplina', headerName: 'DISCIPLINA', flex: 1 },
  ];

  const setor_columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'setor', headerName: 'SETOR', flex: 1 },
  ];

  const getSetores = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/setores/`,
        {
          headers,
        }
      );
      if (response.status === 200) {
        setAllSetores(response.data);
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || "Ocorreu um erro.")
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar setores.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getDisciplinas = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/disciplinas/`,
        {
          headers,
        }
      );
      if (response.status === 200) {
        setAllDisciplinas(response.data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Error ao buscar disciplinas.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  useEffect(() => {
    getSetores();
    getDisciplinas();
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <div className="columns">
        <div className="column">
          <div className="card">
            <header className="card-header">
              <p
                className="card-header-title"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Setores
                </div>
                <div>
                  <button
                    className="button is-primary"
                    type="button"
                    onClick={() => handleOpenModal('setor')}
                  >
                    Novo Setor
                  </button>
                </div>
              </p>
            </header>
            <div
              className="card-content"
              style={{
                display: 'flex',
                margin: '0px 0px 30px 0px',
              }}
            >
              {allSetores && (
                <DataGrid
                  rows={allSetores}
                  columns={setor_columns}
                  pageSize={5}
                  disableRowSelectionOnClick
                  getRowHeight={() => 'auto'}
                  getColumnHeight={() => 'auto'}
                  sx={{
                    '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                      {
                        fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                        fontSize: '16px',
                        color: '#171717',
                        // fontFamily: '"Nunito", sans-serif',
                        lineHeight: '2.5',
                        borderLeft: '5px solid transparent',
                      },
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <div className="column">
          <div className="card">
            <div className="card-header">
              <p
                className="card-header-title"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Disciplinas
                </div>

                <div>
                  <button
                    className="button is-primary"
                    type="button"
                    onClick={() => handleOpenModal('disciplina')}
                  >
                    Nova Disciplina
                  </button>
                </div>
              </p>
            </div>
            <div
              className="card-content"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {allDisciplinas && (
                <DataGrid
                  rows={allDisciplinas}
                  columns={disciplina_columns}
                  pageSize={5}
                  disableRowSelectionOnClick
                  getRowHeight={() => 'auto'}
                  getColumnHeight={() => 'auto'}
                  sx={{
                    '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                      {
                        fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                        fontSize: '16px',
                        color: '#171717',
                        // fontFamily: '"Nunito", sans-serif',
                        lineHeight: '2.5',
                        borderLeft: '5px solid transparent',
                      },
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <FormModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        modalTitle={`Cadastrar ${modalType}`}
      >
        {isModalOpen && modalType === 'setor' && <AdminSetorForm info={info} />}
        {isModalOpen && modalType === 'disciplina' && (
          <AdminDisciplinaForm info={info} />
        )}
      </FormModal>
    </>
  );
};

export default AdminAdmTable;
