import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { cnpjMask } from '../../functional/cnpjMask';
import { cepMask } from '../../functional/cepMask';
import { cnaeMask } from '../../functional/cnaeMask';
import axios from 'axios';
import Checkbox from '@mui/material/Checkbox';
import AlertMessage from '../../layouts/AlertMessage';

const ClientesFormJuridico = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [allBancos, setAllBancos] = useState([]);
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [email, setEmail] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState(0);
  const [bairro, setBairro] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [cep, setCep] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [cnae, setCnae] = useState('');
  const [nomeResponsavel, setNomeResponsavel] = useState('');
  const [emailResponsavel, setEmailResponsavel] = useState('');
  const [cargoResponsavel, setCargoResponsavel] = useState('');
  const [banco, setBanco] = useState('');
  const [agencia, setAgencia] = useState('');
  const [conta, setConta] = useState('');
  const [digito, setDigito] = useState('');
  const [telefone1, setTelefone1] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [clienteReferencia, setClienteReferencia] = useState('');
  const [contemImposto, setContemImposto] = useState(false);
  const [centro, setCentro] = useState('');
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const listaEstados = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  const headers = {
    'Content-Type': 'application/json',
  };

  const getBancos = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/tools/bancos`,
        { headers }
      );
      const bancosData = await response.data;
      const nl = bancosData.map((item) => `${item.compe} - ${item.nome}`);
      setAllBancos(nl);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar lista de bancos'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const capitalizar = (str) => {
    const words = str.split(' ');
    const titlezedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return titlezedWords.join(' ');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      id: null,
      razao_social: razaoSocial.toUpperCase(),
      nome_fantasia: nomeFantasia.toUpperCase(),
      cliente_referencia: clienteReferencia,
      contem_imposto: contemImposto,
      // org_vendas: orgVendas,
      // canal: canalDist,
      // setor_atividade: setorAtv,
      email: email,
      centro: centro,
      logradouro: logradouro.toUpperCase(),
      numero: numero,
      bairro: bairro.toUpperCase(),
      complemento: complemento.toUpperCase(),
      cidade: cidade.toUpperCase(),
      estado: estado.toUpperCase(),
      cep: cep,
      cnpj: cnpj,
      cnae: cnae,
      nome_responsavel: nomeResponsavel,
      email_responsavel: emailResponsavel,
      cargo_responsavel: cargoResponsavel,
      banco: banco,
      numero_banco: banco.split(' - ')[0].trim(),
      agencia: agencia,
      conta: conta,
      digito: digito,
      telefone1: telefone1,
      telefone2: telefone2,
      inscricao_estadual: inscricaoEstadual,
    };

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/fornecedor/clientes/`,
        formData,
        { headers }
      );
      if (response.status === 201) {
        // alert("Solicitação de cadastro de cliente enviada.")
        setAlertMessage('Solicitação de cadastro de cliente enviada.');
        setAlertSeverity('success');
        setAlertOpen(true);
        setRazaoSocial('');
        setNomeFantasia('');
        // setOrgVendas('');
        setClienteReferencia('');
        setContemImposto(false);
        // setCanalDist('');
        // setSetorAtv('');
        setEmail('');
        setCentro('');
        setLogradouro('');
        setNumero(0);
        setBairro('');
        setComplemento('');
        setCidade('');
        setEstado('');
        setCep('');
        setCnpj('');
        setCnae('');
        setNomeResponsavel('');
        setEmailResponsavel('');
        setCargoResponsavel('');
        setBanco('');
        setAgencia('');
        setConta('');
        setDigito('');
        setTelefone1('');
        setTelefone2('');
        setInscricaoEstadual('');
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao cadastrar cliente'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const cnpjCheckerReceita = async (cnpj) => {
    const minhaBaseReceitaURL = `https://minhareceita.org/${String(cnpj)}`;
    try {
      const response = await axios.get(minhaBaseReceitaURL);
      const data = await response.data;
      if (response.status === 200) {
        setLogradouro(
          `${capitalizar(data.descricao_tipo_de_logradouro)} ${capitalizar(data.logradouro)}`
        );
        setComplemento(capitalizar(data.complemento));
        setNumero(data.numero);
        setCidade(capitalizar(data.municipio));
        setBairro(capitalizar(data.bairro));
        setEstado(data.uf);
        setCep(data.cep);
        setCnae(String(data.cnae_fiscal));
        setRazaoSocial(data.razao_social);
      }
    } catch (error) {
      setAlertMessage('CNPJ Inválido');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getBancos();
  }, []);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Suprimentos</li>
                    <li>Cadastrar Clientes</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Cadastrar Clientes</h1>
                  </div>
                </div>
                <div className="level-right" style={{ display: 'none' }}>
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Informações gerais
                </p>
              </header>
              <div className="card-content">
                <form name="cadastrarFornecedor" onSubmit={handleSubmit}>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Razão Social
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={razaoSocial}
                            onChange={(e) => setRazaoSocial(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-building"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Email Principal
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="email"
                            placeholder="exemplo@gmail.com"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-envelope"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Nome Fantasia
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={nomeFantasia}
                            onChange={(e) => setNomeFantasia(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-pen-to-square"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        CNPJ
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder="00.000.000/0001-00"
                            required
                            value={cnpjMask(cnpj)}
                            onChange={(e) => setCnpj(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-stamp"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        CNAE
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={cnaeMask(cnae)}
                            onChange={(e) => setCnae(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-gear"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Telefone principal
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder="+550XX0000000"
                            required
                            value={telefone1}
                            onChange={(e) => setTelefone1(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-phone"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Telefone secundário</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder="+550XX0000000"
                            value={telefone2}
                            onChange={(e) => setTelefone2(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-phone"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Empresa
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <select
                              name="centro"
                              value={centro}
                              required
                              onChange={(e) => setCentro(e.target.value)}
                            >
                              <option value="" disabled></option>
                              <option value={'5000 - GEO ELÉTRICA'}>
                                5000 - GEO ELÉTRICA
                              </option>
                              <option value={'5100 - GEO ENERGÉTICA'}>
                                5100 - GEO ENERGÉTICA
                              </option>
                              <option value={'5701 - CRI GEO'}>
                                5700 - CRI GEO
                              </option>
                            </select>
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Inscrição Estadual
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={inscricaoEstadual}
                            onChange={(e) =>
                              setInscricaoEstadual(e.target.value)
                            }
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-stamp"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Cliente - Referência
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={clienteReferencia}
                            onChange={(e) =>
                              setClienteReferencia(e.target.value)
                            }
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-users-viewfinder"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Contém imposto?</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <Checkbox
                            checked={contemImposto}
                            onChange={(e) => setContemImposto(e.target.checked)}
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fill: '#2B3BED',
                              },
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="field is-horizontal">
                                    <div className="field-label is-normal">
                                        <label className="label">Organização de Vendas</label>
                                    </div>
                                    <div className="field-body">
                                        <div className="field">
                                            <p className="control is-expanded has-icons-left">
                                                <input 
                                                    className="input"
                                                    type="text" 
                                                    placeholder=""
                                                    value={orgVendas}
                                                    onChange={(e) => setOrgVendas(e.target.value)}
                                                />
                                                <span className="icon is-small is-left"><i className="fa-solid fa-shield"></i></span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="field is-horizontal">
                                    <div className="field-label is-normal">
                                        <label className="label">Canal de Distribuição</label>
                                    </div>
                                    <div className="field-body">
                                        <div className="field">
                                            <p className="control is-expanded has-icons-left">
                                                <input 
                                                    className="input"
                                                    type="text" 
                                                    placeholder=""
                                                    value={canalDist}
                                                    onChange={(e) => setCanalDist(e.target.value)}
                                                />
                                                <span className="icon is-small is-left"><i className="fa-solid fa-diagram-project"></i></span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="field is-horizontal">
                                    <div className="field-label is-normal">
                                        <label className="label">Setor de Atividade</label>
                                    </div>
                                    <div className="field-body">
                                        <div className="field">
                                            <p className="control is-expanded has-icons-left">
                                                <input 
                                                    className="input"
                                                    type="text" 
                                                    placeholder=""
                                                    value={setorAtv}
                                                    onChange={(e) => setSetorAtv(e.target.value)}
                                                />
                                                <span className="icon is-small is-left"><i className="fa-solid fa-diagram-project"></i></span>
                                            </p>
                                        </div>
                                    </div>
                                </div> */}

                  <header className="card-header">
                    <p
                      className="card-header-title"
                      style={{ paddingLeft: '0px' }}
                    >
                      <span className="icon" style={{ width: '16px' }}>
                        <i className="mdi mdi-ballot"></i>
                      </span>
                      Endereço
                    </p>
                  </header>
                  <br />

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: '0px 40px 30px 0px',
                    }}
                  >
                    <p
                      className="button is-primary"
                      onClick={() => cnpjCheckerReceita(cnpj)}
                    >
                      Preencher pelo CNPJ
                    </p>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Logradouro
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={logradouro}
                            onChange={(e) => setLogradouro(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-house"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Complemento</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            value={complemento}
                            onChange={(e) => setComplemento(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-house-chimney-medical"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Número
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="number"
                            placeholder=""
                            required
                            step={'1'}
                            value={numero}
                            onChange={(e) => setNumero(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-map-pin"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Cidade
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={cidade}
                            onChange={(e) => setCidade(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-map-location-dot"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Bairro
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={bairro}
                            onChange={(e) => setBairro(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-location-dot"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Estado
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <select
                              name="estado"
                              value={estado}
                              required
                              onChange={(e) => setEstado(e.target.value)}
                            >
                              <option value="" disabled></option>
                              {listaEstados.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-flag"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        CEP
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={cepMask(cep)}
                            onChange={(e) => setCep(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-city"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <header className="card-header">
                    <p
                      className="card-header-title"
                      style={{ paddingLeft: '0px' }}
                    >
                      <span className="icon" style={{ width: '16px' }}>
                        <i className="mdi mdi-ballot"></i>
                      </span>
                      Responsável
                    </p>
                  </header>

                  <br />

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Nome do responsável
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={nomeResponsavel}
                            onChange={(e) => setNomeResponsavel(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-user"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Email do responsável
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={emailResponsavel}
                            onChange={(e) =>
                              setEmailResponsavel(e.target.value)
                            }
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-envelope"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Cargo do responsável
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={cargoResponsavel}
                            onChange={(e) =>
                              setCargoResponsavel(e.target.value)
                            }
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-address-card"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <header className="card-header">
                    <p
                      className="card-header-title"
                      style={{ paddingLeft: '0px' }}
                    >
                      <span className="icon" style={{ width: '16px' }}>
                        <i className="mdi mdi-ballot"></i>
                      </span>
                      Dados bancários
                    </p>
                  </header>
                  <br />

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Banco</label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <select
                              name="estado"
                              value={banco}
                              onChange={(e) => setBanco(e.target.value)}
                              placeholder="Selecione."
                            >
                              <option value="" disabled>
                                Selecione.
                              </option>
                              {allBancos &&
                                allBancos.map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-landmark"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Agência</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            value={agencia}
                            onChange={(e) => setAgencia(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-vault"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Conta</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            value={conta}
                            onChange={(e) => setConta(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-wallet"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Dígito verificador</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            value={digito}
                            onChange={(e) => setDigito(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-coins"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      margin: '40px 0px 20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button className="button is-primary">Cadastrar</button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default ClientesFormJuridico;
