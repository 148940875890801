import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AlertMessage from '../../layouts/AlertMessage';

const AdminSetorForm = ({ info }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [newSetor, setNewSetor] = useState('');
  // const [alertMessage, setAlertMessage] = useState(null);
  // const [alertSeverity, setAlertSeverity] = useState('error');
  // const [alertOpen, setAlertOpen] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [navigateTo, setNavigateTo] = useState("");
  // const [reload, setReload] = useState(false);

  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = {
      setor: newSetor,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/admin/setores/`,
        obj,
        { headers }
      );
      if (response.status === 201) {
        // alert('Setor cadastrado.')
        setAlertMessage('Setor cadastrado.');
        setAlertSeverity('success');
        setAlertOpen(true);
        setReload(true);
        // window.location.reload();
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || 'Ocorreu um erro.')
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao cadastrar setor.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  return (
    <>
      <div className="container">
        <form name="setor" onSubmit={handleSubmit}>
          <div className="field">
            <label className="label" style={{ color: 'white' }}>
              Setor:
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
            </label>
            <input
              className="input"
              type="text"
              required
              value={newSetor}
              onChange={(e) => setNewSetor(e.target.value)}
            />
          </div>
          <br />
          <button
            className="button is-primary"
            style={{ margin: '1px 1px 1px 1px' }}
          >
            Cadastrar
          </button>
        </form>
      </div>
    </>
  );
};

export default AdminSetorForm;
