import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import AlertMessage from '../../layouts/AlertMessage';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Select,
  MenuItem,
  Button,
} from '@mui/material';

const AdminRoleForm = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [roleNome, setRoleNome] = useState('');
  const [roleDescricao, setRoleDescricao] = useState('');

  const [allResources, setAllResources] = useState([]);
  const [rows, setRows] = useState([
    { resource: '', effect: '', acoes: '', availableAcoes: [] },
  ]);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  const getAcoesEResources = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/acoes/`,
        { headers }
      );
      const data = await response.data;
      if (response.status === 200) {
        const uniqueresources = Array.from(
          new Set(data.map((item) => item.prefix))
        );
        setAllResources(uniqueresources);
      }
    } catch (error) {
      console.error(error);
      // alert(error.response.data.detail)
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar recursos e ações.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleResourceChange = async (val, rowIndex) => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/acoes/p/`,
        {
          params: {
            prefix: val,
          },
          headers,
        }
      );
      const data = await response.data;
      if (response.status === 200) {
        const updatedRows = rows.map((row, index) =>
          index === rowIndex
            ? {
                ...row,
                resource: val,
                availableAcoes: data,
                acoes: '',
              }
            : row
        );
        setRows(updatedRows);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar ações.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleEffectChange = (val, rowIndex) => {
    const updatedRows = rows.map((row, index) =>
      index === rowIndex ? { ...row, effect: val } : row
    );
    setRows(updatedRows);
  };

  const handleAcoesChange = (val, rowIndex) => {
    const updatedRows = rows.map((row, index) =>
      index === rowIndex ? { ...row, acoes: val } : row
    );
    setRows(updatedRows);
  };

  const addRow = () => {
    setRows([
      ...rows,
      { resource: '', effect: '', acoes: '', availableAcoes: [] },
    ]);
  };

  const removeRow = (rowIndex) => {
    setRows(rows.filter((row, index) => index !== rowIndex));
  };

  const handleCad = async (e) => {
    e.preventDefault();
    const filteredrows = rows.filter(
      (row) => row.resource && row.acoes && row.effect
    );
    const politicas = filteredrows.map(
      (item, i) => `${item.resource}|${item.acoes}:${item.effect}`
    );
    let requestObj = {
      nome: roleNome,
      descricao: roleDescricao,
      politicas: politicas.join(' '),
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/admin/roles/`,
        requestObj,
        { headers }
      );
      const data = await response.data;
      if (response.status === 201) {
        // alert("Role cadastrado.")
        setAlertMessage('Role cadastrado.');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao cadastrar role.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    getAcoesEResources();
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Configurações</li>
                    <li>Meu perfil</li>
                    <li>Painel ADM</li>
                    <li>Nova role</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Nova role</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="section is-main-section"
            style={{ marginBottom: '50px' }}
          >
            <form name="cadRole" onSubmit={handleCad}>
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Informações da nova role
                  </p>
                </header>
                <div className="card-content">
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Nome</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            required
                            onChange={(e) => setRoleNome(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-shield-halved"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Descrição</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            onChange={(e) => setRoleDescricao(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-file-signature"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Políticas
                  </p>
                  <button
                    type="button"
                    className="button is-secondary"
                    onClick={addRow}
                    style={{
                      color: '#2B3BED',
                      border: '1px solid  #2B3BED',
                      fontSize: '17px',
                      margin: '10px 10px 10px 0px',
                    }}
                  >
                    Adicionar Politica
                  </button>
                </header>
                <div className="card-content">
                  <TableContainer
                    component={Paper}
                    style={{
                      border: '1px solid lightgrey',
                      borderRadius: '5px',
                    }}
                  >
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow
                          style={{
                            display: 'flex',
                            width: '100%',
                            backgroundColor: '#2e323a',
                          }}
                        >
                          <TableCell
                            style={{
                              flex: '4',
                              fontSize: '20px',
                              color: '#ffffff',
                              fontWeight: 'bold',
                              fontFamily: '"Nunito", sans-serif',
                            }}
                          >
                            Recurso
                          </TableCell>
                          <TableCell
                            style={{
                              flex: '4',
                              fontSize: '20px',
                              color: '#ffffff',
                              fontWeight: 'bold',
                              fontFamily: '"Nunito", sans-serif',
                            }}
                            align="right"
                          >
                            Efeito
                          </TableCell>
                          <TableCell
                            style={{
                              flex: '5',
                              fontSize: '20px',
                              color: '#ffffff',
                              fontWeight: 'bold',
                              fontFamily: '"Nunito", sans-serif',
                            }}
                            align="right"
                          >
                            Ações
                          </TableCell>
                          <TableCell
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              width: '110px',
                              fontSize: '20px',
                              color: '#ffffff',
                              fontWeight: 'bold',
                              fontFamily: '"Nunito", sans-serif',
                            }}
                            align="right"
                          >
                            Excluir
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index) => (
                          <TableRow
                            style={{
                              display: 'flex',
                              width: '100%',
                              backgroundColor: '#f7f7f7',
                            }}
                            key={index}
                          >
                            <TableCell
                              style={{
                                flex: 4,
                                fontSize: '20px',
                                fontWeight: '400',
                                border: '0px',
                                fontFamily: '"Nunito", sans-serif',
                              }}
                            >
                              <Select
                                value={row.resource}
                                onChange={(e) =>
                                  handleResourceChange(e.target.value, index)
                                }
                                displayEmpty
                                fullWidth
                              >
                                <MenuItem value="">
                                  <em>Selecione</em>
                                </MenuItem>
                                {allResources.map((resource, i) => (
                                  <MenuItem key={i} value={resource}>
                                    {resource}
                                  </MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                            <TableCell
                              style={{
                                flex: 4,
                                fontSize: '20px',
                                fontWeight: '400',
                                border: '0px',
                                fontFamily: '"Nunito", sans-serif',
                              }}
                            >
                              <Select
                                value={row.effect}
                                onChange={(e) =>
                                  handleEffectChange(e.target.value, index)
                                }
                                displayEmpty
                                fullWidth
                              >
                                <MenuItem value="">
                                  <em>Selecione</em>
                                </MenuItem>
                                <MenuItem value="allow">Permitir</MenuItem>
                                <MenuItem value="deny">Negar</MenuItem>
                              </Select>
                            </TableCell>
                            <TableCell
                              style={{
                                flex: 5,
                                fontSize: '20px',
                                fontWeight: '400',
                                border: '0px',
                                fontFamily: '"Nunito", sans-serif',
                              }}
                            >
                              <Select
                                value={row.acoes}
                                onChange={(e) =>
                                  handleAcoesChange(e.target.value, index)
                                }
                                displayEmpty
                                fullWidth
                              >
                                <MenuItem value="">
                                  <em>Selecione</em>
                                </MenuItem>
                                {row.availableAcoes.map((item, i) => (
                                  <MenuItem
                                    key={i}
                                    value={item.funcao}
                                    title={item.desc}
                                  >
                                    {item.funcao}
                                  </MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                            <TableCell
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                width: '110px',
                                fontSize: '20px',
                                fontWeight: '400',
                                border: '0px',
                                fontFamily: '"Nunito", sans-serif',
                              }}
                              align="right"
                            >
                              <Button
                                type="button"
                                variant="contained"
                                sx={{
                                  backgroundColor: '#d30404',
                                  height: '40px',
                                }}
                                onClick={() => removeRow(index)}
                              >
                                X
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* <p className="subtitle">Políticas</p> */}
                  {/* <table className="table is-fullwidth">
                                        <thead>
                                            <tr key={"thead"}>
                                                <th>Recurso</th>
                                                <th>Efeito</th>
                                                <th>Ações</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows.map((row, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <select
                                                            className="select"
                                                            value={row.resource}
                                                            onChange={(e) => handleResourceChange(e.target.value, index)}
                                                        >
                                                            <option value={""}>Selecione.</option>
                                                            {allResources.map((resource, i) => (
                                                                <option key={i} value={resource}>
                                                                    {resource}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select
                                                            className="select"
                                                            value={row.effect}
                                                            onChange={(e) => handleEffectChange(e.target.value, index)}
                                                        >
                                                            <option value="">Selecione.</option>
                                                            <option value="allow">Permitir</option>
                                                            <option value="deny">Negar</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select
                                                            className="select"
                                                            value={row.acoes}
                                                            onChange={(e) => handleAcoesChange(e.target.value, index)}
                                                        >
                                                            <option value={""}>Selecione.</option>
                                                            {row.availableAcoes.map((item, i) => (
                                                                <option key={i}
                                                                        value={item.funcao}
                                                                        title={item.desc}
                                                                >
                                                                    {item.funcao}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td style={{width: "20px"}}>
                                                        <button
                                                            className="button"
                                                            type="button"
                                                            onClick={() => removeRow(index)}
                                                        >
                                                            X
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>                                     */}

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '35px',
                    }}
                  >
                    <button
                      className="button is-primary"
                      style={{ fontSize: '17px' }}
                      type="submit"
                    >
                      Cadastrar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default AdminRoleForm;
