// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-form-modal {
  font-size: 1.0857143em;
  font-style: inherit;
  line-height: 1.33333;
  font-weight: var(--ds-font-weight-semibold, 600);
  color: var(--ds-text, var(--ds-text, #ffffff));
  /* color: var(--ds-text-subtle, #6B778C); */
  display: inline-block;
  margin-top: 6px;
  margin-bottom: var(--ds-space-050, 6px);
  font-family: 'Segoe UI', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  cursor: default;
}

.p-button {
  width: 95px;
  background-color: #00d1b2;
  color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/components/meic/css/ModalPDM.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,oBAAoB;EACpB,gDAAgD;EAChD,8CAA8C;EAC9C,2CAA2C;EAC3C,qBAAqB;EACrB,eAAe;EACf,uCAAuC;EACvC;cACY;EACZ,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".label-form-modal {\n  font-size: 1.0857143em;\n  font-style: inherit;\n  line-height: 1.33333;\n  font-weight: var(--ds-font-weight-semibold, 600);\n  color: var(--ds-text, var(--ds-text, #ffffff));\n  /* color: var(--ds-text-subtle, #6B778C); */\n  display: inline-block;\n  margin-top: 6px;\n  margin-bottom: var(--ds-space-050, 6px);\n  font-family: 'Segoe UI', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  cursor: default;\n}\n\n.p-button {\n  width: 95px;\n  background-color: #00d1b2;\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
