import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import FormModal from '../../layouts/FormModal';
import ListaMateriaisAvulsaView from './ListaMateriais/ListaMateriaisAvulsaView';
import AlertMessage from '../../layouts/AlertMessage';
import { useUser } from '../../../context/UserContext';

const ListasMateriaisHome = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [allListasAvulsas, setAllListasAvulsas] = useState([]);
  const [allListasVinculadas, setAllListasVinculadas] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedListaId, setSelectedListaId] = useState(null);
  const { idUsuario } = useUser();
  const navigate = useNavigate();

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const handleOpenModal = (listaId) => {
    setSelectedListaId(listaId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedListaId(null);
    setModalOpen(false);
  };

  const handleEditLista = (listaId) => {
    handleOpenModal(listaId);
  };

  const getListasAvulsas = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/listas/lma/user/`,
        {
          params: { user_id: idUsuario },
          headers,
        }
      );
      const data = await response.data;
      setAllListasAvulsas(data);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar listas do usuário.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getListasVinculadas = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/listas/lmv/`,
        { headers }
      );
      const data = await response.data;
      setAllListasVinculadas(data);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar listas vinculadas.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const removeLista = async (value) => {
    try {
      const response = await axios.delete(
        `${REACT_APP_BASE_API_URL}/listas/material/`,
        {
          params: { lm_id: value },
          headers,
        }
      );
      if (response.status === 200) {
        setAlertMessage('Lista deletada com sucesso.');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao excluir lista.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getListasAvulsas();
    getListasVinculadas();
  }, []);

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <section className="main-content">
          <div className="container">
            <div className="columns">
              <div className="column">
                <button className="button">
                  <Link to={'/lma-form'}>Cadastrar Lista Avulsa</Link>
                </button>
                <div className="divider">Listas Avulsas</div>
                <table className="table is-fullwidth">
                  <thead>
                    <tr>
                      <th>Título</th>
                      <th>Custo</th>
                      <th>RC</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allListasAvulsas &&
                      allListasAvulsas.map((lista, i) => (
                        <tr key={i}>
                          <td>{lista.titulo}</td>
                          <td>{formatPreco(lista.custo)}</td>
                          <td>
                            {lista.src_ref !== null && lista.src_ref !== '' ? (
                              <i
                                className="fa-solid fa-check"
                                style={{
                                  color: 'green',
                                }}
                              ></i>
                            ) : (
                              <i
                                className="fa-solid fa-x"
                                style={{
                                  color: 'red',
                                }}
                              ></i>
                            )}
                          </td>
                          <td>
                            <span
                              className=""
                              onClick={() => handleEditLista(lista.id)}
                            >
                              <i
                                className="fa-solid fa-eye"
                                style={{
                                  margin: '2px',
                                }}
                              />
                            </span>
                            {(lista.src_ref === null) |
                            (lista.src_ref === '') ? (
                              <span
                                className=""
                                onClick={() => removeLista(lista.id)}
                              >
                                <i
                                  className="fa-solid fa-trash"
                                  style={{
                                    margin: '2px',
                                  }}
                                />
                              </span>
                            ) : (
                              <span className="">
                                <i
                                  className="fa-solid fa-trash"
                                  style={{
                                    margin: '2px',
                                    cursor: 'not-allowed',
                                    color: 'grey',
                                  }}
                                />
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="divider is-vertical"></div>
              <div className="column">
                <button className="button">
                  <Link to={'/lmv-form'}>Gerar Lista Vinculada</Link>
                </button>
                <div className="divider">Listas Vinculadas</div>
                <table className="table is-fullwidth">
                  <thead>
                    <tr>
                      <th>Título</th>
                      <th>Custo</th>
                      <th>CAPEX</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allListasVinculadas &&
                      allListasVinculadas.map((lista, i) => (
                        <tr key={i}>
                          <td>{lista.titulo}</td>
                          <td>{formatPreco(lista.custo)}</td>
                          <td>{lista.capex_name}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <FormModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          modalTitle={'Editar Lista'}
        >
          {isModalOpen && selectedListaId && (
            <ListaMateriaisAvulsaView listaId={selectedListaId} info={info} />
          )}
        </FormModal>
      </MainLayout>
    </>
  );
};

export default ListasMateriaisHome;
