import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AlertMessage from '../../../layouts/AlertMessage';

const ListaMateriaisAvulsaDupe = ({ listaId, info }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [newTitulo, setNewTitulo] = useState('');
  const navigate = useNavigate();
  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleDuplicate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/listas/lista-material/duplicate/`,
        {},
        {
          params: {
            base_id: listaId,
            titulo: newTitulo,
          },
          headers,
        }
      );
      if (response.status === 200) {
        setAlertMessage('Lista duplicada com sucesso.');
        setAlertSeverity('success');
        setAlertOpen(true);
        setNavigateTo('/lista-materiais/');
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao duplicar lista.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  return (
    <>
      <section className="main-content">
        <div className="container">
          <div className="card">
            <div className="card-content">
              <form name="duplicate" onSubmit={handleDuplicate}>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label" style={{ color: '#171717' }}>
                      Título
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          type="text"
                          placeholder=""
                          value={newTitulo}
                          onChange={(e) => setNewTitulo(e.target.value)}
                          className="input"
                        />
                        <span className="icon is-small is-left">
                          <i className="fa-solid fa-heading"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <button className="button">Duplicar</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ListaMateriaisAvulsaDupe;
