import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserDashboards from './apps/dashboards/UserDashboards';
import { useUser } from '../context/UserContext';

const HomePage = () => {
  const navigate = useNavigate();
  const { usuarioInterno } = useUser();

  useEffect(() => {
    if (usuarioInterno === 'false' || usuarioInterno === false) {
      navigate('/ext/home/');
    }
  }, []);

  return (
    <>
      <UserDashboards />
    </>
  );
};

export default HomePage;
