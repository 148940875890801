import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import AlertMessage from '../../layouts/AlertMessage';

const ConsultarRC = ({ rcId, info }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [rcData, setRcData] = useState(null);
  const [rcApprovers, setRcApprovers] = useState(null);
  const [rcPedido, setRcPedido] = useState(null);

  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleRCCheck = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/solicitacao-rc/consultar/`,
        {
          params: { nrc: parseInt(rcId) },
          headers,
        }
      );
      if (response.status === 200) {
        const responseData = await response.data;
        const { requisitionApprovers, ...data2 } = responseData;
        setRcData(responseData);
        setRcApprovers(response.data.requisitionApprovers);
        setRcPedido(response.data.purchaseOrders);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar RC'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    handleRCCheck();
  }, []);

  return (
    <>
      {rcData && (
        <div className="container" style={{ paddingTop: '10px' }}>
          <div className="columns" style={{ paddingBottom: '10px' }}>
            <div className="column" key="column1">
              <div className="field is-horizontal">
                <div className="field-label">
                  <label className="label">Nº RC</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <input
                      type="text"
                      value={rcData.purchaseRequisition}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="column" key="column2">
              <div className="field is-horizontal">
                <div className="field-label">
                  <label className="label">Status</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <input
                      type="text"
                      value={rcData.statusDescription}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-content" style={{ padding: '2px' }}>
              <TableContainer
                style={{
                  border: '0px solid lightgrey',
                  borderRadius: '5px',
                  marginBottom: '10px',
                }}
              >
                <Table aria-label="custom table">
                  <TableHead>
                    <TableRow
                      style={{
                        display: 'flex',
                        width: '100%',
                        backgroundColor: 'rgb(46, 50, 58)',
                      }}
                    >
                      <TableCell
                        style={{
                          flex: '2',
                          color: 'white',
                          fontSize: '17px',
                          fontWeight: 'bold',
                          fontFamily: '"Nunito", sans-serif',
                        }}
                      >
                        Aprovador
                      </TableCell>
                      <TableCell
                        style={{
                          flex: '1',
                          color: 'white',
                          fontSize: '17px',
                          fontWeight: 'bold',
                          fontFamily: '"Nunito", sans-serif',
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{
                          flex: '1',
                          color: 'white',
                          fontSize: '17px',
                          fontWeight: 'bold',
                          fontFamily: '"Nunito", sans-serif',
                        }}
                      >
                        Data
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rcApprovers &&
                      rcApprovers.map((approver, i) => (
                        <TableRow
                          key={i}
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                        >
                          <TableCell
                            style={{
                              flex: '2',
                              fontFamily: '"Nunito", sans-serif',
                            }}
                          >
                            {approver.approverName}
                          </TableCell>
                          <TableCell
                            style={{
                              flex: '1',
                              fontFamily: '"Nunito", sans-serif',
                            }}
                          >
                            {approver.approval}
                          </TableCell>
                          <TableCell
                            style={{
                              flex: '1',
                              fontFamily: '"Nunito", sans-serif',
                            }}
                          >
                            {approver.completeDate}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          {rcPedido && (
            <div className="card">
              <div className="card-content" style={{ padding: '2px' }}>
                <TableContainer>
                  <Table aria-label="colapsible">
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: 'rgb(46, 50, 58)',
                        }}
                      >
                        <TableCell />
                        <TableCell
                          style={{
                            flex: '2',
                            color: 'white',
                            fontSize: '17px',
                            fontWeight: 'bold',
                            fontFamily: '"Nunito", sans-serif',
                          }}
                        >
                          Pedido
                        </TableCell>
                        <TableCell
                          style={{
                            flex: '1',
                            color: 'white',
                            fontSize: '17px',
                            fontWeight: 'bold',
                            fontFamily: '"Nunito", sans-serif',
                          }}
                        >
                          Data C
                        </TableCell>
                        <TableCell
                          style={{
                            flex: '1',
                            color: 'white',
                            fontSize: '17px',
                            fontWeight: 'bold',
                            fontFamily: '"Nunito", sans-serif',
                          }}
                        >
                          Data E
                        </TableCell>
                        <TableCell
                          style={{
                            flex: '1',
                            color: 'white',
                            fontSize: '17px',
                            fontWeight: 'bold',
                            fontFamily: '"Nunito", sans-serif',
                          }}
                        >
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rcPedido &&
                        rcPedido.map((pedido) => (
                          <PedidosRow pedido={pedido} />
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const PedidosRow = ({ pedido }) => {
  const [open, setOpen] = useState(false);
  const aprovadores = pedido.approvers;
  const hasAprovadores = aprovadores && aprovadores.length > 0;

  return (
    <>
      <TableRow>
        <TableCell>
          {hasAprovadores && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{pedido.purchaseOrder}</TableCell>
        <TableCell>{pedido.createdDate}</TableCell>
        <TableCell>{pedido.deliveryDate}</TableCell>
        <TableCell>{pedido.statusDescription}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              margin={0}
              sx={{
                backgroundColor: '#f0f4ff',
                border: '1px solid #c0c0c0',
                borderRadius: '4px',
                padding: '8px',
              }}
            >
              <Table size="small" aria-label="aprovadores">
                <TableHead>
                  <TableRow>
                    <TableCell>Aprovador</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Data</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {aprovadores &&
                    aprovadores.map((aprovador, i) => (
                      <TableRow key={i}>
                        <TableCell>{aprovador.approverName}</TableCell>
                        <TableCell>{aprovador.approval}</TableCell>
                        <TableCell>{aprovador.completeDate}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ConsultarRC;
