import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { cnpjMask } from '../../functional/cnpjMask';
import { cepMask } from '../../functional/cepMask';
import { cnaeMask } from '../../functional/cnaeMask';
import axios from 'axios';
import AlertMessage from '../../layouts/AlertMessage';

const FornecedoresFormJuridico = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [allBancos, setAllBancos] = useState([]);
  const [allTags, setAllTags] = useState([]);

  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [email, setEmail] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState(0);
  const [bairro, setBairro] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [cep, setCep] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [cnpjValid, setCnpjValid] = useState(false);
  const [cnae, setCnae] = useState('');
  const [nomeResponsavel, setNomeResponsavel] = useState('');
  const [emailResponsavel, setEmailResponsavel] = useState('');
  const [cargoResponsavel, setCargoResponsavel] = useState('');
  const [banco, setBanco] = useState('');
  const [numeroBanco, setNumeroBanco] = useState('');
  const [agencia, setAgencia] = useState('');
  const [conta, setConta] = useState('');
  const [digito, setDigito] = useState('');
  const [telefone1, setTelefone1] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [tags, setTags] = useState([]);
  const [tagStr, setTagStr] = useState('');
  const [observacaoSolicitante, setObservacaoSolicitante] = useState('');
  const [centro, setCentro] = useState('');
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  const listaEstados = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  const getBancos = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/tools/bancos/`,
        { headers }
      );
      const bancosData = await response.data;
      const nl = bancosData.map((item) => `${item.compe} - ${item.nome}`);
      setAllBancos(nl);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar bancos'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getTags = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/tools/tag-fornecedores/`,
        { headers }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAllTags(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar tags'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const capitalizar = (str) => {
    const words = str.split(' ');
    const titlezedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return titlezedWords.join(' ');
  };

  const handleTagChange = (tg) => {
    const selected = Array.from(
      tg.target.selectedOptions,
      (option) => option.value
    );
    setTags(selected);
    setTagStr(selected.join(' '));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      id: null,
      razao_social: razaoSocial.toUpperCase(),
      nome_fantasia: nomeFantasia.toUpperCase(),
      email: email,
      observacao: observacaoSolicitante,
      centro: centro,
      logradouro: logradouro.toUpperCase(),
      numero: numero,
      bairro: bairro.toUpperCase(),
      complemento: complemento.toUpperCase(),
      cidade: cidade.toUpperCase(),
      estado: estado.toUpperCase(),
      cep: cep,
      cnpj: cnpj,
      cnae: cnae,
      cpf: null,
      nome_responsavel: nomeResponsavel,
      email_responsavel: emailResponsavel,
      cargo_responsavel: cargoResponsavel,
      banco: banco,
      numero_banco: banco.split(' - ')[0].trim(),
      agencia: agencia,
      conta: conta,
      digito: digito,
      telefone1: telefone1,
      telefone2: telefone2,
      tags: tagStr,
      inscricao_estadual: inscricaoEstadual,
    };

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/fornecedor`,
        formData,
        { headers }
      );
      if (response.status === 201) {
        // alert("Solicitação de cadastro de fornecedor enviada.")
        setAlertMessage('Solicitação de cadastro de fornecedor enviada.');
        setAlertSeverity('success');
        setAlertOpen(true);
        setRazaoSocial('');
        setNomeFantasia('');
        setEmail('');
        setObservacaoSolicitante('');
        setCentro('');
        setLogradouro('');
        setNumero(0);
        setBairro('');
        setComplemento('');
        setCidade('');
        setEstado('');
        setCep('');
        setCnpj('');
        setCnae('');
        setNomeResponsavel('');
        setEmailResponsavel('');
        setCargoResponsavel('');
        setBanco('');
        setNumeroBanco('');
        setAgencia('');
        setConta('');
        setDigito('');
        setTelefone1('');
        setTelefone2('');
        setTags('');
        setInscricaoEstadual('');
      }
    } catch (error) {
      console.error(error);
      // alert(error.response.data.detail)
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao cadastrar fornecedor'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const cnpjCheckerReceita = async (cnpj) => {
    const minhaBaseReceitaURL = `https://minhareceita.org/${String(cnpj)}`;
    try {
      const response = await axios.get(minhaBaseReceitaURL);
      const data = await response.data;
      if (response.status === 200) {
        setLogradouro(
          `${capitalizar(data.descricao_tipo_de_logradouro)} ${capitalizar(data.logradouro)}`
        );
        setComplemento(capitalizar(data.complemento));
        setNumero(data.numero);
        setCidade(capitalizar(data.municipio));
        setBairro(capitalizar(data.bairro));
        setEstado(data.uf);
        setCep(data.cep);
        setCnae(String(data.cnae_fiscal));
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar dados do CNPJ'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
      // alert("CNPJ Inválido")
    }
  };

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    getBancos();
    getTags();
  }, []);

  return (
    <>
      <AlertMessage
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Informações gerais
                </p>
              </header>
              <div className="card-content">
                <form name="cadastrarFornecedor" onSubmit={handleSubmit}>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Razão Social
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={razaoSocial}
                            onChange={(e) => setRazaoSocial(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-building"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Email Principal
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="email"
                            placeholder="exemplo@gmail.com"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-envelope"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Nome Fantasia
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={nomeFantasia}
                            onChange={(e) => setNomeFantasia(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-pen-to-square"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        CNPJ
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder="00.000.000/0001-00"
                            required
                            value={cnpjMask(cnpj)}
                            onChange={(e) => setCnpj(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-stamp"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        CNAE
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={cnaeMask(cnae)}
                            onChange={(e) => setCnae(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-gear"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Telefone principal
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder="+550XX0000000"
                            required
                            value={telefone1}
                            onChange={(e) => setTelefone1(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-phone"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Telefone secundário</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder="+550XX0000000"
                            value={telefone2}
                            onChange={(e) => setTelefone2(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-phone"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Empresa
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <select
                              name="centro"
                              value={centro}
                              required
                              onChange={(e) => setCentro(e.target.value)}
                            >
                              <option value="" disabled></option>
                              <option value={'5000 - GEO ELÉTRICA'}>
                                5000 - GEO ELÉTRICA
                              </option>
                              <option value={'5100 - GEO ENERGÉTICA'}>
                                5100 - GEO ENERGÉTICA
                              </option>
                              <option value={'5700 - CRI GEO'}>
                                5700 - CRI GEO
                              </option>
                            </select>
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-location-dot"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Inscrição Estadual
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={inscricaoEstadual}
                            onChange={(e) =>
                              setInscricaoEstadual(e.target.value)
                            }
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-stamp"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Observação</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <textarea
                            rows="4"
                            className="input"
                            value={observacaoSolicitante}
                            style={{
                              height: '100px',
                            }}
                            onChange={(e) =>
                              setObservacaoSolicitante(e.target.value)
                            }
                          ></textarea>
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-comments"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        TAGs
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-multiple is-fullwidth">
                            <select
                              multiple
                              name="tags"
                              value={tags}
                              required
                              onChange={handleTagChange}
                              size={'4'}
                            >
                              <option value="" disabled></option>
                              {allTags &&
                                allTags.map((item) => (
                                  <option key={item.id} value={item.tag}>
                                    {item.tag.toUpperCase()}
                                  </option>
                                ))}
                            </select>
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-tag"></i>
                            </span>
                          </div>
                        </div>
                        <p
                          className="help"
                          style={{
                            fontSize: '0.80rem',
                            marginTop: '10px',
                          }}
                        >
                          <i>
                            Segure <strong>CTRL</strong> para selecionar mais de
                            uma TAG.
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label"></label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            value={tagStr}
                            disabled
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-tag"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <header className="card-header">
                    <p
                      className="card-header-title"
                      style={{ paddingLeft: '0px' }}
                    >
                      <span className="icon">
                        <i className="mdi mdi-ballot"></i>
                      </span>
                      Endereço
                    </p>
                  </header>
                  <br />

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      margin: '0px 40px 30px 0px',
                    }}
                  >
                    <p
                      className="button is-primary"
                      onClick={() => cnpjCheckerReceita(cnpj)}
                    >
                      Preencher pelo CNPJ
                    </p>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Logradouro
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={logradouro}
                            onChange={(e) => setLogradouro(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-house"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Complemento</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            value={complemento}
                            onChange={(e) => setComplemento(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-house-chimney-medical"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Número
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="number"
                            placeholder=""
                            required
                            step={'1'}
                            value={numero}
                            onChange={(e) => setNumero(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-map-pin"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Cidade
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={cidade}
                            onChange={(e) => setCidade(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-map-location-dot"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Bairro
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={bairro}
                            onChange={(e) => setBairro(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-location-dot"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Estado
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <select
                              name="estado"
                              value={estado}
                              required
                              onChange={(e) => setEstado(e.target.value)}
                            >
                              <option value="" disabled></option>
                              {listaEstados.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-flag"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        CEP
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={cepMask(cep)}
                            onChange={(e) => setCep(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-city"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <header className="card-header">
                    <p
                      className="card-header-title"
                      style={{ paddingLeft: '0px' }}
                    >
                      <span className="icon" style={{ width: '24px' }}>
                        <i className="mdi mdi-ballot"></i>
                      </span>
                      Dados do fornecedor
                    </p>
                  </header>

                  <br />

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Nome
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={nomeResponsavel}
                            onChange={(e) => setNomeResponsavel(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-user"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Email
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={emailResponsavel}
                            onChange={(e) =>
                              setEmailResponsavel(e.target.value)
                            }
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-envelope"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Cargo
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={cargoResponsavel}
                            onChange={(e) =>
                              setCargoResponsavel(e.target.value)
                            }
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-address-card"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <header className="card-header">
                    <p
                      className="card-header-title"
                      style={{ paddingLeft: '0px' }}
                    >
                      <span className="icon" style={{ width: '24px' }}>
                        <i className="mdi mdi-ballot"></i>
                      </span>
                      Dados bancários
                    </p>
                  </header>
                  <br />

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Banco
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <select
                              name="estado"
                              value={banco}
                              required
                              onChange={(e) => setBanco(e.target.value)}
                              placeholder="Selecione."
                            >
                              <option value="" disabled>
                                Selecione.
                              </option>
                              {allBancos &&
                                allBancos.map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-landmark"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Agência
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={agencia}
                            onChange={(e) => setAgencia(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-vault"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Conta
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={conta}
                            onChange={(e) => setConta(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-wallet"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Dígito verificador
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={digito}
                            onChange={(e) => setDigito(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-coins"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      margin: '40px 0px 20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button className="button is-primary">Cadastrar</button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default FornecedoresFormJuridico;
