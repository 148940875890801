import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import FormModal from '../../layouts/FormModal';
import { useNavigate } from 'react-router-dom';

import AdminGrupoEdit from './AdminGrupoEdit';
import AlertMessage from '../../layouts/AlertMessage';

const AdminGrupoTable = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const [allGrupos, setAllGrupos] = useState([]);
  const [selectedGrupoId, setSelectedGrupoId] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const headers = {
    'Content-Type': 'application/json',
  };

  const RolesCellRenderer = (params) => {
    return (
      <div>
        {params.row.roles.map((role, index) => {
          return (
            <span key={index} className="tag is-info" style={{ margin: 1 }}>
              {role.nome}
            </span>
          );
        })}
      </div>
    );
  };

  const handleOpenModal = (grupoId) => {
    setSelectedGrupoId(grupoId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedGrupoId(null);
    setModalOpen(false);
  };

  const handleEditGrupo = (grupoId) => {
    handleOpenModal(grupoId);
  };

  const grupoTableColumns = [
    { field: 'nome', headerName: 'Nome', flex: 0.2 },
    { field: 'descricao', headerName: 'Descrição', flex: 0.4 },
    {
      field: 'roles',
      headerName: 'Roles',
      renderCell: RolesCellRenderer,
      flex: 0.4,
    },
    {
      field: 'acao',
      headerName: 'Editar',
      width: 80,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>
          <IconButton
            onClick={() => {
              handleEditGrupo(params.row.id);
            }}
            color="black"
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const getGruposCom = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/grupos/com/`,
        {
          headers,
        }
      );
      const data = await response.data;
      if (response.status == 200) {
        setAllGrupos(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar grupos.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  useEffect(() => {
    getGruposCom();
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <div className="card">
        <header className="card-header">
          <p
            className="card-header-title"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <span className="icon">
                <i className="mdi mdi-ballot"></i>
              </span>
              Grupos
            </div>

            <div>
              <button
                className="button is-primary"
                type="button"
                onClick={() => navigate('/admin/gruposform/')}
              >
                Novo Grupo
              </button>
            </div>
          </p>
        </header>
        <div
          className="card-content"
          style={{ display: 'flex', margin: '0px 0px 30px 0px' }}
        >
          <DataGrid
            rows={allGrupos}
            columns={grupoTableColumns}
            pageSize={5}
            disableRowSelectionOnClick
            getRowHeight={() => 'auto'}
            getColumnHeight={() => 'auto'}
            sx={{
              '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle': {
                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                fontSize: '16px',
                color: '#171717',
                // fontFamily: '"Nunito", sans-serif',
                lineHeight: '2.5',
                borderLeft: '5px solid transparent',
              },
            }}
          />
        </div>
      </div>
      <FormModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        modalTitle={'Editar Grupo'}
      >
        {isModalOpen && selectedGrupoId && (
          <AdminGrupoEdit grupoId={selectedGrupoId} info={info} />
        )}
      </FormModal>
    </>
  );
};

export default AdminGrupoTable;
