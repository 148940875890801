import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import AlertMessage from '../layouts/AlertMessage';

const MisInclusionView = ({ onAddHandler, area_str, info }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [mises, setMises] = useState([]);
  const [areaStr, setAreaStr] = useState(area_str);

  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const headers = {
    'Content-Type': 'application/json',
  };

  const getFirstMises = async (area) => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_API_URL}/mis/area/`, {
        params: {
          area: area,
        },
        headers: headers,
      });
      const data = await response.data;
      setMises(data.mis);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar mises.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const handleAddClick = (obj) => {
    onAddHandler(obj);
    // alert(`${obj.did} incluido.`)
    setAlertMessage(`${obj.did} incluido.`);
    setAlertSeverity('success');
    setAlertOpen(true);
  };

  const columns = [
    { field: 'did', headerName: 'DID', flex: 1 },
    { field: 'display', headerName: 'DISPLAY', flex: 1 },
    { field: 'descricao', headerName: 'DESCRICAO', flex: 2 },
    {
      field: 'preco',
      headerName: 'PREÇO',
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>{formatPreco(params.row.preco)}</div>
      ),
    },
    {
      field: 'acao',
      headerName: 'AÇÃO',
      flex: 1,
      renderCell: (params) => {
        const mis = mises.find((mis) => mis.did === params.row.did);

        return (
          <div>
            {/* <div 
                            className="button is-primary" 
                            style={{ fontSize: '13px', padding: '0px 5px'}}                        
                            onClick={(e) => { 
                                e.preventDefault(); 
                                handleAddClick(mis) 
                            }}
                        >
                            Adicionar
                        </div> */}
            <span className="icon" style={{ margin: '0px 10px' }}>
              <i
                className="fa-solid fa-circle-plus"
                style={{
                  cursor: 'pointer',
                  fontSize: '20px',
                  color: '#2B3BED',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleAddClick(mis);
                }}
              ></i>
            </span>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getFirstMises(areaStr);
  }, []);

  return (
    <>
      <div className="container" style={{ width: '800px' }}>
        <div
          className="table-modal"
          style={{ margin: '16px', height: '400px' }}
        >
          {/* <table className="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>DISPLAY</th>
                                <th>DESCRIÇÃO</th>
                                <th>PREÇO</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {mises.map((mis, i) => (
                                <tr key={i}>
                                    <td>{mis.did}</td>
                                    <td>{mis.display}</td>
                                    <td>{mis.descricao}</td>
                                    <td>{formatPreco(mis.preco)}</td>
                                    <td className="has-text-centered"><div className="button is-small is-info" onClick={(e) => { e.preventDefault(); handleAddClick(mis) }}>Adicionar</div></td>
                                </tr>
                            ))}
                        </tbody>
                    </table> */}
          <DataGrid
            rows={mises}
            columns={columns}
            pageSize={5}
            disableSelectionOnClick
            getRowHeight={() => 'auto'}
            getColumnHeight={() => 'auto'}
            sx={{
              color: 'white',
              fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
              '& .MuiDataGrid-cell': {
                display: 'flex',
                alignItems: 'flex-start', // Alinha o conteúdo no topo
                paddingTop: '4px', // Ajuste conforme necessário
                paddingBottom: '4px',
                border: '0px',
                fontFamily: '"Nunito", sans-serif',
                lineHeight: '1.5',
                fontSize: '13px',
              },
              '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle': {
                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                fontSize: '14px',
                color: '#4a4a4a',
                fontFamily: '"Nunito", sans-serif',
                lineHeight: '1.5',
              },
              '& .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders':
                {
                  backgroundColor: '#ffffff',
                },
              '& .MuiDataGrid-row': {
                borderLeft: '0px',
              },
              '& .css-wop1k0-MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                color: 'black',
              },
              '& .css-qvtrhg-MuiDataGrid-virtualScroller': {
                backgroundColor: 'white',
                color: 'black',
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default MisInclusionView;
