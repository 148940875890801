import React, { useEffect, useState } from 'react';
import AceNavbar from '../navbars/NavbarAce';
import { Sidebar } from './Sidebar';
import { SidebarExterno } from './Sidebar';
import '../../scss/main.scss';
import '../../app.scss';
import { useUser } from '../../context/UserContext';

function MainLayout({ children }) {
  const { usuarioInterno } = useUser();

  return (
    <>
      <AceNavbar />
      {usuarioInterno ? <Sidebar /> : <SidebarExterno />}
      <div className="main" style={{ paddingBottom: '22px' }}>
        {children}
      </div>
    </>
  );
}

export default MainLayout;
