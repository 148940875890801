import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import AlertMessage from '../../layouts/AlertMessage';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Select,
  MenuItem,
  Button,
} from '@mui/material';

const AdminGrupoForm = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [allRoles, setAllRoles] = useState([]);
  const [grupoNome, setGrupoNome] = useState('');
  const [grupoDescricao, setGrupoDescricao] = useState('');
  const [requestRoles, setRequestRoles] = useState([]);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const [rows, setRows] = useState([{ role: '', descricao: '' }]);

  const headers = {
    'Content-Type': 'application/json',
  };

  const getRoles = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/roles/`,
        { headers }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAllRoles(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar roles.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleRoleChange = (roleId, rowIndex) => {
    const selectedRole = allRoles.find((role) => role.id === roleId);
    const requestId = requestRoles.find((id) => id === roleId);
    if (!requestId) {
      setRequestRoles([...requestRoles, roleId]);
    }
    const updatedRows = rows.map((row, index) =>
      index === rowIndex
        ? { role: selectedRole.nome, descricao: selectedRole.descricao }
        : row
    );
    setRows(updatedRows);
  };

  const addRow = () => {
    setRows([...rows, { role: '', descricao: '' }]);
  };

  const handleCad = async (e) => {
    e.preventDefault();
    if (requestRoles.length === 0) {
      setAlertMessage('Selecione ao menos uma role.');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }
    let requestObj = {
      nome: grupoNome,
      descricao: grupoDescricao,
      roles: requestRoles.join(' '),
    };

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/admin/grupos/`,
        requestObj,
        { headers }
      );
      const data = await response.data;
      if (response.status === 201) {
        // alert('Grupo cadastrado.')
        setAlertMessage('Grupo cadastrado.');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      // alert(error.response.data.detail)
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao cadastrar grupo.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Configurações</li>
                    <li>Meu perfil</li>
                    <li>Painel ADM</li>
                    <li>Novo grupo</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Novo grupo</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="section is-main-section"
            style={{ marginBottom: '50px' }}
          >
            <form onSubmit={handleCad}>
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Informações do novo grupo
                  </p>
                </header>
                <div className="card-content">
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Nome</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            required
                            onChange={(e) => setGrupoNome(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-shield-halved"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Descrição</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            onChange={(e) => setGrupoDescricao(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-file-signature"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Roles
                  </p>
                  <button
                    type="button"
                    className="button is-secondary"
                    onClick={addRow}
                    style={{
                      color: '#2B3BED',
                      border: '1px solid  #2B3BED',
                      fontSize: '17px',
                      margin: '10px 10px 10px 0px',
                    }}
                  >
                    Adicionar Role
                  </button>
                </header>
                <div className="card-content">
                  <TableContainer
                    component={Paper}
                    style={{
                      border: '1px solid lightgrey',
                      borderRadius: '5px',
                    }}
                  >
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow
                          style={{
                            backgroundColor: '#2e323a',
                          }}
                        >
                          <TableCell
                            style={{
                              color: '#ffffff',
                              fontSize: '20px',
                              fontWeight: 'bold',
                              fontFamily: '"Nunito", sans-serif',
                            }}
                          >
                            Role
                          </TableCell>
                          <TableCell
                            style={{
                              color: '#ffffff',
                              fontSize: '20px',
                              fontWeight: 'bold',
                              fontFamily: '"Nunito", sans-serif',
                            }}
                          >
                            Descrição
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index) => (
                          <TableRow
                            key={index}
                            style={{
                              backgroundColor: '#f7f7f7',
                            }}
                          >
                            <TableCell
                              style={{
                                fontFamily: '"Nunito", sans-serif',
                              }}
                            >
                              <Select
                                value={row.roleId}
                                onChange={(e) =>
                                  handleRoleChange(e.target.value, index)
                                }
                                displayEmpty
                                fullWidth
                              >
                                <MenuItem value="">
                                  <em>Selecione</em>
                                </MenuItem>
                                {allRoles.map((item, i) => (
                                  <MenuItem key={i} value={item.id}>
                                    {item.nome}
                                  </MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                            <TableCell
                              style={{
                                fontFamily: '"Nunito", sans-serif',
                              }}
                            >
                              {row.descricao}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '35px',
                    }}
                  >
                    <button
                      className="button is-primary"
                      style={{ fontSize: '17px' }}
                      type="submit"
                    >
                      Cadastrar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default AdminGrupoForm;
