import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../../layouts/MainLayout';
import AlertMessage from '../../../layouts/AlertMessage';
import axios from 'axios';
import ItemInclusionView from '../../../functional/ItemInclusionView';
import LaunchModalButton from '../../../functional/LaunchModal';
import FormModal from '../../../layouts/FormModal';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Alert,
} from '@mui/material';

const ListaMateriaisAvulsaForm = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [listaItems, setListaItems] = useState([]);
  const navigate = useNavigate();
  const [listaItemsIds, setListaItemsIds] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [listaTitulo, setListaTitulo] = useState('');
  const [listaCusto, setListaCusto] = useState(0.0);
  const [checkedRows, setCheckedRows] = useState([]);
  const { idUsuario, setIdUsuario } = useState(null);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const onAddHandler = (obj) => {
    if (listaItemsIds.includes(obj.id)) {
      setListaItemsIds((prevIds) => [...prevIds]);
    } else {
      setListaItemsIds((prevIds) => [...prevIds, obj.id]);
      setListaItems((prevItems) => [
        ...prevItems,
        { ...obj, quantidade: 0.0, preco_total: 0.0, locked: false },
      ]);
    }
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleRemoveItem = (i) => {
    const removedItem = listaItems[i];
    const updatedItems = listaItems.filter((item, index) => index !== i);
    const updatedIds = listaItemsIds.filter((id) => id !== removedItem.id);
    const updatedCheckedRows = checkedRows.filter((index) => index !== i);
    setListaItems(updatedItems);
    setListaItemsIds(updatedIds);
    setCheckedRows(updatedCheckedRows);
    setListaCusto((prevCusto) => {
      const newCusto = prevCusto - removedItem.preco_total;
      return newCusto < 0 ? 0 : newCusto;
    });
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setModalOpen(false);
  };

  const handleLMACad = async (e) => {
    e.preventDefault();
    let requestLista = {
      titulo: listaTitulo,
      custo: listaCusto,
      dono: idUsuario,
    };

    const LMA = listaItems.map((item) => ({
      item_id: item.id,
      sap_id: item.sap_id,
      quantidade: item.quantidade,
      preco: item.preco,
      preco_cad: item.preco,
    }));

    let reqeustObj = {
      lista: requestLista,
      items: {
        items: LMA,
      },
    };

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/listas/lma`,
        reqeustObj,
        { headers }
      );

      if (response.status === 201) {
        setAlertMessage('Lista criada com sucesso!');
        setAlertSeverity('success');
        setAlertOpen(true);
        setNavigateTo('/lista-materiais/');
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail ||
              'Erro ao criar lista de materiais avulsa.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const isFormValid = () => {
    return listaItems.length > 0 && listaItems.every((item) => item.locked);
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const handleQuantidadeChange = (index, val) => {
    const updatedLinhas = [...listaItems];
    updatedLinhas[index].quantidade = val;
    updatedLinhas[index].preco_total = val * updatedLinhas[index].preco;

    setListaItems(updatedLinhas);
  };

  const handleCheckItem = (i) => {
    const updatedItems = [...listaItems];

    const quantidadeInput = document.querySelector(`input[name="Q${i}"]`);

    if (quantidadeInput.value) {
      updatedItems[i].locked = true;
      setListaItems(updatedItems);
      setListaCusto((prevCusto) => prevCusto + updatedItems[i].preco_total);
      setCheckedRows((prevRows) => [...prevRows, i]);
    } else {
      // alert("Não é possível confirmar um item sem uma quantidade definida.")
      setAlertMessage(
        'Não é possível confirmar um item sem uma quantidade definida.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    setIdUsuario(idUsuario);
  }, []);

  const renderModalContent = (info) => {
    switch (modalContent) {
      case 'Materiais':
        return (
          <ItemInclusionView
            mSchema={'material'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'Equipamentos':
        return (
          <ItemInclusionView
            mSchema={'equip'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'Mão de obra':
        return (
          <ItemInclusionView
            mSchema={'servico'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'SINAPI: Composições':
        return (
          <ItemInclusionView
            mSchema={'sinapi'}
            mTarget={'composicao'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'Composições':
        return (
          <ItemInclusionView
            mSchema={'composicao'}
            mTarget={'composicao'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'SINAPI: Insumos':
        return (
          <ItemInclusionView
            mSchema={'sinapi'}
            mTarget={'insumo'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      default:
        return null;
    }
  };

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Engenharia</li>
                    <li>Lista de materiais avulsa</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Lista de materiais avulsa</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <form name="CadastroListaMateriaisAvulsa" onSubmit={handleLMACad}>
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Formulário para criar lista de materiais avulsa
                  </p>
                </header>
                <div className="card-content">
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Título
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            type="text"
                            placeholder=""
                            value={listaTitulo}
                            onChange={(e) => setListaTitulo(e.target.value)}
                            className="input"
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-heading"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Custo
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            type="text"
                            value={formatPreco(listaCusto)}
                            disabled
                            className="input"
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-dollar-sign"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      margin: '40px 0px 20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button
                      type="submit"
                      className="button is-primary"
                      disabled={!isFormValid()}
                    >
                      <span className="icon">
                        <i className="fa-solid fa-plus"></i>
                      </span>
                      <span style={{ fontSize: '16px' }}>Criar lista</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="card">
                <header className="card-header">
                  <p
                    className="card-header-title"
                    style={{
                      padding: '12px 0px 12px 12px',
                    }}
                  >
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Adicionar items
                  </p>
                </header>
                <div className="card-content" style={{ padding: '1.5rem' }}>
                  <div className="b-table has-pagination">
                    <div className="table-wrapper has-mobile-cards">
                      <ButtonGroup
                        aria-label="outlined is-primary button group"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: '20px 0px',
                        }}
                      >
                        <Button
                          onClick={() => handleOpenModal('Materiais')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          Materiais
                        </Button>
                        <Button
                          onClick={() => handleOpenModal('Equipamentos')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          Equipamentos
                        </Button>
                        <Button
                          onClick={() => handleOpenModal('Composições')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          Composições
                        </Button>
                        <Button
                          onClick={() => handleOpenModal('Mão de obra')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          Serviços
                        </Button>
                        <Button
                          onClick={() => handleOpenModal('SINAPI: Composições')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          SINAPI: Composições
                        </Button>
                        <Button
                          onClick={() => handleOpenModal('SINAPI: Insumos')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          SINAPI: Insumos
                        </Button>
                      </ButtonGroup>

                      <TableContainer
                        component={Paper}
                        style={{
                          border: '1px solid lightgrey',
                          borderRadius: '5px',
                          marginBottom: '20px',
                        }}
                      >
                        <Table aria-label="Items table">
                          <TableHead>
                            <TableRow
                              style={{
                                display: 'flex',
                                width: '100%',
                              }}
                            >
                              <TableCell
                                style={{
                                  flex: '2',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                ITEM
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                UNIDADE
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                QUANTIDADE
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                PREÇO
                              </TableCell>
                              <TableCell
                                style={{
                                  width: '120px',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                CONFIRMAR
                              </TableCell>
                              <TableCell
                                style={{
                                  width: '90px',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                EXCLUIR
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {listaItems &&
                              listaItems.map((item, i) => (
                                <TableRow
                                  key={i}
                                  style={{
                                    display: 'flex',
                                    backgroundColor: item.locked
                                      ? 'rgb(1 255 1 / 17%)'
                                      : 'transparent',
                                  }}
                                >
                                  <TableCell
                                    style={{
                                      flex: '2',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    {item.descl}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    {item.unidade}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    <input
                                      name={`Q${i}`}
                                      className="input"
                                      type="number"
                                      step={'0.0001'}
                                      onChange={(e) =>
                                        handleQuantidadeChange(
                                          i,
                                          e.target.value
                                        )
                                      }
                                      disabled={item.locked}
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    {item.preco}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '120px',
                                      fontFamily: '"Nunito", sans-serif',
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {item.locked ? (
                                      <span
                                        className="icon is-disabled"
                                        style={{
                                          fontSize: '25px',
                                        }}
                                      >
                                        <i
                                          className="fa-solid fa-circle-check"
                                          style={{
                                            color: 'gray',
                                          }}
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        className="icon"
                                        onClick={() => handleCheckItem(i)}
                                        style={{
                                          cursor: 'pointer',
                                          fontSize: '25px',
                                          color: 'green',
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check"></i>
                                      </span>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '90px',
                                      fontFamily: '"Nunito", sans-serif',
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <span
                                      className="icon"
                                      onClick={() => handleRemoveItem(i)}
                                      style={{
                                        cursor: 'pointer',
                                        fontSize: '25px',
                                        color: 'red',
                                      }}
                                    >
                                      <i className="fa-solid fa-trash-can"></i>
                                    </span>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <FormModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              modalTitle={modalContent}
            >
              {renderModalContent(info)}
            </FormModal>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default ListaMateriaisAvulsaForm;
