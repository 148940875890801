import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import FornecedoresFormFisico from './FornecedoresFormF';
import FornecedoresFormJuridico from './FornecedoresFormJ';
import FornecedoresFormInternacional from './FornecedoresFormI';

const FornecedoresForm = () => {
  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;
  const [allBancos, setAllBancos] = useState([]);

  const [tipoFornecedor, setTipoFornecedor] = useState('person');
  const [formVisivel, setFormVisivel] = useState(false);
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [email, setEmail] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState(0);
  const [bairro, setBairro] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [cep, setCep] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [cpf, setCpf] = useState('');
  const [cnpjValid, setCnpjValid] = useState(false);
  const [cnae, setCnae] = useState('');
  const [nomeResponsavel, setNomeResponsavel] = useState('');
  const [emailResponsavel, setEmailResponsavel] = useState('');
  const [cargoResponsavel, setCargoResponsavel] = useState('');
  const [banco, setBanco] = useState('');
  const [numeroBanco, setNumeroBanco] = useState(111);
  const [agencia, setAgencia] = useState(0);
  const [conta, setConta] = useState(0);
  const [digito, setDigito] = useState(0);

  const listaEstados = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  const handleTipoChange = (e) => {
    setTipoFornecedor(e.target.value);
    setFormVisivel(true);
  };

  return (
    <>
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    {/* <li>Setores</li> */}
                    <li>Suprimentos</li>
                    <li>Cadastrar Fornecedores</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Cadastrar Fornecedores</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <div className="search-pdm">
                      <div style={{ display: 'flex' }}>
                        <div
                          className="div-select-search"
                          style={{
                            paddingRight: '15px',
                            width: '400px',
                          }}
                        >
                          <label className="label">Tipo de fornecedor</label>

                          <div className="field-body">
                            <div
                              className="field is-narrow"
                              style={{
                                padding: '0px',
                              }}
                            >
                              <div className="control has-icons-left">
                                <div className="select is-fullwidth">
                                  <select
                                    value={tipoFornecedor}
                                    className="custom-select-style"
                                    onChange={handleTipoChange}
                                  >
                                    <option value="person">
                                      Pessoa Física
                                    </option>
                                    <option value="company">
                                      Pessoa Jurídica
                                    </option>
                                    <option value="internacional">
                                      Internacional
                                    </option>
                                  </select>
                                  <span className="icon is-small is-left">
                                    <i className="fa-solid fa-user-tie"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="section is-main-section"> */}
          <div>
            {tipoFornecedor === 'person' && <FornecedoresFormFisico />}

            {(tipoFornecedor === 'company' || tipoFornecedor === '') && (
              <FornecedoresFormJuridico />
            )}

            {tipoFornecedor === 'internacional' && (
              <FornecedoresFormInternacional />
            )}
          </div>
          {/* </section> */}
        </div>
      </MainLayout>
    </>
  );
};

export default FornecedoresForm;
