import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Select from 'react-select';
import AlertMessage from '../../layouts/AlertMessage';

const FilaTarefasRC = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const [soliciatacoesUser, setSolicitacaoUser] = useState([]);
  const [activeButton, setActiveButton] = useState('');
  const [filaTarefas, setFilaTarefas] = useState([]); // Estado para armazenar os dados da fila
  const [listaTarefas, setListaTarefas] = useState([]); // Estado para armazenar os dados da fila
  const [options, setOptions] = useState([]);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const headers = {
    'Content-Type': 'application/json',
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  useEffect(() => {
    const getEmpreendimentos = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/adm/empreendimento/`,
          { headers }
        );
        const data = await response.data;
        if (response.status === 200) {
          const formattedOptions = data.empreendimentos.map((item) => ({
            value: item.tag,
            label: item.nome,
          }));

          const updatedOptions = [
            {
              value: '',
              label: 'TODOS',
            },
            ...formattedOptions,
          ];

          // const optionsEmp = data.map(emp => {return emp.tag})
          setOptions(updatedOptions);
        }
      } catch (error) {
        console.error(error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao buscar empreendimentos'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    const fetchFilaTarefas = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/tarefas/categoria`,
          {
            params: {
              setor: 'planejamento',
              tag: 'solicitação_rc' + activeButton ? ' ' + activeButton : '',
            },
            headers: headers,
          }
        );

        if (response.data && response.data.fila_tarefas) {
          // Transformar os dados recebidos para se adequar à estrutura do DataGrid
          const tarefas = response.data.fila_tarefas.map((tarefa, index) => ({
            id: tarefa.id,
            idPnj: `PNJ-${tarefa.id.split('-')[1].toUpperCase()}`,
            resumo: tarefa.titulo,
            relator: tarefa.nome_relator + ' ' + tarefa.sobrenome_relator,
            responsavel: tarefa.responsavel,
            status: tarefa.status,
            valor: JSON.parse(tarefa.conteudo).custo,
            data_criacao: new Date(tarefa.criado_em).toLocaleString(),
            tempo_resolucao: new Date(tarefa.data_due).toLocaleString('pt-BR', {
              day: '2-digit', // Dois dígitos para o dia
              month: '2-digit', // Dois dígitos para o mês
              year: 'numeric', // Quatro dígitos para o ano
              hour: '2-digit', // Duas dígitos para a hora
              minute: '2-digit', // Dois dígitos para o minuto
              hour12: false, // Usar formato de 24 horas
            }),
            prioridade: tarefa.prioridade,
            responsavel_id: tarefa.responsavel_id,
          }));
          setFilaTarefas(tarefas);
          setListaTarefas(response.data.fila_tarefas);
        }
      } catch (error) {
        console.error('Erro ao buscar fila de tarefas:', error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao buscar fila de tarefas'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    getEmpreendimentos();
    fetchFilaTarefas();
  }, [activeButton, REACT_APP_BASE_API_URL]);

  const columns = [
    { field: 'idPnj', headerName: 'Chave', flex: 2 },
    { field: 'resumo', headerName: 'Resumo', flex: 3 },
    { field: 'relator', headerName: 'Relator', flex: 3 },
    { field: 'responsavel', headerName: 'Responsável', flex: 3 },
    {
      field: 'status',
      headerName: 'Status',
      editable: true,
      flex: 2,
      renderCell: (params) => (
        <>
          {params.row.status.includes('PREDECESSORA') ? (
            <p className="tag is-warning">{params.row.status}</p>
          ) : params.row.status.includes('CONC') ? (
            <p className="tag is-success">{params.row.status}</p>
          ) : params.row.status.includes('ANDAM') ? (
            <p className="tag is-success is-light">{params.row.status}</p>
          ) : params.row.status === 'PENDENTE' ? (
            <p className="tag is-warning is-light">{params.row.status}</p>
          ) : params.row.status.includes('RECUSA') ? (
            <p className="tag is-danger">{params.row.status}</p>
          ) : params.row.status.includes('ROTA') ? (
            <p className="tag is-link is-light">{params.row.status}</p>
          ) : (
            <p className="tag is-primary is-light">{params.row.status}</p>
          )}
        </>
      ),
    },
    {
      field: 'valor',
      headerName: 'Valor RC',
      flex: 2,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>{formatPreco(params.row.valor)}</div>
      ),
    },
    {
      field: 'data_criacao',
      headerName: 'Criado em',
      type: 'Date',
      flex: 3,
      sortComparator: (v1, v2) => {
        const parseBrDate = (dateStr) => {
          const [dPart, hPart] = dateStr.split(',');
          const [day, month, year] = dPart.split('/').map(Number);
          const [hour, minute] = hPart.split(':').map(Number);
          return new Date(year, month - 1, day, hour, minute);
        };

        return parseBrDate(v1) - parseBrDate(v2);
      },
    },
    {
      field: 'tempo_resolucao',
      headerName: 'Tempo resolução',
      type: 'Date',
      flex: 3,
      renderCell: (params) => {
        const parseBrDate = (dateStr) => {
          const [dPart, hPart] = dateStr.split(',');
          const [day, month, year] = dPart.split('/').map(Number);
          const [hour, minute] = hPart.split(':').map(Number);
          return new Date(year, month - 1, day, hour, minute);
        };

        const today = new Date();
        const resolDate = parseBrDate(params.row.tempo_resolucao);
        const timeDiff = resolDate - today;
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        let color;
        if (daysDiff < 1) {
          color = 'red';
        } else if ((daysDiff <= 2) & (daysDiff > 1)) {
          color = 'orange';
        } else {
          color = 'green';
        }
        return <p style={{ color: color }}>{resolDate.toLocaleString()}</p>;
      },
      sortComparator: (v1, v2) => {
        const parseBrDate = (dateStr) => {
          const [dPart, hPart] = dateStr.split(',');
          const [day, month, year] = dPart.split('/').map(Number);
          const [hour, minute] = hPart.split(':').map(Number);
          return new Date(year, month - 1, day, hour, minute);
        };

        return parseBrDate(v1) - parseBrDate(v2);
      },
    },
    {
      field: 'acao',
      headerName: 'Ação',
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>
          <IconButton
            onClick={() => {
              editTarefa(params.row.id);
            }}
            color="black"
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  const editTarefa = async (id) => {
    const tarefa = listaTarefas.find((tarefa) => tarefa.id === id);

    if (tarefa) {
      navigate('/user/visualizar-rc', {
        state: {
          tarefa,
          req_comp: JSON.parse(tarefa.conteudo),
          route: '/plan/fila-tarefas-rc',
        },
      });
    }
  };

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Planejamento</li>
                    <li>Fila de Tarefas</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Fila de Tarefas</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    {/* <button 
                                        className="button is-primary"
                                        type="button"
                                        onClick={() => {navigate('/n-srch')}}
                                    >
                                        Nova Solicitação de RC
                                    </button>
                                    */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <div className="card-content">
                <div className="div-select-search">
                  <div className="search-input">
                    <div className="info-search">
                      <label className="label">
                        Filtrar por empreendimento
                      </label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={options[0]}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={options}
                        placeholder={
                          <div>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <span> Selecione</span>
                          </div>
                        }
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            setActiveButton(selectedOption.value);
                          } else {
                            setActiveButton('');
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="FilaTarefas">
                      <div
                        style={{
                          height: 600,
                          width: '100%',
                        }}
                      >
                        <DataGrid
                          rows={filaTarefas}
                          columns={columns}
                          pageSize={5}
                          disableSelectionOnClick
                          getRowHeight={() => 'auto'}
                          sx={{
                            fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
                            '& .MuiDataGrid-cell': {
                              display: 'flex',
                              alignItems: 'flex-start', // Alinha o conteúdo no topo
                              paddingTop: '4px', // Ajuste conforme necessário
                              paddingBottom: '4px',
                              border: '0px',
                              // lineHeight: '2.5',
                              fontSize: '16px',
                            },
                            '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                              {
                                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                                fontSize: '16px',
                                color: '#171717',
                                // fontFamily: '"Nunito", sans-serif',
                                lineHeight: '2.5',
                                borderLeft: '5px solid transparent',
                              },
                            '& .MuiDataGrid-virtualScroller': {
                              overflowX: 'hidden',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default FilaTarefasRC;
