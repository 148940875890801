import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import AlertMessage from '../../layouts/AlertMessage';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { useUser } from '../../../context/UserContext';

const ProfilePageNew = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const [userNome, setUserNome] = useState('');
  const [userDNome, setUserDNome] = useState('');
  const [userSobrenome, setUserSobrenome] = useState('');
  const [userRole, setUserRole] = useState('');
  const [userSetor, setUserSetor] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userCargo, setUserCargo] = useState('');
  const [userTel1, setUserTel1] = useState('');
  const [userTel2, setUserTel2] = useState('');
  const [userID, setUserID] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [actualPass, setActualPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [newPassConfirm, setNewPassConfirm] = useState('');

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const { idUsuario } = useUser();

  const headers = {
    'Content-Type': 'application/json',
  };

  const capitalizar = (str) => {
    const words = str.split(' ');
    const titlezedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return titlezedWords.join(' ');
  };

  const handleResendActivationLink = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/auth/regenerate-activation-link/`,
        null,
        {
          params: {
            user_id: userID,
          },
        }
      );
      if (response.status === 200) {
        setAlertMessage('Link de ativação reenviado com sucesso.');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Ocorreu um erro.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getMyData = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_API_URL}/users/me`, {
        headers,
      });
      const data = await response.data;
      if (response.status === 200) {
        setUserEmail(data.email);
        setUserNome(data.nome);
        setUserSobrenome(data.sobrenome);
        setUserDNome(`${data.nome} ${data.sobrenome}`);
        setUserRole(data.role);
        setUserSetor(data.setor);
        setUserCargo(data.cargo);
        setUserTel1(data.telefone1);
        setUserTel2(data.telefone2);
        setUserID(data.id);
        setIsActive(data.ativo);
      }
      if (data.role === 'admin') {
        setIsAdmin(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Ocorreu um erro.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleInfoSubmit = async (e) => {
    e.preventDefault();
    let obj = {
      id: userID,
      nome: userNome,
      sobrenome: userSobrenome,
      email: userEmail,
      telefone1: userTel1,
      telefone2: userTel2,
    };

    try {
      const response = await axios.put(
        `${REACT_APP_BASE_API_URL}/users/${userID}`,
        obj,
        { headers }
      );
      if (response.status === 200) {
        const data = await response.data;
        setAlertMessage('Dados alterados.');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Ocorreu um erro.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handlePassChange = async (e) => {
    e.preventDefault();
    let formData = {
      user_id: idUsuario,
      npas: newPass,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/users/change_pass`,
        formData,
        { headers }
      );
      if (response.status == 200) {
        setAlertMessage('Senha alterada com sucesso.');
        setAlertSeverity('success');
        setAlertOpen(true);
        setNavigateTo('/me/profile');
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Ocorreu um erro.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    // Navigate only if the alert was a success message
    if (navigateTo) {
      navigate(navigateTo);
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    getMyData();
  }, []);

  useEffect(() => {
    return () => {
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
        handleClose={handleClose}
        loading={loading}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Configurações</li>
                    <li>Meu Perfil</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Meu Perfil</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    {isAdmin && (
                      <button
                        className="button is-primary"
                        onClick={() => navigate('/admin')}
                        style={{
                          fontSize: '17px',
                          padding: '0px 30px',
                        }}
                      >
                        <span className="icon">
                          <i className="fa-solid fa-sliders"></i>
                        </span>
                        <span>Painel ADM</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="tile is-ancestor">
              <div className="tile is-parent" style={{ flex: 2 }}>
                <div className="card tile is-child">
                  <header className="card-header">
                    <p className="card-header-title">
                      <span className="icon">
                        <i className="mdi mdi-account-circle default"></i>
                      </span>
                      Editar Perfil
                    </p>
                  </header>
                  <div className="card-content" style={{ paddingTop: '35px' }}>
                    <form name="userdata" onSubmit={handleInfoSubmit}>
                      <div className="field is-horizontal">
                        <div className="field-label is-normal">
                          <label className="label">Nome</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <input
                                className="input"
                                type="text"
                                value={userNome}
                                onChange={(e) => setUserNome(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="field is-horizontal">
                        <div className="field-label is-normal">
                          <label className="label">Sobrenome</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              {/* <input type="text" autocomplete="on" name="name" value="John Doe" className="input" required/> */}
                              <input
                                className="input"
                                type="text"
                                value={userSobrenome}
                                onChange={(e) =>
                                  setUserSobrenome(e.target.value)
                                }
                              />
                            </div>
                            {/* <p className="help">Required. Your name</p> */}
                          </div>
                        </div>
                      </div>

                      <div className="field is-horizontal">
                        <div className="field-label is-normal">
                          <label className="label">E-mail</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              {/* <input type="email" autocomplete="on" name="email" value="user@example.com" className="input" required/> */}
                              <input
                                className="input"
                                type="email"
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                              />
                            </div>
                            {/* <p className="help">Required. Your e-mail</p> */}
                          </div>
                        </div>
                      </div>

                      <div className="field is-horizontal">
                        <div className="field-label is-normal">
                          <label className="label">Telefone 1</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              {/* <input type="email" autocomplete="on" name="email" value="user@example.com" className="input" required/> */}
                              <input
                                className="input"
                                type="text"
                                placeholder="+554300000000"
                                value={userTel1}
                                onChange={(e) => setUserTel1(e.target.value)}
                              />
                            </div>
                            {/* <p className="help">Required. Your e-mail</p> */}
                          </div>
                        </div>
                      </div>

                      <div className="field is-horizontal">
                        <div className="field-label is-normal">
                          <label className="label">Telefone 2</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              {/* <input type="email" autocomplete="on" name="email" value="user@example.com" className="input" required/> */}
                              <input
                                className="input"
                                type="text"
                                value={userTel2 ? userTel2 : ''}
                                placeholder="+554300000000"
                                onChange={(e) => setUserTel2(e.target.value)}
                              />
                            </div>
                            {/* <p className="help">Required. Your e-mail</p> */}
                          </div>
                        </div>
                      </div>

                      <div className="field is-horizontal">
                        <div className="field-label is-normal">
                          <label className="label">Foto</label>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div
                              className="field file"
                              style={{
                                padding: '0px',
                              }}
                            >
                              <label className="upload control">
                                <a
                                  className="button is-secondary"
                                  style={{
                                    color: '#2B3BED',
                                    borderColor: '#2B3BED',
                                  }}
                                >
                                  <span className="icon">
                                    <i className="mdi mdi-upload default"></i>
                                  </span>
                                  <span>Anexar arquivo</span>
                                </a>
                                <input disabled type="file" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr></hr>

                      <div
                        className="control"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <button type="submit" className="button is-primary">
                          Salvar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="tile is-parent">
                <div className="card tile is-child">
                  <div
                    className="card-content"
                    style={{
                      background: 'linear-gradient(to right, black, #575a6a)',
                      height: '40%',
                    }}
                  ></div>

                  <div
                    className="card-content"
                    style={{
                      marginTop: '10%',
                      paddingTop: '12%',
                    }}
                  >
                    <div className="media-content">
                      <div className="columns">
                        <div
                          className="column is-four-fifths"
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <p className="title is-4">{userDNome}</p>
                          <p
                            className="subtitle is-5"
                            style={{
                              marginBottom: '15px',
                            }}
                          >{`${capitalizar(userCargo)} - ${capitalizar(userSetor)}`}</p>
                          <p
                            className="subtitle is-7"
                            style={{
                              marginBottom: '5px',
                            }}
                          >
                            {userID}
                          </p>
                          {isActive ? (
                            <button
                              className="button is-primary"
                              disabled
                              style={{
                                marginTop: '10px',
                              }}
                            >
                              <span className="icon">
                                <i className="fa-solid fa-key"></i>
                              </span>
                              <span>Ativo</span>
                            </button>
                          ) : (
                            <button
                              className="button is-primary"
                              onClick={handleResendActivationLink}
                              style={{
                                marginTop: '10px',
                              }}
                            >
                              <span className="icon">
                                <i className="fa-solid fa-key"></i>
                              </span>
                              <span>Ativar conta</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="is-user-avatar image has-max-width is-aligned-center"
                    style={{
                      maxWidth: 'none',
                      position: 'absolute',
                      top: 'calc(40% - 75px)',
                      left: '50%',
                      transform: 'translateX(-50%)',
                    }}
                  >
                    <img
                      src="https://avatars.dicebear.com/v2/initials/john-doe.svg"
                      style={{ width: '150px' }}
                      alt="John Doe"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-lock default"></i>
                  </span>
                  Alterar Senha
                </p>
              </header>
              <div className="card-content">
                <form onSubmit={handlePassChange}>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Senha atual</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            className="input"
                            type="password"
                            value={actualPass}
                            onChange={(e) => setActualPass(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <hr></hr> */}

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Nova senha</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            className="input"
                            type="password"
                            value={newPass}
                            onChange={(e) => setNewPass(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Confirmar nova senha</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            className="input"
                            type="password"
                            value={newPassConfirm}
                            onChange={(e) => setNewPassConfirm(e.target.value)}
                          />
                        </div>
                        <p className="help">Nova senha mais uma vez</p>
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <div
                    className="control"
                    style={{
                      marginBottom: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button type="submit" className="button is-primary">
                      Cadastrar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default ProfilePageNew;
