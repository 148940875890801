import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ItemInclusionView from '../../functional/ItemInclusionView';
import FormModal from '../../layouts/FormModal';
import { v4 } from 'uuid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './CapexEditView.css';
import AlertMessage from '../../layouts/AlertMessage';
import { useUser } from '../../../context/UserContext';

const CapexEditView = () => {
  const location = useLocation();
  const { capex_id } = useParams();
  const { REACT_APP_BASE_API_URL } = process.env;
  const [capexDetails, setCapexDetails] = useState([]);
  const [capexItems, setCapexItems] = useState([]);
  const [capexNewItems, setCapexNewItems] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [changedRows, setChangedRows] = useState([]);
  const navigate = useNavigate();
  const [advVer, setAdvVer] = useState(true);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const [linhasAlteradas, setLinhasAlteradas] = useState([]);
  const [linhasNovas, setLinhasNovas] = useState([]);
  const [linhasNovasRefs, setLinhasNovasRefs] = useState([]);
  const allowedRoles = ['ADMINISTRADOR'];
  const { gruposUsuario } = useUser();
  const [user, setUser] = useState({
    grupos: gruposUsuario,
  });

  const headers = {
    'Content-Type': 'application/json',
  };

  const getCapex = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/capex/detail`,
        {
          params: {
            capex_id: capex_id,
          },
          headers,
        }
      );
      const data = await response.data;
      setCapexDetails(data.capex);
      setCapexItems(data.linhas);
      setCapexNewItems(data.linhas);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar detalhes do Capex'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const onAddHandler = (obj) => {
    const _id = v4();
    const _did = `L${_id.split('-')[0].toUpperCase()}`;
    const _ref = _id.split('-').slice(-1)[0].toUpperCase().substring(6);
    const objToAdd = {
      area: '',
      ativa: true,
      capex_id: capexDetails.id,
      capex_status: capexDetails.status,
      cca: '',
      descricao: obj.descl,
      did: _did,
      disciplina: '',
      equipamento: obj.descl,
      grupo: '',
      id: _id,
      item_id: obj.id,
      mis: '',
      mis_id: '',
      obra: '',
      preco_und: obj.preco,
      preco_total: '',
      quantidade: '',
      rev: capexDetails.rev,
      subarea: '',
      subitem: '',
      unidade: obj.unidade,
      ref: _ref,
      ver: capexDetails.ver,
    };
    setCapexNewItems((prevItems) => [...prevItems, objToAdd]);
    setLinhasNovas((prevItems) => [...prevItems, objToAdd]);
    setLinhasNovasRefs((prevLinhas) => [...prevLinhas, _ref]);
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setModalOpen(false);
  };

  const renderModalContent = (info) => {
    switch (modalContent) {
      case 'Materiais':
        return (
          <ItemInclusionView
            mSchema={'material'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'Equipamentos':
        return (
          <ItemInclusionView
            mSchema={'equip'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'Mão de obra':
        return (
          <ItemInclusionView
            mSchema={'servico'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'SINAPI: Composições':
        return (
          <ItemInclusionView
            mSchema={'sinapi'}
            mTarget={'composicao'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'Composições':
        return (
          <ItemInclusionView
            mSchema={'composicao'}
            mTarget={'composicao'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'SINAPI: Insumos':
        return (
          <ItemInclusionView
            mSchema={'sinapi'}
            mTarget={'insumo'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      default:
        return null;
    }
  };

  const handleQuantityChange = (index, newVal) => {
    const updatedLinhas = [...capexNewItems];
    updatedLinhas[index].quantidade = newVal;
    updatedLinhas[index].rev = capexDetails.rev;
    updatedLinhas[index].preco_total = updatedLinhas[index].preco_und * newVal;
    setCapexNewItems(updatedLinhas);
    if (!changedRows.includes(index)) {
      setChangedRows((prevChangedRows) => [...prevChangedRows, index]);
      setLinhasAlteradas((prevLinhasAlteradas) => [
        ...prevLinhasAlteradas,
        updatedLinhas[index],
      ]);
    }
  };

  const handleDisciplinaChange = (index, newVal) => {
    const updatedLinhas = [...capexNewItems];
    updatedLinhas[index].disciplina = newVal;
    updatedLinhas[index].rev = capexDetails.rev;
    setCapexNewItems(updatedLinhas);
    if (!changedRows.includes(index)) {
      setChangedRows((prevChangedRows) => [...prevChangedRows, index]);
    }
  };

  const handleAreaChange = (index, newVal) => {
    const updatedLinhas = [...capexNewItems];
    updatedLinhas[index].area = newVal;
    updatedLinhas[index].rev = capexDetails.rev;
    setCapexNewItems(updatedLinhas);
    if (!changedRows.includes(index)) {
      setChangedRows((prevChangedRows) => [...prevChangedRows, index]);
    }
  };

  const handleSubareaChange = (index, newVal) => {
    const updatedLinhas = [...capexNewItems];
    updatedLinhas[index].subarea = newVal;
    updatedLinhas[index].rev = capexDetails.rev;
    setCapexNewItems(updatedLinhas);
    if (!changedRows.includes(index)) {
      setChangedRows((prevChangedRows) => [...prevChangedRows, index]);
    }
  };

  const handleRemoveItem = (index) => {
    const updatedItems = [...capexNewItems];
    const removedRef = updatedItems[index].ref;
    updatedItems[index] = { ...updatedItems[index], ativa: false };
    updatedItems[index].rev = capexDetails.rev;
    setCapexNewItems(updatedItems);
    if (!changedRows.includes(index)) {
      setChangedRows((prevChangedRows) => [...prevChangedRows, index]);
      setLinhasAlteradas((prevLinhasAlteradas) => [
        ...prevLinhasAlteradas,
        { ...updatedItems[index], ativa: false },
      ]);
    }
    if (linhasNovasRefs.includes(removedRef)) {
      setLinhasNovas((prevLinhas) =>
        prevLinhas.filter((item) => item.ref !== removedRef)
      );
    }
  };

  const handleCheckItem = (i) => {};

  const handleSaveCapex = async () => {
    const headers = {
      'Content-Type': 'application/json',
    };
    try {
      const response = await axios.put(
        `${REACT_APP_BASE_API_URL}/capex/salvar/`,
        {
          capex: capexDetails,
          linhas_alteradas: {
            linhas: linhasAlteradas,
          },
          linhas_novas: {
            linhas: linhasNovas,
          },
        },
        { headers }
      );
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao salvar Capex'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleAdvRev = () => {
    setAdvVer(false);
  };

  const handleAdvanceCapex = async (e) => {
    e.preventDefault();

    const capexObj = { ...capexDetails };
    delete capexObj.criado_em;
    const obj = {
      capex: capexObj,
      linhas: {
        linhas: capexNewItems,
      },
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    if (advVer) {
      try {
        const response = await axios.post(
          `${REACT_APP_BASE_API_URL}/capex/advv/`,
          obj,
          { headers }
        );
      } catch (error) {
        console.error(error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao avançar Capex'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    }
    if (!advVer) {
      try {
        const response = await axios.post(
          `${REACT_APP_BASE_API_URL}/capex/advv`,
          obj,
          {
            params: {
              ver_adv: false,
            },
            headers: headers,
          }
        );
      } catch (error) {
        console.error(error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao avançar Capex'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    }
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  useEffect(() => {
    getCapex();
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Geral</li>
                    <li>Tabela de empreendimentos</li>
                    <li>{location.state.empreendimento}</li>
                    <li>Capexes</li>
                    <li>Detalhes capex</li>
                    <li>Editar Linhas</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Editar Linhas</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p
                  className="card-header-title"
                  style={{ padding: '12px 0px 12px 12px' }}
                >
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Editar linhas do capex
                </p>
              </header>
              <div className="card-content" style={{ padding: '1.5rem' }}>
                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <ButtonGroup
                      aria-label="outlined is-primary button group"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '20px 0px',
                      }}
                    >
                      <Button
                        onClick={() => handleOpenModal('Materiais')}
                        style={{
                          color: '#2B3BED',
                          padding: '5px 30px',
                        }}
                      >
                        Materiais
                      </Button>
                      <Button
                        onClick={() => handleOpenModal('Equipamentos')}
                        style={{
                          color: '#2B3BED',
                          padding: '5px 30px',
                        }}
                      >
                        Equipamentos
                      </Button>
                      <Button
                        onClick={() => handleOpenModal('Composições')}
                        style={{
                          color: '#2B3BED',
                          padding: '5px 30px',
                        }}
                      >
                        Composições
                      </Button>
                      <Button
                        onClick={() => handleOpenModal('Mão de obra')}
                        style={{
                          color: '#2B3BED',
                          padding: '5px 30px',
                        }}
                      >
                        Mão de obra
                      </Button>
                      <Button
                        onClick={() => handleOpenModal('SINAPI: Composições')}
                        style={{
                          color: '#2B3BED',
                          padding: '5px 30px',
                        }}
                      >
                        SINAPI: Composições
                      </Button>
                      <Button
                        onClick={() => handleOpenModal('SINAPI: Insumos')}
                        style={{
                          color: '#2B3BED',
                          padding: '5px 30px',
                        }}
                      >
                        SINAPI: Insumos
                      </Button>
                    </ButtonGroup>
                    <form name="EditCapexForm" onSubmit={handleAdvanceCapex}>
                      <div
                        className="div-avancar"
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <label
                          className="checkbox"
                          style={{
                            margin: '20px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={!advVer}
                            onChange={handleAdvRev}
                            style={{
                              margin: '0px 10px',
                            }}
                          />
                          Avançar revisão
                        </label>
                        <button
                          className="button is-primary"
                          disabled={
                            !allowedRoles.some((role) =>
                              user.grupos.includes(role)
                            )
                          }
                          style={{
                            margin: '20px 0px',
                          }}
                        >
                          Avançar
                        </button>
                      </div>

                      <div className="b-table has-pagination">
                        <div className="table-wrapper has-mobile-cards">
                          <TableContainer
                            component={Paper}
                            style={{
                              border: '1px solid lightgrey',
                              borderRadius: '5px',
                            }}
                          >
                            <Table aria-label="collapsible table">
                              <TableHead>
                                <TableRow
                                  style={{
                                    display: 'flex',
                                    width: '100%',
                                  }}
                                >
                                  <TableCell
                                    style={{
                                      width: '30px',
                                    }}
                                  />
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      color: '#171717',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    REF
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      color: '#171717',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    REV
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      color: '#171717',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    GRUPO
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '2',
                                      color: '#171717',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    DISCIPLINA
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '2',
                                      color: '#171717',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    AREA
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '2',
                                      color: '#171717',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    SUBAREA
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      color: '#171717',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    MIS
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '2',
                                      color: '#171717',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    ITEM
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      color: '#171717',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    UND
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      color: '#171717',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    QTD
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      color: '#171717',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    PREÇO
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '80px',
                                      color: '#171717',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    AÇÃO
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {capexNewItems &&
                                  capexNewItems.map((linha, i) => (
                                    <React.Fragment key={i}>
                                      <TableRow
                                        key={i}
                                        className={`is-size-7 ${linha.ativa ? '' : 'has-background-danger-light'} ${changedRows.includes(i) && linha.ativa ? 'changed-row' : ''}`}
                                        style={{
                                          display: 'flex',
                                          width: '100%',
                                        }}
                                      >
                                        <TableCell
                                          style={{
                                            width: '30px',
                                            fontWeight: '400',
                                            border: '0px',
                                            fontFamily: '"Nunito", sans-serif',
                                          }}
                                          align="right"
                                        >
                                          {i}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            flex: '1',
                                            fontWeight: '400',
                                            border: '0px',
                                            fontFamily: '"Nunito", sans-serif',
                                          }}
                                          align="right"
                                        >
                                          {linha.ref}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            flex: '1',
                                            fontWeight: '400',
                                            border: '0px',
                                            fontFamily: '"Nunito", sans-serif',
                                          }}
                                          align="right"
                                        >
                                          {linha.rev}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            flex: '1',
                                            fontWeight: '400',
                                            border: '0px',
                                            fontFamily: '"Nunito", sans-serif',
                                          }}
                                          align="right"
                                        >
                                          {linha.grupo}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            flex: '2',
                                            fontWeight: '400',
                                            border: '0px',
                                            fontFamily: '"Nunito", sans-serif',
                                          }}
                                          align="right"
                                        >
                                          <select
                                            className="select is-small"
                                            value={linha.disciplina}
                                            onChange={(e) =>
                                              handleDisciplinaChange(
                                                i,
                                                e.target.value
                                              )
                                            }
                                            disabled={linha.mis !== ''}
                                          >
                                            <option value={''}>
                                              Selecione.
                                            </option>
                                            <option value={'MECANICA'}>
                                              MECANICA
                                            </option>
                                            <option value={'CIVIL'}>
                                              CIVIL
                                            </option>
                                            <option value={'ELETRICA'}>
                                              ELETRICA
                                            </option>
                                            <option value={'INSTRUMENTACAO'}>
                                              INSTRUMENTACAO
                                            </option>
                                          </select>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            flex: '2',
                                            fontWeight: '400',
                                            border: '0px',
                                            fontFamily: '"Nunito", sans-serif',
                                          }}
                                          align="right"
                                        >
                                          <input
                                            className="input is-small"
                                            type="text"
                                            value={linha.area}
                                            disabled={linha.mis !== ''}
                                            onChange={(e) =>
                                              handleAreaChange(
                                                i,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            flex: '2',
                                            fontWeight: '400',
                                            border: '0px',
                                            fontFamily: '"Nunito", sans-serif',
                                          }}
                                          align="right"
                                        >
                                          <input
                                            className="input is-small"
                                            type="text"
                                            value={linha.subarea}
                                            disabled={linha.mis !== ''}
                                            onChange={(e) =>
                                              handleSubareaChange(
                                                i,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            flex: '1',
                                            fontWeight: '400',
                                            border: '0px',
                                            fontFamily: '"Nunito", sans-serif',
                                          }}
                                          align="right"
                                        >
                                          {linha.mis}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            flex: '2',
                                            fontWeight: '400',
                                            border: '0px',
                                            fontFamily: '"Nunito", sans-serif',
                                            textAlign: 'justify',
                                          }}
                                          align="right"
                                        >
                                          {linha.descricao}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            flex: '1',
                                            fontWeight: '400',
                                            border: '0px',
                                            fontFamily: '"Nunito", sans-serif',
                                          }}
                                          align="right"
                                        >
                                          {linha.unidade}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            flex: '1',
                                            fontWeight: '400',
                                            border: '0px',
                                            fontFamily: '"Nunito", sans-serif',
                                          }}
                                          align="right"
                                        >
                                          <input
                                            className="input is-small"
                                            type="number"
                                            value={linha.quantidade}
                                            onChange={(e) =>
                                              handleQuantityChange(
                                                i,
                                                e.target.value
                                              )
                                            }
                                            style={{
                                              maxWidth: 80,
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            flex: '1',
                                            fontWeight: '400',
                                            border: '0px',
                                            fontFamily: '"Nunito", sans-serif',
                                          }}
                                          align="right"
                                        >
                                          {formatPreco(
                                            linha.preco_und * linha.quantidade
                                          )}
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            width: '80px',
                                            fontWeight: '400',
                                            border: '0px',
                                            fontFamily: '"Nunito", sans-serif',
                                          }}
                                          align="right"
                                        >
                                          <div className="columns">
                                            <div className="column is-1">
                                              <span
                                                className="icon"
                                                onClick={() =>
                                                  handleCheckItem(i)
                                                }
                                              >
                                                <i className="fa-solid fa-circle-check"></i>
                                              </span>
                                            </div>
                                            <div className="column is-1">
                                              <span
                                                className="icon"
                                                onClick={() =>
                                                  handleRemoveItem(i)
                                                }
                                              >
                                                <i className="fa-solid fa-eraser"></i>
                                              </span>
                                            </div>
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <FormModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              modalTitle={modalContent}
            >
              {renderModalContent(info)}
            </FormModal>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default CapexEditView;
