// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.changed-row {
  box-shadow: inset 0 0 0 3px violet;
}
`, "",{"version":3,"sources":["webpack://./src/components/apps/capex/CapexEditView.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC","sourcesContent":[".changed-row {\n  box-shadow: inset 0 0 0 3px violet;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
