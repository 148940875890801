// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  position: relative;
  min-height: 100%;
}

/* body {
    margin: 0 0 50px;
    padding: 25px;
} */

/* footer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 10px;
    width: 100%;
    overflow: hidden;
} */
`, "",{"version":3,"sources":["webpack://./src/css/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;;;GAGG;;AAEH;;;;;;;GAOG","sourcesContent":["html {\n  position: relative;\n  min-height: 100%;\n}\n\n/* body {\n    margin: 0 0 50px;\n    padding: 25px;\n} */\n\n/* footer {\n    position: absolute;\n    left: 0;\n    bottom: 0;\n    height: 10px;\n    width: 100%;\n    overflow: hidden;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
