import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SelectMui from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import './Kanban.css'; // Arquivo de estilos

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/pt-br';

import ComentarioComponent from '../../functional/comentarios/Comentario';
import AlertMessage from '../../layouts/AlertMessage';
import { useUser } from '../../../context/UserContext';

const ModalCardKanban = ({ cardObj, info }) => {
  const [comentario, setComentario] = useState('');
  const [status, setStatus] = useState(cardObj.tarefa.status);
  const [dataPrevista, setDataPrevista] = useState(null);
  const { REACT_APP_BASE_API_URL } = process.env;
  const { idUsuario, gruposUsuario } = useUser();
  const [commentsData, setCommentsData] = useState([]);
  const userGroups = gruposUsuario;
  // const [alertMessage, setAlertMessage] = useState(null);
  // const [alertSeverity, setAlertSeverity] = useState('error');
  // const [alertOpen, setAlertOpen] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [navigateTo, setNavigateTo] = useState("");
  // const [reload, setReload] = useState(false);

  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const lista_feriados_geo = {
    '2024-04-21': 'Tiradentes',
    '2024-05-01': 'Dia do Trabalho',
    '2024-05-30': 'Corpus Christi',
    '2024-05-31': 'Sagrado Coração de Jesus',
    '2024-07-09': 'Revolução Constitucionalista',
    '2024-09-07': 'Independência do Brasil',
    '2024-10-12': 'Nossa Senhora Aparecida',
    '2024-11-02': 'Finados',
    '2024-11-15': 'Proclamação da República',
    '2024-11-20': 'Consciência Negra',
    '2024-12-10': 'Aniversário de Londrina',
    '2024-12-25': 'Natal',
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  const revisarDocumento = async (tarefa_id, e) => {
    e.preventDefault();

    if (!dataPrevista) {
      setAlertMessage('Por favor, selecione uma data.');
      setAlertSeverity('warning');
      setAlertOpen(true);
      e.preventDefault();
      return;
    }

    try {
      let params = {
        tarefa_id: tarefa_id,
        data_prevista: dataPrevista
          ? dayjs(dataPrevista).format('YYYY-MM-DDTHH:mm:ssZ')
          : null,
      };

      const response = await axios.put(
        `${REACT_APP_BASE_API_URL}/kanban/revisar-card-kanban`,
        {},
        { params, headers }
      );

      if (response.status === 201) {
        // alert("Documento revisado, lembre-se de iniciar a tarefa!!!")
        setAlertMessage('Documento revisado, lembre-se de iniciar a tarefa!!!');
        setAlertSeverity('success');
        setAlertOpen(true);
        setReload(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao revisar documento'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const emitirDocumento = async (tarefa_id, e) => {
    e.preventDefault();

    try {
      let params = { tarefa_id: tarefa_id };

      const response = await axios.put(
        `${REACT_APP_BASE_API_URL}/kanban/`,
        {},
        { params, headers }
      );

      if (response.status === 201) {
        setAlertMessage('Documento emitido!!!');
        setAlertSeverity('success');
        setAlertOpen(true);
        setReload(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao emitir documento'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const aprovarDocumento = async (tarefa_id, e) => {
    e.preventDefault();

    if (status === 'PENDENTE') {
      e.preventDefault();
      setAlertMessage('Selecione um status válido para aprovar o documento!');
      setAlertSeverity('warning');
      setAlertOpen(true);
      return;
    }

    if (status === 'RECUSADO' && !comentario) {
      e.preventDefault();
      setAlertMessage('Insira um comentario para recusar o documento!');
      setAlertSeverity('warning');
      setAlertOpen(true);
      return;
    }

    try {
      let params = {
        tarefa_id: tarefa_id,
        comentario: comentario,
        status: status,
      };

      const response = await axios.put(
        `${REACT_APP_BASE_API_URL}/kanban/aprovar-card-kanban/`,
        {},
        { params, headers }
      );

      if (response.status === 201) {
        const commentPost = await axios.post(
          `${REACT_APP_BASE_API_URL}/comments/`,
          { text: comentario, user_id: idUsuario, objeto: tarefa_id },
          { headers }
        );

        if (commentPost.status === 201) {
          setComentario('');
          getComments(tarefa_id);
          if (status === 'RECUSADO') {
            setAlertMessage(
              'Documento recusado e comentário de recusa adicionado!!!'
            );
            setAlertSeverity('success');
            setAlertOpen(true);
            setReload(true);
          } else {
            setAlertMessage(
              'Documento aprovado e comentário de aprovação adicionado!!!'
            );
            setAlertSeverity('success');
            setAlertOpen(true);
            setReload(true);
          }
        }
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao aprovar documento'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const iniciarTarefa = async (tarefa_id, e) => {
    e.preventDefault();
    try {
      let params = { tarefa_id: tarefa_id };
      const response = await axios.put(
        `${REACT_APP_BASE_API_URL}/kanban/iniciar-tarefa/`,
        {},
        { params, headers }
      );

      if (response.status === 200) {
        setAlertMessage('Tarefa iniciada!!!');
        setAlertSeverity('success');
        setAlertOpen(true);
        setReload(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao iniciar tarefa'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const pararTarefa = async (tarefa_id, e) => {
    e.preventDefault();
    try {
      let params = { tarefa_id: tarefa_id };
      const response = await axios.put(
        `${REACT_APP_BASE_API_URL}/kanban/parar-tarefa/`,
        {},
        { params, headers }
      );

      if (response.status === 200) {
        setAlertMessage('Tarefa parada!!!');
        setAlertSeverity('success');
        setAlertOpen(true);
        setReload(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao parar tarefa'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const cancelarDocumento = async (titulo, e) => {
    e.preventDefault();

    try {
      let params = { titulo: titulo };

      const response = await axios.put(
        `${REACT_APP_BASE_API_URL}/kanban/cancelar-fluxo-kanban/`,
        {},
        { params, headers }
      );

      if (response.status === 201) {
        setAlertMessage('Documento cancelado!!!');
        setAlertSeverity('success');
        setAlertOpen(true);
        setReload(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao cancelar documento'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
      setReload(true);
    }
  };

  const getComments = async (id) => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_API_URL}/comments/`, {
        params: { objeto_id: id },
        headers,
      });
      if (response.status === 200) {
        setCommentsData(response.data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar comentários'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const fetchFilaDocEntregavelVersao = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/kanban/fluxo-aprovacao/`,
        {
          params: { fluxo_id: cardObj.tarefa.link_flux },
          headers,
        }
      );

      if (response.data) {
        puxarComentarios(response.data.tarefas);
      }
    } catch (error) {
      console.error('Erro ao buscar fluxo do documentos entregavel:', error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail ||
              'Erro ao buscar fluxo do documentos entregavel'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const puxarComentarios = async (fila) => {
    let fluxo_ids = fila.map((tarefa) => tarefa.id);
    try {
      const response = await axios.get(`${REACT_APP_BASE_API_URL}/comments/`, {
        params: { objeto_id: fluxo_ids[0] },
        headers,
      });

      if (response.status === 200) {
        setCommentsData(response.data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar comentários'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const tempoComercialGasto = (tarefa) => {
    function calcularIntervaloComercial(dataInicial, dataFinal) {
      // Define commercial hours and lunch break
      const inicioComercial = 8; // 8 AM
      const fimComercial = 18; // 6 PM
      const inicioAlmoco = 12; // Noon
      const hourFimAlmoco = 13; // 1 PM
      const minuteFimAlmoco = 12;

      let intervaloValido = 0; // Total valid interval in milliseconds

      // Create a copy of dataInicial to iterate
      let dataAtual = new Date(dataInicial);

      while (dataAtual < dataFinal) {
        // Skip to the next weekday if it's Saturday or Sunday
        if (
          dataAtual.getDay() === 6 ||
          dataAtual.getDay() === 0 ||
          lista_feriados_geo[dataAtual.toLocaleDateString('en-CA')]
        ) {
          dataAtual.setDate(dataAtual.getDate() + 1);
          dataAtual.setHours(inicioComercial, 0, 0, 0);
          continue;
        }

        // Skip to the next day's start if we're past commercial hours
        if (dataAtual.getHours() >= fimComercial) {
          dataAtual.setDate(dataAtual.getDate() + 1);
          dataAtual.setHours(inicioComercial, 0, 0, 0);
          continue;
        }

        // Move to the start of the day if we're before commercial hours
        if (dataAtual.getHours() < inicioComercial) {
          dataAtual.setHours(inicioComercial, 0, 0, 0);
          continue;
        }

        // Set the end of the current period based on lunch and end of commercial hours
        let fimPeriodo;
        if (dataAtual.getHours() < inicioAlmoco) {
          fimPeriodo = new Date(
            dataAtual.getFullYear(),
            dataAtual.getMonth(),
            dataAtual.getDate(),
            inicioAlmoco,
            0,
            0
          );
        } else if (
          dataAtual.getHours() === inicioAlmoco ||
          dataAtual.getHours() < hourFimAlmoco ||
          (dataAtual.getHours() === hourFimAlmoco &&
            dataAtual.getMinutes() < minuteFimAlmoco)
        ) {
          dataAtual.setHours(hourFimAlmoco, minuteFimAlmoco, 0, 0);
          continue;
        } else {
          fimPeriodo = new Date(
            dataAtual.getFullYear(),
            dataAtual.getMonth(),
            dataAtual.getDate(),
            fimComercial,
            0,
            0
          );
        }

        // Adjust the end of the period if it goes beyond dataFinal
        if (fimPeriodo > dataFinal) {
          fimPeriodo = new Date(dataFinal);
        }

        // Add valid time to total interval
        intervaloValido += fimPeriodo - dataAtual;
        dataAtual = new Date(fimPeriodo);
      }

      // Convert milliseconds back to hours, minutes, or whatever format you need
      return intervaloValido;
    }

    function parseDateString(dateString) {
      const [datePart, timePart] = dateString.split('-');
      const [day, month, year] = datePart.split('/');
      const [hour, minute] = timePart.split(':');

      // Return a new Date object with the parsed values
      return new Date(year, month - 1, day, hour, minute);
    }

    let carimbos = tarefa.carimbos;

    // "[31/10/2024-17:52]REVISAO [31/10/2024-17:52]PENDENTE [01/11/2024-13:53]INICIADA [04/11/2024-08:03]PENDENTE"
    let carimbosArray = carimbos.split(' ');
    let ultimoCarimbo = carimbosArray[carimbosArray.length - 1];

    if (ultimoCarimbo.includes('INICIADA')) {
      let dataInicio = ultimoCarimbo.split(']')[0].replace('[', '');
      dataInicio = parseDateString(dataInicio);
      let dataFim = new Date();
      let intervaloComercial = calcularIntervaloComercial(dataInicio, dataFim);
      const hours = intervaloComercial / (1000 * 60 * 60);
      return tarefa.tempo_gasto + hours;
    } else {
      return tarefa.tempo_gasto;
    }
  };

  useEffect(() => {
    fetchFilaDocEntregavelVersao();
  }, [REACT_APP_BASE_API_URL]);

  return (
    <>
      <div
        className="container"
        style={{
          maxWidth: '500px',
          minWidth: '600px',
          height: 'auto',
          margin: '0px',
          zIndex: 1500,
        }}
      >
        <div className="modal-form">
          <div className="field" style={{ marginTop: '15px' }}>
            <div className="row-field">
              <label
                className="label-field"
                style={{
                  color: 'hsl(0, 0%, 29%)',
                  fontSize: '1.2rem',
                }}
              >
                Documento
              </label>
              <span
                className="cell-value"
                style={{
                  color: 'hsl(0, 0%, 29%)',
                  fontSize: '1.2rem',
                  wordBreak: 'break-all',
                }}
              >
                {cardObj.conteudo.nome_documento}
              </span>
            </div>
          </div>
          <div className="field" style={{ marginTop: '15px' }}>
            <div className="row-field">
              <label
                className="label-field"
                style={{
                  color: 'hsl(0, 0%, 29%)',
                  fontSize: '1.2rem',
                }}
              >
                TAG
              </label>
              <span
                className="cell-value"
                style={{
                  color: 'hsl(0, 0%, 29%)',
                  fontSize: '1.2rem',
                  wordBreak: 'break-all',
                }}
              >
                {cardObj.conteudo.tag}
              </span>
            </div>
          </div>

          {/* <div className="field" style={{marginTop: '15px'}}>
                  <div className="row-field">
                    <label className="label-field" style={{ color: 'hsl(0, 0%, 29%)', fontSize: '1.2rem'}}>
                    Disciplina
                    </label>                        
                    <span className="cell-value" style={{ color: 'hsl(0, 0%, 29%)', fontSize: '1.2rem', wordBreak: 'break-all'}}>
                      {cardObj.conteudo.disciplina.toUpperCase()}
                    </span>
                  </div>
                </div> */}
          {/* <div className="field" style={{marginTop: '15px'}}>
                  <div className="row-field">
                    <label className="label-field" style={{ color: 'hsl(0, 0%, 29%)', fontSize: '1.2rem'}}>
                    Projeto
                    </label>                        
                    <span className="cell-value" style={{ color: 'hsl(0, 0%, 29%)', fontSize: '1.2rem', wordBreak: 'break-all'}}>
                      {cardObj.conteudo.projeto}  
                    </span>
                  </div>
                </div>      */}
          <div className="field" style={{ marginTop: '15px' }}>
            <div className="row-field">
              <label
                className="label-field"
                style={{
                  color: 'hsl(0, 0%, 29%)',
                  fontSize: '1.2rem',
                }}
              >
                Permalink
              </label>
              <span
                className="cell-value"
                style={{
                  color: 'hsl(0, 0%, 29%)',
                  fontSize: '1.2rem',
                  wordBreak: 'break-all',
                }}
              >
                {cardObj.conteudo.permalink !== '-' &&
                cardObj.conteudo.permalink !== '' &&
                cardObj.conteudo.permalink !== null ? (
                  <a href={cardObj.conteudo.permalink}>
                    {cardObj.conteudo.permalink}
                  </a>
                ) : (
                  'Sem link'
                )}
              </span>
            </div>
          </div>
          {/* <div className="row-detail">
                  <div className="label-field title-card"> Permalink</div>
                  <div className="cell-field"><a href= {cardObj.conteudo.permalink}>{cardObj.conteudo.permalink}</a></div> 
                </div>  */}
          <div className="field" style={{ marginTop: '15px' }}>
            <div className="row-field">
              <label
                className="label-field"
                style={{
                  color: 'hsl(0, 0%, 29%)',
                  fontSize: '1.2rem',
                }}
              >
                Responsável da tarefa
              </label>
              <span
                className="cell-value"
                style={{
                  color: 'hsl(0, 0%, 29%)',
                  fontSize: '1.2rem',
                  wordBreak: 'break-all',
                }}
              >
                {cardObj.conteudo.user_nome}
              </span>
            </div>
          </div>
          <div className="field" style={{ marginTop: '15px' }}>
            <div className="row-field">
              <label
                className="label-field"
                style={{
                  color: 'hsl(0, 0%, 29%)',
                  fontSize: '1.2rem',
                }}
              >
                Data limite para aprovação
              </label>
              <span
                className="cell-value"
                style={{
                  color: 'hsl(0, 0%, 29%)',
                  fontSize: '1.2rem',
                  wordBreak: 'break-all',
                }}
              >
                {new Date(cardObj.tarefa.data_due).toLocaleString()}
              </span>
            </div>
          </div>

          {!cardObj.tarefa.link_bw ? (
            cardObj.tarefa.status === 'REVISAO' ? (
              <div>
                <div className="field" style={{ marginTop: '15px' }}>
                  <div className="row-field">
                    <label
                      className="label-field"
                      style={{
                        color: 'hsl(0, 0%, 29%)',
                        fontSize: '1.2rem',
                      }}
                    >
                      Status
                    </label>
                    <span
                      className="cell-value"
                      style={{
                        color: 'hsl(0, 0%, 29%)',
                        fontSize: '1.2rem',
                        wordBreak: 'break-all',
                      }}
                    >
                      {cardObj.tarefa.status}
                    </span>
                  </div>
                </div>

                {(idUsuario === cardObj.tarefa.responsavel_id ||
                  userGroups.includes('KANBAN - PLANEJAMENTO')) && (
                  <div>
                    <form
                      name="revisarDocumento"
                      onSubmit={(e) => revisarDocumento(cardObj.tarefa.id, e)}
                    >
                      <div className="field" style={{ marginTop: '15px' }}>
                        <div className="row-field">
                          <label
                            className="label-field"
                            style={{
                              color: 'hsl(0, 0%, 29%)',
                              fontSize: '1.2rem',
                            }}
                          >
                            Data Prevista emissão
                          </label>
                          <span
                            className="cell-value"
                            style={{
                              color: 'hsl(0, 0%, 29%)',
                              fontSize: '1.2rem',
                              wordBreak: 'break-all',
                            }}
                          >
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale="pt-br"
                            >
                              <DemoContainer
                                sx={{
                                  padding: '0px',
                                  height: '40px',
                                  color: 'hsl(0, 0%, 29%)',
                                }}
                                components={['DatePicker']}
                              >
                                <DatePicker
                                  label=""
                                  minDate={dayjs()}
                                  sx={{
                                    '& .MuiInputBase-input': {
                                      padding: '8px',
                                      fontSize: '1rem',
                                      color: 'hsl(0, 0%, 29%)',
                                    },

                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        borderColor: 'hsl(0, 0%, 29%)', // Cor da borda padrão
                                      },
                                    },
                                  }}
                                  value={dataPrevista}
                                  onChange={(newValue) =>
                                    setDataPrevista(newValue ? newValue : null)
                                  }
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </span>
                        </div>
                      </div>

                      <div
                        style={{
                          margin: '10px 0px 20px 0px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <button
                          type="submit"
                          disabled={cardObj.tarefa.data_conclusao}
                          className="button is-primary"
                        >
                          Atualizar nova data prevista de emissão
                        </button>
                      </div>
                    </form>
                    <hr></hr>
                    <div style={{ margin: '10px 10px' }}>
                      <form
                        name="cancelarDocumento"
                        onSubmit={(e) =>
                          cancelarDocumento(cardObj.conteudo.titulo, e)
                        }
                      >
                        <div
                          style={{
                            margin: '0px',
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <button
                            style={{
                              backgroundColor: 'red',
                            }}
                            type="submit"
                            disabled={cardObj.tarefa.data_conclusao}
                            className="button is-primary"
                          >
                            Parar aprovações
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              // SENÃO FOR REVISÃO

              <div>
                <div className="field" style={{ marginTop: '15px' }}>
                  <div className="row-field">
                    <label
                      className="label-field"
                      style={{
                        color: 'hsl(0, 0%, 29%)',
                        fontSize: '1.2rem',
                      }}
                    >
                      Status
                    </label>
                    <span
                      className="cell-value"
                      style={{
                        color: 'hsl(0, 0%, 29%)',
                        fontSize: '1.2rem',
                        wordBreak: 'break-all',
                      }}
                    >
                      {cardObj.tarefa.status}
                    </span>
                  </div>
                  <div className="row-field">
                    <label
                      className="label-field"
                      style={{
                        color: 'hsl(0, 0%, 29%)',
                        fontSize: '1.2rem',
                      }}
                    >
                      Tempo planejado
                    </label>
                    <span
                      className="cell-value"
                      style={{
                        color: 'hsl(0, 0%, 29%)',
                        fontSize: '1.2rem',
                        wordBreak: 'break-all',
                      }}
                    >
                      {cardObj.tarefa.tempo_emissao
                        ? cardObj.tarefa.tempo_emissao + ' horas'
                        : 'Tempo não definido'}
                    </span>
                  </div>
                  <div className="row-field">
                    <label
                      className="label-field"
                      style={{
                        color: 'hsl(0, 0%, 29%)',
                        fontSize: '1.2rem',
                      }}
                    >
                      Tempo gasto
                    </label>
                    <span
                      className="cell-value"
                      style={{
                        color: 'hsl(0, 0%, 29%)',
                        fontSize: '1.2rem',
                        wordBreak: 'break-all',
                      }}
                    >
                      {tempoComercialGasto(cardObj.tarefa)
                        ? tempoComercialGasto(cardObj.tarefa) + ' horas'
                        : 'Tempo não definido'}
                    </span>
                  </div>
                </div>
                {cardObj.tarefa.status !== 'CONCLUIDA' && <hr></hr>}
                {(idUsuario === cardObj.tarefa.responsavel_id ||
                  userGroups.includes('KANBAN - PLANEJAMENTO')) && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '20px',
                    }}
                  >
                    {cardObj.tarefa.status === 'PENDENTE' && (
                      <div>
                        <form
                          name="inciarTarefa"
                          onSubmit={(e) => iniciarTarefa(cardObj.tarefa.id, e)}
                        >
                          <div
                            style={{
                              margin: '0px',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <button
                              type="submit"
                              className="button is-primary"
                              disabled={cardObj.tarefa.status !== 'PENDENTE'}
                              style={{
                                minWidth: '62px',
                                width: '100px',
                                backgroundColor: 'green',
                              }}
                            >
                              Iniciar tarefa
                            </button>
                          </div>
                        </form>
                      </div>
                    )}

                    {cardObj.tarefa.status === 'INICIADA' && (
                      <div>
                        <form
                          name="pararTarefa"
                          onSubmit={(e) => pararTarefa(cardObj.tarefa.id, e)}
                        >
                          <div
                            style={{
                              margin: '0px',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <button
                              type="submit"
                              disabled={cardObj.tarefa.status !== 'INICIADA'}
                              className="button is-primary"
                              style={{
                                minWidth: '62px',
                                width: '100px',
                                backgroundColor: 'yellow',
                                color: 'black',
                              }}
                            >
                              Parar tarefa
                            </button>
                          </div>
                        </form>
                      </div>
                    )}

                    {cardObj.tarefa.status === 'INICIADA' && (
                      <div>
                        <form
                          name="emitirDocumento"
                          onSubmit={(e) =>
                            emitirDocumento(cardObj.tarefa.id, e)
                          }
                        >
                          <div
                            style={{
                              margin: '0px',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <button
                              type="submit"
                              disabled={cardObj.tarefa.data_conclusao}
                              className="button is-primary"
                              style={{
                                minWidth: '62px',
                                width: '90px',
                              }}
                            >
                              Emitir
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                    {cardObj.tarefa.status !== 'CONCLUIDA' && (
                      <div>
                        <form
                          name="cancelarDocumento"
                          onSubmit={(e) =>
                            cancelarDocumento(cardObj.conteudo.titulo, e)
                          }
                        >
                          <div
                            style={{
                              margin: '0px',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <button
                              className="button is-primary"
                              type="submit"
                              disabled={cardObj.tarefa.data_conclusao}
                              style={{
                                backgroundColor: 'red',
                              }}
                            >
                              Cancelar documento (fluxo)
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )
          ) : (
            // SENAO FOR CARD DO EMISSOR/REVISOR
            (idUsuario === cardObj.tarefa.responsavel_id ||
              userGroups.includes('KANBAN - PLANEJAMENTO')) && (
              <form
                name="aprovarDocumento"
                onSubmit={(e) => aprovarDocumento(cardObj.tarefa.id, e)}
              >
                <div>
                  <div className="field" style={{ marginTop: '15px' }}>
                    <div className="row-field">
                      <label
                        className="label-field"
                        style={{
                          color: 'hsl(0, 0%, 29%)',
                          fontSize: '1.2rem',
                        }}
                      >
                        Status
                      </label>
                      <span
                        className="cell-value"
                        style={{
                          color: 'hsl(0, 0%, 29%)',
                          fontSize: '1.2rem',
                        }}
                      >
                        <SelectMui
                          style={{
                            height: '37px',
                            fontSize: '1rem',
                            fontWeight: '400',
                            color: 'black',
                            background:
                              status === 'PENDENTE'
                                ? 'yellow'
                                : status === 'APROVADO'
                                  ? 'green'
                                  : status === 'RECUSADO'
                                    ? 'red'
                                    : 'inherit',
                          }}
                          disabled={cardObj.tarefa.data_conclusao}
                          className="select-status"
                          value={status}
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                        >
                          <MenuItem
                            value={'PENDENTE'}
                            style={{
                              background: 'yellow',
                            }}
                          >
                            PENDENTE
                          </MenuItem>
                          <MenuItem
                            value={'APROVADO'}
                            style={{
                              background: 'green',
                            }}
                          >
                            APROVADO
                          </MenuItem>
                          <MenuItem
                            value={'RECUSADO'}
                            style={{
                              background: 'red',
                            }}
                          >
                            RECUSADO
                          </MenuItem>
                        </SelectMui>
                      </span>
                    </div>
                  </div>

                  <div className="field" style={{ marginTop: '15px' }}>
                    <div
                      className="row-field"
                      style={{ flexDirection: 'column' }}
                    >
                      <label
                        className="label-field"
                        style={{
                          color: 'hsl(0, 0%, 29%)',
                          fontSize: '1.2rem',
                          paddingBottom: '10px',
                        }}
                      >
                        Comentários
                      </label>
                      <span
                        className="cell-value"
                        style={{
                          color: 'hsl(0, 0%, 29%)',
                          fontSize: '1.2rem',
                          paddingLeft: '0px',
                        }}
                      >
                        {commentsData &&
                          commentsData.map((comment) => (
                            <ComentarioComponent
                              key={comment.id}
                              comment={comment}
                              userId={idUsuario}
                              objeto={cardObj.tarefa}
                              getCommentFunction={getComments}
                              reply={false}
                            />
                          ))}
                        {commentsData ? (
                          commentsData.length === 0 ? (
                            <p>Nenhum comentário</p>
                          ) : null
                        ) : null}
                      </span>
                    </div>
                  </div>

                  <div className="field" style={{ marginTop: '15px' }}>
                    <div
                      className="row-field"
                      style={{ flexDirection: 'column' }}
                    >
                      <label
                        className="label-field"
                        style={{
                          color: 'hsl(0, 0%, 29%)',
                          fontSize: '1.2rem',
                          paddingBottom: '10px',
                        }}
                      >
                        Adicionar comentario
                      </label>
                      <span
                        className="cell-value"
                        style={{
                          color: 'hsl(0, 0%, 29%)',
                          fontSize: '1.2rem',
                          paddingLeft: '0px',
                        }}
                      >
                        <textarea
                          className="input"
                          rows="6"
                          style={{ height: '150px' }}
                          value={comentario}
                          onChange={(e) => setComentario(e.target.value)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </span>
                    </div>
                  </div>

                  {status === 'RECUSADO' && (
                    <div
                      style={{
                        margin: '10px 0px 20px 0px',
                        padding: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div>
                        <button
                          type="submit"
                          disabled={cardObj.tarefa.data_conclusao}
                          className="button is-primary"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{
                            backgroundColor: 'red',
                          }}
                        >
                          Recusar documento
                        </button>
                      </div>
                    </div>
                  )}

                  {status === 'APROVADO' && (
                    <div
                      style={{
                        margin: '10px 0px 20px 0px',
                        padding: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div>
                        <button
                          type="submit"
                          disabled={cardObj.tarefa.data_conclusao}
                          className="button is-primary"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{
                            backgroundColor: 'green',
                          }}
                        >
                          Aprovar documento
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default ModalCardKanban;
