import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import AlertMessage from '../../layouts/AlertMessage';
import { useUser } from '../../../context/UserContext';

const FreteHomeFornecedor = () => {
  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;
  const [allOrcamentos, setAllOrcamentos] = useState(null);
  const [allOrcamentosF, setAllOrcamentosF] = useState(null);
  const { idUsuario } = useUser();
  const [fornecedorId, setFornecedorId] = useState('');

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const getFornecedorId = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/fornecedor/user/ace-id/`,
        {
          params: {
            user_id: idUsuario,
          },
          headers,
        }
      );
      if (response.status === 200) {
        setFornecedorId(response.data);
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || "Ocorreu um erro.")
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Eror ao buscar fornecedor'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getOrcamentos = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/frete/orcamento-frete/fornecedor/`,
        {
          params: {
            fornecedor_id: fornecedorId,
          },
          headers,
        }
      );
      if (response.status === 200) {
        setAllOrcamentos(response.data);
        const formattedData = response.data.map((item, index) => ({
          id: item.orcamento.id,
          freteRef: item.frete.referencia,
          freteOrigem: item.frete.origem,
          freteDestino: item.frete.destino,
          freteOrcado: item.orcamento.orcado,
          fretePreco: item.orcamento.preco,
        }));
        setAllOrcamentosF(formattedData);
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || "Ocorreu um erro.")
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar orçamentos'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const columns = [
    { field: 'freteRef', headerName: 'REF', flex: 1 },
    { field: 'freteOrigem', headerName: 'ORIGEM', flex: 1 },
    { field: 'freteDestino', headerName: 'DESTINO', flex: 1 },
    {
      field: 'freteOrcado',
      headerName: 'ORÇADO',
      flex: 1,
      renderCell: (params) =>
        params.value ? (
          <i className="fa-solid fa-check" style={{ color: 'green' }}></i>
        ) : (
          <i className="fa-solid fa-x" style={{ color: 'red' }}></i>
        ),
    },
    {
      field: 'fretePreco',
      headerName: 'PREÇO',
      flex: 1,
      renderCell: (params) => formatPreco(params.value),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      flex: 1,
      renderCell: (params) => (
        <button
          className="button is-small is-info"
          onClick={() => navigate(`/ext/fretes/fornecedor/${params.row.id}`)}
        >
          Ver Detalhes
        </button>
      ),
      sortable: false,
      filterable: false,
    },
  ];

  useEffect(() => {
    getFornecedorId();
  }, []); // Only run when userId changesnly run when fornecedorId changes

  useEffect(() => {
    if (fornecedorId) {
      getOrcamentos();
      console.log('a');
    }
  }, [fornecedorId]);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Portal de Fornecedores</li>
                    <li>Fretes</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Fretes</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            {allOrcamentosF && (
              <DataGrid
                rows={allOrcamentosF}
                columns={columns}
                pageSize={5}
                disableSelectionOnClick
                getRowHeight={() => 'auto'}
                sx={{
                  fontFamily: '"Nunito", sans-serif',
                  '& .MuiDataGrid-cell': {
                    display: 'flex',
                    alignItems: 'flex-start',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    border: '0px',
                    fontFamily: '"Nunito", sans-serif',
                    lineHeight: '1.5',
                    fontSize: '16px',
                  },
                  '& .MuiDataGrid-row': {
                    borderLeft: '0px',
                  },
                  '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                    {
                      fontWeight: '600',
                      fontSize: '16px',
                      color: '#171717',
                      fontFamily: '"Nunito", sans-serif',
                      lineHeight: '1.5',
                    },
                }}
              />
            )}
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default FreteHomeFornecedor;
