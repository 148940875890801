import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

const PrivateRoute = ({ children, allowedRoles = [] }) => {
  const {
    gruposUsuario,
    setNomeUsuario,
    setRoleUsuario,
    setIdUsuario,
    setSetorUsuario,
    setGruposUsuario,
    setCargoUsuario,
    setTelefone1Usuario,
    setTelefone2Usuario,
    setUsuarioInterno,
  } = useUser();

  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;
  const [loading, setLoading] = useState(true);

  const loginUserWithToken = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/auth/login-using-token/`,
        {},
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (response.status === 200) {
        const data = response.data;
        // Update user context with fetched data
        setGruposUsuario(data.user_grupos);
        setNomeUsuario(data.user_nome);
        setRoleUsuario(data.user_role);
        setIdUsuario(data.user_id);
        setSetorUsuario(data.user_setor);
        setCargoUsuario(data.user_cargo);
        setTelefone1Usuario(data.user_telefone1);
        setTelefone2Usuario(data.user_telefone2);
        setUsuarioInterno(data.user_intern);

        return data.user_grupos;
      }
    } catch (error) {
      console.error('Error during user authentication:', error);
      return null;
    }
  };

  const verifyAccess = async () => {
    let userGroups = gruposUsuario;

    // Fetch user groups if not already set
    if (!userGroups) {
      userGroups = await loginUserWithToken();
    }

    if (!userGroups) {
      navigate('/login');
      return;
    }

    // Check access permissions
    if (
      allowedRoles.length > 0 &&
      !allowedRoles.some((role) => userGroups.includes(role))
    ) {
      navigate('/not-authorized');
      return;
    }

    setLoading(false); // Mark loading as complete
  };

  useEffect(() => {
    verifyAccess();
  }, []); // Only runs on mount

  if (loading) {
    return (
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" size="5rem" />
        <Typography variant="h6" color="inherit">
          Carregando...
        </Typography>
      </Backdrop>
    );
  }

  return children;
};

export default PrivateRoute;
