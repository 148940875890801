import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import '../../scss/main.scss';
import '../../app.scss';
import { useUser } from '../../context/UserContext';

const Sidebar = () => {
  const [isOpenEng, setIsOpenEng] = useState(false);
  const [isOpenSup, setIsOpenSup] = useState(false);
  const [isOpenCont, setIsOpenCont] = useState(false);
  const [isOpenPlan, setIsOpenPlan] = useState(false);
  const [isOpenComer, setIsOpenComer] = useState(false);

  const { roleUsuario } = useUser();

  const [isAdmin, setIsAdmin] = useState(roleUsuario === 'admin');
  const [isSupervisor, setIsSupervisor] = useState(
    roleUsuario === 'supervisor' || roleUsuario === 'gerente'
  );

  return (
    <aside className="aside is-placed-left menu-scrollable is-expanded">
      <div className="aside-tools">
        <div className="logo">
          <img
            src="/logo-geo-monocromatico-preto1.svg"
            style={{ width: '18rem' }}
            alt="Logo"
          />
        </div>
      </div>
      <div className="menu is-menu-main">
        <p className="menu-label">Geral</p>
        <ul className="menu-list">
          {isAdmin && (
            <li>
              <NavLink
                to={'/admin'}
                style={{ display: 'flex' }}
                className={({ isActive }) =>
                  'has-icon' + (isActive ? ' is-active router-link-active' : '')
                }
              >
                <span className="icon">
                  <i className="mdi mdi-desktop-mac"></i>
                </span>
                <span className="menu-item-label">Painel Admin</span>
              </NavLink>
            </li>
          )}
          {isSupervisor | isAdmin && (
            <li>
              <NavLink
                to={'/supervisor/'}
                style={{ display: 'flex' }}
                className={({ isActive }) =>
                  'has-icon' + (isActive ? ' is-active router-link-active' : '')
                }
              >
                <span className="icon">
                  <i className="mdi mdi-desktop-mac"></i>
                </span>
                <span className="menu-item-label">Painel Supervisor</span>
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              to={'/user/dashboard/'}
              style={{ display: 'flex' }}
              className={({ isActive }) =>
                'has-icon' + (isActive ? ' is-active router-link-active' : '')
              }
            >
              <span className="icon">
                <i className="fa-solid fa-table-columns"></i>
              </span>
              <span className="menu-item-label">Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/geral/minhas-solicitacoes'}
              style={{ display: 'flex' }}
              className={({ isActive }) =>
                'has-icon' + (isActive ? ' is-active router-link-active' : '')
              }
            >
              <span className="icon">
                <i className="fa-solid fa-chalkboard-user"></i>
              </span>
              <span className="menu-item-label">Minhas solicitações</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/geral/cadastros'}
              style={{ display: 'flex' }}
              className={({ isActive }) =>
                'has-icon' + (isActive ? ' is-active router-link-active' : '')
              }
            >
              <span className="icon">
                <i className="far fa-edit"></i>
              </span>
              <span className="menu-item-label">Cadastros</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/geral/tabela_empreendimentos/'}
              style={{ display: 'flex' }}
              className={({ isActive }) =>
                'has-icon' + (isActive ? ' is-active router-link-active' : '')
              }
            >
              <span className="icon" style={{ minWidth: '48px' }}>
                <i className="fas fa-chart-line"></i>
              </span>
              <span className="menu-item-label">Tabela de empreendimentos</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/geral/tabela_fornecedores/'}
              style={{ display: 'flex' }}
              className={({ isActive }) =>
                'has-icon' + (isActive ? ' is-active router-link-active' : '')
              }
            >
              <span className="icon" style={{ minWidth: '48px' }}>
                <i className="fas fa-dolly"></i>
              </span>
              <span className="menu-item-label">Tabela de fornecedores</span>
            </NavLink>
          </li>

          <li style={{ display: 'none' }}>
            <NavLink
              to={'/adm/empreendimentos'}
              style={{ display: 'flex' }}
              className={({ isActive }) =>
                'has-icon' + (isActive ? ' is-active router-link-active' : '')
              }
            >
              <span className="icon">
                <i className="fas fa-chart-line"></i>
              </span>
              <span className="menu-item-label">Empreendimentos</span>
            </NavLink>
          </li>
        </ul>
        <p className="menu-label">Setores</p>
        <ul className="menu-list">
          <li>
            <a
              onClick={() => setIsOpenComer(!isOpenComer)}
              className="has-icon has-dropdown-icon"
            >
              <span className="icon">
                <i className="fa-solid fa-handshake"></i>
              </span>
              <span className="menu-item-label">Comercial</span>
              <div className="dropdown-icon">
                <span className="icon">
                  <i
                    className={`mdi ${isOpenComer ? 'mdi-minus' : 'mdi-plus'}`}
                  ></i>
                </span>
              </div>
            </a>
            {isOpenComer && (
              <ul style={{ display: 'block' }}>
                <li>
                  <Link to={'/pedidos-servico'} style={{ display: 'flex' }}>
                    <span className="icon">
                      <i className="fas fa-table"></i>
                    </span>
                    <span className="menu-item-label">Fila de serviços</span>
                  </Link>
                </li>
                <li>
                  <Link to={'/loh'} style={{ display: 'flex' }}>
                    <span className="icon">
                      <i className="fas fa-donate"></i>
                    </span>
                    <span className="menu-item-label">Orçamentos</span>
                  </Link>
                </li>
                <li>
                  <Link to={'/srch'} style={{ display: 'flex' }}>
                    <span className="icon">
                      <i className="fas fa-comments-dollar"></i>
                    </span>
                    <span className="menu-item-label">
                      Requisições de compra
                    </span>
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <li>
            <a
              onClick={() => setIsOpenCont(!isOpenCont)}
              className="has-icon has-dropdown-icon"
            >
              <span className="icon">
                <i className="fas fa-hand-holding-usd"></i>
              </span>
              <span className="menu-item-label">Controladoria</span>
              <div className="dropdown-icon">
                <span className="icon">
                  <i
                    className={`mdi ${isOpenCont ? 'mdi-minus' : 'mdi-plus'}`}
                  ></i>
                </span>
              </div>
            </a>
            {isOpenCont && (
              <ul style={{ display: 'block' }}>
                <li>
                  <Link
                    to={'/controladoria/fila-tarefas'}
                    style={{ display: 'flex' }}
                  >
                    <span className="icon">
                      <i className="fas fa-table"></i>
                    </span>
                    <span className="menu-item-label">Fila de tarefas</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <li>
            <a
              onClick={() => setIsOpenEng(!isOpenEng)}
              className="has-icon has-dropdown-icon"
            >
              <span className="icon">
                <i className="fas fa-pencil-ruler"></i>
              </span>
              <span className="menu-item-label">Engenharia</span>
              <div className="dropdown-icon">
                <span className="icon">
                  <i
                    className={`mdi ${isOpenEng ? 'mdi-minus' : 'mdi-plus'}`}
                  ></i>
                </span>
              </div>
            </a>
            {isOpenEng && (
              <ul style={{ display: 'block' }}>
                {/* <p className="menu-label">Comercial</p>      */}

                <li>
                  <Link to={'/meic/composicoes'} style={{ display: 'flex' }}>
                    <span className="icon">
                      <i className="fas fa-sitemap"></i>
                    </span>
                    <span className="menu-item-label">Composições</span>
                  </Link>
                </li>
                <li>
                  <Link to={'/meic/equipamentos'} style={{ display: 'flex' }}>
                    <span className="icon">
                      <i className="fas fa-snowplow"></i>
                    </span>
                    <span className="menu-item-label">Equipamentos</span>
                  </Link>
                </li>
                <li>
                  <Link to={'/lista-materiais/'}>
                    <span className="icon">
                      <i className="fas fa-th-list"></i>
                    </span>
                    <span className="menu-item-label">
                      Listas de materiais e serviços
                    </span>
                  </Link>
                </li>
                {/* <p className="menu-label">Cadmat</p>  */}
                <li>
                  <Link to={'/meic/materiais'} style={{ display: 'flex' }}>
                    <span className="icon">
                      <i className="fas fa-box"></i>
                    </span>
                    <span className="menu-item-label">Materiais</span>
                  </Link>
                </li>
                <li>
                  <Link to={'/meic/mis'} style={{ display: 'flex' }}>
                    <span className="icon">
                      <i className="fas fa-drafting-compass"></i>
                    </span>
                    <span className="menu-item-label">MIS</span>
                  </Link>
                </li>
                <li>
                  <Link to={'/meic/serv'} style={{ display: 'flex' }}>
                    <span className="icon">
                      <i className="fas fa-people-carry"></i>
                    </span>
                    <span className="menu-item-label">Serviço</span>
                  </Link>
                </li>
                <li>
                  <Link to={'/meic/sinapi'} style={{ display: 'flex' }}>
                    <span className="icon">
                      <i className="fas fa-comments-dollar"></i>
                    </span>
                    <span className="menu-item-label">SINAPI</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <li>
            <a
              onClick={() => setIsOpenPlan(!isOpenPlan)}
              className="has-icon has-dropdown-icon"
            >
              <span className="icon">
                <i className="fa-solid fa-chart-pie"></i>
              </span>
              <span className="menu-item-label">Planejamento</span>
              <div className="dropdown-icon">
                <span className="icon">
                  <i
                    className={`mdi ${isOpenPlan ? 'mdi-minus' : 'mdi-plus'}`}
                  ></i>
                </span>
              </div>
            </a>
            {isOpenPlan && (
              <ul style={{ display: 'block' }}>
                <li>
                  <Link
                    to={'/cadastro-card-kanban-pl'}
                    style={{ display: 'flex' }}
                  >
                    <span className="icon">
                      <i className="fa-solid fa-layer-group"></i>
                    </span>
                    <span className="menu-item-label">
                      Cadastrar card kanban
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={'/visualizar-doc-entregaveis'}
                    style={{ display: 'flex' }}
                  >
                    <span className="icon">
                      <i className="fa-solid fa-folder-tree"></i>
                    </span>
                    <span className="menu-item-label">
                      Documentos entregáveis
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={'/plan/fila-tarefas-rc'}
                    style={{ display: 'flex' }}
                  >
                    <span className="icon">
                      <i className="fas fa-table"></i>
                    </span>
                    <span className="menu-item-label">Fila de tarefas</span>
                  </Link>
                </li>

                <li>
                  <Link to={'/kanban-planejamento'} style={{ display: 'flex' }}>
                    <span className="icon">
                      <i className="fa-solid fa-table-columns"></i>
                    </span>
                    <span className="menu-item-label">Kanban</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <a
              onClick={() => setIsOpenSup(!isOpenSup)}
              className="has-icon has-dropdown-icon"
            >
              <span className="icon">
                <i className="fas fa-boxes"></i>
              </span>
              <span className="menu-item-label">Suprimentos</span>
              <div className="dropdown-icon">
                <span className="icon">
                  <i
                    className={`mdi ${isOpenSup ? 'mdi-minus' : 'mdi-plus'}`}
                  ></i>
                </span>
              </div>
            </a>
            {isOpenSup && (
              <ul style={{ display: 'block' }}>
                <li>
                  <Link to={'/clientes/registrar'} style={{ display: 'flex' }}>
                    <span className="icon">
                      <i className="fa-solid fa-users"></i>
                    </span>
                    <span className="menu-item-label">Cadastrar clientes</span>
                  </Link>
                </li>
                <li>
                  <li>
                    <Link
                      to={'/fornecedores/registrar'}
                      style={{ display: 'flex' }}
                    >
                      <span className="icon">
                        <i className="fas fa-dolly"></i>
                      </span>
                      <span className="menu-item-label">
                        Cadastrar fornecedores
                      </span>
                    </Link>
                    <li>
                      <Link
                        to={'/suprimentos/fila-tarefas'}
                        style={{ display: 'flex' }}
                      >
                        <span className="icon">
                          <i className="fas fa-table"></i>
                        </span>
                        <span className="menu-item-label">Fila de tarefas</span>
                      </Link>
                    </li>
                  </li>
                  <Link to={'/fretes/'} style={{ display: 'flex' }}>
                    <span className="icon">
                      <i className="fa-solid fa-truck-fast"></i>
                    </span>
                    <span className="menu-item-label">Fretes</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
    </aside>
  );
};

// ###############################################################################################

// -------------------------------------- SIDEBAR EXTERNO --------------------------------------

//###############################################################################################

const SidebarExterno = () => {
  return (
    <aside className="aside is-placed-left is-expanded menu-scrollable">
      <div className="aside-tools">
        <div className="logo">
          <img
            src="/logo-geo-monocromatico-preto1.svg"
            style={{ width: '224px' }}
            alt="Logo"
          />
        </div>
      </div>
      <div className="menu is-menu-main">
        <p className="menu-label">Geral</p>
        <ul className="menu-list">
          <li>
            <NavLink
              to={'/ext/home'}
              style={{ display: 'flex' }}
              className={({ isActive }) =>
                'has-icon' + (isActive ? ' is-active router-link-active' : '')
              }
            >
              <span className="icon">
                <i className="mdi mdi-desktop-mac"></i>
              </span>
              <span className="menu-item-label">Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/ext/pedidos'}
              style={{ display: 'flex' }}
              className={({ isActive }) =>
                'has-icon' + (isActive ? ' is-active router-link-active' : '')
              }
            >
              <span className="icon">
                <i className="fa-solid fa-chalkboard-user"></i>
              </span>
              <span className="menu-item-label">Pedidos</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={'/ext/home'}
              style={{ display: 'flex' }}
              className={({ isActive }) =>
                'has-icon' + (isActive ? ' is-active router-link-active' : '')
              }
            >
              <span className="icon">
                <i className="far fa-edit"></i>
              </span>
              <span className="menu-item-label">Propostas</span>
            </NavLink>
          </li>

          <li style={{ display: 'none' }}>
            <NavLink
              to={'/adm/empreendimentos'}
              style={{ display: 'flex' }}
              className={({ isActive }) =>
                'has-icon' + (isActive ? ' is-active router-link-active' : '')
              }
            >
              <span className="icon">
                <i className="fas fa-chart-line"></i>
              </span>
              <span className="menu-item-label">Empreendimentos</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export { Sidebar, SidebarExterno };
