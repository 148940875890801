import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MainLayout from '../../layouts/MainLayout';
import axios from 'axios';
import Modal from '../../layouts/InsumosModal';
import GeoCompDetail from './GeoCompDetail';
import { DataGrid } from '@mui/x-data-grid';
import AlertMessage from '../../layouts/AlertMessage';

const CompHome = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [allComps, setAllComps] = useState([]);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    compObj: {},
    compItems: [],
  });
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setModalOpen(false);
  };

  const renderModalContent = (data) => {
    return { compObj: data.composicao, compItems: data.items };
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  const getAllComps = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/composicoes/rnd/`,
        {
          params: {
            n: 25,
          },
          headers: headers,
        }
      );
      const data = await response.data;
      setAllComps(data);
      if (!data) {
        setAllComps([]);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar composições'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const handleTermSeach = async (e) => {
    e.preventDefault();

    try {
      let headers = {
        'Content-Type': 'application/json',
      };

      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/composicoes/filter/`,
        {
          params: {
            search_str: searchTerm,
          },
          headers: headers,
        }
      );

      const data = await response.data;
      if (response.status === 200) {
        setAllComps(data);
      }
      if (!data) {
        setAllComps([]);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar composições'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleRowClick = async (id) => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/composicoes/c`,
        {
          params: {
            comp_id: id,
          },
          headers: headers,
        }
      );
      let data = await response.data;

      handleOpenModal(modalContent);
      setModalContent(renderModalContent(data));
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar composição'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'titulo', headerName: 'Título', flex: 2 },
    { field: 'descricao', headerName: 'Descrição', flex: 3 },
    { field: 'unidade', headerName: 'Unidade', flex: 1 },
    {
      field: 'preco',
      headerName: 'Preço',
      flex: 1,
      valueFormatter: ({ value }) => formatPreco(value),
    },
  ];

  useEffect(() => {
    getAllComps();
  }, []);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Engenharia</li>
                    <li>Composições</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Composições</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <div className="buttons is-right">
                      <button
                        className="button is-primary"
                        onClick={() => navigate(`/meic/composicoes/geo`)}
                      >
                        <span className="icon">
                          <i className="fa-regular fa-pen-to-square"></i>
                        </span>
                        <span>Ir para cadastro</span>
                      </button>
                    </div>
                    {/* <div className="column">
                                    <p className="button is-primary-dark"><Link to={"/meic/composicoes/geo"}>Cadastrar</Link></p>
                                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card has-table">
              <div className="card-content">
                <div className="div-select-search" style={{ padding: '15px' }}>
                  <form name="BuscaMateriais" onSubmit={handleTermSeach}>
                    <div
                      className="search-input"
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <div className="info-search">
                        <div className="field">
                          <label className="label">Buscar composição</label>
                          <input
                            className="input"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </div>
                      </div>
                      <div
                        className="button-search"
                        style={{
                          padding: '0px 15px 0px 15px',
                        }}
                      >
                        <button className="button is-primary">Buscar</button>
                      </div>
                    </div>
                  </form>
                </div>

                {/* <table className="table is-fullwidth is-striped">
                                <thead>
                                    <tr>
                                        <th><p className="has-text-centered">TÍTULO</p></th>
                                        <th><p className="has-text-centered">DESCRIÇÃO</p></th>
                                        <th><p className="has-text-centered">UNIDADE</p></th>
                                        <th><p className="has-text-centered">PREÇO</p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allComps && allComps.map((comp, i) => (
                                        <tr key={comp.id} onClick={() => handleRowClick(comp.id)}>
                                            <td>< p className="has-text-centered">{comp.titulo}</p></td>
                                            <td>< p className="has-text-centered">{comp.descricao}</p></td>
                                            <td>< p className="has-text-centered">{comp.unidade}</p></td>
                                            <td>< p className="has-text-centered">{formatPreco(comp.preco)}</p></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table> */}

                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="TabelaComposicoes">
                      <div
                        style={{
                          height: 600,
                          width: '100%',
                          padding: '15px 15px 30px 15px',
                        }}
                      >
                        <DataGrid
                          rows={allComps}
                          columns={columns}
                          pageSize={5}
                          // checkboxSelection
                          disableSelectionOnClick
                          getRowHeight={() => 'auto'}
                          sx={{
                            fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
                            '& .MuiDataGrid-cell': {
                              display: 'flex',
                              alignItems: 'flex-start', // Alinha o conteúdo no topo
                              paddingTop: '4px', // Ajuste conforme necessário
                              paddingBottom: '4px',
                              border: '0px',
                              // lineHeight: '2.5',
                              fontSize: '16px',
                            },
                            '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                              {
                                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                                fontSize: '16px',
                                color: '#171717',
                                lineHeight: '2.5',
                                borderLeft: '5px solid transparent',
                              },
                            '& .MuiDataGrid-virtualScroller': {
                              overflowX: 'hidden',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                  {modalContent && (
                    <GeoCompDetail
                      compObj={modalContent.compObj}
                      compItems={modalContent.compItems}
                    />
                  )}
                </Modal>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default CompHome;
