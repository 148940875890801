import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import { useNavigate, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import FormModal from '../../layouts/FormModal';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Select from 'react-select';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Badge from '@mui/material/Badge';
import { LinkSharp, Search } from '@mui/icons-material';
import AlertMessage from '../../layouts/AlertMessage';

const TabelaVersoesDocsEntregaveis = () => {
  const location = useLocation();
  const { docEntregavel, route } = location.state;
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const [filaDocEntregavelVersao, setFilaDocEntregavelVersao] = useState([]); // Estado para armazenar os dados da fila
  const [listaDocEntregavelVersao, setListaDocEntregavelVersao] = useState([]); // Estado para armazenar os dados da fila
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  useEffect(() => {
    const fetchFilaDocEntregavelVersao = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/kanban/doc-entregavel/`,
          {
            params: { tag: docEntregavel.tag },
            headers,
          }
        );

        if (response.data) {
          const docs = response.data.docs;

          const documentos = docs.map((docsE, index) => ({
            id: docsE.id,
            versao: docsE.versao,
            data_emissao: docsE.data_emissao,
            projeto: docEntregavel.projeto,
            disciplina: docEntregavel.disciplina,
            tag: docsE.tag,
            nome_documento: docsE.nome_documento,
            permalink: docsE.permalink,
          }));

          setFilaDocEntregavelVersao(documentos);
          setListaDocEntregavelVersao(docs);
        }
      } catch (error) {
        console.error(
          'Erro ao buscar fila de documentos entregaveis por versão:',
          error
        );
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail ||
                'Erro ao buscar fila de documentos entregaveis por versão'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    fetchFilaDocEntregavelVersao();
  }, [REACT_APP_BASE_API_URL]);

  const parseBrDate = (dateStr) => {
    if (dateStr) {
      const [dPart, hPart] = dateStr.split(',');
      const [day, month, year] = dPart.split('/').map(Number);
      const [hour, minute] = hPart.split(':').map(Number);
      return new Date(year, month - 1, day, hour, minute);
    } else {
      return new Date();
    }
  };

  const columns = [
    { field: 'versao', headerName: 'Versão', flex: 1 },
    { field: 'projeto', headerName: 'Projeto', flex: 1 },
    { field: 'disciplina', headerName: 'Disciplina', flex: 1 },
    { field: 'tag', headerName: 'Tag', flex: 2 },
    { field: 'nome_documento', headerName: 'Nome documento', flex: 2 },
    {
      field: 'permalink',
      headerName: 'Permalink',
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.permalink !== '-' && params.row.permalink !== null ? (
            <a
              href={params.row.permalink}
              style={{ width: '100%', wordBreak: 'break-all' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {params.row.permalink}
            </a>
          ) : (
            <p style={{ width: '100%', wordBreak: 'break-all' }}>
              Não disponível
            </p>
          )}
          {params.row.permalink !== '-' && params.row.permalink !== null ? (
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(params.row.permalink);
              }}
              color="black"
            >
              <LinkSharp />
            </IconButton>
          ) : null}
        </>
      ),
    },
    {
      field: 'data_emissao',
      headerName: 'Data Emissão',
      flex: 1.5,
      renderCell: (params) => (
        <div>
          {params.row.data_emissao
            ? new Date(params.row.data_emissao).toLocaleString()
            : ''}
        </div>
      ),
    },
    {
      field: 'acao',
      headerName: 'Detalhes',
      flex: 0.6,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>
          <IconButton
            onClick={() => {
              editDocEntregavelVersao(params.row);
            }}
            color="black"
          >
            <Search />
          </IconButton>
        </div>
      ),
    },
  ];

  const editDocEntregavelVersao = async (doc_ent_versao) => {
    navigate('/fluxo-doc-entregavel', {
      state: { doc_ent_versao, route: '/doc-entregavel-versao' },
    });
  };

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Planejamento</li>
                    <li>Documentos Entregáveis</li>
                    <li>Versões</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Versões</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Lista dos Documentos Entregáveis por versão
                </p>
              </header>
              <div className="card-content">
                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="FilaTarefas">
                      <div
                        style={{
                          height: 600,
                          width: '100%',
                        }}
                      >
                        <DataGrid
                          rows={filaDocEntregavelVersao}
                          columns={columns}
                          pageSize={5}
                          disableSelectionOnClick
                          getRowHeight={() => 'auto'}
                          sx={{
                            fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
                            '& .MuiDataGrid-cell': {
                              display: 'flex',
                              alignItems: 'flex-start', // Alinha o conteúdo no topo
                              paddingTop: '4px', // Ajuste conforme necessário
                              paddingBottom: '4px',
                              border: '0px',
                              // lineHeight: '2.5',
                              fontSize: '16px',
                            },
                            '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                              {
                                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                                fontSize: '16px',
                                color: '#171717',
                                // fontFamily: '"Nunito", sans-serif',
                                lineHeight: '2.5',
                                borderLeft: '5px solid transparent',
                              },
                            '& .MuiDataGrid-virtualScroller': {
                              overflowX: 'hidden',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default TabelaVersoesDocsEntregaveis;
