import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import AlertMessage from '../../layouts/AlertMessage';
import { useNavigate } from 'react-router-dom';
import './css/Filas.css';
import { DataGrid } from '@mui/x-data-grid';

const Solicitacoes = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const [Solicitacoes, setSolicitacoes] = useState([]); // Estado para armazenar os dados da fila
  const [listaTarefas, setListaSolicitacoes] = useState([]); // Estado para armazenar os dados da fila
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  useEffect(() => {
    const fetchSolicitacoes = async () => {
      try {
        let headers = {
          'Content-Type': 'application/json',
        };
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/tarefas/solicitante`,
          {
            params: {},
            headers: headers,
          }
        );

        if (response.data && response.data.fila_tarefas) {
          // Transformar os dados recebidos para se adequar à estrutura do DataGrid
          const tarefas = response.data.fila_tarefas.map((tarefa, index) => ({
            id: tarefa.id,
            idSetor:
              tarefa.setor === 'suprimentos'
                ? `SUP-${tarefa.id.split('-')[1].toUpperCase()}`
                : tarefa.setor === 'controladoria'
                  ? `CTL-${tarefa.id.split('-')[1].toUpperCase()}`
                  : tarefa.setor === 'planejamento'
                    ? `PNJ-${tarefa.id.split('-')[1].toUpperCase()}`
                    : tarefa.setor === 'engenharia'
                      ? `ENG-${tarefa.id.split('-')[1].toUpperCase()}`
                      : `${tarefa.id.split('-')[1].toUpperCase()}`,
            setor: tarefa.setor,
            resumo: tarefa.titulo,
            relator: tarefa.nome_relator + ' ' + tarefa.sobrenome_relator,
            responsavel: tarefa.nome_resp + ' ' + tarefa.sobrenome_resp,
            status: tarefa.status,
            data_criacao: new Date(tarefa.criado_em).toLocaleString(),
            tempo_resolucao: new Date(tarefa.data_due).toLocaleString('pt-BR', {
              day: '2-digit', // Dois dígitos para o dia
              month: '2-digit', // Dois dígitos para o mês
              year: 'numeric', // Quatro dígitos para o ano
              hour: '2-digit', // Duas dígitos para a hora
              minute: '2-digit', // Dois dígitos para o minuto
              hour12: false, // Usar formato de 24 horas
            }),
            prioridade: tarefa.prioridade,
            responsavel_id: tarefa.responsavel_id,
            tags: tarefa.tags.toUpperCase(),
          }));

          setSolicitacoes(tarefas);
          setListaSolicitacoes(response.data.fila_tarefas);
        }
      } catch (error) {
        console.error('Erro ao buscar fila de tarefas:', error);
        setAlertMessage('Erro ao buscar fila de tarefas.');
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    fetchSolicitacoes();
  }, [REACT_APP_BASE_API_URL]);

  const columns = [
    { field: 'idSetor', headerName: 'Chave', flex: 2 },
    {
      field: 'tags',
      headerName: 'Tag',
      flex: 2,
      renderCell: (params) => (
        <>
          {params.row.tags.includes('_RC') ? (
            <p className="tag is-dark">RC</p>
          ) : params.row.tags === 'MATERIAL' ? (
            <p className="tag is-info">{params.row.tags}</p>
          ) : params.row.tags === 'PDM' ? (
            <p className="tag is-info is-light">{params.row.tags}</p>
          ) : params.row.tags === 'FORNECEDOR' ? (
            <p className="tag is-link">{params.row.tags}</p>
          ) : params.row.tags === 'FRETE' ? (
            <p className="tag is-link is-light">{params.row.tags}</p>
          ) : (
            <p className="tag">{params.row.tags}</p>
          )}
        </>
      ),
    },
    { field: 'setor', headerName: 'Setor', flex: 2 },
    { field: 'resumo', headerName: 'Resumo', flex: 3 },
    { field: 'responsavel', headerName: 'Responsável', flex: 3 },
    {
      field: 'status',
      headerName: 'Status',
      editable: true,
      flex: 2,
      renderCell: (params) => (
        <>
          {params.row.status.includes('PREDECESSORA') ? (
            <p className="tag is-warning">{params.row.status}</p>
          ) : params.row.status.includes('CONC') ? (
            <p className="tag is-success">{params.row.status}</p>
          ) : params.row.status.includes('ANDAM') ? (
            <p className="tag is-success is-light">{params.row.status}</p>
          ) : params.row.status === 'PENDENTE' ? (
            <p className="tag is-warning is-light">{params.row.status}</p>
          ) : params.row.status.includes('RECUSA') ? (
            <p className="tag is-danger">{params.row.status}</p>
          ) : params.row.status.includes('ROTA') ? (
            <p className="tag is-link is-light">{params.row.status}</p>
          ) : (
            <p className="tag is-primary is-light">{params.row.status}</p>
          )}
        </>
      ),
    },
    {
      field: 'data_criacao',
      headerName: 'Criado em',
      type: 'Date',
      flex: 3,
      sortComparator: (v1, v2) => {
        const parseBrDate = (dateStr) => {
          const [dPart, hPart] = dateStr.split(',');
          const [day, month, year] = dPart.split('/').map(Number);
          const [hour, minute] = hPart.split(':').map(Number);
          return new Date(year, month - 1, day, hour, minute);
        };

        return parseBrDate(v1) - parseBrDate(v2);
      },
    },
    {
      field: 'tempo_resolucao',
      headerName: 'Prazo de resolução',
      type: 'Date',
      flex: 3,
      renderCell: (params) => {
        const parseBrDate = (dateStr) => {
          const [dPart, hPart] = dateStr.split(',');
          const [day, month, year] = dPart.split('/').map(Number);
          const [hour, minute] = hPart.split(':').map(Number);
          return new Date(year, month - 1, day, hour, minute);
        };

        const today = new Date();
        const resolDate = parseBrDate(params.row.tempo_resolucao);
        const timeDiff = resolDate - today;
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        let color;
        if (daysDiff < 1) {
          color = 'red';
        } else if (daysDiff <= 2) {
          color = 'orange';
        } else {
          color = 'green';
        }

        return <p style={{ color: color }}>{resolDate.toLocaleString()}</p>;
      },
      sortComparator: (v1, v2) => {
        const parseBrDate = (dateStr) => {
          const [dPart, hPart] = dateStr.split(',');
          const [day, month, year] = dPart.split('/').map(Number);
          const [hour, minute] = hPart.split(':').map(Number);
          return new Date(year, month - 1, day, hour, minute);
        };

        return parseBrDate(v1) - parseBrDate(v2);
      },
    },
    // {
    //   field: 'acao',
    //   headerName: 'Ação',
    //   flex: 2,
    //   renderCell: (params) => (
    //     <div className="has-text-centered">
    //       <span
    //         className="icon is-medium"
    //         style={{ cursor: 'pointer' }}
    //         onClick={() => navigate(`/user/tarefa/view/`, {
    //           state: {
    //             tarefa_id: params.row.id,
    //             scope: params.row.tags.split(' ')[0]
    //           }
    //         })}
    //       >
    //         <i className="fas fa-binoculars"></i>
    //       </span>
    //     </div>
    //   ),
    // },
  ];

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Geral</li>
                    <li>Fila de solicitações</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Minhas solicitações</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <div className="card-content">
                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="Solicitacoes">
                      <div
                        style={{
                          height: 600,
                          width: '100%',
                        }}
                      >
                        <DataGrid
                          rows={Solicitacoes}
                          columns={columns}
                          pageSize={5}
                          disableSelectionOnClick
                          getRowHeight={() => 'auto'}
                          sx={{
                            fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
                            '& .MuiDataGrid-cell': {
                              display: 'flex',
                              alignItems: 'flex-start', // Alinha o conteúdo no topo
                              paddingTop: '4px', // Ajuste conforme necessário
                              paddingBottom: '4px',
                              border: '0px',
                              // lineHeight: '2.5',
                              fontSize: '16px',
                            },
                            '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                              {
                                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                                fontSize: '16px',
                                color: '#171717',
                                // fontFamily: '"Nunito", sans-serif',
                                lineHeight: '2.5',
                                borderLeft: '5px solid transparent',
                              },
                            '& .MuiDataGrid-virtualScroller': {
                              overflowX: 'hidden',
                            },
                          }}
                          getRowClassName={(params) => {
                            if (
                              params.row.prioridade_material ===
                                '1 - Processo parado' ||
                              params.row.prioridade_material ===
                                '2 - Máquina parada'
                            ) {
                              return 'urgente'; // Retorna a classe 'importante' quando 'responsavel' for null
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default Solicitacoes;
