import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AlertMessage from '../../layouts/AlertMessage';

const AdminDisciplinaForm = ({ info }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [newDisciplina, setNewDisciplina] = useState('');

  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = {
      disciplina: newDisciplina,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/admin/disciplinas/`,
        obj,
        { headers }
      );
      if (response.status === 201) {
        setAlertMessage('Disciplina cadastrada.');
        setAlertSeverity('success');
        setAlertOpen(true);
        setReload(true);
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || 'Ocorreu um erro.')
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao cadastrar disciplina.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  return (
    <>
      <div className="container">
        <form name="setor" onSubmit={handleSubmit}>
          <div className="field">
            <label className="label" style={{ color: 'white' }}>
              Disciplina:
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
            </label>
            <input
              className="input"
              type="text"
              required
              value={newDisciplina}
              onChange={(e) => setNewDisciplina(e.target.value)}
            />
          </div>
          <br />
          <button
            className="button is-primary"
            style={{ margin: '1px 1px 1px 1px' }}
          >
            Cadastrar
          </button>
        </form>
      </div>
    </>
  );
};

export default AdminDisciplinaForm;
