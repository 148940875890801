import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import axios from 'axios';
import Modal from '../layouts/InsumosModal';
import FormModal from '../layouts/FormModal';
import LaunchModalButton from '../functional/LaunchModal';
import ItemInclusionView from '../functional/ItemInclusionView';
import ModalPDM from './ModalPDM';
import './css/ModalPDM.css';
import AlertMessage from '../layouts/AlertMessage';

const FormularioCadEquipamento = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const { REACT_APP_BASE_API_URL } = process.env;
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  useEffect(() => {
    if (location.state && location.state.context) {
      const defaultFormData = {};
      location.state.context.forEach((item, i) => {
        // if (item[2]) {
        // item[3].length > 0 ? (
        //   defaultFormData[item[0]] = item[3][0]
        // ) : defaultFormData[item[0]] = '';
        // }

        if (item[2]) {
          item[3].length > 0
            ? (defaultFormData[item[0]] = item[3][0])
            : (defaultFormData[item[0]] = '');
        } else {
          defaultFormData[item[0]] = '';
        }
      });
      setData(defaultFormData);
    }
  }, [location.state]);

  const updateData = (e) => {
    setData((data) => {
      const updatedData = {
        ...data,
        [e.target.name]: e.target.value,
      };
      return updatedData;
    });
  };

  const handleCad = async (e) => {
    e.preventDefault();

    try {
      let params = {};

      switch (location.state.route) {
        case 'mats':
          params = { material: location.state.target };
          break;

        case 'equips':
          params = { equipamento: location.state.target };
          break;

        case 'pdms':
          params = { pdm: location.state.target };
          break;

        // Add more cases if needed

        default:
          console.error('Unhandled case:', location.state.route);
          return;
      }

      const headers = {
        'Content-Type': 'application/json',
      };

      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/${location.state.route}/`,
        data,
        { params, headers }
      );

      if (response.status === 201) {
        // alert("Item cadastrado com sucesso!");
        setAlertMessage('Item cadastrado com sucesso!');
        setAlertSeverity('success');
        setAlertOpen(true);
        // navigate('/meic')
        // location.state && location.state.route === "equips" ? navigate('/meic/equipamentos') : navigate('/meic/materiais')
        location.state && location.state.route === 'equips'
          ? setNavigateTo('/meic/equipamentos')
          : setNavigateTo('/meic/materiais');
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail ||
              'Erro no formulario cad equipamento.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const replaceDash = (str) =>
    str.replace(/-/g, ' ').replace(/^\w/, (char) => char.toUpperCase());

  function getUnits(s) {
    // Função responsável por ler os nomes dos campos e tratar eles.
    let [first, last] = s.split('_f_', 2);

    if (last === undefined) {
      return s.split('_').join(' ').toUpperCase();
    }

    last = last.replace('PCT', '%');

    let lastUnits = last.split('_');

    let tNumerator = '';
    let tDenominator = '';

    for (let unit of lastUnits) {
      let start = unit[0];
      if (start === start.toUpperCase()) {
        tNumerator += unit.toUpperCase();
      } else {
        if (tDenominator !== '') {
          tDenominator += '/';
        }
        tDenominator += unit.toUpperCase();
      }
    }

    let unitMounted =
      tDenominator !== '' ? `${tNumerator}/${tDenominator}` : tNumerator;

    let result = (
      first.split('_').join(' ') + ` [${unitMounted}]`
    ).toUpperCase();

    return result.toUpperCase();
  }

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setModalOpen(false);
  };

  const handleEditPDM = async (e) => {
    e.preventDefault();

    try {
      const params = { material: location.state.target };

      const headers = {
        'Content-Type': 'application/json',
      };

      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/suprimentos/obter-pdm`,
        {
          params: {
            material: location.state.target,
          },
          headers,
        }
      );

      const material = await response.data;

      const target = location.state.target;

      if (response.status === 200) {
        navigate('/suprimentos/editar-pdm', {
          state: { material, target, route: location.state.route },
        });
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar PDM.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const renderModalContent = (info) => {
    // Verifique se location.state e location.state.target estão definidos
    if (location.state && location.state.target) {
      return (
        <ModalPDM
          mTarget={location.state.target}
          mDataPDM={data}
          mRoute={location.state.route}
          info={info}
        />
      );
    } else {
      // Se location.state ou location.state.target forem nulos, retorne null ou uma mensagem de erro
      return null; // ou uma mensagem de erro, por exemplo: <div>Ocorreu um erro ao carregar o conteúdo do modal</div>
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleOpenModal('Cadastrar');
  };

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Engenharia</li>
                    {location.state && location.state.route === 'equips' && (
                      <li>Equipamentos</li>
                    )}
                    {location.state &&
                      (location.state.route === 'mats' ||
                        location.state.route === 'pdms') && <li>Materiais</li>}
                    {location.state && (
                      <li>
                        {location.state.target
                          ? replaceDash(location.state.target)
                          : ''}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">
                      {location.state &&
                        location.state.target &&
                        replaceDash(location.state.target)}
                    </h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <div className="buttons is-right">
                      <button
                        onClick={(e) => handleEditPDM(e)}
                        className="button is-primary"
                      >
                        <span className="icon">
                          <i className="fa-solid fa-pen"></i>
                        </span>
                        <span>Editar PDM</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Formulário
                </p>
              </header>
              <div className="card-content">
                <form name="CadMatForm" onSubmit={handleSubmit}>
                  {location.state &&
                    location.state.context &&
                    location.state.context.map((item, i) => (
                      <div key={i}>
                        {/* {item[2] && ( // Check if the field is required */}
                        <>
                          <div className="field is-horizontal">
                            <div className="field-label is-normal">
                              <label htmlFor={`${item[0]}`} className="label">
                                {getUnits(item[0])}
                                {item[2] ? (
                                  <span
                                    className="required-symbol"
                                    style={{
                                      fontSize: '1.2rem',
                                    }}
                                  >
                                    {' '}
                                    *
                                  </span>
                                ) : (
                                  ''
                                )}
                              </label>
                            </div>

                            {/* <label htmlFor={`${item[0]}`} className="label">{getUnits(item[0])}</label> */}
                            <div className="field-body">
                              {item[1] === 'text' || item[1] === 'number' ? (
                                <div className="field">
                                  <p className="control">
                                    <input
                                      type={item[1]}
                                      name={item[0]}
                                      step={item[4]}
                                      id={`input-${i}`}
                                      required={item[2]}
                                      onChange={updateData}
                                      className="input"
                                    />
                                    {/* <span className="icon is-small is-left"><i className="fa-solid fa-pen"></i></span> */}
                                  </p>
                                </div>
                              ) : item[3].length > 0 ? (
                                <div className="field is-narrow">
                                  <div className="control">
                                    <div className="select is-fullwidth">
                                      <select
                                        id={`select-${i}`}
                                        name={`${item[0]}`}
                                        required={item[2]}
                                        onChange={updateData}
                                      >
                                        {item[3].map((option, index) => (
                                          <option key={index} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <br />
                        </>
                        {/* )} */}
                      </div>
                    ))}
                  <br />

                  <div
                    style={{
                      margin: '40px 0px 20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button type="submit" className="button is-primary">
                      <span className="icon">
                        <i className="fa-solid fa-floppy-disk"></i>
                      </span>
                      <span>Cadastrar</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <FormModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              modalTitle={'Informações do Material'}
            >
              {renderModalContent(info)}
            </FormModal>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default FormularioCadEquipamento;
