import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import AlertMessage from '../../layouts/AlertMessage';

const FreteFormFornecedor = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const { orc_id } = useParams();
  const [frete, setFrete] = useState({});
  const [orcamento, setOrcamento] = useState({});
  const [fornecedor, setFornecedor] = useState({});
  const [pacotes, setPacotes] = useState([]);

  const [fornecedorPreco, setFornecedorPreco] = useState(0.0);
  const [fornecedorColeta, setFornecedorColeta] = useState(0);
  const [fornecedorEntrega, setFornecedorEntrega] = useState(0);
  const [fornecedorPagamento, setFornecedorPagamento] = useState('');
  const [fornecedorObs, setFornecedorObs] = useState('');

  const [isOrcado, setIsOrcado] = useState(false);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const getOrcDetail = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/frete/orcamento-frete/detail/`,
        {
          params: {
            orc_id: orc_id,
          },
          headers: headers,
        }
      );
      if (response.status === 200) {
        const data = await response.data;
        setFrete(data.frete);
        setOrcamento(data.orcamento);
        setFornecedor(data.fornecedor);
        setPacotes(data.pacotes);

        setFornecedorColeta(data.orcamento.prazo_coleta);
        setFornecedorEntrega(data.orcamento.prazo_entrega);
        setFornecedorObs(data.orcamento.obs);
        setFornecedorPagamento(data.orcamento.forma_pagamento);
        setFornecedorPreco(data.orcamento.preco);
        setIsOrcado(data.orcamento.orcado);
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || 'Ocorreu um erro')
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail ||
              'Erro ao buscar detalhes do orçamento'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedOrcamento = {
      ...orcamento,
      preco: parseFloat(fornecedorPreco),
      prazo_coleta: parseInt(fornecedorColeta),
      prazo_entrega: parseInt(fornecedorEntrega),
      forma_pagamento: fornecedorPagamento,
      obs: fornecedorObs,
    };

    try {
      const response = await axios.put(
        `${REACT_APP_BASE_API_URL}/frete/orcamento-frete/`,
        updatedOrcamento,
        { headers }
      );
      if (response.status === 200) {
        // alert('Orcamento respondido com sucesso.')
        setAlertMessage('Orcamento respondido com sucesso.');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || 'Ocorreu um erro.')
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao responder orçamento'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getOrcDetail();
  }, [orc_id]);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Portal de Fornecedores</li>
                    <li>Fretes</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Fretes</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section main-content">
            <form name="updateOrcamentoFrete" onSubmit={handleSubmit}>
              <div className="container">
                <div className="card">
                  <header className="card-header">
                    <p className="card-header-title">Frete</p>
                  </header>
                  <div className="card-content">
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Referência</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              value={frete.referencia}
                              readOnly
                              disabled
                              style={{
                                color: 'black',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Origem</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              value={frete.origem}
                              readOnly
                              disabled
                              style={{
                                color: 'black',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Destino</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              value={frete.destino}
                              readOnly
                              disabled
                              style={{
                                color: 'black',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Tipo</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              value={frete.tipo}
                              readOnly
                              disabled
                              style={{
                                color: 'black',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{' '}
                {/*card-frete*/}
                <div className="card">
                  <header className="card-header">
                    <p className="card-header-title">Pacotes</p>
                  </header>
                  <div className="card-content">
                    <div className="container">
                      <div
                        className="grid"
                        style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(2, 1fr)',
                          marginLeft: '20px',
                        }}
                      >
                        {pacotes &&
                          pacotes.map((item, i) => (
                            <div
                              key={i}
                              className="box"
                              style={{
                                margin: '0px 20px 20px 0px',
                              }}
                            >
                              <p>
                                <strong>{item.referencia}</strong>
                              </p>
                              <p>
                                <strong>Dimensões: </strong>({item.dimensoes})
                                CM
                              </p>
                              <p>
                                <strong>Peso: </strong>
                                {item.peso} KG
                              </p>
                              <p>
                                <strong>Valor: </strong>
                                {formatPreco(item.valor)}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>{' '}
                {/*card-pacotes*/}
                <div className="card">
                  <header className="card-header">
                    <p className="card-header-title">Orçamento</p>
                  </header>
                  <div className="card-content">
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Fornecedor</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              value={fornecedor.razao_social}
                              readOnly
                              disabled
                              style={{
                                color: 'black',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Preço</label>
                      </div>
                      <div className="field-body">
                        <div className="field has-addons">
                          <p className="control">
                            <a className="button is-static">R$</a>
                          </p>
                          <div className="control is-expanded">
                            <input
                              className="input"
                              type="number"
                              value={fornecedorPreco}
                              onChange={(e) =>
                                setFornecedorPreco(e.target.value)
                              }
                              style={{
                                color: 'black',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Prazo Coleta</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              className="input"
                              type="number"
                              step={'1'}
                              value={fornecedorColeta}
                              onChange={(e) =>
                                setFornecedorColeta(e.target.value)
                              }
                              style={{
                                color: 'black',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Prazo Entrega</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              className="input"
                              type="number"
                              step={'1'}
                              value={fornecedorEntrega}
                              onChange={(e) =>
                                setFornecedorEntrega(e.target.value)
                              }
                              style={{
                                color: 'black',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Forma de Pagamento</label>
                      </div>
                      <div className="field-body">
                        <div className="field is-narrow">
                          <div className="control has-icons-left">
                            <div className="select is-fullwidth">
                              <p className="control is-expanded has-icons-left">
                                <select
                                  name="pagamento"
                                  value={fornecedorPagamento}
                                  required
                                  onChange={(e) =>
                                    setFornecedorPagamento(e.target.value)
                                  }
                                >
                                  <option value="">Selecione.</option>
                                  <option value="A VISTA">A VISTA</option>
                                  <option value="7 DIAS">7 DIAS</option>
                                  <option value="14 DIAS">14 DIAS</option>
                                  <option value="21 DIAS">21 DIAS</option>
                                  <option value="30 DIAS">30 DIAS</option>
                                </select>
                                <span className="icon is-small is-left">
                                  <i className="fa-solid fa-list"></i>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Observações</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <textarea
                              className="textarea"
                              value={fornecedorObs || ''}
                              onChange={(e) => setFornecedorObs(e.target.value)}
                              style={{
                                color: 'black',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="button is-primary"
                      type="submit"
                      disabled={isOrcado}
                    >
                      Salvar
                    </button>
                  </div>{' '}
                  {/*card-content*/}
                </div>{' '}
                {/*card-orcamento*/}
              </div>{' '}
              {/*container*/}
            </form>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default FreteFormFornecedor;
