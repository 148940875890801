import React, { useEffect, useState } from 'react';
import MainLayout from '../../../layouts/MainLayout';
import axios from 'axios';
import { Link } from 'react-router-dom';
import AlertMessage from '../../../layouts/AlertMessage';

const ListaOrcamentoHome = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [LOsOrcadas, setLOsOrcadas] = useState([]);
  const [LOs, setLOs] = useState([]);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const headers = {
    'Content-Type': 'application/json',
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const getLOsOrcadas = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/listas/orcamento`,
        {
          params: {
            orcada: true,
          },
          headers: headers,
        }
      );
      if (response.status === 200) {
        const data = await response.data;
        setLOsOrcadas(data.listas);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar listas orçadas'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getLOs = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/listas/orcamento`,
        {
          params: {
            orcada: false,
          },
          headers: headers,
        }
      );
      if (response.status === 200) {
        const data = await response.data;
        setLOs(data.listas);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar listas orçadas'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getLOs();
    getLOsOrcadas();
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        setOpen={setAlertOpen}
      />
      <MainLayout>
        <section className="main-content">
          <div className="container">
            <button className="button">
              <Link to={'/lof/'}>Criar</Link>
            </button>
            <br />
            <div className="columns">
              <div className="column">
                <p className="title is-5">Listas sem resposta</p>
                <table className="table">
                  <thead>
                    <tr>
                      <th>TITULO</th>
                      <th>FORNECEDOR</th>
                      <th>CUSTO REF</th>
                    </tr>
                  </thead>
                  <tbody>
                    {LOs &&
                      LOs.map((item, i) => (
                        <tr key={i}>
                          <td>{item.titulo}</td>
                          <td>{item.fornecedor_nome}</td>
                          <td>{formatPreco(item.custo_ref)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              <div className="column">
                <p className="title is-5">Listas orçadas</p>
                <table className="table">
                  <thead>
                    <tr>
                      <th>TITULO</th>
                      <th>FORNECEDOR</th>
                      <th>CUSTO REF</th>
                      <th>CUSTO ORÇADO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {LOsOrcadas &&
                      LOsOrcadas.map((item, i) => (
                        <tr key={i}>
                          <td>{item.titulo}</td>
                          <td>{item.fornecedor_nome}</td>
                          <td>{formatPreco(item.custo_ref)}</td>
                          <td>{formatPreco(item.custo)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
};

export default ListaOrcamentoHome;
