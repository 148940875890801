import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MainLayout from '../../layouts/MainLayout';
import AlertMessage from '../../layouts/AlertMessage';

const OrcamentoFreteDetail = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const { orc_id } = useParams();
  const [frete, setFrete] = useState({});
  const [orcamento, setOrcamento] = useState({});
  const [fornecedor, setFornecedor] = useState({});
  const [pacotes, setPacotes] = useState([]);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const getOrcDetail = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/frete/orcamento-frete/detail/`,
        {
          params: {
            orc_id: orc_id,
          },
          headers: headers,
        }
      );
      if (response.status === 200) {
        const data = await response.data;
        setFrete(data.frete);
        setOrcamento(data.orcamento);
        setFornecedor(data.fornecedor);
        setPacotes(data.pacotes);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail ||
              'Erro ao buscar detalhes do orçamento'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getOrcDetail();
  }, []);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <section className="section main-content">
          <div className="container">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">Frete</p>
              </header>
              <div className="card-content">
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Referência</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          value={frete.referencia}
                          readOnly
                          disabled
                          style={{ color: 'black' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Origem</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          value={frete.origem}
                          readOnly
                          disabled
                          style={{ color: 'black' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Destino</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          value={frete.destino}
                          readOnly
                          disabled
                          style={{ color: 'black' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Tipo</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          value={frete.tipo}
                          readOnly
                          disabled
                          style={{ color: 'black' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <header className="card-header">
                <p className="card-header-title">Pacotes</p>
              </header>
              <div className="card-content">
                <div className="container">
                  <div
                    className="grid"
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      marginLeft: '20px',
                    }}
                  >
                    {pacotes &&
                      pacotes.map((item, i) => (
                        <div
                          key={i}
                          className="box"
                          style={{
                            margin: '0px 20px 20px 0px',
                          }}
                        >
                          <p>
                            <strong>{item.referencia}</strong>
                          </p>
                          <p>
                            <strong>Dimensões: </strong>({item.dimensoes}) CM
                          </p>
                          <p>
                            <strong>Peso: </strong>
                            {item.peso} KG
                          </p>
                          <p>
                            <strong>Valor: </strong>
                            {formatPreco(item.valor)}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <header className="card-header">
                <p className="card-header-title">Orçamento</p>
              </header>
              <div className="card-content">
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Fornecedor</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          value={fornecedor.razao_social}
                          readOnly
                          disabled
                          style={{ color: 'black' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Preço</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          value={formatPreco(orcamento.preco)}
                          readOnly
                          disabled
                          style={{ color: 'black' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Prazo Coleta</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          value={`${orcamento.prazo_coleta} DIAS`}
                          readOnly
                          disabled
                          style={{ color: 'black' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Prazo Entrega</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          value={`${orcamento.prazo_entrega} DIAS`}
                          readOnly
                          disabled
                          style={{ color: 'black' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Forma de Pagamento</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          value={orcamento.forma_pagamento}
                          readOnly
                          disabled
                          style={{ color: 'black' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Observações</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <textarea
                          className="textarea"
                          value={orcamento.obs || ''}
                          readOnly
                          disabled
                          style={{ color: 'black' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Orçado</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          type="checkbox"
                          checked={orcamento.orcado}
                          readOnly
                          disabled
                          style={{ color: 'black' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
};

export default OrcamentoFreteDetail;
