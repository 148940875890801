import React, { useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import axios from 'axios';
import AlertMessage from '../../layouts/AlertMessage';

const LocacaoForm = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [locacaoItem, setLocacaoItem] = useState('');
  const [locacaoObs, setLocacaoObs] = useState('');
  const [locacaoUnidade, setLocacaoUnidade] = useState('HORA');
  const [locacaoPreco, setLocacaoPreco] = useState(0);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const handleCad = async (e) => {
    e.preventDefault();

    let locacaoRequest = {
      item: locacaoItem,
      obs: locacaoObs,
      unidade: locacaoUnidade,
      preco: locacaoPreco,
    };

    const headers = {
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/serv/locacao/`,
        locacaoRequest,
        { headers }
      );
      const data = await response.data;
      if (response.status === 201) {
        // alert('Serviço de locação cadastrado com sucesso.')
        setAlertMessage('Serviço de locação cadastrado com sucesso.');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      // alert(error.response.data.detail);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail ||
              'Erro ao cadastrar serviço de locação'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const debugCad = async (e) => {
    e.preventDefault();
    let locacaoRequest = {
      item: locacaoItem,
      obs: locacaoObs,
      unidade: locacaoUnidade,
      preco: locacaoPreco,
    };
  };

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Cadastrar Locação
                </p>
              </header>
              <div className="card-content">
                <form name="MOForm" onSubmit={handleCad}>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Item
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            required
                            value={locacaoItem}
                            onChange={(e) => setLocacaoItem(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-file-lines"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Unidade
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <div className="select is-fullwidth">
                            <select
                              className="custom-select-style"
                              name="unidade"
                              value={locacaoUnidade}
                              onChange={(e) =>
                                setLocacaoUnidade(e.target.value)
                              }
                            >
                              <option value={'HORA'} key={'HORA'}>
                                HORA
                              </option>
                              <option value={'DIA'} key={'DIA'}>
                                DIA
                              </option>
                              <option value={'MES'} key={'MES'}>
                                MES
                              </option>
                              <option value={'ANO'} key={'ANO'}>
                                ANO
                              </option>
                            </select>
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-file-lines"></i>
                            </span>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Preço
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="number"
                            step={'0.0001'}
                            value={locacaoPreco}
                            onChange={(e) => setLocacaoPreco(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-coins"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Observações</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control ">
                          <textarea
                            rows="4"
                            className="input"
                            style={{
                              height: '100px',
                            }}
                            value={locacaoObs}
                            onChange={(e) => setLocacaoObs(e.target.value)}
                          ></textarea>
                          {/* <span className="icon is-small is-left"><i className="fa-solid fa-comments"></i></span> */}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      margin: '40px 0px 20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button className="button is-primary" type="submit">
                      <span className="icon">
                        <i className="fa-solid fa-paper-plane"></i>
                      </span>
                      <span>Cadastrar</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default LocacaoForm;
