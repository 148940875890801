import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { cpfMask } from '../../functional/cpfMask';
import { Link, useNavigate } from 'react-router-dom';
import MainLayout from '../../layouts/MainLayout';
import AlertMessage from '../../layouts/AlertMessage';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        ACE
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

const { REACT_APP_BASE_API_URL } = process.env;

const Register = () => {
  const navigate = useNavigate();
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setcPassword] = useState('');
  const [cpf, setCpf] = useState('');
  const [cpfValid, setCpfValid] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const validarCPF = (cpf) => {
    if (!cpf || cpf.length !== 11 || /^(.)\1+$/.test(cpf)) return false;
    let sum = 0;
    let resto;
    for (let i = 1; i <= 9; i++) {
      sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    resto = (sum * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;
    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    resto = (sum * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return false;
    return true;
  };

  const handleCPFChange = (e) => {
    const cpfInput = e.target.value;
    const formatedCpf = cpfInput.replace(/\D/g, '');
    setCpf(formatedCpf);
    if (formatedCpf.length === 11) {
      setCpfValid(validarCPF(formatedCpf));
    } else {
      setCpfValid(false);
    }
  };

  const registerSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      nome: nome,
      sobrenome: sobrenome,
      email: email,
      password: password,
      cpf: cpf,
    };

    if (password !== cpassword) {
      // alert("Senhas não batem.")
      setAlertMessage('Senhas não batem.');
      setAlertSeverity('error');
      setAlertOpen(true);
    } else {
      try {
        const response = await axios.post(
          `${REACT_APP_BASE_API_URL}/users/`,
          formData
        );

        if (response.status === 201) {
          setAlertMessage('Registro realizado com sucesso, ative sua conta!');
          setAlertSeverity('success');
          setAlertOpen(true);
          setNavigateTo('/');
        }
      } catch (error) {
        // alert(error.response.data.detail);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao realizar o registro.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    }
  };

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <ThemeProvider theme={defaultTheme}>
        <Grid
          container
          component="main"
          sx={{
            position: 'absolute',
            left: '0',
            top: '0',
            height: '100vh',
          }}
        >
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundColor: 'black',
            }}
          >
            <div
              style={{
                display: 'flex',
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src="/logo-monocromatica-login.png"
                style={{ width: '50%' }}
                alt="Logo"
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '200px',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'black' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Registrar
              </Typography>
              <Box component="form" onSubmit={registerSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="nome"
                  label="Nome"
                  name="nome"
                  value={nome}
                  autoComplete="nome"
                  autoFocus
                  onChange={(e) => setNome(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  value={sobrenome}
                  name="sobrenome"
                  label="Sobrenome" //
                  id="sobrenome"
                  autoComplete="sobrenome"
                  onChange={(e) => setSobrenome(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  value={email}
                  name="email"
                  label="E-mail" //
                  id="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  value={password}
                  name="password"
                  type="password"
                  label="Senha" //
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  value={cpassword}
                  name="cpassword"
                  label="Confirmação de senha" //
                  type="password"
                  id="cpassword"
                  autoComplete="ccurrent-password"
                  onChange={(e) => setcPassword(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  placeholder="00000000000"
                  value={cpfMask(cpf)}
                  name="cpf"
                  label="CPF" //
                  id="CPF"
                  autoComplete="cpf"
                  onChange={handleCPFChange}
                  maxLength={14}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={!cpfValid}
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: 'black',
                    '&:hover': {
                      backgroundColor: '#2e323a',
                      color: 'white', // Cor do texto quando o mouse está em cima
                    },
                    '&:active': {
                      backgroundColor: 'darkgray',
                      color: 'white', // Cor do texto quando o botão é clicado
                    },
                  }}
                >
                  Cadastrar
                </Button>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default Register;
