import React from 'react';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  const showFooter = !['/login', '/registrar'].includes(location.pathname);

  return (
    <>
      {showFooter && (
        <footer
          className="footer"
          style={{
            position: 'absolute',
            bottom: '0px',
            right: '0px',
            left: '224px',
            marginTop: '10px',
          }}
        >
          <div
            className="container-fluid"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <p>
                    <strong>ACES</strong> por{' '}
                    <a href="https://geobiogas.tech/">Geo Bio Gas & Carbon</a>.
                    Todos os direitos reservados.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
