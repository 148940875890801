import React, { useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import Button from '@mui/material/Button';
import { ButtonGroup } from '@mui/material';

import AdminRoleTable from './AdminRoleTable';
import AdminGrupoTable from './AdminGrupoTable';
import AdminUserTable from './AdminUserTable';
import AdminAdmTable from './AdminAdmTable';

const AdminPanel = () => {
  const [activeButton, setActiveButton] = useState('users');
  const [activeComponent, setActiveComponent] = useState('users');

  const handleButtonClick = (val) => {
    setActiveButton(val);
    setActiveComponent(val);
  };

  const handleRenderPanel = () => {
    switch (activeComponent) {
      case 'users':
        return <AdminUserTable />;
      case 'roles':
        return <AdminRoleTable />;
      case 'grupos':
        return <AdminGrupoTable />;
      case 'adm':
        return <AdminAdmTable />;
    }
  };

  return (
    <>
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    {/* <li>Setores</li> */}
                    <li>Configurações</li>
                    <li>Meu perfil</li>
                    <li>Painel ADM</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Painel ADM</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <ButtonGroup aria-label="outlined is-primary button group">
                      <Button
                        style={{
                          color: activeButton === 'adm' ? 'white' : '#2B3BED',
                          backgroundColor:
                            activeButton === 'adm' ? '#2B3BED' : 'transparent',
                          padding: '5px 30px',
                        }}
                        onClick={() => handleButtonClick('adm')}
                      >
                        ADM
                      </Button>
                      <Button
                        style={{
                          color: activeButton === 'users' ? 'white' : '#2B3BED',
                          backgroundColor:
                            activeButton === 'users'
                              ? '#2B3BED'
                              : 'transparent',
                          padding: '5px 30px',
                        }}
                        onClick={() => handleButtonClick('users')}
                      >
                        Usuários
                      </Button>
                      <Button
                        style={{
                          color: activeButton === 'roles' ? 'white' : '#2B3BED',
                          backgroundColor:
                            activeButton === 'roles'
                              ? '#2B3BED'
                              : 'transparent',
                          padding: '5px 30px',
                        }}
                        onClick={() => handleButtonClick('roles')}
                      >
                        Roles
                      </Button>
                      <Button
                        style={{
                          color:
                            activeButton === 'grupos' ? 'white' : '#2B3BED',
                          backgroundColor:
                            activeButton === 'grupos'
                              ? '#2B3BED'
                              : 'transparent',
                          padding: '5px 30px',
                        }}
                        onClick={() => handleButtonClick('grupos')}
                      >
                        Grupos
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            {handleRenderPanel()}
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default AdminPanel;
