import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../layouts/MainLayout';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import axios from 'axios';
import Select from 'react-select';
import SelectMui from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from '@mui/x-data-grid';
import ModalMaterial from '../ModalMaterial';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import FormModal from '../../layouts/FormModal';
import AlertMessage from '../../layouts/AlertMessage';

const Materiais = () => {
  const eventState = {
    button: 1,
  };
  const navigate = useNavigate();
  const [allMateriais, setAllMateriais] = useState([]);
  const [tabelasMateriais, setTabelasMateriais] = useState([]);
  const [targetInsumo, setTargetInsumo] = useState('');
  const { REACT_APP_BASE_API_URL } = process.env;
  const [searchTerm, setSearchTerm] = useState('');
  const [filtro, setFiltro] = useState('');
  const [listaFiltrada, setListaFiltrada] = useState([]);
  const [listaVisivel, setListaVisivel] = useState(false);
  const inputRef = useRef(null);
  const [options, setOptions] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [materialSAP, setMaterialSAP] = useState([]);
  const [optionSearch, setOptionSearch] = useState('descl');

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  React.useEffect(() => {
    setListaFiltrada(
      tabelasMateriais.filter((material) =>
        material.toLowerCase().includes(filtro.toLowerCase())
      )
    );
  }, [filtro, tabelasMateriais]);

  useEffect(() => {
    // Adiciona um manipulador de eventos para detectar cliques fora do campo de entrada e da lista
    function handleClickOutside(event) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setListaVisivel(false);
      }
    }
    // Adiciona o manipulador de eventos ao documento
    document.addEventListener('mousedown', handleClickOutside);
    // Remove o manipulador de eventos quando o componente é desmontado
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getMaterialSAP = async (id_sap) => {
    try {
      setMaterialSAP([]);
      const headers = {
        'Content-Type': 'application/json',
      };

      let params = { material_id: parseInt(id_sap) };

      const response = await axios.get(`${REACT_APP_BASE_API_URL}/mats/sap`, {
        params,
        headers,
      });

      if (!response.status === 200) {
        // alert("Ocorreu um erro ao buscar material.")
        setAlertMessage('Ocorreu um erro ao buscar material.');
        setAlertSeverity('error');
        setAlertOpen(true);
      }

      setMaterialSAP(response.data);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar material.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Botão de cadastro

    const headers = {
      'Content-Type': 'application/json',
    };

    if (eventState.button === 1) {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/tools/formfields/`,
          {
            params: {
              target: targetInsumo,
              kind: 'material',
            },
            headers: headers,
          }
        );

        const data = await response.data;
        if (response.status === 200 && data) {
          navigate('/cadmat-form', {
            state: {
              context: data,
              target: targetInsumo,
              route: 'mats',
            },
          });
        } else {
          const response = await axios.get(
            `${REACT_APP_BASE_API_URL}/tools/formfields/`,
            {
              params: {
                target: targetInsumo,
                kind: 'pdm',
              },
              headers: headers,
            }
          );

          const data = await response.data;
          if (response.status === 200 && data) {
            navigate('/cadmat-form', {
              state: {
                context: data,
                target: targetInsumo,
                route: 'pdms',
              },
            });
          }
        }
      } catch (error) {
        console.error(error.response.data.detail);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao buscar material.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    }
    // Botão filtrar
    if (eventState.button === 2) {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/mats/insumos/`,
          {
            params: {
              schema: 'material',
              item: targetInsumo,
            },
            headers: headers,
          }
        );
        const data = await response.data;
        if (response.status === 200) {
          setAllMateriais(data);
        }
      } catch (error) {
        console.error(error.response.data.detail);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao buscar material.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    }
  };

  const getMateriais = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
      };

      const response = await axios.get(`${REACT_APP_BASE_API_URL}/mats/rnd/`, {
        params: {
          schema: 'material',
        },
        headers: headers,
      });
      const data = await response.data;

      setAllMateriais(data);
      if (!data) {
        setAllMateriais([]);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar material.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getTabelasMateriais = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
      };

      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/tools/tableschema/`,
        {
          params: {
            schema: 'material',
          },
          headers: headers,
        }
      );

      const formattedOptions = response.data.tabelas.map((item) => ({
        value: item,
        label: item,
      }));

      setOptions(formattedOptions);

      const data = await response.data;
      setTabelasMateriais(data.tabelas);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail ||
              'Erro ao buscar tabelas de materiais.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'sap_id', headerName: 'SAP', flex: 1 },
    {
      field: 'descl',
      headerName: 'DESCRIÇÃO',
      flex: 6,
      renderCell: (params) => (
        <div className="has-text-justified is-size-7">
          {params.value.split(';').map((substring, index) => {
            const [key, value] = substring.split(':');
            return (
              <React.Fragment key={index}>
                {index > 0 && '; '}
                {key && (
                  <strong className="has-text-primary-dark">{key}</strong>
                )}
                :{value}
              </React.Fragment>
            );
          })}
        </div>
      ),
    },
    { field: 'unidade', headerName: 'UN', flex: 1 },
    {
      field: 'expandir',
      headerName: 'EXPANDIR',
      flex: 1,
      renderCell: (params) => (
        <div style={{ paddingLeft: '17px', width: '100%' }}>
          <IconButton
            onClick={() => {
              getMaterialSAP(params.row.sap_id);
              handleOpenModal('Expandir');
            }}
            color="black"
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleTermSeach = async (e) => {
    e.preventDefault();

    const headers = {
      'Content-Type': 'application/json',
    };

    try {
      let params = {};

      if (optionSearch == 'sap_id') {
        params = { sap_id: searchTerm, schema: 'material' };
      } else if (optionSearch == 'id') {
        params = { id: searchTerm, schema: 'material' };
      } else {
        params = { search_str: searchTerm, schema: 'material' };
      }

      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/mats/insumos/q`,
        {
          params: params,
          headers: headers,
        }
      );

      const data = await response.data;
      if (response.status === 200) {
        setAllMateriais(data);
      }
      if (!data) {
        setAllMateriais([]);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar material.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setModalOpen(false);
  };

  const renderModalContent = (info) => {
    return <ModalMaterial materialSap={materialSAP} info={info} />;
  };

  useEffect(() => {
    getMateriais();
    getTabelasMateriais();
  }, []);

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Engenharia</li>
                    <li>Materiais</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body" style={{ padding: '13px 24px' }}>
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Materiais</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <div className="search-pdm">
                      <form
                        onSubmit={handleFormSubmit}
                        name="selecionarMaterial"
                        className="is-inline"
                      >
                        <div style={{ display: 'flex' }}>
                          <div
                            className="div-select-search"
                            style={{
                              paddingRight: '15px',
                              width: '400px',
                            }}
                          >
                            <label className="label">Cadastrar Material</label>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={options[0]}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={options}
                              placeholder={
                                <div>
                                  <i className="fa-solid fa-magnifying-glass"></i>
                                  <span> Selecione o Material</span>
                                </div>
                              }
                              onChange={(selectedOption) => {
                                if (selectedOption) {
                                  setTargetInsumo(selectedOption.value);
                                } else {
                                  setTargetInsumo('');
                                }
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-end',
                            }}
                          >
                            <button
                              className="button is-primary"
                              onClick={() => {
                                eventState.button = 1;
                              }}
                            >
                              <span className="icon">
                                <i className="fa-solid fa-arrow-right"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Lista de materiais cadastrados
                </p>
              </header>
              <div className="card-content">
                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="TabelaMateriais">
                      <div style={{ display: 'flex' }}>
                        <div
                          className="div-select-search"
                          style={{ width: '800px' }}
                        >
                          <form
                            name="BuscaMateriais"
                            onSubmit={handleTermSeach}
                          >
                            <div className="search-input">
                              <div className="info-search">
                                <div className="field">
                                  <label className="label">
                                    Buscar Material
                                  </label>
                                  <div
                                    style={{
                                      display: 'flex',
                                    }}
                                  >
                                    <SelectMui
                                      name="option"
                                      sx={{
                                        minWidth: '131px',
                                        textAlign: 'center',
                                        fontSize: '14px',
                                        borderRadius: '4px 0px 0px 4px',
                                        '& .MuiSelect-select': {
                                          padding: '7px 32px 7px 7px',
                                          color: '#2B3BED',
                                          // fontWeight: 'bold',
                                        },
                                        '& .MuiSvgIcon-root': {
                                          color: '#2B3BED',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          border: '1px solid #2B3BED',
                                        },
                                      }}
                                      value={optionSearch}
                                      required
                                      onChange={(e) =>
                                        setOptionSearch(e.target.value)
                                      }
                                    >
                                      <MenuItem
                                        style={{
                                          padding: '0px 10px',
                                          fontSize: '14px',
                                        }}
                                        value={'descl'}
                                      >
                                        DESCRIÇÃO
                                      </MenuItem>
                                      <MenuItem
                                        style={{
                                          padding: '0px 10px',
                                          fontSize: '14px',
                                        }}
                                        value={'sap_id'}
                                      >
                                        SAP
                                      </MenuItem>
                                      <MenuItem
                                        style={{
                                          padding: '0px 10px',
                                          fontSize: '14px',
                                        }}
                                        value={'id'}
                                      >
                                        ID
                                      </MenuItem>
                                    </SelectMui>
                                    <input
                                      className="input"
                                      value={searchTerm}
                                      style={{
                                        borderRadius: '0px 4px 4px 0px',
                                        borderLeft: '0px',
                                      }}
                                      onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="button-search">
                                <button className="button is-primary">
                                  Buscar
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div
                          className="btn-criar-pdm"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            paddingBottom: '15px',
                            width: '100%',
                          }}
                        >
                          <button
                            className="button is-primary"
                            onClick={() =>
                              navigate(`/suprimentos/criar-pdm-form`, {
                                state: {
                                  route: 'pdms',
                                },
                              })
                            }
                          >
                            <span className="icon">
                              <i className="fa-regular fa-pen-to-square"></i>
                            </span>
                            <span>Criar PDM</span>
                          </button>
                        </div>
                      </div>

                      <div
                        style={{
                          height: 550,
                          width: '100%',
                        }}
                      >
                        <DataGrid
                          rows={allMateriais}
                          columns={columns}
                          pageSize={5}
                          disableSelectionOnClick
                          getRowHeight={() => 'auto'}
                          sx={{
                            fontFamily: '"Nunito", sans-serif',
                            '& .MuiDataGrid-cell': {
                              display: 'flex',
                              alignItems: 'flex-start',
                              paddingTop: '4px',
                              paddingBottom: '4px',
                              border: '0px',
                              fontFamily: '"Nunito", sans-serif',
                              lineHeight: '1.5',
                              fontSize: '16px',
                            },
                            '& .MuiDataGrid-row': {
                              borderLeft: '0px',
                            },
                            '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                              {
                                fontWeight: '600',
                                fontSize: '16px',
                                color: '#171717',
                                fontFamily: '"Nunito", sans-serif',
                                lineHeight: '1.5',
                              },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FormModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              modalTitle={'Expansão de Material'}
            >
              {renderModalContent(info)}
            </FormModal>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default Materiais;
