import dayjs from 'dayjs';
import { useEffect } from 'react';
import Select from 'react-select';

const ModalFiltroKanban = ({ filtersInfo }) => {
  const {
    showFilters,
    setShowFilters,
    empreendimentos,
    listaProjetosPorEmpreendimento,
    disciplinas,
    handleButtonClick,
    setActiveEmpreendimento,
    setActiveProject,
    setNomeResponsavel,
    activeEmpreendimento,
    activeProject,
    selectedDisciplinas,
    setSelectedDisciplinas,
    activeButton,
    setActiveButton,
    nomeResponsavel,
    setPeriodStart,
    periodStart,
  } = filtersInfo;

  const handleDayChange = (date) => {
    if (date === '') {
      date = dayjs().format('YYYY-MM-DD');
    }
    setPeriodStart(date);
  };

  useEffect(() => {
    setPeriodStart(dayjs().format('YYYY-MM-DD'));
  }, []);

  return (
    <>
      {showFilters && (
        <div className="level-item">
          <div className="select-lista">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                className="div-select-search"
                style={{ padding: '15px', width: '400px' }}
              >
                <label className="label">Empreendimento</label>

                <div className="field-body">
                  <div className="field is-narrow" style={{ padding: '0px' }}>
                    <div className="control has-icons-left">
                      <Select
                        className="selectf"
                        classNamePrefix="select"
                        defaultValue={
                          activeEmpreendimento
                            ? empreendimentos[
                                empreendimentos.findIndex(
                                  (obj) => obj.value === activeEmpreendimento
                                )
                              ]
                            : empreendimentos[0]
                        }
                        isClearable={true}
                        isSearchable={true}
                        name="empre"
                        options={empreendimentos}
                        placeholder={
                          <div>
                            <i className="fa-solid fa-folder-tree"></i>
                            <span> Selecione o empreendimento</span>
                          </div>
                        }
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            setActiveEmpreendimento(selectedOption.value);
                            setActiveProject(null);
                          } else {
                            setActiveEmpreendimento(null);
                            setActiveProject(null);
                          }
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            fontSize: '1rem', // Adjust the font size as needed
                            maxHeight: '54px', // Adjust the height to make it larger
                            minHeight: '54px',
                            minWidth: '230px', // Optional: set a minimum width
                          }),
                          option: (base) => ({
                            ...base,
                            color: 'black',
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="div-select-search"
                style={{ padding: '15px', width: '400px' }}
              >
                <label className="label">Projeto</label>

                <div className="field-body">
                  <div className="field is-narrow" style={{ padding: '0px' }}>
                    <div className="control has-icons-left">
                      <Select
                        className="selectf"
                        classNamePrefix="select"
                        defaultValue={null}
                        isClearable={true}
                        isSearchable={true}
                        isDisabled={!activeEmpreendimento}
                        name="proj"
                        value={activeProject}
                        // styles={{ menu: (provided) => ({ ...provided, zIndex: 1000, }) }}
                        options={
                          activeEmpreendimento
                            ? listaProjetosPorEmpreendimento[
                                activeEmpreendimento.id
                              ]
                            : []
                        }
                        placeholder={
                          <div>
                            <i className="fa-solid fa-folder-tree"></i>
                            <span> Selecione o projeto</span>
                          </div>
                        }
                        onChange={(selectedOption) => {
                          setActiveProject(selectedOption);
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            fontSize: '1rem', // Adjust the font size as needed
                            maxHeight: '54px', // Adjust the height to make it larger
                            minHeight: '54px',
                            minWidth: '230px', // Optional: set a minimum width
                          }),
                          option: (base) => ({
                            ...base,
                            color: 'black',
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="div-select-search"
                style={{ padding: '15px', width: '400px' }}
                onWheel={(e) => {
                  const selectBox =
                    e.currentTarget.querySelector('.selectf_disc'); // Target the scrollable area of react-select
                  if (
                    selectBox &&
                    (selectBox.scrollTop > 0 ||
                      selectBox.scrollTop + selectBox.clientHeight <
                        selectBox.scrollHeight)
                  ) {
                    e.stopPropagation(); // Prevent page scrolling when inside the component
                  }
                }}
              >
                <label className="label">Disciplina(s)</label>
                <div className="field-body">
                  <div className="field is-narrow" style={{ padding: '0px' }}>
                    <div className="control has-icons-left">
                      <Select
                        className="selectf_disc"
                        isMulti // Enables multiple selection
                        value={selectedDisciplinas} // Controlled component state
                        onChange={(selectedOptions) => {
                          setSelectedDisciplinas(selectedOptions);
                          const temp = selectedOptions
                            ? selectedOptions.map((option) => option.value)
                            : [];
                          setActiveButton(temp.join(','));
                        }}
                        options={disciplinas} // Array of options for react-select
                        styles={{
                          control: (base) => ({
                            ...base,
                            overflowY: 'auto',
                            fontSize: '1rem', // Adjust the font size as needed
                            // maxHeight: '54px', // Adjust the height to make it larger
                            minHeight: '54px',
                            maxHeight: '100px',
                            minWidth: '230px', // Optional: set a minimum width
                          }),
                          option: (base) => ({
                            ...base,
                            color: 'black',
                          }),
                        }}
                        placeholder={
                          <div>
                            <i className="fa-solid fa-folder-tree"></i>
                            <span> Selecione a(s) disciplina(s)</span>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="div-select-search"
                style={{ padding: '15px', width: '400px' }}
              >
                <label className="label">Nome</label>

                <div className="field-body">
                  <div className="field is-narrow" style={{ padding: '0px' }}>
                    <div className="control">
                      <textarea
                        className="textarea"
                        placeholder="Nome do responsável"
                        style={{
                          minHeight: '54px',
                          fontSize: '1rem',
                          resize: 'none',
                        }}
                        value={nomeResponsavel}
                        onChange={(e) => setNomeResponsavel(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault(); // Prevents a new line in the textarea
                            handleButtonClick(
                              activeEmpreendimento,
                              activeProject,
                              activeButton,
                              nomeResponsavel,
                              periodStart
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="div-select-search"
                style={{ padding: '15px', width: '400px' }}
              >
                <label className="label">Considerar cards a partir de:</label>
                <div className="field-body">
                  <div
                    className="field is-narrow"
                    style={{
                      padding: '0px',
                      textAlign: 'left',
                    }}
                  >
                    <div className="control">
                      <input
                        className="input"
                        type="date"
                        style={{
                          fontSize: '1rem',
                          minHeight: '54px',
                        }}
                        value={periodStart}
                        onChange={(e) => handleDayChange(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="button-search"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '15px',
                  marginBottom: '10px',
                  justifyContent: 'center',
                }}
              >
                <button
                  type="button"
                  className="button is-primary"
                  onClick={() => {
                    handleButtonClick(
                      activeEmpreendimento,
                      activeProject,
                      activeButton,
                      nomeResponsavel,
                      periodStart
                    );
                  }}
                >
                  <span>Filtrar</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalFiltroKanban;
