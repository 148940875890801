import React from 'react';
import MainLayout from '../../../layouts/MainLayout';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import AlertMessage from '../../../layouts/AlertMessage';

const ListaOrcamentoFornecedor = () => {
  const { lista_id } = useParams();
  const { REACT_APP_BASE_API_URL } = process.env;
  const [orcamentoTitulo, setOrcamentoTitulo] = useState('');
  const [orcamentoCapex, setOrcamentoCapex] = useState('');
  const [orcamentoCapexId, setOrcamentoCapexId] = useState('');
  const [orcamentoDocRef, setOrcamentoDocRef] = useState('');
  const [orcamentoCusto, setOrcamentoCusto] = useState(0.0);
  const [orcamentoLm, setOrcamentoLm] = useState('');
  const [orcamentoFornecedor, setOrcamentoFornecedor] = useState('');
  const [orcamentoFornecedorId, setOrcamentoFornecedorId] = useState('');

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const [listaItems, setListaItems] = useState([]);

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  const getListaOrcamento = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/listas/lo/${lista_id}`
      );
      const data = await response.data;
      const updatedData = data.items.map((item) => {
        const total = 0.0;
        return {
          ...item,
          preco_total: total,
        };
      });
      setListaItems(updatedData);
      setOrcamentoCapexId(data.lista.capex);
      setOrcamentoLm(data.lista.lm_id);
      setOrcamentoDocRef(data.lista.doc_ref);
      setOrcamentoTitulo(data.lista.titulo);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar lista de orçamento'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handlePrecoChange = (index, value) => {
    const updatedItems = [...listaItems];
    const currentItem = updatedItems[index];
    const originalTotal = currentItem.preco_total;

    currentItem.preco_und = value;
    currentItem.preco_total = value * currentItem.quantidade;
    setListaItems(updatedItems);
    const totalDif = currentItem.preco_total - originalTotal;
    setOrcamentoCusto((prevCusto) => prevCusto + totalDif);
  };

  const handleObsChange = (index, value) => {
    const updatedItems = [...listaItems];
    updatedItems[index].obs = value;
    setListaItems(updatedItems);
  };

  const handleDeclineItem = (index) => {
    const updatedItems = [...listaItems];
    const currItem = updatedItems[index];

    const itemCost = currItem.preco_total;
    setOrcamentoCusto((prevCusto) => prevCusto - itemCost);
    updatedItems[index].declinado = true;
    setListaItems(updatedItems);
  };

  const handleAcceptAgain = (index) => {
    const updatedItems = [...listaItems];
    const currItem = updatedItems[index];

    const itemCost = currItem.preco_total;
    setOrcamentoCusto((prevCusto) => prevCusto + itemCost);
    updatedItems[index].declinado = false;
    setListaItems(updatedItems);
  };

  const handleOrcamento = async (e) => {
    e.preventDefault();

    const updatedItems = listaItems.map((item) => ({
      id: item.id,
      lm_id: item.lm_id,
      capex_linha_ref: item.capex_linha_ref,
      item_id: item.item_id,
      sap_id: item.sap_id,
      quantidade: item.quantidade,
      lo_id: item.lo_id,
      preco_und: parseFloat(item.preco_und),
      obs: item.obs,
      declinado: item.declinado,
    }));
    const requestObj = {
      items: updatedItems,
    };
    try {
      const response = await axios.put(
        `${REACT_APP_BASE_API_URL}/listas/lo/${lista_id}`,
        requestObj,
        {
          headers: headers,
        }
      );
      const data = await response.data;
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao enviar orçamento'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getListaOrcamento();
  }, []);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <section className="main-content">
          <div className="container">
            <form name="CadastroListaOrcamento" onSubmit={handleOrcamento}>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Título:</label>
                    <input
                      className="input"
                      value={`${orcamentoTitulo}-Orcamento`}
                      type="text"
                      onChange={(e) => setOrcamentoTitulo(e.target.value)}
                      disabled
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Custo:</label>
                    <input
                      className="input"
                      value={formatPreco(orcamentoCusto)}
                      type="text"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Documento Referência:</label>
                <input
                  className="input"
                  value={orcamentoDocRef}
                  type="text"
                  onChange={(e) => setOrcamentoDocRef(e.target.value)}
                />
              </div>

              <div className="divider">ITENS</div>

              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>REF</th>
                    <th>ITEM</th>
                    <th>UND.</th>
                    <th>QTD.</th>
                    <th>PREÇO</th>
                    <th>TOTAL</th>
                    <th>OBSERVAÇÕES</th>
                    <th>DECLINAR</th>
                  </tr>
                </thead>
                <tbody>
                  {listaItems &&
                    listaItems.map((item, i) => (
                      <tr
                        key={i}
                        style={
                          item.declinado
                            ? {
                                backgroundColor: 'lightpink',
                              }
                            : {}
                        }
                      >
                        <td>{item.capex_linha_ref}</td>
                        <td>{item.desc}</td>
                        <td>{item.unidade}</td>
                        <td>{item.quantidade}</td>
                        <td>
                          <input
                            className="input is-small"
                            name={`Q${i}`}
                            step={'0.0001'}
                            type="number"
                            style={{
                              maxWidth: 100,
                            }}
                            onChange={(e) =>
                              handlePrecoChange(i, e.target.value)
                            }
                            disabled={item.declinado}
                          />
                        </td>
                        <td>{formatPreco(item.preco_total)}</td>
                        <td>
                          <input
                            className="input is-small"
                            name={`D${i}`}
                            type="text"
                            onChange={(e) => handleObsChange(i, e.target.value)}
                            disabled={item.declinado}
                          />
                        </td>
                        <td>
                          {!item.declinado ? (
                            <span onClick={() => handleDeclineItem(i)}>
                              <i className="fa-regular fa-face-tired"></i>
                            </span>
                          ) : (
                            <span onClick={() => handleAcceptAgain(i)}>
                              <i className="fa-regular fa-face-smile"></i>
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <button className="button" type="submit">
                Enviar
              </button>
            </form>
          </div>
        </section>
      </MainLayout>
    </>
  );
};

export default ListaOrcamentoFornecedor;
