import React, { useState, useEffect } from 'react';
import MainLayout from '../../../layouts/MainLayout';
import Modal from '../../../layouts/InsumosModal';
import CapexDetailModalView from '../../capex/CapexDetailModalView';
import LaunchModalButton from '../../../functional/LaunchModal';
import axios from 'axios';
import AlertMessage from '../../../layouts/AlertMessage';
import { useUser } from '../../../../context/UserContext';

const ListasMateriaisVinculadasForm = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [listaItems, setListaItems] = useState([]);
  const [listaItemsIds, setListaItemsIds] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [listaTitulo, setListaTitulo] = useState('');
  const [listaCusto, setListaCusto] = useState(0.0);
  const [listaCapex, setListaCapex] = useState('');
  const [listaDocRef, setListaDocRef] = useState('');
  const [checkedRows, setCheckedRows] = useState([]);
  const [allCapex, setAllCapex] = useState([]);
  const [capexOptions, setCapexOptions] = useState([]);
  const [isPanelDisabled, setPanelDisabled] = useState(true);
  const [allListas, setAllListas] = useState([]);
  const { idUsuario, setIdUsuario } = useUser();

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  const onAddHandler = (obj) => {
    if (listaItemsIds.includes(obj.id)) {
      setListaItemsIds((prevIds) => [...prevIds]);
    } else {
      setListaItemsIds((prevIds) => [...prevIds, obj.id]);
      setListaItems((prevItems) => [...prevItems, obj]);
      setListaCusto((prevCusto) => prevCusto + obj.preco_total);
    }
  };

  const handleRemoveItem = (i) => {
    const removedItem = listaItems[i];
    const updatedItems = listaItems.filter((item, index) => index !== i);
    const updatedIds = listaItemsIds.filter((id) => id !== removedItem.id);
    const updatedCheckedRows = checkedRows.filter((index) => index !== i);
    setListaItems(updatedItems);
    setListaItemsIds(updatedIds);
    setCheckedRows(updatedCheckedRows);
    setListaCusto((prevCusto) => {
      const newCusto = prevCusto - removedItem.preco_total;
      return newCusto < 0 ? 0 : newCusto;
    });
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setModalOpen(false);
  };

  const validateCapex = () => {
    return listaCapex === '';
  };

  const renderModalContent = () => {
    return (
      <CapexDetailModalView capex_id={listaCapex} onAddHandler={onAddHandler} />
    );
  };

  const getCapex = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/capex/lastcapex`,
        { headers }
      );
      const data = await response.data;
      setAllCapex(data.capex);
      const capexO = data.capex.map((item) => ({
        option: `${item.empreendimento}|${item.projeto}`,
        id: item.id,
      }));
      setCapexOptions(capexO);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar capex'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleCapexSelect = async (e) => {
    setListaCapex(e.target.value);
  };

  const handleLMVCad = async (e) => {
    e.preventDefault();
    const requestItems = listaItems.map((item, i) => ({
      item_id: item.item_id,
      quantidade: item.quantidade,
      capex_linha_ref: item.ref,
    }));

    const requestLista = {
      titulo: listaTitulo,
      capex: listaCapex,
      doc_ref: listaDocRef,
      custo: listaCusto,
      dono: idUsuario,
    };

    const requestObj = {
      lista: requestLista,
      items: {
        items: requestItems,
      },
    };

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/listas/lmv/`,
        requestObj,
        { headers }
      );
      const data = await response.data;
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao criar lista'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getCapex();
    setPanelDisabled(listaCapex === '');
    setIdUsuario(idUsuario);
  }, []);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
        handleClose={handleClose}
      />
      <MainLayout>
        <section className="main-content">
          <div className="container">
            <form name="CadastroListaMateriaisAvulsa" onSubmit={handleLMVCad}>
              <div className="field">
                <label className="label">Título:</label>
                <input
                  className="input"
                  value={listaTitulo}
                  onChange={(e) => setListaTitulo(e.target.value)}
                  disabled={validateCapex()}
                />
              </div>

              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">CAPEX:</label>
                    <div className="select">
                      <select
                        name="capex"
                        value={listaCapex}
                        onChange={handleCapexSelect}
                      >
                        <option value={''}>Selecione.</option>
                        {capexOptions &&
                          capexOptions.map((cpx, i) => (
                            <option key={cpx.id} value={cpx.id}>
                              {cpx.option}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="column">
                  <div className="field">
                    <label className="label">Documento Referência:</label>
                    <input
                      className="input"
                      value={listaDocRef}
                      onChange={(e) => setListaDocRef(e.target.value)}
                    />
                  </div>
                </div>

                <div className="column">
                  <div className="field">
                    <label className="label">Custo:</label>
                    <input
                      className="input"
                      value={formatPreco(listaCusto)}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="divider">Itens</div>

              <div className="columns">
                <div className="column is-four-fifths">
                  <table className="table is-fullwidth is-hoverable">
                    <thead>
                      <tr>
                        <th>REF</th>
                        <th>ITEM</th>
                        <th>UNIDADE</th>
                        <th>QUANTIDADE</th>
                        <th>PRECO</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {listaItems &&
                        listaItems.map((item, i) => (
                          <tr key={i}>
                            <td>{item.ref}</td>
                            <td>{item.descricao}</td>
                            <td>{item.unidade}</td>
                            <td>
                              <input
                                name={`Q${i}`}
                                className="input"
                                type="number"
                                step={'0.0001'}
                                value={item.quantidade}
                                disabled={item.locked}
                              />
                            </td>
                            <td>{formatPreco(item.preco_und)}</td>
                            <td>
                              <div className="columns">
                                <div className="column is-1">
                                  <span className="icon">
                                    <i className="fa-solid fa-circle-check"></i>
                                  </span>
                                </div>
                                <div className="column is-1">
                                  <span
                                    className="icon"
                                    onClick={() => handleRemoveItem(i)}
                                  >
                                    <i className="fa-solid fa-eraser"></i>
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="column">
                  <p>
                    <LaunchModalButton
                      onOpenModal={handleOpenModal}
                      pText={'CAPEX'}
                    ></LaunchModalButton>
                  </p>
                </div>
              </div>
              <button className="button">Criar</button>
            </form>
          </div>
          <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
            {renderModalContent()}
          </Modal>
        </section>
      </MainLayout>
    </>
  );
};

export default ListasMateriaisVinculadasForm;
