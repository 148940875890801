// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Classe para estilizar as linhas/dados da tabela */
.MuiDataGrid-root .MuiDataGrid-cell {
  margin: 5px 0px 5px 0px;
}

.MuiDataGrid-row {
  border-left: 5px solid transparent;
}
.urgente {
  border-left: 5px solid red;
}

.ocultar {
  display: none !important;
}

.table.is-rounded {
  border: 1px solid lightgrey;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
}

/* .table.is-rounded thead tr {
    border-radius: 5px 5px 0 0;
    border: 1px solid lightgrey;
} */

.table.is-rounded tbody tr {
  border-radius: 0 0 5px 5px;
  border: 2px solid lightgrey;
  border-top: 0px;
  background-color: #f7f7f7;
}

/* .div-select-search {
    max-width: 600px ;
} */
`, "",{"version":3,"sources":["webpack://./src/components/controladoria/css/Filas.css"],"names":[],"mappings":"AAAA,oDAAoD;AACpD;EACE,uBAAuB;AACzB;;AAEA;EACE,kCAAkC;AACpC;AACA;EACE,0BAA0B;AAC5B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;AACb;;AAEA;;;GAGG;;AAEH;EACE,0BAA0B;EAC1B,2BAA2B;EAC3B,eAAe;EACf,yBAAyB;AAC3B;;AAEA;;GAEG","sourcesContent":["/* Classe para estilizar as linhas/dados da tabela */\n.MuiDataGrid-root .MuiDataGrid-cell {\n  margin: 5px 0px 5px 0px;\n}\n\n.MuiDataGrid-row {\n  border-left: 5px solid transparent;\n}\n.urgente {\n  border-left: 5px solid red;\n}\n\n.ocultar {\n  display: none !important;\n}\n\n.table.is-rounded {\n  border: 1px solid lightgrey;\n  border-radius: 5px;\n  overflow: hidden;\n  width: 100%;\n}\n\n/* .table.is-rounded thead tr {\n    border-radius: 5px 5px 0 0;\n    border: 1px solid lightgrey;\n} */\n\n.table.is-rounded tbody tr {\n  border-radius: 0 0 5px 5px;\n  border: 2px solid lightgrey;\n  border-top: 0px;\n  background-color: #f7f7f7;\n}\n\n/* .div-select-search {\n    max-width: 600px ;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
