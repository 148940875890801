import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MainLayout from '../layouts/MainLayout';
import './css/Formularios.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import ComentarioComponent from '../functional/comentarios/Comentario';
import AlertMessage from '../layouts/AlertMessage';
import { useUser } from '../../context/UserContext';

const AnalisarMaterialControladoriaForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;
  const { tarefa, material, route } = location.state;

  const [status, setStatus] = useState(tarefa.status);
  const [comentarioControladoria, setComentarioControladoria] = useState(
    material.comentario_controladoria
  );
  // const [classeAvaliacao, setClasseAvaliacao] = useState(material.classe_avaliacao);
  const [anexos, setAnexos] = useState(material.lista_anexos);
  const [tipoMaterial, setTipoMaterial] = useState(material.tipo_material);
  const [utilizacaoMaterial, setUtilizacaoMaterial] = useState(
    material.utilizacao_material
  );
  // const [origemMaterial, setOrigemMaterial] = useState(material.origem_material);
  const [respostaSolicitante, setRespostaSolicitante] = useState('');
  const [responsavelSup, setResponsavelSup] = useState([]);
  const [validUtil, setValidUtil] = useState(material.utilizacao_material);

  const [commentsData, setCommentsData] = useState([]);
  const [userComment, setUserComment] = useState('');
  const { idUsuario, setIdUsuario } = useUser();
  const statusMiddleList = ['ANDAMENTO', 'PENDENTE', 'ROTA', 'COTACAO'];

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  useEffect(() => {
    if (idUsuario === null || idUsuario === 'null' || idUsuario === undefined) {
      setIdUsuario(idUsuario);
    }
  }, [idUsuario]);

  const handleFlux = async (status_val) => {
    const mensagem = `
    A sua solicitação para cadastrar ${material.descricao_longa} foi atualizada para ${status_val}
    

    Detalhes: ${respostaSolicitante}
    `;
    let schema = {
      comentario: mensagem,
      tarefa_id: tarefa.id,
      email: tarefa.email_relator,
    };
    if (statusMiddleList.includes(status_val)) {
      try {
        const response = await axios.put(
          `${REACT_APP_BASE_API_URL}/tarefas/status/`,
          schema,
          {
            params: { status: status_val, send_mail: false },
            headers,
          }
        );
        if (response.status === 200) {
          // alert('O status da solicitação foi alterado com sucesso.');
          setAlertMessage('O status da solicitação foi alterado com sucesso.');
          setAlertSeverity('success');
          setAlertOpen(true);
          setStatus(status_val);
          // navigate('/controladoria/fila-tarefas/');
          setNavigateTo('/controladoria/fila-tarefas/');
        }
      } catch (error) {
        // alert(error.response.data.detail);
        console.error(error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail ||
                'Erro ao alterar o status da solicitação.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    } else if (status_val === 'RECUSADO') {
      if (respostaSolicitante === '') {
        // alert("É necessário explicar o motivo da recusa.")
        setAlertMessage('É necessário explicar o motivo da recusa.');
        setAlertSeverity('error');
        setAlertOpen(true);
        return;
      }
      try {
        const refuseResponse = await axios.put(
          `${REACT_APP_BASE_API_URL}/tarefas/done/`,
          schema,
          {
            params: { status: status_val },
            headers,
          }
        );
        if (refuseResponse.status === 200) {
          // alert("Solicitação recusada com sucesso.");
          setAlertMessage('Solicitação recusada com sucesso.');
          setAlertSeverity('success');
          setAlertOpen(true);
          setStatus(status_val);
          // navigate('/controladoria/fila-tarefas/');
          setNavigateTo('/controladoria/fila-tarefas/');
        }
      } catch (error) {
        // alert(error.response.data.detail);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao recusar a solicitação.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
        console.error(error);
      }
    } else if (status_val === 'CONCLUIDO') {
      try {
        const cadStatusResponse = await axios.put(
          `${REACT_APP_BASE_API_URL}/tarefas/done/`,
          schema,
          {
            params: { status: status_val, send_mail: false },
            headers,
          }
        );
        if (cadStatusResponse.status === 200) {
          // alert("Solicitação concluida com sucesso.");
          setAlertMessage('Solicitação concluida com sucesso.');
          setAlertSeverity('success');
          setAlertOpen(true);
          setStatus(status_val);
          // navigate('/controladoria/fila-tarefas/');
          setNavigateTo('/controladoria/fila-tarefas/');
        }
      } catch (error) {
        console.error(error);
        // alert(error.response.data.detail);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao concluir a solicitação.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedMaterial = {
      ...material,
      comentario_controladoria: comentarioControladoria,
      utilizacao_material: utilizacaoMaterial,
      tipo_material: tipoMaterial,
      status: status,
      lista_anexos: anexos,
    };

    try {
      const conteudo = JSON.stringify(updatedMaterial);
      const params = new URLSearchParams({ conteudo: conteudo });
      try {
        const response = await axios.put(
          `${REACT_APP_BASE_API_URL}/tarefas/editar-conteudo-tarefa/?tarefa_id=${tarefa.id}&${params.toString()}`,
          {}, // Corpo vazio, já que estamos enviando status como parâmetro de query
          { headers }
        );

        if (response.status === 200) {
          // alert("Material atualizado.");
          setAlertMessage('Material atualizado.');
          setAlertSeverity('success');
          setAlertOpen(true);
          setValidUtil(utilizacaoMaterial);
        }
      } catch (error) {
        console.error(error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao atualizar o material.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao atualizar o material.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    const getResponsavelSuprimentos = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/tarefas/responsavel`,
          {
            params: {
              tarefa_id: tarefa.link_fw,
            },
            headers: headers,
          }
        );

        if (response.data) {
          setResponsavelSup(response.data);
        }
      } catch (error) {
        console.error('Erro ao buscar responsavel suprimentos:', error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail ||
                'Erro ao buscar responsável de suprimentos.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };
    getResponsavelSuprimentos();
  }, [REACT_APP_BASE_API_URL]);

  const postRootComment = async (e) => {
    e.preventDefault();
    let obj = {
      text: userComment,
      user_id: idUsuario,
      objeto: tarefa.id,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/comments/`,
        obj,
        { headers }
      );
      if (response.status === 201) {
        setUserComment('');
        getComments();
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || "Ocorreu um erro.")
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao comentar.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getComments = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_API_URL}/comments/`, {
        params: { objeto_id: tarefa.id },
        headers,
      });
      if (response.status === 200) {
        setCommentsData(response.data);
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || "Ocoreu um erro.")
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar comentários.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getComments();
  }, []);

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    {/* <li>Setores</li> */}
                    <li>Controladoria</li>
                    <li>Fila de materiais</li>
                    <li>Analisar material</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Analisar material</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <div className="buttons is-right">
                      <div className="div-select-box-status">
                        <Select
                          style={{
                            height: '42px',
                            marginBottom: '8px',
                          }}
                          className="select-status"
                          value={status}
                          disabled={validUtil === null || validUtil === 'null'}
                          onChange={(e) => {
                            handleFlux(e.target.value);
                          }}
                        >
                          <MenuItem value={'PENDENTE'}>PENDENTE</MenuItem>
                          <MenuItem value={'ANDAMENTO'}>EM ANDAMENTO</MenuItem>
                          <MenuItem
                            value={'CONCLUIDO'}
                            disabled={
                              validUtil === null || validUtil === 'null'
                            }
                          >
                            CONCLUÍDO
                          </MenuItem>
                          <MenuItem value={'RECUSADO'}>RECUSADO</MenuItem>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Detalhes da solicitação
                </p>
              </header>
              <div
                className="card-content"
                style={{
                  display: 'flex',
                  margin: '0px 0px 30px 0px',
                }}
              >
                <div className="atributos-form">
                  <div className="div-quadro-main-form">
                    <div className="div-quadro-material">
                      {/* <div className="field">   
                        <div className="row-field" style={{ fontSize: '20px'}}>                   
                          <label className="label-field">
                            {material.relator}
                          </label>  
                          <span className="cell-value">criou esta solicitação</span> 
                        </div>                   
                      </div> */}

                      <div
                        className="field"
                        style={{
                          paddingBottom: '12px',
                          borderBottom: '1px solid #dbdbdb',
                        }}
                      >
                        <div className="row-field" style={{ fontSize: '20px' }}>
                          <label className="label-field">
                            {tarefa.nome_relator +
                              ' ' +
                              tarefa.sobrenome_relator}{' '}
                            criou esta solicitação
                          </label>
                        </div>
                      </div>

                      <div className="field">
                        <div className="row-field">
                          <label className="label-field">Id</label>
                          <span className="cell-value">{tarefa.id}</span>
                        </div>
                      </div>

                      <div className="field">
                        <div className="row-field">
                          <label className="label-field">Resumo</label>
                          <span className="cell-value">{tarefa.titulo}</span>
                        </div>
                      </div>

                      <div className="field">
                        <div className="row-field">
                          <label className="label-field">Prioridade</label>
                          <span className="cell-value">
                            {material.prioridade_material}
                          </span>
                        </div>
                      </div>

                      <div className="field">
                        <div className="row-field">
                          <label className="label-field">Centro</label>
                          <span className="cell-value">{material.centro}</span>
                        </div>
                      </div>

                      {material.descricao_breve && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Descrição breve
                            </label>
                            <span className="cell-value">
                              {material.descricao_breve}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.tipo_material && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Tipo de material
                            </label>
                            <span className="cell-value">
                              {material.tipo_material}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.cfop && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">CFOP</label>
                            <span className="cell-value">{material.cfop}</span>
                          </div>
                        </div>
                      )}

                      {material.unidade_medida && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Unidade de medida
                            </label>
                            <span className="cell-value">
                              {material.unidade_medida}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.ncm && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">NCM</label>
                            <span className="cell-value">{material.ncm}</span>
                          </div>
                        </div>
                      )}

                      {material.norma && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Norma</label>
                            <span className="cell-value">{material.norma}</span>
                          </div>
                        </div>
                      )}

                      {material.fabricante && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Fabricante</label>
                            <span className="cell-value">
                              {material.fabricante}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.preco_medio != 0 && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Preço médio</label>
                            <span className="cell-value">
                              R$ {material.preco_medio}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.finalidade && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Finalidade</label>
                            <span className="cell-value">
                              {material.finalidade}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.quantidade_itens && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Quantidade</label>
                            <span className="cell-value">
                              {material.quantidade_itens}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.descricao_longa && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Descrição longa
                            </label>
                            <span className="cell-value">
                              {material.descricao_longa}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.observacoes_solicitante && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Observações do solicitante
                            </label>
                            <span className="cell-value">
                              {material.observacoes_solicitante}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.observacoes && (
                        <div className="field">
                          <label className="label-field">Observações</label>
                          <div className="row-field">
                            <span className="cell-value-full">
                              {material.observacoes}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* ----------------------- QUADRO DE SUPRIMENTOS ----------------------- */}

                    {responsavelSup.nome && (
                      <div className="div-quadro-material-oculto">
                        <div className="div-quadro-material">
                          <div
                            className="field"
                            style={{
                              paddingBottom: '12px',
                              borderBottom: '1px solid #dbdbdb',
                            }}
                          >
                            <div
                              className="row-field"
                              style={{
                                fontSize: '20px',
                              }}
                            >
                              <label className="label-field">
                                {responsavelSup.nome +
                                  ' ' +
                                  responsavelSup.sobrenome}{' '}
                                editou esta solicitação
                              </label>
                            </div>
                          </div>

                          {material.id_sap && (
                            <div className="field">
                              <div className="row-field">
                                <label className="label-field">ID SAP</label>
                                <span className="cell-value">
                                  {material.id_sap}
                                </span>
                              </div>
                            </div>
                          )}

                          {material.descricao_breve_suprimentos && (
                            <div className="field">
                              <div className="row-field">
                                <label className="label-field">
                                  Descrição breve (suprimentos)
                                </label>
                                <span className="cell-value">
                                  {material.descricao_breve_suprimentos}
                                </span>
                              </div>
                            </div>
                          )}

                          {/* {material.descricao_longa_suprimentos && (
                            <div className="field">                      
                              <label className="label-field">
                                Descrição longa (suprimentos)
                              </label>   
                              <div className="row-field">                
                                <span className="cell-value-full">
                                  {material.descricao_longa_suprimentos}
                                </span>
                              </div>
                            </div>
                          )} */}

                          {material.descricao_longa && (
                            <div className="field">
                              <div className="row-field">
                                <label className="label-field">
                                  Descrição longa
                                </label>
                                <span className="cell-value">
                                  {material.descricao_longa}
                                </span>
                              </div>
                            </div>
                          )}

                          {material.comentario_suprimentos && (
                            <div className="field">
                              <label className="label-field">
                                Comentários (suprimentos)
                              </label>
                              <div className="row-field">
                                <span className="cell-value-full">
                                  {material.comentario_suprimentos}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {/* ------------------- Quadros laterais ------------------ */}

                  <div className="div-quadros-secundarios">
                    <div className="div-quadro-SLAs">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">
                          Adicionar detalhes ao status
                        </label>
                      </div>
                      <div className="div-quadro-content">
                        <div
                          className="row-field-message"
                          style={{ width: '100%' }}
                        >
                          <label
                            className="text"
                            style={{
                              color: 'hsl(0, 0%, 21%)',
                            }}
                          >
                            Informe ao solicitante detalhes sobre o status da
                            tarefa:
                          </label>
                          <div
                            className="cell-value"
                            style={{
                              paddingLeft: '0px',
                              paddingTop: '15px',
                            }}
                          >
                            <textarea
                              rows="4"
                              className="input"
                              value={respostaSolicitante}
                              style={{
                                height: '100px',
                                backgroundColor: '#f9f9f9',
                              }}
                              onChange={(e) =>
                                setRespostaSolicitante(e.target.value)
                              }
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="div-quadro-SLAs">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">SLAs</label>
                      </div>
                      <div className="div-quadro-content">
                        <div className="row-field">
                          <label className="label-field-secundario">
                            Criado em
                          </label>
                          <span className="cell-value">
                            {new Date(tarefa.criado_em).toLocaleString()}
                          </span>
                        </div>

                        <div className="row-field">
                          <label className="label-field-secundario">
                            Tempo para resolução
                          </label>
                          <span className="cell-value">
                            {new Date(tarefa.data_due).toLocaleString()}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="div-quadro-informacoes">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">
                          Informações
                        </label>
                      </div>
                      <div className="div-quadro-content">
                        <div className="row-field">
                          <label className="label-field-secundario">
                            Responsável Suprimentos
                          </label>
                          <span className="cell-value">
                            {responsavelSup.nome &&
                              responsavelSup.nome +
                                ' ' +
                                responsavelSup.sobrenome}
                          </span>
                        </div>

                        <div className="row-field">
                          <label className="label-field-secundario">
                            Responsável Controladoria
                          </label>
                          <span className="cell-value">
                            {tarefa.responsavel}
                          </span>
                        </div>

                        <div className="row-field">
                          <label className="label-field-secundario">
                            Relator
                          </label>
                          <span className="cell-value">
                            {tarefa.nome_relator +
                              ' ' +
                              tarefa.sobrenome_relator}
                          </span>
                        </div>

                        <div className="row-field">
                          <label className="label-field-secundario">
                            Prioridade
                          </label>
                          <span className="cell-value">
                            {tarefa.prioridade}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ----------------------- RESPONDER AO SOLICITANTE ----------------------- */}

            {/* ----------------------- ADICIONAR DETALHES CONTROLADORIA ----------------------- */}

            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Editar informações do material
                </p>
              </header>

              <div className="card-content">
                {/* <div className="edit-cad-label">
                  <div className="label-title-edit">                      
                    <p>Editar informações do material</p>        
                  </div>
                </div> */}
                <form
                  name="EditarMaterialControladoriaForm"
                  onSubmit={handleSubmit}
                >
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Tipo de material
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <div className="select is-fullwidth">
                            <select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className="custom-select-style"
                              required
                              value={tipoMaterial}
                              onChange={(e) => setTipoMaterial(e.target.value)}
                            >
                              <option value={''}></option>
                              <option value={'DIEN'}>
                                DIEN - Prestação de serviços
                              </option>
                              <option value={'ERSA'}>
                                ERSA - Peças de reposição
                              </option>
                              <option value={'FERT'}>
                                FERT - Produto acabado
                              </option>
                              <option value={'HALB'}>
                                HALB - Produto semiacabado
                              </option>
                              <option value={'HAWA'}>
                                HAWA - Produto comercializável
                              </option>
                              <option value={'HERS'}>
                                HERS - Peça de fabricante
                              </option>
                              <option value={'HIBE'}>
                                HIBE - Material auxiliar de consumo
                              </option>
                              <option value={'IBAU'}>
                                IBAU - Conjunto de manutenção
                              </option>
                              <option value={'LEIH'}>
                                LEIH - Embalagem retornável
                              </option>
                              <option value={'NLAG'}>
                                NLAG - Material não estocável
                              </option>
                              <option value={'ROH/ZROH'}>
                                ROH/ZROH - Matérias-primas
                              </option>
                              <option value={'UNBW/ZLEI'}>
                                UNBW/ZLEI - Material não avaliado
                              </option>
                              <option value={'VERP'}>VERP - Embalagem</option>
                            </select>
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-box"></i>
                            </span>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Utilizacao do material
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <div className="select is-fullwidth">
                            <select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              required
                              className="custom-select-style"
                              value={utilizacaoMaterial}
                              onChange={(e) =>
                                setUtilizacaoMaterial(e.target.value)
                              }
                            >
                              <option value={''}></option>
                              <option value={'0 - Revenda'}>0 - Revenda</option>
                              <option value={'1 - Industrialização'}>
                                1 - Industrialização
                              </option>
                              <option value={'2 - Consumo'}>2 - Consumo</option>
                              <option value={'3 - Imobilizado'}>
                                3 - Imobilizado
                              </option>
                              <option
                                value={'4 - Consumo para atividade principal'}
                              >
                                4 - Consumo para atividade principal
                              </option>
                            </select>
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-people-carry-box"></i>
                            </span>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Adicionar observações</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <textarea
                            rows="4"
                            className="input"
                            value={comentarioControladoria}
                            style={{
                              height: '100px',
                            }}
                            onChange={(e) =>
                              setComentarioControladoria(e.target.value)
                            }
                          ></textarea>
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-comments"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      margin: '40px 0px 20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button className="button is-primary">
                      <span className="icon">
                        <i className="fa-solid fa-floppy-disk"></i>
                      </span>
                      <span>Salvar</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Comentários
                </p>
              </header>
              <div className="card-content" style={{ width: '100%' }}>
                <form name="comment" onSubmit={postRootComment}>
                  <div className="field">
                    <div className="control">
                      <textarea
                        className="textarea"
                        placeholder="Comentário"
                        value={userComment}
                        onChange={(e) => setUserComment(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div
                    className="div"
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <button className="button is-primary" type="submit">
                      Comentar
                    </button>
                  </div>
                </form>
                <br />
                {commentsData &&
                  commentsData.map((comment) => (
                    <ComentarioComponent
                      key={comment.id}
                      comment={comment}
                      userId={idUsuario}
                      objeto={tarefa}
                      getCommentFunction={getComments}
                    />
                  ))}
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default AnalisarMaterialControladoriaForm;
