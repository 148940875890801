import React from 'react';

const FormModal = ({
  isOpen,
  onClose,
  modalTitle,
  children,
  width,
  backgroundColorTitle,
  backgroundColorBody,
}) => {
  const modalStyle = {
    display: isOpen ? 'block' : 'none',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: backgroundColorBody ? backgroundColorBody : '#212529',
    padding: '0px',
    zIndex: 999,
    minHeight: '100px',
    minWidth: width ? `${width}px` : '800px',
    width: 'auto',
    color: '#FFFFFF',
    // zIndex:  1500,
    overflowY: 'auto',
    maxHeight: '800px',
    borderRadius: '6px',
    border: '1px solid #495057',
    fontFamily:
      'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  };

  const modalHeaderStyle = {
    padding: '14px',
    backgroundColor: backgroundColorTitle,
    color: '#FFFFFF',
    display: 'flex',
    borderBottom: '1px solid #495057',
  };

  const textModalHeaderStyle = {
    fontSize: 'calc(1.275rem + .3vw)',
    width: '100%',
    fontWeight: '500',
  };

  return (
    <div className="box" style={modalStyle}>
      <div className="headerModal" style={modalHeaderStyle}>
        <div className="modal-title h4" style={textModalHeaderStyle}>
          {modalTitle}
        </div>
        <div
          className="iconClose"
          style={{ display: 'flex', justifyContent: 'end' }}
        >
          <i
            className="mdi mdi-close"
            style={{ fontSize: '25px', cursor: 'pointer' }}
            onClick={onClose}
          ></i>
        </div>
      </div>
      <div className="modal-content-edit">{children}</div>
    </div>
  );
};

export default FormModal;
