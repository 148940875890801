import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/pt-br';
import { useNavigate } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AlertMessage from '../../layouts/AlertMessage';
import { useUser } from '../../../context/UserContext';

const SolicitacaoRCFormA = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();

  const [titulo, setTitulo] = useState('');
  const [listaMateriais, setListaMateriais] = useState('');
  const [empreendimento, setEmpreendimento] = useState('');
  const [empreendimentoId, setEmpreendimentoId] = useState('');
  const [projeto, setProjeto] = useState('');
  const [projetoId, setProjetoId] = useState('');

  const { idUsuario, setIdUsuario, nomeUsuario, setNomeUsuario } = useUser();

  const [responsavelId, setResponsavelId] = useState(idUsuario);

  const [responsavel, setResponsavel] = useState(nomeUsuario);

  const [prioridade, setPrioridade] = useState('');
  const [tipo, setTipo] = useState('');
  const [dataNecessidadeObra, setDataNecessidadeObra] = useState(null);
  const [orcamento, setOrcamento] = useState('');
  const [escopo, setEscopo] = useState('');
  const [localEntrega, setLocalEntrega] = useState('');
  const [fornecedorEntrega, setFornecedorEntrega] = useState(null);
  const [docRef, setDocRef] = useState('');
  const [tipoCompra, setTipoCompra] = useState('');
  const [fornecimentoExclusivo, setFornecimentoExclusivo] = useState('');
  const [justFornecimentoExc, setJustFornecimentoExc] = useState('');
  const [freteIncluso, setFreteIncluso] = useState('');
  const [compraPacote, setCompraPacote] = useState('');
  const [proposta, setProposta] = useState('');
  const [observacoes, setObservacoes] = useState('');
  const [capex, setCapex] = useState('');
  const [allCapex, setAllCapex] = useState([]);
  const [capexObjRef, setCapexObjRef] = useState({});

  const [allListas, setAllListas] = useState([]);
  const [listaItens, setListaItens] = useState([]);
  const [listaObject, setListaObject] = useState(null);
  const [allEmpreendimentos, setAllEmpreendimentos] = useState([]);
  const [allProjetos, setAllProjetos] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [showHiddenFields, setShowHiddenFields] = useState(false);

  const [isUserResponsavel, setUserResponsavel] = useState(true);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleSelectChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    setCapexObjRef(allCapex[selectedIndex - 1]);
    setCapex(e.target.value);
    getListas();
    setShowHiddenFields(capex !== '');
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const getListas = async () => {
    try {
      const listasResponse = await axios.get(
        `${REACT_APP_BASE_API_URL}/listas/lma/user/`,
        {
          params: { user_id: idUsuario, src: true },
          headers,
        }
      );
      const data = await listasResponse.data;
      if (listasResponse.status === 200) {
        setAllListas(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar listas'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getEmpreendimentos = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/adm/empreendimento`,
        { headers }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAllEmpreendimentos(data.empreendimentos);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar empreendimentos'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const readLista = async (lmId) => {
    setListaMateriais(lmId);
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/listas/lmid/`,
        {
          params: { lista_id: lmId },
          headers,
        }
      );
      const data = await response.data;
      if (response.status === 200) {
        setListaItens(data.items);
        setListaObject(data.lista);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar lista de materiais'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getUsersMain = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/users/user-main/`,
        { headers }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAllUsers(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar usuários'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleEmpreendimentoChange = async (val) => {
    setEmpreendimentoId(val);
    const empreendimentoObj = allEmpreendimentos.find((emp) => emp.id === val);
    setEmpreendimento(empreendimentoObj.tag);
    setLocalEntrega(`${empreendimentoObj.tag}: ${empreendimentoObj.endereco}`);
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/adm/empreendimento/projetos/`,
        {
          params: { empreendimento_id: val },
          headers,
        }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAllProjetos(data.projetos);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar projetos'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleProjetoChange = (val) => {
    setProjetoId(val);
    const projetoObj = allProjetos.find((proj) => proj.id === val);
    setProjeto(`${projetoObj.tag} - ${projetoObj.titulo}`);
  };

  const handleResponsavelChange = (val) => {
    if (!val) {
      setResponsavelId('');
      setResponsavel('');
      return;
    }
    setResponsavelId(val);
    const responsavelObj = allUsers.find((user) => user.id === val);
    setResponsavel(`${responsavelObj.nome} ${responsavelObj.sobrenome}`);
  };

  const handlePrecoChange = (index, value) => {
    const updatedItems = [...listaItens];
    updatedItems[index].preco = value;
    setListaItens(updatedItems);
  };
  const handleREFChange = (index, value) => {
    const updatedItems = [...listaItens];
    updatedItems[index].capex_linha_ref = value;
    setListaItens(updatedItems);
  };
  const handleREFValChange = (index, value) => {
    const updatedItems = [...listaItens];
    updatedItems[index].capex_linha_valor_ref = value;
    setListaItens(updatedItems);
  };

  const handleSRCSubmit = async (e) => {
    e.preventDefault();
    let SRC = {
      titulo: titulo,
      solicitante: nomeUsuario,
      solicitante_id: idUsuario,
      responsavel: !isUserResponsavel ? responsavel : nomeUsuario,
      responsavel_id: !isUserResponsavel ? responsavelId : idUsuario,
      lista_materiais: listaMateriais,
      empreendimento: empreendimento,
      empreendimento_id: empreendimentoId,
      projeto: projeto,
      projeto_id: projetoId,
      prioridade: prioridade,
      tipo: tipo,
      data_necessidade_obra: dataNecessidadeObra,
      orcamento: orcamento,
      local_entrega: localEntrega,
      fornecedor_entrega: fornecedorEntrega,
      doc_ref: docRef,
      tipo_compra: tipoCompra,
      fornecimento_exclusivo: fornecimentoExclusivo,
      justificativa_fornecimento_exclusivo: justFornecimentoExc,
      frete_incluso: freteIncluso,
      compra_pacote: compraPacote,
      proposta: proposta,
      observacoes: observacoes,
      custo: listaObject.custo,
    };

    let items = listaItens.map((item) => ({
      id: item.lm_item_id,
      lm_id: listaMateriais,
      capex_linha_ref: item.capex_linha_ref,
      capex_linha_valor_ref: item.capex_linha_valor_ref,
      item_id: item.item_id,
      sap_id: item.sap_id,
      quantidade: item.quantidade,
      preco: item.preco,
      preco_cad: item.preco_cad,
      vinculada: item.vinculada,
    }));

    let objRequest = {
      src: SRC,
      newitems: {
        items: items,
      },
    };

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/solicitacao-rc/src-a/`,
        objRequest,
        { headers }
      );
      const data = await response.data;
      if (response.status === 201) {
        setAlertMessage('Solicitação efetuada com sucesso.');
        setAlertSeverity('success');
        setAlertOpen(true);
        setNavigateTo('/srch');
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao efetuar solicitação'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    setIdUsuario(idUsuario);
    setNomeUsuario(nomeUsuario);
    if (idUsuario) {
      getListas();
      getEmpreendimentos();
      getUsersMain();
    }
  }, []);

  useEffect(() => {
    return () => {
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-main-section">
            <form name="SRCForm" onSubmit={handleSRCSubmit}>
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Requisição de Compra Avulsa
                  </p>
                </header>
                <div className="card-content">
                  <div
                    className="field is-horizontal"
                    style={{ marginBottom: '20px' }}
                  >
                    <div className="field-label is-normal">
                      <label style={{ textAlign: 'end' }} className="label">
                        Você é o responsável pela RC?
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div
                      className="field-body"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingTop: '5px',
                      }}
                    >
                      <div className="field">
                        <div className="control">
                          <label className="radio">
                            <input
                              type="radio"
                              name="statusRC"
                              value={isUserResponsavel}
                              required
                              onChange={() => setUserResponsavel(true)}
                            />
                            &nbsp;SIM
                          </label>
                          <label className="radio">
                            <input
                              type="radio"
                              name="statusRC"
                              value="false"
                              onChange={() => setUserResponsavel(false)}
                            />
                            &nbsp;NÃO
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {!isUserResponsavel && (
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          Responsável
                          <span className="required-symbol"> *</span>
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field is-narrow">
                          <div className="control has-icons-left">
                            <div className="select is-fullwidth">
                              <p className="control is-expanded has-icons-left">
                                <select
                                  required
                                  onChange={(e) =>
                                    handleResponsavelChange(e.target.value)
                                  }
                                >
                                  <option value={''}>Selecione.</option>
                                  {allUsers &&
                                    allUsers.map((user, idx) => (
                                      <option key={idx} value={user.id}>
                                        {user.nome} {user.sobrenome}
                                      </option>
                                    ))}
                                </select>
                                <span className="icon is-small is-left">
                                  <i className="fa-solid fa-landmark"></i>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Lista de Materiais
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <p className="control is-expanded has-icons-left">
                              <select
                                name="capex"
                                value={listaMateriais}
                                required
                                onChange={(e) => readLista(e.target.value)}
                              >
                                <option value="">Selecione.</option>
                                {allListas.map((lista, i) => (
                                  <option key={lista.id} value={lista.id}>
                                    {lista.titulo}
                                  </option>
                                ))}
                              </select>
                              <span className="icon is-small is-left">
                                <i className="fa-solid fa-list"></i>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Título
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            type="text"
                            placeholder=""
                            value={titulo}
                            onChange={(e) => setTitulo(e.target.value)}
                            className="input"
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-heading"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Empreendimento
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <p className="control is-expanded has-icons-left">
                              <select
                                required
                                onChange={(e) =>
                                  handleEmpreendimentoChange(e.target.value)
                                }
                              >
                                <option value={''}>Selecione.</option>
                                {allEmpreendimentos.map((emp, idx) => (
                                  <option key={idx} value={emp.id}>
                                    {emp.tag}
                                  </option>
                                ))}
                              </select>
                              <span className="icon is-small is-left">
                                <i className="fa-solid fa-landmark"></i>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Projeto
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <p className="control is-expanded has-icons-left">
                              <select
                                required
                                onChange={(e) =>
                                  handleProjetoChange(e.target.value)
                                }
                              >
                                <option value={''}>Selecione.</option>
                                {allProjetos &&
                                  allProjetos.map((proj, idx) => (
                                    <option key={idx} value={proj.id}>
                                      {proj.tag} - {proj.titulo}
                                    </option>
                                  ))}
                              </select>
                              <span className="icon is-small is-left">
                                <i className="fa-solid fa-landmark"></i>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Prioridade
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <p className="control is-expanded has-icons-left">
                              <select
                                required
                                onChange={(e) => setPrioridade(e.target.value)}
                              >
                                <option value={''}>Selecione.</option>
                                <option value={'NORMAL'}>NORMAL</option>
                                <option value={'REGULARIZACAO'}>
                                  REGULARIZACAO
                                </option>
                                <option value={'EMERGENCIAL'}>
                                  EMERGENCIAL
                                </option>
                                <option value={'PRIORITARIA'}>
                                  PRIORITARIA
                                </option>
                              </select>
                              <span className="icon is-small is-left">
                                <i className="fa-solid fa-list-ol"></i>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Tipo
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <p className="control is-expanded has-icons-left">
                              <select
                                onChange={(e) => setTipo(e.target.value)}
                                value={tipo}
                                required
                              >
                                <option value={''}>Selecione.</option>
                                <option value={'MATERIAL'}>MATERIAL</option>
                                <option value={'SERVIÇO'}>SERVIÇO</option>
                                <option value={'MATERIAL E SERVIÇO'}>
                                  MATERIAL E SERVIÇO
                                </option>
                              </select>
                              <span className="icon is-small is-left">
                                <i className="fa-solid fa-people-carry-box"></i>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div
                      className="field-label is-normal"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <label className="label">
                        Data de Necessidade na Obra
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="pt-br"
                          >
                            <DemoContainer
                              sx={{
                                padding: '0px',
                                height: '40px',
                              }}
                              components={['DatePicker']}
                            >
                              <DatePicker
                                label=""
                                required
                                sx={{
                                  '& .MuiInputBase-input': {
                                    padding: '8px',
                                    fontSize: '1rem',
                                  },
                                }}
                                value={dataNecessidadeObra}
                                onChange={(newValue) =>
                                  setDataNecessidadeObra(
                                    newValue ? newValue : null
                                  )
                                }
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Orçamento
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <p className="control is-expanded has-icons-left">
                              <select
                                name="orcamento"
                                value={orcamento}
                                required
                                onChange={(e) => setOrcamento(e.target.value)}
                              >
                                <option value={''}>Selecione.</option>
                                <option value={'CAPEX'} key={'CAPEX'}>
                                  CAPEX
                                </option>
                                <option value={'OPEX'} key={'OPEX'}>
                                  OPEX
                                </option>
                              </select>
                              <span className="icon is-small is-left">
                                <i className="fa-solid fa-comments-dollar"></i>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Escopo
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <p className="control is-expanded has-icons-left">
                              <select
                                name="escopo"
                                required
                                value={escopo}
                                onChange={(e) => setEscopo(e.target.value)}
                              >
                                <option value={''}>Selecione.</option>
                                <option value={'GEO'} key={'GEO'}>
                                  GEO
                                </option>
                                <option value={'FORNECEDOR'} key={'FORNECEDOR'}>
                                  FORNECEDOR
                                </option>
                              </select>
                              <span className="icon is-small is-left">
                                <i className="fa-solid fa-users-between-lines"></i>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Local de entrega
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            type="text"
                            placeholder=""
                            required
                            value={localEntrega}
                            onChange={(e) => setLocalEntrega(e.target.value)}
                            className="input"
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-map-location-dot"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Documento referência
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            type="text"
                            placeholder=""
                            value={docRef}
                            onChange={(e) => setDocRef(e.target.value)}
                            className="input"
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-file-circle-check"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Tipo de compra
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <p className="control is-expanded has-icons-left">
                              <select
                                name="tipo_compra"
                                required
                                value={tipoCompra}
                                onChange={(e) => setTipoCompra(e.target.value)}
                              >
                                <option value={''}>Selecione.</option>
                                <option value={'TOTAL'} key={'cTOTAL'}>
                                  TOTAL
                                </option>
                                <option value={'PARCIAL'} key={'cPARCIAL'}>
                                  PARCIAL
                                </option>
                              </select>
                              <span className="icon is-small is-left">
                                <i className="fa-solid fa-cart-arrow-down"></i>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Fornecimento exclusivo
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <p className="control is-expanded has-icons-left">
                              <select
                                required
                                name="fornecimentoExclusivo"
                                value={fornecimentoExclusivo}
                                onChange={(e) =>
                                  setFornecimentoExclusivo(e.target.value)
                                }
                              >
                                <option value={''}>Selecione.</option>
                                <option value={'NÃO'} key={'NÃO'}>
                                  NÃO
                                </option>
                                <option value={'SIM'} key={'SIM'}>
                                  SIM
                                </option>
                              </select>
                              <span className="icon is-small is-left">
                                <i className="fa-solid fa-truck-ramp-box"></i>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {fornecimentoExclusivo === 'SIM' && (
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          Justificativa de fornecimento exclusivo
                          <span className="required-symbol"> *</span>
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <p className="control is-expanded has-icons-left">
                            <input
                              type="text"
                              placeholder=""
                              value={justFornecimentoExc}
                              onChange={(e) =>
                                setJustFornecimentoExc(e.target.value)
                              }
                              className="input"
                              required
                            />
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-comments"></i>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Frete incluso
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <p className="control is-expanded has-icons-left">
                              <select
                                required
                                name="frete"
                                value={freteIncluso}
                                onChange={(e) =>
                                  setFreteIncluso(e.target.value)
                                }
                              >
                                <option value={''}>Selecione.</option>
                                <option value={'NÃO'} key={'NÃO'}>
                                  NÃO
                                </option>
                                <option value={'SIM'} key={'SIM'}>
                                  SIM
                                </option>
                              </select>
                              <span className="icon is-small is-left">
                                <i className="fa-solid fa-truck-fast"></i>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Compra por pacote
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <p className="control is-expanded has-icons-left">
                              <select
                                required
                                name="compraPacote"
                                value={compraPacote}
                                onChange={(e) =>
                                  setCompraPacote(e.target.value)
                                }
                              >
                                <option value={''}>Selecione.</option>
                                <option value={'NÃO'} key={'NÃO'}>
                                  NÃO
                                </option>
                                <option value={'SIM'} key={'SIM'}>
                                  SIM
                                </option>
                              </select>
                              <span className="icon is-small is-left">
                                <i className="fa-solid fa-tags"></i>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Link da proposta
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            type="text"
                            placeholder=""
                            value={proposta}
                            onChange={(e) => setProposta(e.target.value)}
                            className="input"
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-link"></i>
                          </span>
                        </p>
                        <p className="help">Hospedado no Sharepoint</p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Observações</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <textarea
                            className="input"
                            rows="4"
                            style={{
                              height: '100px',
                            }}
                            value={observacoes}
                            onChange={(e) => setObservacoes(e.target.value)}
                          ></textarea>
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-comment"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Informações dos items
                  </p>
                </header>
                <div className="card-content">
                  <div className="b-table has-pagination">
                    <div className="table-wrapper has-mobile-cards">
                      <div className="TableItems">
                        <TableContainer
                          component={Paper}
                          style={{
                            border: '1px solid lightgrey',
                            borderRadius: '5px',
                          }}
                        >
                          <Table aria-label="collapsible table">
                            <TableHead>
                              <TableRow
                                style={{
                                  display: 'flex',
                                  width: '100%',
                                }}
                              >
                                <TableCell
                                  style={{
                                    flex: '0.5',
                                    color: '#171717',
                                    fontWeight: 'bold',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  SAP
                                </TableCell>
                                <TableCell
                                  style={{
                                    flex: '0.6',
                                    color: '#171717',
                                    fontWeight: 'bold',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  REF
                                </TableCell>
                                <TableCell
                                  style={{
                                    flex: '0.6',
                                    color: '#171717',
                                    fontWeight: 'bold',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  VALOR REF [R$]
                                </TableCell>
                                <TableCell
                                  style={{
                                    flex: '4',
                                    color: '#171717',
                                    fontWeight: 'bold',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  ITEM
                                </TableCell>
                                <TableCell
                                  style={{
                                    flex: '0.5',
                                    color: '#171717',
                                    fontWeight: 'bold',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  QTD
                                </TableCell>
                                <TableCell
                                  style={{
                                    flex: '0.3',
                                    color: '#171717',
                                    fontWeight: 'bold',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  UN
                                </TableCell>
                                <TableCell
                                  style={{
                                    flex: '1',
                                    color: '#171717',
                                    fontWeight: 'bold',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  PREÇO [R$]
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {listaItens &&
                                listaItens.map((item, i) => (
                                  <React.Fragment key={i}>
                                    <TableRow
                                      key={i}
                                      // className={`is-size-7 ${linha.ativa ? '' : 'has-background-danger-light'} ${changedRows.includes(i) && linha.ativa ? 'changed-row': ''}`}
                                      className="is-size-7"
                                      style={{
                                        display: 'flex',
                                        width: '100%',
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          flex: '0.5',
                                          fontWeight: '400',
                                          border: '0px',
                                          fontFamily: '"Nunito", sans-serif',
                                        }}
                                        align="right"
                                      >
                                        {item.sap_id}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          flex: '0.6',
                                          fontWeight: '400',
                                          border: '0px',
                                          fontFamily: '"Nunito", sans-serif',
                                        }}
                                        align="right"
                                      >
                                        <input
                                          className="input is-small"
                                          type="text"
                                          value={item.capex_linha_ref}
                                          onChange={(e) =>
                                            handleREFChange(i, e.target.value)
                                          }
                                          // style={{width: 60}}
                                        />
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          flex: '0.6',
                                          fontWeight: '400',
                                          border: '0px',
                                          fontFamily: '"Nunito", sans-serif',
                                        }}
                                        align="right"
                                      >
                                        <input
                                          className="input is-small"
                                          type="number"
                                          value={item.capex_linha_valor_ref}
                                          step={'0.01'}
                                          // style={{width: 60}}
                                          onChange={(e) =>
                                            handleREFValChange(
                                              i,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          flex: '4',
                                          fontWeight: '400',
                                          border: '0px',
                                          fontFamily: '"Nunito", sans-serif',
                                        }}
                                        align="right"
                                      >
                                        {item.descl}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          flex: '0.5',
                                          fontWeight: '400',
                                          border: '0px',
                                          fontFamily: '"Nunito", sans-serif',
                                        }}
                                        align="right"
                                      >
                                        {item.quantidade}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          flex: '0.3',
                                          fontWeight: '400',
                                          border: '0px',
                                          fontFamily: '"Nunito", sans-serif',
                                        }}
                                        align="right"
                                      >
                                        {item.unidade}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          flex: '1',
                                          fontWeight: '400',
                                          border: '0px',
                                          fontFamily: '"Nunito", sans-serif',
                                        }}
                                        align="right"
                                      >
                                        <input
                                          className="input is-small"
                                          type="number"
                                          // value={Number((item.preco).toFixed(2))}
                                          value={item.preco}
                                          step="0.01"
                                          // style={{width: 60}}
                                          onChange={(e) =>
                                            handlePrecoChange(i, e.target.value)
                                          }
                                        />
                                      </TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '20px',
                          }}
                        >
                          <button className="button is-primary" type="submit">
                            Solicitar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default SolicitacaoRCFormA;
