import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import AlertMessage from '../../layouts/AlertMessage';

import FormModal from '../../layouts/FormModal';
import AdminRoleEdit from './AdminRoleEdit';

const AdminRoleTable = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [allRoles, setAllRoles] = useState([]);
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleOpenModal = (roleId) => {
    setSelectedRoleId(roleId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedRoleId(null);
    setModalOpen(false);
  };

  const getRoles = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/roles/`,
        { headers }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAllRoles(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar Roles'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleEditRole = (roleId) => {
    handleOpenModal(roleId);
  };

  const roleTableColumns = [
    { field: 'nome', headerName: 'Role', flex: 3 },
    { field: 'descricao', headerName: 'Descrição', flex: 5 },
    {
      field: 'politicas',
      headerName: 'Políticas',
      renderCell: (params) => (
        <div>
          {params.value.split(' ').map((politica, index) => {
            const className = politica.endsWith('allow')
              ? 'tag is-success'
              : 'tag is-danger';
            return (
              <span key={index} className={className}>
                {politica}
              </span>
            );
          })}
        </div>
      ),
      flex: 6,
    },
    {
      field: 'acao',
      headerName: 'Editar',
      width: 80,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>
          <IconButton
            onClick={() => {
              handleEditRole(params.row.id);
            }}
            color="black"
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <>
      <AlertMessage
        open={alertOpen}
        handleClose={handleClose}
        severity={alertSeverity}
        message={alertMessage}
      />
      <div className="card">
        <header className="card-header">
          <p
            className="card-header-title"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <span className="icon">
                <i className="mdi mdi-ballot"></i>
              </span>
              Roles
            </div>

            <div>
              <button
                className="button is-primary"
                type="button"
                onClick={() => navigate('/admin/rolesform/')}
              >
                Novo Role
              </button>
            </div>
          </p>
        </header>
        <div
          className="card-content"
          style={{ display: 'flex', margin: '0px 0px 30px 0px' }}
        >
          <DataGrid
            rows={allRoles}
            columns={roleTableColumns}
            pageSize={5}
            disableSelectionOnClick
            getRowHeight={() => 'auto'}
            getColumnHeight={() => 'auto'}
            sx={{
              '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle': {
                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                fontSize: '16px',
                color: '#171717',
                // fontFamily: '"Nunito", sans-serif',
                lineHeight: '2.5',
                borderLeft: '5px solid transparent',
              },
            }}
          />
        </div>
      </div>
      <FormModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        modalTitle={'Editar Role'}
      >
        {isModalOpen && selectedRoleId && (
          <AdminRoleEdit roleId={selectedRoleId} info={info} />
        )}
      </FormModal>
    </>
  );
};

export default AdminRoleTable;
