import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import SelectMui from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import './Kanban.css'; // Arquivo de estilos
import { DateTime, Duration, Interval } from 'luxon';
import Tooltip from '@mui/material/Tooltip';
import FormModal from '../../layouts/FormModal';
import ModalCardKanban from './ModalCardKanban';
import AlertMessage from '../../layouts/AlertMessage';
import ModalFiltroKanban from './ModalFiltroKanban';
import BoardScrum from './BoardScrum';
import TarefasConcluidas from './TarefasConcluidas';

const Card = ({
  cardObj,
  style,
  backColor,
  info,
  setModalOpen,
  setCardObj,
  setModalCardBackgroundColor,
}) => {
  return (
    <div
      style={{ zIndex: 100 }}
      onClick={(e) => {
        e.stopPropagation();
        setCardObj(cardObj);
        setModalCardBackgroundColor(backColor);
        setModalOpen(true);
      }}
    >
      <div
        className="card-i"
        style={{
          ...style,
          top: 'auto', // Definindo top: 0 apenas quando expandido
          left: 'auto',
          maxHeight: '60px',
          maxWidth: '307px',
          transition: 'max-height 0.7s ease, top 0.7s ease, left 0.7s ease',
          position: 'relative',
          zIndex: 10,
        }}
      >
        <Tooltip
          placement="right-end"
          componentsProps={{ tooltip: { sx: { maxWidth: '35rem' } } }}
          arrow
          title={
            <div style={{ textAlign: 'justify', fontSize: '15px' }}>
              <b>{cardObj.conteudo.titulo.toUpperCase()}</b>{' '}
              <p>
                Data limite:{' '}
                {new Date(cardObj.tarefa.data_due).toLocaleString()}
              </p>
              <br></br>
            </div>
          }
        >
          <div style={{ display: 'flex', width: '100%' }}>
            <p
              className="tag"
              style={{
                marginRight: '4px',
                backgroundColor: backColor,
              }}
            >
              {!cardObj.tarefa.link_bw
                ? cardObj.tarefa.status === 'REVISAO'
                  ? 'R'
                  : 'E'
                : 'A'}
            </p>
            <div className={`label-field-title collapsed-card`}>
              {cardObj.conteudo.titulo.toUpperCase()}
            </div>
            {cardObj.diasExcedentes != 0 && (
              <p
                className="tag"
                style={{
                  marginLeft: 'auto',
                  backgroundColor: backColor,
                }}
              >
                {cardObj.diasExcedentes}
              </p>
            )}
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

const Column = ({
  name,
  cards,
  info,
  setModalOpen,
  setCardObj,
  setModalCardBackgroundColor,
}) => {
  return (
    <div className="column column-kanban">
      <div
        style={{
          display: 'flex',
          marginBottom: '20px',
          alignItems: 'center',
          flexFlow: 'column',
          flexDirection: 'column-reverse',
        }}
      >
        <ButtonGroup aria-label="outlined is-primary button group">
          <Button
            style={{
              color: 'rgb(92, 92, 92)',
              backgroundColor: 'rgba(0, 155, 100, 0.56)',
              padding: '0px 40px',
            }}
            disabled
          >
            {cards.cards_green.length}
          </Button>
          <Button
            style={{
              color: 'rgb(92, 92, 92)',
              backgroundColor: 'rgb(247, 252, 142)',
              padding: '0px 40px',
            }}
            disabled
          >
            {cards.cards_yellow.length}
          </Button>
          <Button
            style={{
              color: 'rgb(92, 92, 92)',
              backgroundColor: 'rgb(255, 168, 168)',
              padding: '0px 40px',
            }}
            disabled
          >
            {cards.cards_red.length}
          </Button>
        </ButtonGroup>
        <label
          style={{
            fontSize: '22px',
            boxSizing: 'border-box',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingTop: '15px',
            maxWidth: '340px',
          }}
        >
          {name}
        </label>
      </div>

      <div
        className="cards-container"
        style={{ backgroundColor: 'rgba(0, 155, 100, 0.56)' }}
      >
        {cards.cards_green.map((card, index) => (
          <div className="card-container-i">
            <Card
              key={parseInt(`1${index}`)}
              cardObj={card}
              style={card.style}
              backColor={'rgba(0, 155, 100, 0.56)'}
              info={info}
              setModalOpen={setModalOpen}
              setCardObj={setCardObj}
              setModalCardBackgroundColor={setModalCardBackgroundColor}
            />
          </div>
        ))}
        {cards.cards_green.length < 5 &&
          Array.from({ length: 5 - cards.cards_green.length }).map(
            (_, index) => <div className="card-container-i"></div>
          )}
      </div>

      <div
        className="cards-container"
        style={{ backgroundColor: 'rgb(247 252 142)' }}
      >
        {cards.cards_yellow.map((card, index) => (
          <div className="card-container-i">
            <Card
              key={parseInt(`2${index}`)}
              cardObj={card}
              style={card.style}
              backColor={'rgb(247 252 142)'}
              info={info}
              setModalOpen={setModalOpen}
              setCardObj={setCardObj}
              setModalCardBackgroundColor={setModalCardBackgroundColor}
            />
          </div>
        ))}
        {cards.cards_yellow.length < 5 &&
          Array.from({ length: 5 - cards.cards_yellow.length }).map(
            (_, index) => <div className="card-container-i"></div>
          )}
      </div>

      <div className="cards-container" style={{ backgroundColor: '#ffa8a8' }}>
        {cards.cards_red.map((card, index) => (
          <div className="card-container-i">
            <Card
              key={parseInt(`3${index}`)}
              cardObj={card}
              style={card.style}
              backColor={'#ffa8a8'}
              info={info}
              setModalOpen={setModalOpen}
              setCardObj={setCardObj}
              setModalCardBackgroundColor={setModalCardBackgroundColor}
            />
          </div>
        ))}
        {cards.cards_red.length < 5 &&
          Array.from({ length: 5 - cards.cards_red.length }).map((_, index) => (
            <div className="card-container-i"></div>
          ))}
      </div>
    </div>
  );
};

const KanbanPlanejamento = () => {
  const navigate = useNavigate();
  const [listaTarefas, setListaTarefas] = useState([]); // Estado para armazenar os dados da fila
  const [activeButton, setActiveButton] = useState('');
  const [nomeResponsavel, setNomeResponsavel] = useState('');
  const [activeProject, setActiveProject] = useState(null);
  const [activeEmpreendimento, setActiveEmpreendimento] = useState(null);
  const [empreendimentos, setEmpreendimentos] = useState([]);
  const [disciplinas, setDisciplinas] = useState([]);
  const [usersIDList, setUsersIDList] = useState([]);
  const [listaProjetosPorEmpreendimento, setListaProjetosPorEmpreendimento] =
    useState([]);
  const [selectedDisciplinas, setSelectedDisciplinas] = useState([]);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [cardObj, setCardObj] = useState(null);
  const [modalCardBackgroundColor, setModalCardBackgroundColor] =
    useState('#000');
  const [tarefas, setTarefas] = useState([]);
  const [tarefasConcluidas, setTarefasConcluidas] = useState([]);

  // Expected: Kanban or Scrum
  const [visionType, setVisionType] = useState('Kanban');

  const [showFilters, setShowFilters] = useState(false);

  const [periodStart, setPeriodStart] = useState(null);

  const lista_feriados_geo = {
    '2024-04-21': 'Tiradentes',
    '2024-05-01': 'Dia do Trabalho',
    '2024-05-30': 'Corpus Christi',
    '2024-05-31': 'Sagrado Coração de Jesus',
    '2024-07-09': 'Revolução Constitucionalista',
    '2024-09-07': 'Independência do Brasil',
    '2024-10-12': 'Nossa Senhora Aparecida',
    '2024-11-02': 'Finados',
    '2024-11-15': 'Proclamação da República',
    '2024-11-20': 'Consciência Negra',
    '2024-12-10': 'Aniversário de Londrina',
    '2024-12-25': 'Natal',
  };

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const { REACT_APP_BASE_API_URL } = process.env;
  const headers = {
    'Content-Type': 'application/json',
  };

  useEffect(() => {
    const getEmpreendimentos = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/adm/empreendimento/`,
          { headers }
        );

        if (response.status === 200) {
          let emps = response.data.empreendimentos.map((emp, index) => ({
            value: emp,
            label: emp.tag,
          }));

          emps = [{ value: null, label: 'TODOS' }, ...emps];
          setEmpreendimentos(emps);
          let emps_ids = emps
            .map((emp) => emp.value?.id)
            .filter((id) => id !== undefined);
          getProjetosByEmpreendimento(emps_ids);
        }
      } catch (error) {
        console.error(error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail ||
                'Erro ao carregar os empreendimentos'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    const getProjetosByEmpreendimento = async (empreendimentos_id) => {
      let projetosPorEmpreendimento = {};

      for (const empreendimento_id of empreendimentos_id) {
        try {
          const response = await axios.get(
            `${REACT_APP_BASE_API_URL}/adm/emp/detail/`,
            {
              params: {
                empreendimento: empreendimento_id,
              },
              headers: headers,
            }
          );
          projetosPorEmpreendimento[empreendimento_id] = response.data.p;
        } catch (error) {
          console.error(error);
          setAlertMessage(
            Array.isArray(error.response?.data?.detail)
              ? JSON.stringify(error.response?.data?.detail, null, 2)
              : error.response?.data?.detail || 'Erro ao carregar os projetos'
          );
          setAlertSeverity('error');
          setAlertOpen(true);
        }
      }

      const transformedProjetos = Object.entries(
        projetosPorEmpreendimento
      ).reduce((acc, [empreendimentoId, projetos]) => {
        const formattedProjetos = projetos.map((projeto) => ({
          value: projeto,
          label: `${projeto.tag} - ${projeto.titulo}`,
        }));

        //Sort formattedProjetos by label
        formattedProjetos.sort((a, b) => a.label.localeCompare(b.label));

        acc[empreendimentoId] = formattedProjetos; // or formattedProjetos[0] if you only want the first
        return acc;
      }, {});

      setListaProjetosPorEmpreendimento(transformedProjetos);
    };

    const getDisciplinas = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/admin/disciplinas/`,
          { headers }
        );

        if (response.status === 200) {
          let list_disc = response.data
            .filter((disc) => disc.disciplina.toLowerCase() !== 'outros')
            .map((disc, index) => ({
              value: disc.disciplina,
              label: disc.disciplina,
            }));

          // list_disc = [ { value: null, label: 'TODOS'}, ...list_disc]
          setDisciplinas(list_disc);
        }
      } catch (error) {
        console.error(error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao carregar as disciplinas'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    const fetchFilaTarefas = async () => {
      try {
        let params = null;

        // if (activeButton) {
        //   params = { disciplina: activeButton.toLowerCase()};
        // }

        if (activeProject && activeProject.id) {
          params = { ...params, projeto_id: activeProject.id };
        }

        const response = await axios.get(`${REACT_APP_BASE_API_URL}/kanban/`, {
          params,
          headers,
        });

        // Inicializa um objeto para armazenar as colunas
        const columnsData = {};

        if (response.status === 200) {
          response.data.sort((a, b) => {
            const tituloComparison = a.titulo.localeCompare(b.titulo);
            if (tituloComparison !== 0) {
              return tituloComparison;
            }
            const conteudoA = JSON.parse(a.conteudo);
            const conteudoB = JSON.parse(b.conteudo);
            return conteudoA.user_nome.localeCompare(conteudoB.user_nome);
          });

          if (nomeResponsavel) {
            response.data = response.data.filter((tarefa) => {
              const conteudo = JSON.parse(tarefa.conteudo);
              return conteudo.user_nome
                .toLowerCase()
                .startsWith(nomeResponsavel.toLowerCase());
            });
          }

          setTarefas(JSON.parse(JSON.stringify(response.data)));
          setTarefasConcluidas(
            response.data.filter((tarefa) => tarefa.status === 'CONCLUIDA')
          );
          // Processa os dados recebidos para construir a estrutura de columns
          response.data.forEach((tarefa) => {
            if (
              tarefa.status === 'PREDECESSORA' ||
              tarefa.status === 'CONCLUIDA'
            )
              return;
            const conteudo = JSON.parse(tarefa.conteudo);

            if (!columnsData[tarefa.responsavel_id]) {
              columnsData[tarefa.responsavel_id] = {
                name: conteudo.user_nome,
                cards_green: [],
                cards_yellow: [],
                cards_red: [],
              };
            }

            // Adicionar os cartões à categoria correta
            let card = {
              tarefa: tarefa,
              conteudo: conteudo,
              style: {},
            };
            let inicio = DateTime.fromISO(tarefa.data_inicio_efetivo);

            if (tarefa.link_bw) {
              inicio = DateTime.fromISO(conteudo.data_emissao); //alterar para receber o valor de data_emissao
            }

            let fim = DateTime.now();

            let horasUteis = 0;
            let totalHoras = Number(conteudo.tempo_aprov);

            // Itera do início até o fim, dia por dia
            let atual = inicio;
            while (atual < fim) {
              // Se for um dia útil (segunda a sexta)
              if (atual.weekday < 6) {
                // Define o horário de início e fim do dia útil
                const inicioDiaUtil = atual.set({
                  hour: 8,
                  minute: 0,
                  second: 0,
                });
                const fimDiaUtil = atual.set({
                  hour: 18,
                  minute: 0,
                  second: 0,
                });

                // Define o horário de almoço
                const inicioAlmoco = atual.set({
                  hour: 12,
                  minute: 0,
                  second: 0,
                });
                const fimAlmoco = atual.set({
                  hour: 13,
                  minute: 12,
                  second: 0,
                });

                // Calcula o intervalo de horas úteis do dia, excluindo o horário de almoço
                const intervaloUtilManha = Interval.fromDateTimes(
                  inicioDiaUtil,
                  inicioAlmoco
                );
                const intervaloUtilTarde = Interval.fromDateTimes(
                  fimAlmoco,
                  fimDiaUtil
                );

                // Verifica as interseções entre o intervalo útil do dia (manhã) e o intervalo entre inicio e fim
                const intersecaoManha = intervaloUtilManha.intersection(
                  Interval.fromDateTimes(inicio, fim)
                );
                if (intersecaoManha) {
                  horasUteis += intersecaoManha.length('hours');
                }

                // Verifica as interseções entre o intervalo útil do dia (tarde) e o intervalo entre inicio e fim
                const intersecaoTarde = intervaloUtilTarde.intersection(
                  Interval.fromDateTimes(inicio, fim)
                );
                if (intersecaoTarde) {
                  horasUteis += intersecaoTarde.length('hours');
                }
              }

              // Avança para o próximo dia
              atual = atual.plus({ days: 1 }).startOf('day');
            }

            let diasExcedentes = 0;
            let horasExcedentes = 0;

            card.diasExcedentes = diasExcedentes;

            // Verifica a porcentagem do andamento do projeto
            if (horasUteis <= (totalHoras * 60) / 100) {
              columnsData[tarefa.responsavel_id].cards_green.push(card);
            } else if (
              horasUteis > (totalHoras * 60) / 100 &&
              horasUteis <= (totalHoras * 80) / 100
            ) {
              columnsData[tarefa.responsavel_id].cards_yellow.push(card);
            } else if (horasUteis > (totalHoras * 80) / 100) {
              // Se as horas do projeto ultrapassou as horas planejadas
              if (horasUteis > totalHoras) {
                horasExcedentes = horasUteis - totalHoras;

                // Converte horasExcedentes para minutos e depois para dias
                const minutosPorDia = 8 * 60 + 48;
                const minutosExcedentes = horasExcedentes * 60; // Convertendo horas para minutos
                diasExcedentes = Math.floor(minutosExcedentes / minutosPorDia);
                card.diasExcedentes = diasExcedentes;
              }
              columnsData[tarefa.responsavel_id].cards_red.push(card);
            }
          });
        }

        params = { setor: 'Engenharia' };
        const resposta = await axios.get(
          `${REACT_APP_BASE_API_URL}/users/setor/`,
          {
            params,
            headers,
          }
        );

        if (resposta.status === 200) {
          resposta.data.users.forEach((user) => {
            if (!columnsData[user.id]) {
              columnsData[user.id] = {
                name: `${user.nome} ${user.sobrenome}`,
                cards_green: [],
                cards_yellow: [],
                cards_red: [],
                diasExcedentes: 0,
              };
            }
          });
        }

        // Transformar o objeto columnsData em um array
        const columns = Object.values(columnsData);
        columns.sort((a, b) => a.name.localeCompare(b.name));

        setListaTarefas(columns);
      } catch (error) {
        console.error(error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail ||
                'Erro ao carregar a fila de tarefas'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    getDisciplinas();
    getEmpreendimentos();
    fetchFilaTarefas();
  }, [REACT_APP_BASE_API_URL]);

  const downloadRelatorio = async (e) => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/kanban/exporta-relatorio/`,
        {
          headers: {
            'Content-Type': 'text/csv; charset=utf-8',
          },
          responseType: 'blob', // Importante para tratar o retorno como um arquivo binário
        }
      );

      if (response.status === 200) {
        // Cria uma URL para o blob retornado
        if ('showSaveFilePicker' in window) {
          const fileHandle = await window.showSaveFilePicker({
            suggestedName: 'relatorio-kanban.csv',
            types: [
              {
                description: 'CSV Files',
                accept: { 'text/csv': ['.csv'] },
              },
            ],
          });
          const writableStream = await fileHandle.createWritable();
          await writableStream.write(response.data);
          await writableStream.close();
        } else {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: 'text/csv; charset=utf-8',
            })
          );
          const a = document.createElement('a');
          a.href = url;
          a.download = 'relatorio-kanban.csv'; // Nome do arquivo que será baixado
          document.body.appendChild(a);
          a.click();
          a.remove();

          setAlertMessage('Relatório criado e baixado com sucesso!');
          setAlertSeverity('success');
          setAlertOpen(true);
        }
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao baixar o relatório'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  function addWorkdays(periodStart, daysToAdd) {
    let currentDay = periodStart; // periodStart is already a DateTime object
    let addedDays = 0;

    while (addedDays < daysToAdd) {
      currentDay = currentDay.plus({ days: 1 }); // Move to the next day

      // Check if the current day is a weekday (Monday to Friday) and not a holiday
      const currentDateStr = currentDay.toISODate();
      if (
        currentDay.weekday >= 1 &&
        currentDay.weekday <= 5 && // Weekday check (Mon-Fri)
        !lista_feriados_geo[currentDateStr] // Not a holiday
      ) {
        addedDays++;
      }
    }

    return currentDay; // Return the final date after adding 10 working days
  }

  const handleButtonClick = async (
    empreendimentoAtual,
    projetoAtual,
    disciplinasAtuais,
    nomeResponsavel,
    periodStart
  ) => {
    try {
      let params = null;

      if (empreendimentoAtual && empreendimentoAtual.id) {
        params = { empreendimento_id: empreendimentoAtual.id };
      }

      if (projetoAtual && projetoAtual.id) {
        params = { ...params, projeto_id: projetoAtual.id };
      }

      if (disciplinasAtuais && disciplinasAtuais !== '') {
        params = {
          ...params,
          disciplina: disciplinasAtuais.toLowerCase(),
        };
      }

      const response = await axios.get(`${REACT_APP_BASE_API_URL}/kanban/`, {
        params,
        headers,
      });

      // Inicializa um objeto para armazenar as colunas
      const columnsData = {};

      if (response.status === 200) {
        response.data.sort((a, b) => {
          const tituloComparison = a.titulo.localeCompare(b.titulo);
          if (tituloComparison !== 0) {
            return tituloComparison;
          }
          const conteudoA = JSON.parse(a.conteudo);
          const conteudoB = JSON.parse(b.conteudo);
          return conteudoA.user_nome.localeCompare(conteudoB.user_nome);
        });

        if (nomeResponsavel) {
          response.data = response.data.filter((tarefa) => {
            const conteudo = JSON.parse(tarefa.conteudo);
            return conteudo.user_nome
              .toLowerCase()
              .startsWith(nomeResponsavel.toLowerCase());
          });
        }

        setTarefas(JSON.parse(JSON.stringify(response.data)));
        setTarefasConcluidas(
          response.data.filter((tarefa) => tarefa.status === 'CONCLUIDA')
        );
        // Processar os dados recebidos para construir a estrutura de columns
        response.data.forEach((tarefa) => {
          if (tarefa.status === 'PREDECESSORA' || tarefa.status === 'CONCLUIDA')
            return;

          const conteudo = JSON.parse(tarefa.conteudo);

          if (projetoAtual) {
            // if projetoAtual.value.tag not in conteudo.tag continue
            if (!conteudo.tag.includes(projetoAtual.value.tag)) {
              return;
            }
          }

          if (!columnsData[tarefa.responsavel_id]) {
            columnsData[tarefa.responsavel_id] = {
              name: conteudo.user_nome,
              cards_green: [],
              cards_yellow: [],
              cards_red: [],
            };
          }

          // Adicionar os cartões à categoria correta
          const card = {
            tarefa: tarefa,
            conteudo: conteudo,
            style: {},
          };
          let inicio = DateTime.fromISO(tarefa.data_inicio_efetivo);

          if (tarefa.link_bw) {
            inicio = DateTime.fromISO(conteudo.data_emissao); //alterar para receber o valor de data_emissao
          }

          let fim = DateTime.now();

          let horasUteis = 0;
          let totalHoras = Number(conteudo.tempo_aprov);

          // Itera do início até o fim, dia por dia
          let atual = inicio;
          while (atual < fim) {
            // Se for um dia útil (segunda a sexta)
            if (atual.weekday < 6) {
              // Define o horário de início e fim do dia útil
              const inicioDiaUtil = atual.set({
                hour: 8,
                minute: 0,
                second: 0,
              });
              const fimDiaUtil = atual.set({
                hour: 18,
                minute: 0,
                second: 0,
              });

              // Define o horário de almoço
              const inicioAlmoco = atual.set({
                hour: 12,
                minute: 0,
                second: 0,
              });
              const fimAlmoco = atual.set({
                hour: 13,
                minute: 12,
                second: 0,
              });

              // Calcula o intervalo de horas úteis do dia, excluindo o horário de almoço
              const intervaloUtilManha = Interval.fromDateTimes(
                inicioDiaUtil,
                inicioAlmoco
              );
              const intervaloUtilTarde = Interval.fromDateTimes(
                fimAlmoco,
                fimDiaUtil
              );

              // Verifica as interseções entre o intervalo útil do dia (manhã) e o intervalo entre inicio e fim
              const intersecaoManha = intervaloUtilManha.intersection(
                Interval.fromDateTimes(inicio, fim)
              );
              if (intersecaoManha) {
                horasUteis += intersecaoManha.length('hours');
              }

              // Verifica as interseções entre o intervalo útil do dia (tarde) e o intervalo entre inicio e fim
              const intersecaoTarde = intervaloUtilTarde.intersection(
                Interval.fromDateTimes(inicio, fim)
              );
              if (intersecaoTarde) {
                horasUteis += intersecaoTarde.length('hours');
              }
            }

            // Avança para o próximo dia
            atual = atual.plus({ days: 1 }).startOf('day');
          }

          let diasExcedentes = 0;
          let horasExcedentes = 0;

          card.diasExcedentes = diasExcedentes;

          // Verde para 0-60% do tempo, amarelo para 60-80% e vermelho para 80-100%
          if (horasUteis <= (totalHoras * 60) / 100) {
            // If tarefa.date_due > periodStart + 10 days, continue
            if (periodStart) {
              const due = DateTime.fromISO(tarefa.data_due);
              const period = DateTime.fromISO(periodStart);
              if (due > addWorkdays(period, 10)) {
                return;
              }
            }
            columnsData[tarefa.responsavel_id].cards_green.push(card);
          } else if (
            horasUteis > (totalHoras * 60) / 100 &&
            horasUteis <= (totalHoras * 80) / 100
          ) {
            columnsData[tarefa.responsavel_id].cards_yellow.push(card);
            if (periodStart) {
              const due = DateTime.fromISO(tarefa.data_due);
              const period = DateTime.fromISO(periodStart);
              if (due > addWorkdays(period, 10)) {
                return;
              }
            }
          } else if (horasUteis > (totalHoras * 80) / 100) {
            // Se as horas do projeto ultrapassou as horas planejadas
            if (horasUteis > totalHoras) {
              horasExcedentes = horasUteis - totalHoras;

              // Converte horasExcedentes para minutos e depois para dias
              const minutosPorDia = 8 * 60 + 48;
              const minutosExcedentes = horasExcedentes * 60; // Convertendo horas para minutos
              diasExcedentes = Math.floor(minutosExcedentes / minutosPorDia);
              card.diasExcedentes = diasExcedentes;
            }

            columnsData[tarefa.responsavel_id].cards_red.push(card);
          }
        });
      }

      if (!activeProject) {
        params = {
          setor: 'Engenharia',
          disciplinas:
            disciplinasAtuais.length > 0
              ? disciplinasAtuais
                  .split(',')
                  .map((disc) => disc.toLowerCase())
                  .join(',')
              : null,
        };
        const resposta = await axios.get(
          `${REACT_APP_BASE_API_URL}/users/setor/`,
          {
            params,
            headers,
          }
        );

        if (resposta.status === 200) {
          resposta.data.users.forEach((user) => {
            if (!columnsData[user.id]) {
              columnsData[user.id] = {
                name: `${user.nome} ${user.sobrenome}`,
                cards_green: [],
                cards_yellow: [],
                cards_red: [],
              };
            }
          });
        }
      }

      // Transformar o objeto columnsData em um array
      let columns = Object.values(columnsData);
      if (nomeResponsavel) {
        columns = columns.filter((col) =>
          col.name.toLowerCase().startsWith(nomeResponsavel.toLowerCase())
        );
      }
      columns.sort((a, b) => a.name.localeCompare(b.name));
      setListaTarefas(columns);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao carregar a fila de tarefas'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  const filtersInfo = {
    showFilters,
    setShowFilters,
    empreendimentos,
    listaProjetosPorEmpreendimento,
    disciplinas,
    handleButtonClick,
    setActiveEmpreendimento,
    setActiveProject,
    setNomeResponsavel,
    activeEmpreendimento,
    activeProject,
    selectedDisciplinas,
    setSelectedDisciplinas,
    activeButton,
    setActiveButton,
    nomeResponsavel,
    setPeriodStart,
    periodStart,
  };

  const renderFilters = (showFilters, filtersInfo, visionType) => {
    if (!showFilters) return null;

    return <ModalFiltroKanban filtersInfo={filtersInfo} />;
  };

  const renderModalContent = (isModalOpen, info, cardObj) => {
    return isModalOpen ? (
      <ModalCardKanban cardObj={cardObj} info={info} />
    ) : null;
  };

  return (
    <>
      <AlertMessage
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Planejamento</li>
                    <li>Tarefas</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">{visionType}</h1>
                  </div>
                </div>

                <div className="level-right">
                  <div
                    style={{
                      margin: '10px',
                      padding: '8px',
                      backgroundColor: '#bfbfbf',
                      borderRadius: '5px',
                    }}
                  >
                    <span>Alterar visão das tarefas:</span>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      margin: '10px',
                    }}
                  >
                    <button
                      className="button is-primary"
                      onClick={() => setVisionType('Kanban')}
                      disabled={visionType === 'Kanban'}
                    >
                      <span className="icon is-small">
                        <i className="fas fa-tasks"></i>
                      </span>
                      <span>Kanban</span>
                    </button>

                    <div
                      style={{
                        width: '1px',
                        height: '30px',
                        backgroundColor: '#ccc',
                      }}
                    ></div>

                    <button
                      className="button is-primary"
                      onClick={() => setVisionType('Scrum')}
                      disabled={visionType === 'Scrum'}
                    >
                      <span className="icon is-small">
                        <i className="fas fa-tasks"></i>
                      </span>
                      <span>Scrum</span>
                    </button>

                    {
                      <>
                        <div
                          style={{
                            width: '1px',
                            height: '30px',
                            backgroundColor: '#ccc',
                          }}
                        ></div>

                        <button
                          className="button is-primary"
                          onClick={() => setShowFilters(!showFilters)}
                        >
                          <span className="icon is-small">
                            <i className="fas fa-filter"></i>
                          </span>
                          <span>Filtros</span>
                        </button>
                        <FormModal
                          isOpen={showFilters}
                          onClose={(e) => {
                            e.stopPropagation();
                            setShowFilters(false);
                          }}
                          onOpen={renderFilters}
                          modalTitle={
                            visionType === 'Kanban'
                              ? 'Filtros das tarefas - Kanban'
                              : 'Filtros das tarefas - Scrum'
                          }
                          backgroundColorBody="#F8F8F8"
                          backgroundColorTitle="rgba(0, 155, 100, 0.56)"
                          width={500}
                        >
                          {renderFilters(showFilters, filtersInfo, visionType)}
                        </FormModal>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            {visionType === 'Kanban' && (
              <>
                <div
                  className="card"
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <button
                      className="button is-primary"
                      style={{ margin: '20px ' }}
                      onClick={(e) => downloadRelatorio(e)}
                    >
                      Baixar Relatório
                    </button>
                  </div>
                  <div
                    className="board-container "
                    style={{ marginTop: '0px' }}
                  >
                    <div className="board">
                      {listaTarefas.map((column, index) => (
                        <Column
                          key={index}
                          name={column.name}
                          cards={column}
                          info={info}
                          setModalOpen={setModalOpen}
                          setCardObj={setCardObj}
                          setModalCardBackgroundColor={
                            setModalCardBackgroundColor
                          }
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}
            {visionType === 'Scrum' && <BoardScrum tarefas={tarefas} />}
          </section>
          {visionType === 'Kanban' && (
            <>
              <section className="hero is-hero-bar">
                <div className="hero-body">
                  <div className="level">
                    <div className="level-left">
                      <div className="level-item">
                        <h1 className="title">
                          Tarefas concluídas (por disciplina)
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="section is-main-section">
                <div
                  className="card"
                  style={{
                    marginBottom: '0px',
                    border: '0px',
                  }}
                >
                  <div className="board-container ">
                    <div className="board">
                      <TarefasConcluidas
                        tarefas={tarefasConcluidas}
                        setModalOpen={setModalOpen}
                        setCardObj={setCardObj}
                        setModalCardBackgroundColor={
                          setModalCardBackgroundColor
                        }
                      />
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </div>
        {cardObj && (
          <FormModal
            isOpen={isModalOpen}
            onClose={(e) => {
              e.stopPropagation();
              setModalOpen(false);
              setCardObj(null);
            }}
            onOpen={renderModalContent}
            modalTitle={
              cardObj.tarefa.status === 'CONCLUIDA'
                ? 'Detalhes tarefa concluída'
                : !cardObj.tarefa.link_bw
                  ? cardObj.tarefa.status === 'REVISAO'
                    ? 'Revisar Documento'
                    : 'Emitir Documento'
                  : 'Aprovar Documento'
            }
            width={500}
            backgroundColorBody="#F8F8F8"
            backgroundColorTitle={
              modalCardBackgroundColor === 'rgba(0, 155, 100, 0.56)'
                ? 'rgba(0, 155, 100, 0.56)'
                : modalCardBackgroundColor === 'rgb(247 252 142)'
                  ? 'rgb(225 235 32)'
                  : 'rgb(238 105 105)'
            }
          >
            {renderModalContent(isModalOpen, info, cardObj)}
          </FormModal>
        )}
      </MainLayout>
    </>
  );
};

export default KanbanPlanejamento;
