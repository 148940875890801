import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ItemInclusionView from '../../functional/ItemInclusionView';
import FormModal from '../../layouts/FormModal';
import { useNavigate } from 'react-router-dom';

import './MIS.css';
import AlertMessage from '../../layouts/AlertMessage';

const MISForm = () => {
  const { REACT_APP_BASE_API_URL } = process.env;

  const [misDescricao, setMISDescricao] = useState('');
  const [misSigla, setMISSigla] = useState('');
  const [misArea, setMISArea] = useState('');
  const [misSubarea, setMISSubarea] = useState('');
  const [misMeio, setMISMeio] = useState('');
  const [misVariancia, setMISVariancia] = useState(0);
  const [misPreco, setMisPreco] = useState(0);

  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const [modalContent, setModalContent] = useState(null);

  const [misItemsList, setMISItemsList] = useState([]);
  const [requestMISItems, setRequestMISItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const onAddHandler = (obj) => {
    setMISItemsList((prevItems) => [...prevItems, obj]);
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setModalOpen(false);
  };

  const renderModalContent = (info) => {
    switch (modalContent) {
      case 'Materiais':
        return (
          <ItemInclusionView
            mSchema={'material'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'Equipamentos':
        return (
          <ItemInclusionView
            mSchema={'equip'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'Mão de obra':
        return (
          <ItemInclusionView
            mSchema={'servico'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'SINAPI: Composições':
        return (
          <ItemInclusionView
            mSchema={'sinapi'}
            mTarget={'composicao'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'Composições':
        return (
          <ItemInclusionView
            mSchema={'composicao'}
            mTarget={'composicao'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'SINAPI: Insumos':
        return (
          <ItemInclusionView
            mSchema={'sinapi'}
            mTarget={'insumo'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      default:
        return null;
    }
  };

  const handleRemoveItem = (indexToRemove) => {
    const itemToRemove = misItemsList[indexToRemove];
    const removedItemIndex = requestMISItems.findIndex(
      (item) => item.item_id === itemToRemove.id
    );
    if (removedItemIndex !== '-1') {
      const removedItem = requestMISItems[removedItemIndex];
      const removedItemPreco = removedItem.preco_und * removedItem.quantidade;

      const updatedList = [...misItemsList];
      updatedList.splice(indexToRemove, 1);
      setMISItemsList(updatedList);

      setSelectedRows((prevRows) =>
        prevRows.filter((index) => index !== indexToRemove)
      );

      const newRequestCompItems = [...requestMISItems];
      newRequestCompItems.splice(removedItemIndex, 1);
      setRequestMISItems(newRequestCompItems);

      setMisPreco((prevPreco) => prevPreco - removedItemPreco);
    }
  };

  const handleCheckItem = (indexToAdd) => {
    const itemToAdd = misItemsList[indexToAdd];
    const qtdInput = document.querySelector(`input[name="Q${indexToAdd}"]`);
    const disciplinaInput = document.querySelector(
      `select[name="D${indexToAdd}"]`
    );
    const quantidade = parseFloat(qtdInput.value);
    const disciplina = disciplinaInput.value;

    if (
      qtdInput &&
      quantidade > 0 &&
      disciplinaInput !== '' &&
      disciplinaInput !== 'Selecione.'
    ) {
      const newItem = {
        item_id: itemToAdd.id,
        preco_und: itemToAdd.preco,
        quantidade: quantidade,
        unidade: itemToAdd.unidade,
        item: itemToAdd.descl,
        tipo: `${itemToAdd.schema.charAt(0)}${itemToAdd.origem.charAt(0)}`.toUpperCase(),
        disciplina: disciplina,
      };
      if (!selectedRows.includes(indexToAdd)) {
        const itemPreco = itemToAdd.preco * quantidade;
        setMisPreco((prevPreco) => prevPreco + itemPreco);

        setRequestMISItems((prevItems) => [...prevItems, newItem]);
        setSelectedRows((prevRows) => [...prevRows, indexToAdd]);
      }
    } else {
      // alert("Não é possível confirmar um item com coeficiente nulo.")
      setAlertMessage('Não é possível confirmar um item com coeficiente nulo.');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const isCadButDisabled = () => {
    const term =
      misArea === '' ||
      misSubarea === '' ||
      misSigla === '' ||
      misMeio === '' ||
      misVariancia === '' ||
      misDescricao === '' ||
      requestMISItems.length === 0;
    return term;
  };

  const resetForm = () => {
    document.getElementById('MISForm').reset();
  };

  const handleMISCad = async (e) => {
    e.preventDefault();

    const headers = {
      'Content-Type': 'application/json',
    };

    const newItems = misItemsList.map((item, i) => {
      const quantidadeInput = document.querySelector(`input[name=Q${i}]`);
      const disciplinaInput = document.querySelector(`select[name=D${i}]`);

      const {
        criado_em,
        descc,
        descl,
        id,
        origem,
        item_id,
        schema,
        sap_id,
        ...restAtt
      } = item;

      return {
        ...restAtt,
        quantidade: quantidadeInput.value,
        disciplina:
          disciplinaInput.options[disciplinaInput.selectedIndex].value,
        tipo: item.schema,
        item_id: item.id,
      };
    });

    setRequestMISItems(newItems);

    let misSchema = {
      sigla: misSigla,
      area: misArea,
      subarea: misSubarea,
      meio: misMeio,
      descricao: misDescricao,
      variancia: misVariancia,
      preco: misPreco,
    };

    let requestBody = {
      mis: misSchema,
      items: {
        items: requestMISItems,
      },
    };

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/mis`,
        requestBody,
        { headers }
      );
      const data = await response.data;

      if (response.status === 201) {
        // alert('MIS cadastrado.')
        setAlertMessage('MIS cadastrado com sucesso.');
        setAlertSeverity('success');
        setAlertOpen(true);
        resetForm();
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao cadastrar MIS.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Engenharia</li>
                    <li>MIS</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">MIS</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="section is-main-section"
            style={{ marginBottom: '50px' }}
          >
            <form name="MISForm" onSubmit={handleMISCad} id="MISForm">
              <div className="card">
                <header className="card-header">
                  <p
                    className="card-header-title"
                    style={{
                      padding: '12px 0px 12px 12px',
                    }}
                  >
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Formulário para a criação de um novo mis
                  </p>
                </header>
                <div className="card-content" style={{ padding: '1.5rem' }}>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Sigla
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            value={misSigla}
                            onChange={(e) => setMISSigla(e.target.value)}
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-s"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Meio
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            value={misMeio}
                            onChange={(e) => setMISMeio(e.target.value)}
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-gears"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Area
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            value={misArea}
                            onChange={(e) => setMISArea(e.target.value)}
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-brands fa-codepen"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Subarea
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            value={misSubarea}
                            onChange={(e) => setMISSubarea(e.target.value)}
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-layer-group"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Display</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            value={`${misSigla}${misArea}${misSubarea}-${misMeio}/${misVariancia}`}
                            placeholder={`${misSigla}${misArea}${misSubarea}-${misMeio}/${misVariancia}`}
                            onChange={(e) => setMISVariancia(e.target.value)}
                            disabled
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-mobile-screen"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Variação
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            value={misVariancia}
                            onChange={(e) => setMISVariancia(e.target.value)}
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-sliders"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Descrição</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            value={misDescricao}
                            onChange={(e) => setMISDescricao(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-file-lines"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Preço</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="number"
                            value={misPreco}
                            disabled
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-circle-dollar-to-slot"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      margin: '40px 0px 20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button
                      type="button"
                      className="button is-primary"
                      onClick={handleMISCad}
                      disabled={isCadButDisabled()}
                    >
                      Cadastrar
                    </button>
                  </div>
                </div>
              </div>

              <div className="card">
                <header className="card-header">
                  <p
                    className="card-header-title"
                    style={{
                      padding: '12px 0px 12px 12px',
                    }}
                  >
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Tabela de items
                  </p>
                </header>
                <div className="card-content" style={{ padding: '1.5rem' }}>
                  <div className="b-table has-pagination">
                    <div className="table-wrapper has-mobile-cards">
                      <ButtonGroup
                        aria-label="outlined is-primary button group"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: '20px 0px',
                        }}
                      >
                        <Button
                          onClick={() => handleOpenModal('Materiais')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          Materiais
                        </Button>
                        <Button
                          onClick={() => handleOpenModal('Equipamentos')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          Equipamentos
                        </Button>
                        <Button
                          onClick={() => handleOpenModal('Composições')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          Composições
                        </Button>
                        <Button
                          onClick={() => handleOpenModal('Mão de obra')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          Mão de obra
                        </Button>
                        <Button
                          onClick={() => handleOpenModal('SINAPI: Composições')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          SINAPI: Composições
                        </Button>
                        <Button
                          onClick={() => handleOpenModal('SINAPI: Insumos')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          SINAPI: Insumos
                        </Button>
                      </ButtonGroup>

                      <div className="divider is-size-6">ITENS</div>

                      {/* <table className="table is-fullwidth has-text-justified">
                      <thead>
                        <tr>
                          <th><p className="has-text-centered is-size-7">ID</p></th>
                          <th><p className="has-text-centered is-size-7">DESCRIÇÃO</p></th>
                          <th><p className="has-text-centered is-size-7">UND.</p></th>
                          <th><p className="has-text-centered is-size-7">PRECO</p></th>
                          <th><p className="has-text-centered is-size-7">QUANTIDADE</p></th>
                          <th><p className="has-text-centered is-size-7">DISCIPLINA</p></th>
                          <th></th>

                        </tr>
                      </thead>
                      <tbody>
                        {misItemsList.map((item, i) => (
                          <tr key={`K${i}`} className={selectedRows.includes(i) ? 'row-in-request' : ''}>
                            <td><p className="is-size-7">{item.did}</p></td>
                            <td><p className="is-size-7">{item.descl}</p></td>
                            <td><p className="is-size-7">{item.unidade}</p></td>
                            <td><p className="is-size-7">{item.preco}</p></td>
                            <td>
                              <input className="input is-small"
                                name={`Q${i}`}
                                step={"0.001"}
                                type="number"
                                style={{ maxWidth: 100 }}
                              />
                            </td>
                            <td>
                              <div className="select is-small">
                                <select
                                  name={`D${i}`}
                                  className="has-text-centered is-size-7"
                                  style={{ maxWidth: 100 }}
                                >
                                  <option value={""}>Selecione.</option>
                                  <option value={"MECANICA"}>MECANICA</option>
                                  <option value={"CIVIL"}>CIVIL</option>
                                  <option value={"ELETRICA"}>ELETRICA</option>
                                  <option value={"INSTRUMENTACAO"}>INSTRUMENTACAO</option>
                                </select>
                              </div>
                            </td>
                            <td>
                              <div className="columns">
                                <div className="column">
                                  <span onClick={() => handleCheckItem(i)}>
                                    <i className={`fa-solid fa-circle-check ${selectedRows.includes(i) ? 'green-icon' : ''}`}></i>
                                  </span>
                                </div>
                                <div className="column">
                                  <span onClick={() => handleRemoveItem(i)}>
                                    <i className="fa-solid fa-eraser"></i>
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>    */}

                      <TableContainer
                        component={Paper}
                        style={{
                          border: '1px solid lightgrey',
                          borderRadius: '5px',
                          marginBottom: '20px',
                        }}
                      >
                        <Table aria-label="MIS items table">
                          <TableHead>
                            <TableRow
                              style={{
                                display: 'flex',
                                width: '100%',
                              }}
                            >
                              <TableCell
                                style={{
                                  flex: '1',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                ID
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '3',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                DESCRIÇÃO
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                UND.
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                PREÇO
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                QUANTIDADE
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                DISCIPLINA
                              </TableCell>
                              <TableCell
                                style={{
                                  width: '80px',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                AÇÃO
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {misItemsList.map((item, i) => (
                              <TableRow
                                key={`K${i}`}
                                className={
                                  selectedRows.includes(i)
                                    ? 'row-in-request'
                                    : ''
                                }
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <TableCell
                                  style={{
                                    flex: '1',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  {item.did}
                                </TableCell>
                                <TableCell
                                  style={{
                                    flex: '3',
                                    fontFamily: '"Nunito", sans-serif',
                                    textAlign: 'justify',
                                  }}
                                >
                                  {item.descl}
                                </TableCell>
                                <TableCell
                                  style={{
                                    flex: '1',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  {item.unidade}
                                </TableCell>
                                <TableCell
                                  style={{
                                    flex: '1',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  {item.preco}
                                </TableCell>
                                <TableCell
                                  style={{
                                    flex: '1',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  <input
                                    className="input is-small"
                                    name={`Q${i}`}
                                    step={'0.001'}
                                    type="number"
                                    style={{
                                      maxWidth: 100,
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  style={{
                                    flex: '1',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  <div className="select is-small">
                                    <select
                                      name={`D${i}`}
                                      className="has-text-centered is-size-7"
                                      style={{
                                        maxWidth: 100,
                                      }}
                                    >
                                      <option value={''}>Selecione.</option>
                                      <option value={'MECANICA'}>
                                        MECANICA
                                      </option>
                                      <option value={'CIVIL'}>CIVIL</option>
                                      <option value={'ELETRICA'}>
                                        ELETRICA
                                      </option>
                                      <option value={'INSTRUMENTACAO'}>
                                        INSTRUMENTACAO
                                      </option>
                                    </select>
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: '80px',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  <div
                                    className="columns"
                                    style={{
                                      fontSize: '18px',
                                    }}
                                  >
                                    <div className="column is-1">
                                      <span
                                        className="icon"
                                        onClick={() => handleCheckItem(i)}
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <i
                                          className={`fa-solid fa-circle-check ${selectedRows.includes(i) ? 'green-icon' : ''}`}
                                        ></i>
                                      </span>
                                    </div>
                                    <div className="column is-1">
                                      <span
                                        className="icon"
                                        onClick={() => handleRemoveItem(i)}
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <i className="fa-solid fa-eraser"></i>
                                      </span>
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <FormModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              modalTitle={modalContent}
            >
              {renderModalContent(info)}
            </FormModal>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default MISForm;
