import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import FormModal from '../../layouts/FormModal';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import ListaMateriaisAvulsaView from './ListaMateriais/ListaMateriaisAvulsaView';
import AlertMessage from '../../layouts/AlertMessage';
import { useUser } from '../../../context/UserContext';

const ListasMateriaisHomed = () => {
  const [tipoLista, setTipoLista] = useState('lma');
  const [formVisivel, setFormVisivel] = useState(false);
  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;
  const [allListasAvulsas, setAllListasAvulsas] = useState([]);
  const [allListasVinculadas, setAllListasVinculadas] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedListaId, setSelectedListaId] = useState(null);
  const { idUsuario } = useUser();

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const handleOpenModal = (listaId) => {
    setSelectedListaId(listaId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedListaId(null);
    setModalOpen(false);
  };

  const handleEditLista = (listaId) => {
    handleOpenModal(listaId);
  };

  const getListasAvulsas = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/listas/lma/user/`,
        {
          params: { user_id: idUsuario },
          headers,
        }
      );
      const data = await response.data;
      setAllListasAvulsas(data);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar listas do usuário.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getListasVinculadas = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/listas/lmv/`,
        { headers }
      );
      const data = await response.data;
      setAllListasVinculadas(data);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar listas vinculadas.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const removeLista = async (value) => {
    const confirmed = window.confirm(
      'Tem certeza que deseja excluir esta lista? Essa ação não é reversível.'
    );
    if (!confirmed) {
      return;
    }

    try {
      const response = await axios.delete(
        `${REACT_APP_BASE_API_URL}/listas/material/`,
        {
          params: { lm_id: value },
          headers,
        }
      );
      if (response.status === 200) {
        // alert("Lista deletada com sucesso.");
        setAlertMessage('Lista deletada com sucesso.');
        setAlertSeverity('success');
        setAlertOpen(true);
        setReload(true);
        // window.location.reload();
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao excluir lista.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleTipoChange = (e) => {
    setTipoLista(e.target.value);
    setFormVisivel(true);
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (tipoLista === 'lmv') {
      navigate('/lmv-form/');
    } else {
      navigate('/lma-form/');
    }
  };

  useEffect(() => {
    tipoLista === 'lma' ? getListasAvulsas() : getListasVinculadas();
  }, []);

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
    };
  }, []);

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Engenharia</li>
                    <li>Lista de materiais</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Lista de materiais</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <div className="select-lista">
                      <form
                        onSubmit={handleFormSubmit}
                        name="selecionarListaM"
                        className="is-inline"
                      >
                        <div style={{ display: 'flex' }}>
                          <div
                            className="div-select-search"
                            style={{
                              paddingRight: '15px',
                              width: '400px',
                            }}
                          >
                            <label className="label">
                              Cadastrar lista de materiais
                            </label>

                            <div className="field-body">
                              <div
                                className="field is-narrow"
                                style={{
                                  padding: '0px',
                                }}
                              >
                                <div className="control has-icons-left">
                                  <div className="select is-fullwidth">
                                    <select
                                      value={tipoLista}
                                      className="custom-select-style"
                                      onChange={handleTipoChange}
                                    >
                                      <option value="lma">Avulsa</option>
                                      <option value="lmv">Vinculada</option>
                                    </select>
                                    <span className="icon is-small is-left">
                                      <i className="fa-solid fa-list"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-end',
                            }}
                          >
                            <button className="button is-primary" type="submit">
                              <span className="icon">
                                <i className="fa-solid fa-arrow-right"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Listas de materiais cadastrados
                </p>
              </header>
              <div className="card-content">
                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="TabelaMateriais">
                      <div style={{ width: '100%' }}>
                        {tipoLista === 'lma' ? (
                          <TableContainer
                            component={Paper}
                            style={{
                              border: '1px solid lightgrey',
                              borderRadius: '5px',
                              marginBottom: '20px',
                            }}
                          >
                            <Table aria-label="custom table">
                              <TableHead>
                                <TableRow
                                  style={{
                                    display: 'flex',
                                    width: '100%',
                                    backgroundColor: 'rgb(46, 50, 58)',
                                  }}
                                >
                                  <TableCell
                                    style={{
                                      flex: '2',
                                      color: 'white',
                                      fontSize: '17px',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    Título
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      color: 'white',
                                      fontSize: '17px',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    Custo
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      color: 'white',
                                      fontSize: '17px',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    RC
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '120px',
                                      color: 'white',
                                      fontSize: '17px',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    Visualizar
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: '90px',
                                      color: 'white',
                                      fontSize: '17px',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    Excluir
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {allListasAvulsas &&
                                  allListasAvulsas.map((lista, i) => (
                                    <TableRow
                                      key={i}
                                      style={{
                                        display: 'flex',
                                        width: '100%',
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          flex: '2',
                                          fontFamily: '"Nunito", sans-serif',
                                        }}
                                      >
                                        {lista.titulo}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          flex: '1',
                                          fontFamily: '"Nunito", sans-serif',
                                        }}
                                      >
                                        {formatPreco(lista.custo)}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          flex: '1',
                                          fontFamily: '"Nunito", sans-serif',
                                        }}
                                      >
                                        {lista.src_ref ? (
                                          <i
                                            className="fa-solid fa-check"
                                            style={{
                                              color: 'green',
                                            }}
                                          ></i>
                                        ) : (
                                          <i
                                            className="fa-solid fa-x"
                                            style={{
                                              color: 'red',
                                            }}
                                          ></i>
                                        )}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: '120px',
                                          fontFamily: '"Nunito", sans-serif',
                                          display: 'flex',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <span
                                          onClick={() =>
                                            handleEditLista(lista.id)
                                          }
                                          style={{
                                            cursor: 'pointer',
                                            fontSize: '19px',
                                          }}
                                        >
                                          <i
                                            className="fa-regular fa-window-restore"
                                            style={{
                                              margin: '2px',
                                            }}
                                          ></i>
                                          {/* <i className="fa-solid fa-eye"  /> */}
                                        </span>
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: '90px',
                                          fontFamily: '"Nunito", sans-serif',
                                          display: 'flex',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        {lista.src_ref ? (
                                          <span
                                            style={{
                                              cursor: 'not-allowed',
                                            }}
                                          >
                                            <i
                                              className="fa-solid fa-trash"
                                              style={{
                                                margin: '2px',
                                                color: 'grey',
                                                fontSize: '18px',
                                              }}
                                            />
                                          </span>
                                        ) : (
                                          <span
                                            onClick={() =>
                                              removeLista(lista.id)
                                            }
                                            style={{
                                              cursor: 'pointer',
                                              fontSize: '18px',
                                              color: 'red',
                                            }}
                                          >
                                            <i
                                              className="fa-solid fa-trash"
                                              style={{
                                                margin: '2px',
                                              }}
                                            />
                                          </span>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        ) : (
                          <TableContainer
                            component={Paper}
                            style={{
                              border: '1px solid lightgrey',
                              borderRadius: '5px',
                              marginBottom: '20px',
                            }}
                          >
                            <Table aria-label="Listas Vinculadas">
                              <TableHead>
                                <TableRow
                                  style={{
                                    display: 'flex',
                                    width: '100%',
                                    backgroundColor: 'rgb(46, 50, 58)',
                                  }}
                                >
                                  <TableCell
                                    style={{
                                      flex: '2',
                                      color: 'white',
                                      fontSize: '17px',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    Título
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      color: 'white',
                                      fontSize: '17px',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    Custo
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      color: 'white',
                                      fontSize: '17px',
                                      fontWeight: 'bold',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                  >
                                    Capex
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {allListasVinculadas &&
                                  allListasVinculadas.map((lista, i) => (
                                    <TableRow
                                      key={i}
                                      style={{
                                        display: 'flex',
                                        backgroundColor:
                                          i % 2 === 0
                                            ? '#f9f9f9'
                                            : 'transparent',
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          flex: '2',
                                          fontFamily: '"Nunito", sans-serif',
                                        }}
                                      >
                                        {lista.titulo}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          flex: '1',
                                          fontFamily: '"Nunito", sans-serif',
                                        }}
                                      >
                                        {formatPreco(lista.custo)}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          flex: '1',
                                          fontFamily: '"Nunito", sans-serif',
                                        }}
                                      >
                                        {lista.capex_name}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FormModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              modalTitle={'Visualizar Lista'}
            >
              {isModalOpen && selectedListaId && (
                <ListaMateriaisAvulsaView
                  listaId={selectedListaId}
                  info={info}
                />
              )}
            </FormModal>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default ListasMateriaisHomed;
