import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './ProjetosDetailView.css';
import { DataGrid } from '@mui/x-data-grid';

const ProjetoDetailView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { projeto_id } = useParams();
  const { REACT_APP_BASE_API_URL } = process.env;
  const [capexes, setCapexes] = useState([]);
  const { pageName } = location.state;
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const getCapexes = async () => {
    try {
      let headers = {
        'Content-Type': 'application/json',
      };

      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/capex/projeto/`,
        {
          params: {
            projeto_id: projeto_id,
          },
          headers: headers,
        }
      );
      const sortedCapex = response.data.sort((a, b) => b.ver - a.ver);
      setCapexes(sortedCapex);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar capexes'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleInspect = (capexId) => {
    navigate(`/adm/capex/${capexId}`, {
      state: { page1: pageName, page2: 'Capexes' },
    });
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const validatePopulate = (capexObj) => {
    // const capexObj = capexes[index];
    if (
      capexObj.fase === 'CONCEITUAL' &&
      capexObj.ver === 0 &&
      capexObj.rev === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handlePopulate = (capexConId) => {
    // const capexConRef = capexes[index];
    // const capexConId = capexConRef.id;

    navigate(`/adm/capex/populate/${capexConId}`, {
      state: { empreendimento: pageName, fluxo: '' },
    });
  };

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const formattedDate = `${dateObj.getDate().toString().padStart(2, '0')}/${(dateObj.getMonth() + 1).toString().padStart(2, '0')}/${dateObj.getFullYear()}`;
    return formattedDate;
  };

  useEffect(() => {
    getCapexes();
  }, []);

  const columns = [
    {
      field: 'fase',
      headerName: 'FASE',
      flex: 2,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>
          <p
            className={`${params.row.fase.charAt(0) === 'C' ? 'tag is-danger' : params.row.fase.charAt(0) === 'B' ? 'tag is-warning' : 'tag is-success'}`}
          >
            {params.row.fase}
          </p>
        </div>
      ),
    },
    { field: 'rev', headerName: 'REV', flex: 1 },
    { field: 'ver', headerName: 'VER', flex: 1 },
    { field: 'status', headerName: 'STATUS', flex: 3 },
    {
      field: 'criado_em',
      headerName: 'ULTIMA ATUALIZAÇÃO',
      flex: 3,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>
          <p className="has-text">{formatDate(params.row.criado_em)}</p>
        </div>
      ),
    },
    {
      field: 'acao',
      headerName: 'ACAO',
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: '100%', paddingLeft: '10px' }}>
          <span onClick={() => handleInspect(params.row.id)}>
            <i className="fa-solid fa-binoculars pr-1"></i>
          </span>
          <span
            onClick={
              validatePopulate(params.row)
                ? () => handlePopulate(params.row.id)
                : undefined
            }
            className={`${validatePopulate(params.row) ? '' : 'no-populate'}`}
          >
            <i className="fa-solid fa-chart-bar pr-1 ml-1"></i>
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Geral</li>
                    <li>Tabela de empreendimentos</li>
                    <li>{pageName}</li>
                    <li>CAPEX</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">CAPEX</h1>
                  </div>
                </div>
                <div className="level-right" style={{ display: 'none' }}>
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card has-table">
              <div className="card-content">
                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="TabelaEmpreendimento">
                      <div
                        style={{
                          height: 660,
                          width: '100%',
                        }}
                      >
                        {' '}
                        {/* padding: '0px 50px 0px 50px' */}
                        <DataGrid
                          rows={capexes}
                          columns={columns}
                          pageSize={5}
                          disableSelectionOnClick
                          getRowHeight={() => 'auto'}
                          getColumnHeight={() => 'auto'}
                          sx={{
                            fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
                            '& .MuiDataGrid-cell': {
                              display: 'flex',

                              alignItems: 'center', // Alinha o conteúdo no topo
                              paddingTop: '4px', // Ajuste conforme necessário
                              paddingBottom: '4px',
                              border: '0px',
                              fontFamily: '"Nunito", sans-serif',
                              lineHeight: '1.5',
                              fontSize: '16px',
                            },
                            '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                              {
                                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                                fontSize: '16px',
                                color: '#171717',
                                fontFamily: '"Nunito", sans-serif',
                                lineHeight: '1.5',
                              },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default ProjetoDetailView;
