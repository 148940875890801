import { Alert, Snackbar, Typography } from '@mui/material';

function AlertMessage({ open, severity, message, handleClose }) {
  return (
    <div>
      <Snackbar
        open={open}
        // autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Centered on the screen
        sx={{
          maxWidth: '80%', // Adjust width as desired
          margin: 'auto',
          zIndex: 10000, // Set z-index to be above the header
        }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{
            width: '100%',
            padding: '16px 24px', // Increase padding for larger alert
            borderRadius: '8px', // Rounded corners for larger styling
            zIndex: 20000, // Set z-index to be above the header
          }}
        >
          {(() => {
            // Try parsing the message into a JSON object
            try {
              message = JSON.parse(message);
              let msg = message[0]['msg'];

              // If successful, print the JSON object
              return (
                <Typography
                  style={{
                    fontSize: '1.2rem',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {`${message[0]['type']}: ${JSON.stringify(msg).replace(/"/g, '')}\n\n`}
                  {message[0]['param']
                    ? `Campo: ${message[0]['param']}\n\n`
                    : ''}
                  {message[0]['input']
                    ? `Valor passado: ${message[0]['input']}\n\n`
                    : ''}
                </Typography>
              );
            } catch (error) {
              // Do nothing
            }

            if (Array.isArray(message)) {
              return message.map((line, index) => (
                <Typography key={index} style={{ fontSize: '1.2rem' }}>
                  {line}
                </Typography>
              ));
            } else if (typeof message === 'string' && message.includes('\n')) {
              return message.split('\n').map((line, index) => (
                <Typography key={index} style={{ fontSize: '1.2rem' }}>
                  {line}
                </Typography>
              ));
            } else {
              return (
                <Typography style={{ fontSize: '1.2rem' }}>
                  {message}
                </Typography>
              );
            }
          })()}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AlertMessage;
