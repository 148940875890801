import React, { useEffect, useContext, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import FormModal from '../../layouts/FormModal';
import ProjetosForm from '../projetos/ProjetosForm';
import { DataGrid } from '@mui/x-data-grid';
import AlertMessage from '../../layouts/AlertMessage';
import { useUser } from '../../../context/UserContext';

const EmpreendimentoDetailView = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const { empreendimento_id } = useParams();
  const [empreendimentoObj, setEmpreendimentoObj] = useState({});
  const [projetos, setProjetos] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);
  const { gruposUsuario } = useUser();
  const [user, setUser] = useState({
    grupos: gruposUsuario,
  });
  const allowedRoles = ['ADMINISTRADOR', 'NOVO NEGOCIOS - CAPEX'];

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setModalOpen(false);
  };

  const handleProjCreated = () => {
    setModalOpen(false);
    window.location.reload();
  };

  const renderModalContent = (info) => {
    switch (modalContent) {
      case 'Novo projeto':
        return (
          <ProjetosForm
            info={info}
            empreendimento_id={empreendimento_id}
            onProjCreated={handleProjCreated}
          />
        );
      default:
        return null;
    }
  };
  const headers = {
    'Content-Type': 'application/json',
  };

  const getProjetos = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/adm/emp/detail/`,
        {
          params: {
            empreendimento: empreendimento_id,
          },
          headers: headers,
        }
      );
      setProjetos(response.data.p);
      setEmpreendimentoObj(response.data.e);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar projetos.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleInspect = (projetoId) => {
    navigate(`/adm/projetos/${projetoId}`, {
      state: { pageName: empreendimentoObj.nome },
    });
  };

  useEffect(() => {
    getProjetos();
  }, []);

  const columns = [
    { field: 'tag', headerName: 'TAG', flex: 2 },
    { field: 'titulo', headerName: 'NOME', flex: 4 },
    {
      field: 'fase',
      headerName: 'FASE',
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>
          <p
            className={`${params.row.fase.charAt(0) === 'C' ? 'tag is-danger' : params.row.fase.charAt(0) === 'B' ? 'tag is-warning' : 'tag is-success'}`}
          >
            {params.row.fase.charAt(0)}
          </p>
        </div>
      ),
    },
    { field: 'empreendimento', headerName: 'EMPREENDIMENTO', flex: 4 },
    { field: 'cpx_status', headerName: 'STATUS', flex: 3 },
    { field: 'ver', headerName: 'VER', flex: 1 },
    {
      field: 'acao',
      headerName: 'ACAO',
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: '100%', paddingLeft: '10px' }}>
          <span onClick={() => handleInspect(params.row.id)}>
            <i className="fa-solid fa-binoculars pr-1"></i>
          </span>
        </div>
      ),
    },
  ];

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    {/* <li>Setores</li> */}
                    <li>Geral</li>
                    <li>Tabela de empreendimentos</li>
                    <li>{empreendimentoObj.nome}</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">{empreendimentoObj.nome}</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <div className="buttons is-right">
                      <button
                        disabled={
                          !allowedRoles.some((role) =>
                            user.grupos.includes(role)
                          )
                        }
                        onClick={() => handleOpenModal('Novo projeto')}
                        className="button is-primary"
                      >
                        <span className="icon">
                          <i className="fa-solid fa-folder-plus"></i>
                        </span>
                        <span>Novo projeto</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  PROJETOS DE {projetos[0] ? projetos[0].empreendimento : ''}
                </p>
              </header>
              <div className="card-content">
                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="TabelaEmpreendimento">
                      <div
                        style={{
                          height: 550,
                          width: '100%',
                        }}
                      >
                        {' '}
                        {/*padding: '0px 50px 0px 50px' */}
                        <DataGrid
                          rows={projetos}
                          columns={columns}
                          pageSize={5}
                          disableSelectionOnClick
                          getRowHeight={() => 'auto'}
                          getColumnHeight={() => 'auto'}
                          sx={{
                            fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
                            '& .MuiDataGrid-cell': {
                              display: 'flex',
                              alignItems: 'flex-start', // Alinha o conteúdo no topo
                              paddingTop: '4px', // Ajuste conforme necessário
                              paddingBottom: '4px',
                              border: '0px',
                              fontFamily: '"Nunito", sans-serif',
                              lineHeight: '1.5',
                              fontSize: '16px',
                            },
                            '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                              {
                                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                                fontSize: '16px',
                                color: '#171717',
                                fontFamily: '"Nunito", sans-serif',
                                lineHeight: '1.5',
                              },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FormModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              modalTitle={'Criar Projeto'}
            >
              {renderModalContent(info)}
            </FormModal>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default EmpreendimentoDetailView;
