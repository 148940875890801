import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MainLayout from '../../layouts/MainLayout';
import axios from 'axios';
import AlertMessage from '../../layouts/AlertMessage';

const FornecedoresHome = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [allFornecedores, setAllFornecedores] = useState([]);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const getAllFornecedores = async () => {
    const headers = {
      'Content-Type': 'application/json',
    };
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/fornecedor/`,
        { headers }
      );
      const data = await response.data;
      setAllFornecedores(data.fornecedores);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar fornecedores'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    getAllFornecedores();
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <p>
          <Link to={'/fornecedores/registrar'}>Cadastrar</Link>
        </p>
        {allFornecedores.map((fornecedor, i) => (
          <p key={i}>{fornecedor.nome_fantasia}</p>
        ))}
      </MainLayout>
    </>
  );
};

export default FornecedoresHome;
