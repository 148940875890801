import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AlertMessage from '../../layouts/AlertMessage';

const CapexMisDetailModal = ({
  handleRemoveMis,
  misObj,
  subArea,
  area_str,
  info,
}) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [misDetails, setMisDetails] = useState({});
  const [misItems, setMisItems] = useState([]);

  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  const getItemsMis = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/mis/detail/`,
        {
          params: {
            mis_id: misObj.id,
          },
          headers: headers,
        }
      );
      const data = await response.data;
      setMisDetails(data.mis);
      setMisItems(data.items);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar detalhes do MIS'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getItemsMis();
  }, []);

  return (
    <>
      <div className="container" style={{ maxWidth: '1200px', height: 'auto' }}>
        <div className="divider is-size-5">Detalhes MIS</div>

        <TableContainer
          component={Paper}
          style={{
            border: '1px solid lightgrey',
            borderRadius: '5px',
            margin: '16px',
            height: 'auto',
            width: '1000px',
          }}
        >
          <Table aria-label="MIS details table">
            <TableHead>
              <TableRow style={{ backgroundColor: '#2e323a' }}>
                <TableCell
                  style={{
                    flex: '1',
                    fontSize: '16px',
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontFamily: '"Nunito", sans-serif',
                  }}
                >
                  AREA
                </TableCell>
                <TableCell
                  style={{
                    flex: '1',
                    fontSize: '16px',
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontFamily: '"Nunito", sans-serif',
                  }}
                >
                  SUBAREA
                </TableCell>
                <TableCell
                  style={{
                    flex: '3',
                    fontSize: '16px',
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontFamily: '"Nunito", sans-serif',
                  }}
                >
                  DESCRIÇÃO
                </TableCell>
                <TableCell
                  style={{
                    flex: '1',
                    fontSize: '16px',
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontFamily: '"Nunito", sans-serif',
                  }}
                >
                  MEIO
                </TableCell>
                <TableCell
                  style={{
                    flex: '1',
                    fontSize: '16px',
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontFamily: '"Nunito", sans-serif',
                  }}
                >
                  SIGLA
                </TableCell>
                <TableCell
                  style={{
                    flex: '1',
                    fontSize: '16px',
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontFamily: '"Nunito", sans-serif',
                  }}
                >
                  VARIANCIA
                </TableCell>
                <TableCell
                  style={{
                    flex: '1',
                    fontSize: '16px',
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontFamily: '"Nunito", sans-serif',
                  }}
                >
                  PREÇO
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{ backgroundColor: '#f7f7f7' }}>
                <TableCell
                  style={{
                    flex: '1',
                    fontFamily: '"Nunito", sans-serif',
                    fontSize: '14px',
                  }}
                >
                  {misDetails.area}
                </TableCell>
                <TableCell
                  style={{
                    flex: '1',
                    fontFamily: '"Nunito", sans-serif',
                    fontSize: '14px',
                  }}
                >
                  {misDetails.subarea}
                </TableCell>
                <TableCell
                  style={{
                    flex: '3',
                    fontFamily: '"Nunito", sans-serif',
                    fontSize: '14px',
                  }}
                >
                  {misDetails.descricao}
                </TableCell>
                <TableCell
                  style={{
                    flex: '1',
                    fontFamily: '"Nunito", sans-serif',
                    fontSize: '14px',
                  }}
                >
                  {misDetails.meio}
                </TableCell>
                <TableCell
                  style={{
                    flex: '1',
                    fontFamily: '"Nunito", sans-serif',
                    fontSize: '14px',
                  }}
                >
                  {misDetails.sigla}
                </TableCell>
                <TableCell
                  style={{
                    flex: '1',
                    fontFamily: '"Nunito", sans-serif',
                    fontSize: '14px',
                  }}
                >
                  {misDetails.variancia}
                </TableCell>
                <TableCell
                  style={{
                    flex: '1',
                    fontFamily: '"Nunito", sans-serif',
                    fontSize: '14px',
                  }}
                >
                  {formatPreco(misDetails.preco)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <div className="divider is-size-5">Itens</div>

        <TableContainer
          component={Paper}
          style={{
            border: '1px solid lightgrey',
            borderRadius: '5px',
            margin: '16px',
            height: 'auto',
            width: '1000px',
          }}
        >
          <Table aria-label="MIS items table">
            <TableHead>
              <TableRow
                style={{
                  display: 'flex',
                  backgroundColor: '#2e323a',
                }}
              >
                <TableCell
                  style={{
                    flex: '3',
                    fontSize: '16px',
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontFamily: '"Nunito", sans-serif',
                  }}
                >
                  ITEM
                </TableCell>
                <TableCell
                  style={{
                    flex: '1',
                    fontSize: '16px',
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontFamily: '"Nunito", sans-serif',
                  }}
                >
                  TIPO
                </TableCell>
                <TableCell
                  style={{
                    flex: '1',
                    fontSize: '16px',
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontFamily: '"Nunito", sans-serif',
                  }}
                >
                  QUANTIDADE
                </TableCell>
                <TableCell
                  style={{
                    flex: '1',
                    fontSize: '16px',
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontFamily: '"Nunito", sans-serif',
                  }}
                >
                  UNIDADE
                </TableCell>
                <TableCell
                  style={{
                    flex: '1',
                    fontSize: '16px',
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontFamily: '"Nunito", sans-serif',
                  }}
                >
                  PREÇO UNIDADE
                </TableCell>
                <TableCell
                  style={{
                    flex: '1',
                    fontSize: '16px',
                    color: '#ffffff',
                    fontWeight: 'bold',
                    fontFamily: '"Nunito", sans-serif',
                  }}
                >
                  DISCIPLINA
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {misItems.map((item, index) => (
                <TableRow
                  key={index}
                  style={{
                    display: 'flex',
                    backgroundColor: '#f7f7f7',
                  }}
                >
                  <TableCell
                    style={{
                      flex: '3',
                      textAlign: 'justify',
                      fontFamily: '"Nunito", sans-serif',
                      fontSize: '14px',
                    }}
                  >
                    {item.item}
                  </TableCell>
                  <TableCell
                    style={{
                      flex: '1',
                      textAlign: 'justify',
                      fontFamily: '"Nunito", sans-serif',
                      fontSize: '14px',
                    }}
                  >
                    {item.tipo}
                  </TableCell>
                  <TableCell
                    style={{
                      flex: '1',
                      textAlign: 'justify',
                      fontFamily: '"Nunito", sans-serif',
                      fontSize: '14px',
                    }}
                  >
                    {item.quantidade}
                  </TableCell>
                  <TableCell
                    style={{
                      flex: '1',
                      textAlign: 'justify',
                      fontFamily: '"Nunito", sans-serif',
                      fontSize: '14px',
                    }}
                  >
                    {item.unidade}
                  </TableCell>
                  <TableCell
                    style={{
                      flex: '1',
                      textAlign: 'justify',
                      fontFamily: '"Nunito", sans-serif',
                      fontSize: '14px',
                    }}
                  >
                    {formatPreco(item.preco_und)}
                  </TableCell>
                  <TableCell
                    style={{
                      flex: '1',
                      textAlign: 'justify',
                      fontFamily: '"Nunito", sans-serif',
                      fontSize: '14px',
                    }}
                  >
                    {item.disciplina}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <hr></hr>

        <div className="buttons is-right">
          <button
            onClick={() => handleRemoveMis(misObj, subArea)}
            className="button is-primary"
            style={{
              margin: '0px 16px 20px',
              backgroundColor: 'red',
              color: 'white',
              fontWeight: '600',
            }}
          >
            <span className="icon">
              <i className="fa-regular fa-trash-can"></i>
            </span>
            <span>Remover MIS</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default CapexMisDetailModal;
