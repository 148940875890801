import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';

import FormModal from '../../layouts/FormModal';
import AlertMessage from '../../layouts/AlertMessage';
import AdminUserRegister from './AdminUserRegister';

const AdminUserTable = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const capitalizar = (texto) => {
    if (texto) {
      const words = texto.split(' ');
      const titlezedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });
      return titlezedWords.join(' ');
    } else {
      return '';
    }
  };

  const handleUserClick = (id) => {
    navigate(`/admin/user/edit/${id}`);
  };

  const userTableColumns = [
    { field: 'nome_completo', headerName: 'Nome', flex: 0.5 },
    {
      field: 'cargo',
      headerName: 'Cargo',
      width: 130,
      minWidth: 130,
      valueGetter: (val) => {
        return capitalizar(val.value);
      },
    },
    {
      field: 'setor',
      headerName: 'Setor',
      width: 130,
      minWidth: 130,
      valueGetter: (val) => {
        return capitalizar(val.value);
      },
    },
    { field: 'role', headerName: 'Permissões', width: 130, minWidth: 130 },
    {
      field: 'criado_em',
      headerName: 'Entrou em',
      width: 130,
      minWidth: 130,
      type: 'date',
      format: 'DD-MM-YYYY',
      valueGetter: (val) => {
        const nd = new Date(val.value);
        return nd;
      },
    },
    {
      field: 'acao',
      headerName: 'Editar',
      width: 80,
      minWidth: 80,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <IconButton
            onClick={() => {
              handleUserClick(params.row.id);
            }}
            color="black"
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const getAllUsers = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_API_URL}/users`, {
        headers,
      });

      if (response.status === 200) {
        const data = await response.data.users;
        const modified = data.map((user) => {
          const nome_completo = `${user.nome} ${user.sobrenome}`;
          return {
            ...user,
            nome_completo,
          };
        });
        setAllUsers(modified);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar usuários.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <div className="card">
        <header className="card-header">
          <p
            className="card-header-title"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <span className="icon">
                <i className="mdi mdi-ballot"></i>
              </span>
              Usuários
            </div>

            <div>
              <button
                className="button is-primary"
                type="button"
                onClick={handleOpenModal}
              >
                Pré-aprovar
              </button>
            </div>
          </p>
        </header>
        <div
          className="card-content"
          style={{ display: 'flex', margin: '0px 0px 30px 0px' }}
        >
          {/* <div>
                        <button className="button" onClick={handleOpenModal}>Pré-aprovar</button>
                    </div>                 */}

          <DataGrid
            rows={allUsers}
            columns={userTableColumns}
            pageSize={5}
            disableSelectionOnClick
            getRowHeight={() => 'auto'}
            getColumnHeight={() => 'auto'}
            sx={{
              '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle': {
                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                fontSize: '16px',
                color: '#171717',
                // fontFamily: '"Nunito", sans-serif',
                lineHeight: '2.5',
                borderLeft: '5px solid transparent',
              },
            }}
          />
        </div>
      </div>
      <FormModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        modalTitle={'Pré-aprovar registro de usuário.'}
      >
        {isModalOpen && <AdminUserRegister info={info} />}
      </FormModal>
    </>
  );
};

export default AdminUserTable;
