import React from 'react';
import { BarChart } from '@mui/x-charts';

export const TypeBarChart = ({ barData }) => {
  return (
    <BarChart
      width={420}
      height={200}
      series={[{ data: barData.y }]}
      xAxis={[
        {
          scaleType: 'band',
          data: barData.x,
          //   colorMap: {
          //     type: 'ordinal',
          //     colors: generateColors('#4472c4', '#f2e5af', barData.x.length),
          //   },
        },
      ]}
    />
  );
};

export const SLABarChart = ({ SLAData }) => {
  return (
    <BarChart
      width={420}
      height={200}
      series={[
        { data: [SLAData.quantidade_sla_ok, SLAData.quantidade_sla_nok] },
      ]}
      xAxis={[{ data: ['IN SLA', 'OUT SLA'], scaleType: 'band' }]}
    />
  );
};

export const EmptyStackedBarChart = () => {
  return <BarChart width={940} height={515} series={[]}></BarChart>;
};

export const StackedBarChart = ({ stackedBarData }) => {
  const categories = Object.keys(stackedBarData[0]).filter(
    (key) => key !== 'date'
  );

  const dataset = stackedBarData.map((item) => ({
    date: item.date,
    ...categories.reduce((acc, category) => {
      acc[category] = item[category] || 0;
      return acc;
    }, {}),
  }));

  const labelKeys = {
    pdm: 'PDM',
    fornecedor: 'Fornecedor',
    frete: 'Frete',
    material: 'Material',
    solicitacao_rc: 'SRC',
  };

  const series = categories.map((category) => ({
    dataKey: category,
    label: labelKeys[category] || category,
    stacking: 'normal',
    stack: 'total',
  }));

  const xAxis = [
    {
      scaleType: 'band',
      dataKey: 'date',
    },
  ];

  return (
    <BarChart
      dataset={dataset}
      xAxis={xAxis}
      series={series}
      width={940}
      height={515}
      grid={{ horizontal: true }}
    />
  );
};
