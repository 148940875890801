import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MainLayout from '../layouts/MainLayout';
import './css/Formularios.css';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import { useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import AlertMessage from '../layouts/AlertMessage';

const FormEditarPDM = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;
  const { material, target, route } = location.state;

  const [dados, setDados] = useState(material[target]);

  const [pdmName, setPdmName] = useState(target);
  // const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const [fields, setFields] = useState([]);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  useEffect(() => {
    // Construir a estrutura de fields com base nos dados recebidos
    const campos = Object.keys(dados).map((nomeCampo) => ({
      nome: nomeCampo,
      obrigatorio: dados[nomeCampo].Obrigatorio || false,
      valores: dados[nomeCampo].Valores || [],
    }));
    setFields(campos);
  }, [dados]);

  const unidecodeString = (str) => {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace('.', '')
      .replace(',', '')
      .toUpperCase();
  };

  const handleChangeObrigatorio = (fieldIndex, e) => {
    const updatedFields = [...fields];
    updatedFields[fieldIndex].obrigatorio = e.target.checked;
    setFields(updatedFields);
  };

  const handleChangeKey = (fieldIndex, e) => {
    const updatedFields = [...fields];
    updatedFields[fieldIndex].nome = unidecodeString(e.target.value);
    setFields(updatedFields);
  };

  const handleChangeValue = (fieldIndex, valueIndex, e) => {
    const updatedFields = [...fields];
    updatedFields[fieldIndex].valores[valueIndex] = unidecodeString(
      e.target.value
    );
    setFields(updatedFields);
  };

  const addValue = (fieldIndex) => {
    const updatedFields = [...fields];
    updatedFields[fieldIndex].valores.push('');
    setFields(updatedFields);
  };

  const removeValue = (fieldIndex, valueIndex) => {
    // if (fields[fieldIndex].valores.length > 1) {
    const updatedFields = [...fields];
    updatedFields[fieldIndex].valores.splice(valueIndex, 1);
    setFields(updatedFields);
    // }
  };

  const addField = () => {
    const newField = {
      nome: '',
      obrigatorio: false,
      valores: [''],
    };
    setFields([...fields, newField]);
  };

  const removeField = (fieldIndex) => {
    const updatedFields = [...fields];
    updatedFields.splice(fieldIndex, 1);
    setFields(updatedFields);
  };

  const replaceDash = (str) =>
    str.replace(/-/g, ' ').replace(/^\w/, (char) => char.toUpperCase());

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Filtrar campos onde a chave não é nula e a lista de valores não está vazia

    // const filteredFields = fields.filter(field => field.nome && field.valores.some(value => value.trim() !== ''));
    const filteredFields = fields.filter((field) => field.nome);

    const data = filteredFields.reduce((acc, field) => {
      // Filtrar valores para remover strings vazias
      const cleanedValues = field.valores.filter(
        (value) => value.trim() !== ''
      );

      // Se cleanedValues estiver vazio, atribuir uma lista vazia a field.valores
      acc[field.nome] = {
        Obrigatorio: field.obrigatorio,
        Valores: cleanedValues.length > 0 ? cleanedValues : [],
      };

      return acc;
    }, {});

    // const data = filteredFields.reduce((acc, field) => {
    // acc[field.nome] = { Obrigatorio: field.obrigatorio, Valores: field.valores };
    // return acc;
    // }, {});

    const payload = { [pdmName]: data };

    // // Verificar se há campos adicionais além do nome
    // if (Object.keys(payload[pdmName]).length === 0) {
    // alert("Por favor, complete pelo menos um campo antes de enviar.");
    // return;
    // }

    let headers = {
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/pdms/editar-pdm`,
        payload,
        {
          params: { categoria: material.categoria },
          headers,
        }
      );
      if (response.status === 201) {
        // alert("Template de PDM enviado para análise.")
        setAlertMessage('Template de PDM enviado para análise.');
        setAlertSeverity('success');
        setAlertOpen(true);
        setPdmName('');
        setFields([]);
        // navigate('/meic/materiais');
        // location.state && location.state.route === "equips" ? navigate('/meic/equipamentos') : navigate('/meic/materiais')
        location.state && location.state.route === 'equips'
          ? setNavigateTo('/meic/equipamentos')
          : setNavigateTo('/meic/materiais');
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || "Ocorreu um erro.")
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao enviar template de PDM.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    return () => {
      setAlertOpen(false);
      if (navigateTo) {
        navigate(navigateTo, { replace: true });
      }
    };
  }, []);

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo, { replace: true });
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Engenharia</li>
                    {location.state && route === 'equips' && (
                      <li>Equipamentos</li>
                    )}
                    {location.state &&
                      (route === 'mats' || route === 'pdms') && (
                        <li>Materiais</li>
                      )}
                    {location.state && (
                      <li>{target ? replaceDash(target) : ''}</li>
                    )}
                    <li>Editar PDM</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Editar PDM</h1>
                  </div>
                </div>
                <div className="level-right" style={{ display: 'none' }}>
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Editar template do PDM
                </p>
              </header>
              <div
                className="card-content"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <form onSubmit={handleSubmit}>
                  {fields.map((field, fieldIndex) => (
                    <div className="field" key={fieldIndex}>
                      <div
                        className="div-input-form-pdm"
                        style={{
                          paddingTop: '20px',
                          display: 'flex',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div
                          className="div-required"
                          style={{
                            margin: '0px 30px 0px 0px',
                          }}
                        >
                          <label className="label">Obrigatório</label>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Checkbox
                              checked={field.obrigatorio}
                              onChange={(e) =>
                                handleChangeObrigatorio(fieldIndex, e)
                              }
                              sx={{
                                '& .MuiSvgIcon-root': {
                                  fill: '#2B3BED',
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            marginRight: '15px',
                          }}
                        >
                          <label className="label">
                            Campo
                            <span
                              className="required-simbol"
                              aria-hidden="true"
                            >
                              *
                            </span>
                          </label>
                          <div className="div-input">
                            <input
                              type="text"
                              className="input"
                              placeholder="Campo"
                              required
                              value={field.nome}
                              onChange={(e) => handleChangeKey(fieldIndex, e)}
                              style={{
                                width: '400px',
                              }}
                            />
                          </div>
                        </div>
                        <div style={{ width: '700px' }}>
                          <label className="label">
                            Valores
                            <Tooltip
                              placement="right-end"
                              title={
                                <div
                                  style={{
                                    textAlign: 'justify',
                                    fontSize: '15px',
                                  }}
                                >
                                  <b>
                                    <p>
                                      {
                                        'Adicione opções de valores que deseje atribuir ao campo, caso queira limitar as opções para o usuário informar. Exemplo:'
                                      }
                                    </p>
                                  </b>
                                  <br></br>
                                  <div
                                    style={{
                                      display: 'flex',
                                      width: '100%',
                                    }}
                                  >
                                    <div
                                      style={{
                                        paddingRight: '10px',
                                        width: '100%',
                                      }}
                                    >
                                      <b>
                                        <p>Campo</p>
                                      </b>
                                      <input
                                        type="text"
                                        className="input"
                                        disabled
                                        value="Cor"
                                        style={{
                                          width: '100%',
                                        }}
                                      />
                                    </div>
                                    <br></br>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                      }}
                                    >
                                      <b>
                                        <p>Valores</p>
                                      </b>
                                      <input
                                        type="text"
                                        className="input"
                                        disabled
                                        value="Azul"
                                        style={{
                                          width: '100%',
                                          marginBottom: '10px',
                                        }}
                                      />
                                      <input
                                        type="text"
                                        className="input"
                                        disabled
                                        value="Verde"
                                        style={{
                                          width: '100%',
                                        }}
                                      />
                                      <p
                                        style={{
                                          textAlign: 'center',
                                          fontWeight: 'bold',
                                          fontSize: '15px',
                                        }}
                                      >
                                        ...
                                      </p>
                                      <br></br>
                                    </div>
                                  </div>

                                  <br></br>
                                  <b>
                                    <p>
                                      {
                                        'Ou deixe sem valores, caso queira um campo livre para o usuário preencher como desejar. Exemplo:'
                                      }
                                    </p>
                                  </b>
                                  <br></br>
                                  <b>
                                    <p>Campo</p>
                                  </b>
                                  <input
                                    type="text"
                                    className="input"
                                    disabled
                                    value="Largura"
                                    style={{
                                      width: '50%',
                                      marginBottom: '10px',
                                    }}
                                  />
                                </div>
                              }
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    maxWidth: '35rem',
                                  },
                                },
                              }}
                              arrow
                            >
                              <span
                                style={{
                                  color: '#ff3e3e',
                                  marginLeft: '6px',
                                  padding: '2px 7px 0px',
                                  fontSize: '13px',
                                  border: '1px solid #ff3e3e',
                                  borderRadius: '15px',
                                }}
                              >
                                ?
                              </span>
                            </Tooltip>
                          </label>
                          {field.valores.map((value, valueIndex) => (
                            <React.Fragment key={valueIndex}>
                              <div
                                className="div-input"
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <input
                                  type="text"
                                  className="input"
                                  placeholder="Valor"
                                  value={value}
                                  onChange={(e) =>
                                    handleChangeValue(fieldIndex, valueIndex, e)
                                  }
                                  style={{
                                    marginBottom: '10px',
                                    width: '650px',
                                  }}
                                />
                                <div className="div-btn-delete-valor">
                                  <IconButton
                                    style={{
                                      padding: '10px 8px 0px 15px',
                                    }}
                                    color="secondary"
                                    onClick={() =>
                                      removeValue(fieldIndex, valueIndex)
                                    }
                                  >
                                    <ClearSharpIcon fontSize="small" />
                                  </IconButton>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                          <div
                            className="div-add-linha"
                            onClick={() => addValue(fieldIndex)}
                            style={{
                              width: '612px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'flex-end',
                            }}
                          >
                            <span
                              className="icon"
                              style={{
                                fontSize: '16px',
                                color: '#2B3BED',
                                cursor: 'pointer',
                              }}
                            >
                              <i className="fa-solid fa-plus"></i>
                            </span>
                            <label
                              className="label"
                              style={{
                                fontSize: '13px',
                                cursor: 'pointer',
                                marginTop: '12px',
                              }}
                            >
                              Adicionar valor
                            </label>
                          </div>
                        </div>
                        {/* <div className='div-delete-linha' style={{ margin: '37px 0px 0px 0px', width: '100%'}} >
                                                <IconButton color="secondary" style={{ padding: '0px 8px 0px 8px', display: 'flex', flexDirection: 'column'  }} onClick={() => removeField(fieldIndex)}>
                                                    <DeleteIcon fontSize='small'  />
                                                    <label className='label' style={{fontSize: '13px', cursor: 'pointer', margin: '5px 0px 5px 0px' }}>
                                                        Deletar campo
                                                    </label>
                                                </IconButton>
                                            </div> */}
                        <div
                          className="div-delete-linha"
                          onClick={() => removeField(fieldIndex)}
                          style={{
                            margin: '37px 0px 0px 10px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <span
                            className="icon"
                            style={{
                              padding: '0px 8px 0px 8px',
                              fontSize: '16px',
                              color: '#2B3BED',
                              cursor: 'pointer',
                            }}
                          >
                            <i className="fa-solid fa-trash-can"></i>
                          </span>
                          <label
                            className="label"
                            style={{
                              fontSize: '13px',
                              cursor: 'pointer',
                              margin: '5px 0px 5px 0px',
                            }}
                          >
                            Deletar campo
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div
                    style={{
                      margin: '40px 0px 20px 0px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      className="button is-secondary"
                      onClick={addField}
                      style={{
                        borderRadius: '20px',
                        marginBottom: '7px',
                      }}
                    >
                      <span className="icon">
                        <i className="fa-solid fa-plus"></i>
                      </span>
                    </button>
                    <span style={{ cursor: 'pointer' }} onClick={addField}>
                      Adicionar Campo
                    </span>
                  </div>
                  <div
                    style={{
                      margin: '40px 0px 20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button
                      className="button is-primary"
                      type="submit"
                      style={{ width: '160px' }}
                    >
                      <span className="icon">
                        <i className="fa-solid fa-paper-plane"></i>
                      </span>
                      <span>Enviar</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default FormEditarPDM;
