import React from 'react';

const CheckBoxAceHorizontal = ({ isChecked, onChange }) => {
  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">Ativo</label>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            <input
              className="checkbox"
              type="checkbox"
              checked={isChecked}
              onChange={onChange}
            />
            <span className="checkmark"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckBoxAceHorizontal;
