import React, { useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import axios from 'axios';
import Select from 'react-select';
import AlertMessage from '../../layouts/AlertMessage';

const MOForm = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [moDescricao, setMODescricao] = useState('');
  const [moUnidade, setMOUnidade] = useState('');
  const [moCbo, setMOCbo] = useState('');
  const [moPreco, setMOPreco] = useState(0);

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setModalOpen(false);
  };

  const handleCad = async (e) => {
    e.preventDefault();
    let moRequest = {
      fcbm: moCbo,
      desc: moDescricao,
      preco: moPreco,
      unidade: moUnidade,
    };
    const headers = {
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/serv/`,
        moRequest,
        { headers }
      );
      const data = await response.data;
      if (response.status === 201) {
        // alert("Mão de obra cadastrada com sucesso.")
        setAlertMessage('Mão de obra cadastrada com sucesso.');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      // alert(error.response.data.detail)
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao cadastrar mão de obra'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const mockHandle = async (e) => {
    e.preventDefault();
    let moRequest = {
      desc: moDescricao,
      preco: parseFloat(moPreco),
      unidade: moUnidade,
    };
  };

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Cadastrar mão de obra
                </p>
              </header>
              <div className="card-content">
                <form name="MOForm" onSubmit={handleCad}>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Descrição
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            required
                            value={moDescricao}
                            onChange={(e) => setMODescricao(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-file-lines"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Unidade
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <div className="select is-fullwidth">
                            <select
                              className="custom-select-style"
                              name="unidade"
                              value={moUnidade}
                              required
                              placeholder={
                                <i className="fa-solid fa-layer-group"></i>
                              }
                              onChange={(e) => setMOUnidade(e.target.value)}
                            >
                              <option value={''}>Selecione</option>
                              <option value={'CHI'} key={'CHI'}>
                                CHI
                              </option>
                              <option value={'CHP'} key={'CHP'}>
                                CHP
                              </option>
                              <option value={'HH'} key={'HH'}>
                                HH
                              </option>
                              <option value={'KG'} key={'KG'}>
                                KG
                              </option>
                              <option value={'M'} key={'M'}>
                                M
                              </option>
                              <option value={'M2'} key={'M2'}>
                                M2
                              </option>
                              <option value={'M3'} key={'M3'}>
                                M3
                              </option>
                              <option value={'KG'} key={'KG'}>
                                KG
                              </option>
                              <option value={'UN'} key={'UN'}>
                                UN
                              </option>
                            </select>
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-layer-group"></i>
                            </span>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Preço
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="number"
                            value={moPreco}
                            required
                            onChange={(e) => setMOPreco(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-coins"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      margin: '40px 0px 20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button className="button is-primary">
                      <span className="icon">
                        <i className="fa-solid fa-floppy-disk"></i>
                      </span>
                      <span>Cadastrar</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default MOForm;
