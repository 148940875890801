import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './listas.css';
import AlertMessage from '../../../layouts/AlertMessage';

const ListaMateriaisAvulsaShare = ({ listaId, info }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleTagChange = (tg) => {
    const selected = Array.from(
      tg.target.selectedOptions,
      (option) => option.value
    );
    setSelectedUsers(selected);
  };

  const getUsersMain = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/users/user-main/`,
        { headers }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAllUsers(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar usuários'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const showCustomAlert = (message) => {
    const alertBox = document.getElementById('custom-alert');
    const alertMessage = document.getElementById('alert-message');
    alertMessage.innerHTML = message;
    alertBox.style.display = 'flex';

    const closeAlert = document.getElementById('close-alert');
    closeAlert.onclick = () => {
      alertBox.style.display = 'none';
    };
  };

  window.onclick = (event) => {
    const alertBox = document.getElementById('custom-alert');
    if (event.target === alertBox) {
      alertBox.style.display = 'none';
    }
  };

  const handleShare = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/listas/lista-material/compartilhar/`,
        selectedUsers,
        {
          params: { lista_id: listaId },
          headers,
        }
      );
      if (response.status === 200) {
        const dataString = response.data
          .map((item) =>
            Object.entries(item)
              .map(([key, value]) => `${key}: ${value}`)
              .join('<br />')
          )
          .join('<br><br>');
        showCustomAlert(dataString);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao compartilhar lista'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getUsersMain();
  }, []);

  return (
    <>
      <section className="main-content">
        <div className="container">
          <div className="card">
            <div className="card-content">
              <form name="share" onSubmit={handleShare}>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      Users
                      <span className="required-symbol"> *</span>
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow">
                      <div className="control has-icons-left">
                        <div className="select is-multiple is-fullwidth">
                          <select
                            multiple
                            name="tags"
                            value={selectedUsers}
                            onChange={handleTagChange}
                            required
                            size={'4'}
                          >
                            <option value="" disabled></option>
                            {allUsers &&
                              allUsers.map((user) => (
                                <option key={user.id} value={user.id}>
                                  {user.nome} {user.sobrenome}
                                </option>
                              ))}
                          </select>
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-tag"></i>
                          </span>
                        </div>
                      </div>
                      <p
                        className="help"
                        style={{
                          fontSize: '0.80rem',
                          marginTop: '10px',
                        }}
                      >
                        <i>
                          Segure <strong>CTRL</strong> para selecionar mais de
                          uma TAG.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
                <button className="button">Compartilhar</button>
              </form>
            </div>
          </div>
        </div>
        <div id="custom-alert" className="custom-alert">
          <div className="custom-alert-content">
            <span id="close-alert" className="close-alert">
              &times;
            </span>
            <div id="alert-message" className="alert-message"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ListaMateriaisAvulsaShare;
