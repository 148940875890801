import React, { useState } from 'react';
import MainLayout from './MainLayout';
import { useNavigate } from 'react-router-dom';
// import { ReactComponent as WarningIcon } from '../../resources/NotAuthorized2.svg';

const NotAuthorizedPage = () => {
  return (
    <>
      <MainLayout>
        <div className="main-section">
          <section
            className="section is-main-section"
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '800px',
            }}
          >
            <div
              className="box"
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '80%',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '750px',
                fontSize: '40px',
                color: '#a51b34',
              }}
            >
              <i
                style={{ fontSize: '60px', padding: '20px' }}
                className="fa-solid fa-circle-exclamation"
              ></i>
              <label
                style={{
                  paddingLeft: '20px',
                  fontWeight: 'bold',
                  fontFamily: 'Arial',
                }}
              >
                ERROR 401: NOT AUTHORIZED
              </label>
              {/* <WarningIcon style={{ width: '750px'}} /> */}
              <img
                src="/NotAuthorized2.png"
                style={{ width: '50%' }}
                alt="NotAuth"
              />
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default NotAuthorizedPage;
