import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import { useNavigate } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Typography,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import AlertMessage from '../../layouts/AlertMessage';

const FreteHome2 = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const [allFretes, setAllFretes] = useState([]);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  const getAllFretes = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/frete/fretes/`,
        { headers }
      );
      if (response.status == 200) {
        const data = await response.data;
        setAllFretes(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar fretes'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const inspectOrcamento = async (val) => {
    return navigate(`/ext/frete-orc/detail/${val}`);
  };

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    getAllFretes();
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Portal de Fornecedores</li>
                    <li>Fretes</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Fretes</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button
                      className="button is-primary"
                      onClick={() => navigate('/fretes/form/')}
                    >
                      Nova solicitação
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>
                      <strong>Referência</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Origem</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Destino</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Tipo</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Pacotes</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Orçamentos</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Orçados</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allFretes &&
                    allFretes.map((item, i) => (
                      <FreteRow
                        key={i}
                        frete={item.frete}
                        orcamentos={item.orcamentos}
                        inspectOrcamento={inspectOrcamento}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

const FreteRow = ({ frete, orcamentos, inspectOrcamento }) => {
  const [open, setOpen] = useState(false);
  const hasOrcamentos = orcamentos && orcamentos.length > 0;

  const totalOrcamentos = orcamentos.length;
  const orcadosTrue = orcamentos.filter((orc) => orc.orcado).length;
  const nPacotes = frete.pacotes.split(' ').length;

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          {hasOrcamentos && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{frete.referencia}</TableCell>
        <TableCell>{frete.origem}</TableCell>
        <TableCell>{frete.destino}</TableCell>
        <TableCell>{frete.tipo}</TableCell>
        <TableCell>{nPacotes}</TableCell>
        <TableCell>{totalOrcamentos}</TableCell>
        <TableCell align="center">{orcadosTrue}</TableCell>
      </TableRow>
      {hasOrcamentos && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                margin={0}
                sx={{
                  backgroundColor: '#f0f4ff',
                  border: '1px solid #c0c0c0',
                  borderRadius: '4px',
                  padding: '8px',
                }}
              >
                {/* <Typography variant="h6" gutterBottom component="div">
                                    Orçamentos
                                </Typography> */}
                <Table size="small" aria-label="orcamentos">
                  <TableHead>
                    <TableRow>
                      <TableCell>Fornecedor</TableCell>
                      <TableCell>Orçado</TableCell>
                      <TableCell>Valor</TableCell>
                      <TableCell>Prazo Coleta</TableCell>
                      <TableCell>Prazo Entrega</TableCell>
                      <TableCell>Pagamento</TableCell>
                      <TableCell>Ação</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orcamentos &&
                      orcamentos.map((orc, orc_i) => (
                        <TableRow key={orc_i}>
                          <TableCell>{orc.fornecedor}</TableCell>
                          <TableCell>
                            <input
                              type="checkbox"
                              checked={orc.orcado}
                              readOnly
                            />
                          </TableCell>
                          <TableCell>
                            {orc.orcado ? formatPreco(orc.preco) : '-'}
                          </TableCell>

                          <TableCell>
                            {orc.orcado ? orc.prazo_coleta : '-'}
                          </TableCell>

                          <TableCell>
                            {orc.orcado ? orc.prazo_entrega : '-'}
                          </TableCell>

                          <TableCell>
                            {orc.orcado ? orc.forma_pagamento : '-'}
                          </TableCell>

                          <TableCell>
                            <IconButton
                              onClick={() => inspectOrcamento(orc.id)}
                            >
                              <i
                                className="fa-solid fa-magnifying-glass"
                                style={{
                                  color: '#2B3BED',
                                }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default FreteHome2;
