import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  const modalStyle = {
    display: isOpen ? 'block' : 'none',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    padding: '5px',
    zIndex: 999,
    minHeight: '10vh',
    maxHeight: '70vh',
    minWidth: '100vh',
    overflowY: 'auto',
    borderRadius: '6px',
  };

  return (
    <div className="box" style={modalStyle}>
      <div className="has-background-primary-dark">
        <i className="fa fa-times m-2" onClick={onClose}></i>
      </div>
      {children}
    </div>
  );
};

export default Modal;
