import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import AlertMessage from '../../layouts/AlertMessage';

const TabelaFornecedores = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [targetSearch, setTargetSearch] = useState('');
  const [fornecedores, setFornecedores] = useState([]); // Estado para armazenar os dados da fila
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  useEffect(() => {
    const fetchFornecedores = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/fornecedor`,
          { headers }
        );

        if (response.data && response.data.fornecedores) {
          // Transformar os dados recebidos para se adequar à estrutura do DataGrid
          const lista_fornecedores = response.data.fornecedores.map(
            (fornecedor, index) => ({
              id: fornecedor.id,
              razao_social: fornecedor.razao_social,
              email: fornecedor.email,
              cnpj: fornecedor.cnpj,
              cpf: fornecedor.cpf,
              telefone1: fornecedor.telefone1,
              tags: fornecedor.tags,
              sap_id: fornecedor.sap_id,
            })
          );

          setFornecedores(lista_fornecedores);
          // setListaTarefas(response.data.fila_tarefas);
        }
      } catch (error) {
        console.error('Erro ao buscar fornecedores: ', error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao buscar fornecedores'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    fetchFornecedores();
  }, [REACT_APP_BASE_API_URL]);

  const handleFilterFornec = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/fornecedor/razao-social/`,
        {
          params: {
            razao_social: targetSearch,
          },
          headers: headers,
        }
      );
      const data = await response.data;
      if (response.status === 200) {
        setFornecedores(data);
      }
    } catch (error) {
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao filtrar fornecedores'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const columns = [
    // { field: 'id', headerName: 'ID', flex: 2},
    { field: 'sap_id', headerName: 'SAP', flex: 2 },
    { field: 'razao_social', headerName: 'Razão social', flex: 3 },
    { field: 'email', headerName: 'Email', flex: 3 },
    { field: 'cnpj', headerName: 'CNPJ', flex: 3 },
    { field: 'cpf', headerName: 'CPF', flex: 3 },
    { field: 'telefone1', headerName: 'Telefone', flex: 3 },
    {
      field: 'tags',
      headerName: 'TAGs',
      flex: 2,
      renderCell: (params) => (
        <div>
          {params.row.tags &&
            params.row.tags !== '' &&
            params.row.tags.split(' ').map((tag) => (
              <p className="tag is-info is-light" style={{ margin: '1px' }}>
                {tag}
              </p>
            ))}
        </div>
      ),
    },
  ];

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Geral</li>
                    <li>Tabela de fornecedores</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Tabela de fornecedores</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Informações gerais do fornecedor
                </p>
              </header>
              <div className="card-content">
                {/* <div className="div-select-search">
                  <div className="search-input" >
                    <div className="info-search">
                      <label>Buscar cadastro de material</label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable= {true}
                        name="color"
                        options={filaTarefas.map(tarefa => ({
                          value: tarefa.id, 
                          label: tarefa.idSup + " " + tarefa.resumo
                        }))}
                        placeholder={<i className="fa-solid fa-magnifying-glass"></i>}
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            setTargetTarefa(selectedOption.value);
                            editTarefa(selectedOption.value);
                          } else {
                            // Caso o input do select seja limpo
                            setTargetTarefa('');
                          }
                        }}
                      />
                    </div>
                  </div>
                </div> */}

                <div className="div-select-search">
                  <form name="SinapiSearch" onSubmit={handleFilterFornec}>
                    <div
                      className="search-input"
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <div className="info-search">
                        <div className="field">
                          <label className="label">Buscar </label>
                          <input
                            className="input"
                            type="text"
                            onChange={(e) => setTargetSearch(e.target.value)}
                          />
                          {/* <p className="help">Separe os termos por vírgula</p>                                          */}
                        </div>
                      </div>
                      <div
                        className="button-search"
                        style={{
                          padding: '0px 15px 0px 15px',
                        }}
                      >
                        <button className="button is-primary">Buscar</button>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="TabelaFornecedores">
                      <div
                        style={{
                          height: 600,
                          width: '100%',
                        }}
                      >
                        <DataGrid
                          rows={fornecedores}
                          columns={columns}
                          pageSize={5}
                          disableSelectionOnClick
                          getRowHeight={() => 'auto'}
                          sx={{
                            fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
                            '& .MuiDataGrid-cell': {
                              display: 'flex',
                              alignItems: 'flex-start', // Alinha o conteúdo no topo
                              paddingTop: '4px', // Ajuste conforme necessário
                              paddingBottom: '4px',
                              border: '0px',
                              // lineHeight: '2.5',
                              fontSize: '16px',
                            },
                            '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                              {
                                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                                fontSize: '16px',
                                color: '#171717',
                                // fontFamily: '"Nunito", sans-serif',
                                lineHeight: '2.5',
                                borderLeft: '5px solid transparent',
                              },
                            '& .MuiDataGrid-virtualScroller': {
                              overflowX: 'hidden',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default TabelaFornecedores;
