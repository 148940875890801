import React, { useEffect, useContext, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import FormModal from '../../layouts/FormModal';
import MisInclusionView from '../../functional/MisInclusionView';
import CapexMisDetailModal from './CapexMisDetailModal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import AlertMessage from '../../layouts/AlertMessage';
import { useUser } from '../../../context/UserContext';

const CapexPopulateView = () => {
  const { capex_id } = useParams();
  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;
  const location = useLocation();
  const [capexDetails, setCapexDetails] = useState({});
  const [capexItems, setCapexItems] = useState([]);
  const [areasESubareas, setAreasESubareas] = useState([]);
  const [openRows, setOpenRows] = useState({});
  const [misObject, setMisObject] = useState({});
  const [subareaObj, setSubareaObj] = useState('');
  const [nLagoasBuffer, setNLagoasBuffer] = useState(0);
  const [areaSilos, setAreaSilos] = useState(0.0);
  const [nSAS, setNSAS] = useState(0);
  const [nBVs, setNBVs] = useState(0);
  const [nBHs, setNBHs] = useState(0);
  const [nSSS, setNSSS] = useState(0);
  const [sRemovido, setSRemovido] = useState(0.0);
  const [nMotogeradores, setNMotogeradores] = useState(0);
  const [volBGSProduzido, setVolBGSProduzido] = useState(0.0);
  const [volBMProduzido, setVolBMProduzido] = useState(0.0);
  const { gruposUsuario } = useUser();
  const [user, setUser] = useState({
    grupos: gruposUsuario,
  });
  const allowedRoles = ['ADMINISTRADOR'];
  const [areaStr, setAreaStr] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalOption, setModalOption] = useState(null);
  const [requestMis, setRequestMis] = useState([]);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  const getCapex = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/capex/detail/`,
        {
          params: {
            capex_id: capex_id,
          },
          headers: headers,
        }
      );
      const data = await response.data;
      setCapexDetails(data.capex);
      setCapexItems(data.linhas);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar detalhes do Capex'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getAreas = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/tools/areas/`,
        { headers }
      );
      const data = await response.data;

      const formatedData = data.map((area) => {
        const updatedSubareas = Object.values(area.data.subareas).map(
          (subarea) => {
            return {
              ...subarea,
              qtd_mis: 0,
              cumpreco: 0.0,
              associatedMis: [],
            };
          }
        );

        return {
          ...area,
          data: {
            subareas: updatedSubareas,
          },
        };
      });

      setAreasESubareas(formatedData);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar áreas'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const handlePrePopulate = async (e) => {
    e.preventDefault();

    // Extrair apenas os IDs dos objetos em requestMis
    const requestObjects = requestMis.map((mis) => ({
      mis_id: mis.id,
      quantidade: parseFloat(mis.quantidade_mult),
    }));
    let obj = {
      mis: requestObjects,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/capex/populate2/`,
        requestObjects,
        {
          params: {
            capex_id: capexDetails.id,
          },
          headers: headers,
        }
      );
      const data = await response.data;
      if (response.status === 200) {
        // alert('Capex populado!.')
        setAlertMessage('Capex populado!');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao popular capex'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const onAddHandler = (obj) => {
    const misObj = {
      ...obj,
      quantidade_mult: 1,
    };

    if (!requestMis.some((item) => item.id === obj.id)) {
      const updatedAreas = areasESubareas.map((area) => {
        const updatedSubareas = Object.values(area.data.subareas).map(
          (subarea) => {
            if (
              obj.area === area.area[1] &&
              obj.subarea === subarea.cod.slice(2)
            ) {
              const newCumpreco =
                parseFloat(subarea.cumpreco) + parseFloat(obj.preco);
              const newAssociatedMis = [
                ...subarea.associatedMis,
                {
                  display: misObj.display,
                  quantidade_mult: misObj.quantidade_mult,
                  original_preco: misObj.preco,
                },
              ];

              return {
                ...subarea,
                cumpreco: newCumpreco,
                qtd_mis: subarea.qtd_mis + 1,
                associatedMis: newAssociatedMis,
              };
            }
            return subarea;
          }
        );

        return {
          ...area,
          data: { ...area.data, subareas: updatedSubareas },
        };
      });

      setRequestMis([...requestMis, misObj]);
      setAreasESubareas(updatedAreas);
    } else {
      // alert(`MIS ${obj.display} já incluido`)
      setAlertMessage(`MIS ${obj.display} já incluido`);
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleRemoveMis = (misObjeto, subarea) => {
    const updatedSubarea = { ...subarea }; // Se for um objeto único
    // const removedMisDisplay = updatedSubarea.associatedMis[misIndexToRemove];

    const misIndexToRemove = updatedSubarea.associatedMis.findIndex(
      (display) => display === misObjeto.display
    );

    const updatedrequestMis = requestMis.filter(
      (mis) => mis.display !== misObjeto.display
    );

    const updatedAreasESubareas = areasESubareas.map((area) => {
      if (area.data.subareas.some((sub) => sub.id === subarea.id)) {
        return {
          ...area,
          data: {
            ...area.data,
            subareas: area.data.subareas.map((sub) => {
              if (sub.id === subarea.id) {
                const updatedSub = { ...sub }; // Copie o sub objeto para evitar mutação direta
                updatedSub.qtd_mis -= 1;
                updatedSub.cumpreco -=
                  requestMis.find((mis) => mis.display === misObjeto.display)
                    .preco *
                  requestMis.find((mis) => mis.display === misObjeto.display)
                    .quantidade_mult;
                updatedSub.associatedMis.splice(misIndexToRemove, 1);
                return updatedSub; // Retorne o objeto atualizado
              }
              return sub; // Retorne o objeto não modificado
            }),
          },
        };
      }
      return area;
    });

    setRequestMis(updatedrequestMis);
    setAreasESubareas(updatedAreasESubareas);
    handleCloseModal();
  };

  const handleOpenModal = (content, area_str, optionModal) => {
    setAreaStr(area_str);
    setModalContent(content);
    setModalOption(optionModal);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setModalOpen(false);
    setModalOption(null);
  };

  const renderModalContent = (info) => {
    switch (modalOption) {
      case 'ADICIONAR MIS':
        return (
          <MisInclusionView
            onAddHandler={onAddHandler}
            area_str={areaStr}
            info={info}
          />
        );

      case 'DETALHES MIS':
        return (
          <CapexMisDetailModal
            handleRemoveMis={handleRemoveMis}
            misObj={misObject}
            subArea={subareaObj}
            area_str={areaStr}
            info={info}
          />
        );
    }
  };

  const handleRowClick = (name) => {
    setOpenRows({
      ...openRows,
      [name]: !openRows[name],
    });
  };

  const debugPopulate = () => {};

  const handleMacroInputChange = (e, area, subarea) => {
    const value = parseInt(e.target.value);
    const tgstring = `${area}${subarea}`;
    const updatedrequestMis = requestMis.map((mis) => {
      if (mis.area === area && mis.subarea === subarea) {
        return { ...mis, quantidade_mult: value };
      }
      return mis;
    });
    const updatedAreas = areasESubareas.map((area, areaIndex) => {
      const updatedSubareas = Object.values(area.data.subareas).map(
        (subarea) => {
          if (subarea.cod === tgstring) {
            const newMis = subarea.associatedMis.map((mis) => {
              if (mis.display.substring(2, 6) === tgstring) {
                return { ...mis, quantidade_mult: value };
              }
              return mis;
            });
            const newPreco = newMis.reduce(
              (total, mis) => total + mis.quantidade_mult * mis.original_preco,
              0
            );
            return {
              ...subarea,
              associatedMis: newMis,
              cumpreco: newPreco,
            };
          }
          return subarea;
        }
      );
      return {
        ...area,
        data: { ...area.data, subareas: updatedSubareas },
      };
    });

    switch (tgstring) {
      case '0200':
        setNBVs(value);
        break;
      case '0500':
        setNBHs(value);
        break;
      case '0261':
        setNSAS(value);
        break;
      case '0301':
        setNSSS(value);
        break;
      case '0401':
        setNLagoasBuffer(value);
        break;
      default:
        break;
    }

    setRequestMis(updatedrequestMis);
    setAreasESubareas(updatedAreas);
  };

  useEffect(() => {
    getCapex();
    getAreas();
  }, []);

  useEffect(() => {
    const totalPreco = areasESubareas.reduce(
      (total, area) => total + area.cumpreco,
      0
    );
    setCapexDetails((prevDetails) => ({
      ...prevDetails,
      custo: totalPreco,
    }));
  }, [areasESubareas]);

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Geral</li>
                    <li>Tabela de empreendimentos</li>
                    <li>{location.state.empreendimento}</li>
                    <li>CAPEX</li>
                    {location.state &&
                      location.state.fluxo === 'Detalhes capex' && (
                        <li>Detalhes capex </li>
                      )}
                    <li>Popular </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Popular</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item" style={{ display: 'none' }}></div>
                </div>
              </div>
            </div>
          </section>
          <button className="button" onClick={() => debugPopulate()}>
            DEBUGAR
          </button>
          <section className="section is-main-section">
            <div className="card has-table">
              <header className="card-header">
                <p
                  className="card-header-title"
                  style={{ padding: '12px 0px 12px 12px' }}
                >
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Informações gerais do capex
                </p>
              </header>
              <div style={{ padding: '1.5rem' }}>
                <table className="table is-rounded">
                  <thead style={{ backgroundColor: '#2e323a' }}>
                    <tr>
                      <th style={{ color: 'white' }}>ID</th>
                      <th style={{ color: 'white' }}>EMPREENDIMENTO</th>
                      <th style={{ color: 'white' }}>PROJETO</th>
                      <th style={{ color: 'white' }}>FASE</th>
                      <th style={{ color: 'white' }}>REV</th>
                      <th style={{ color: 'white' }}>CUSTO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{capexDetails.did}</td>
                      <td>{capexDetails.empreendimento}</td>
                      <td>{capexDetails.projeto}</td>
                      <td>
                        {capexDetails.fase && (
                          <p
                            className={`${capexDetails.fase.charAt(0) === 'C' ? 'tag is-danger' : capexDetails.fase.charAt(0) === 'B' ? 'tag is-warning' : 'tag is-success'}`}
                          >
                            {capexDetails.fase}
                          </p>
                        )}
                      </td>
                      <td>{capexDetails.rev}</td>
                      <td>{formatPreco(capexDetails.custo)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <form name="PopulateCapexForm" onSubmit={handlePrePopulate}>
              <div className="card">
                <header className="card-header">
                  <p
                    className="card-header-title"
                    style={{
                      padding: '12px 0px 12px 12px',
                    }}
                  >
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Números macro
                  </p>
                </header>
                <div className="card-content">
                  <div className="field is-horizontal">
                    <div
                      className="field-label is-normal"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <label className="label">BVs</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input is-rounded is-small"
                            type="number"
                            value={nBVs}
                            onChange={(e) =>
                              handleMacroInputChange(e, '02', '00')
                            }
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-asterisk"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div
                      className="field-label is-normal"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <label className="label">BHs</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input is-rounded is-small"
                            type="number"
                            value={nBHs}
                            onChange={(e) =>
                              handleMacroInputChange(e, '05', '00')
                            }
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-asterisk"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div
                      className="field-label is-normal"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <label className="label">Lagoas Buffer</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input is-rounded is-small"
                            type="number"
                            value={nLagoasBuffer}
                            onChange={(e) =>
                              handleMacroInputChange(e, '04', '01')
                            }
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-asterisk"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div
                      className="field-label is-normal"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <label className="label">Sistemas de alimentação</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input is-rounded is-small"
                            type="number"
                            value={nSAS}
                            onChange={(e) =>
                              handleMacroInputChange(e, '02', '61')
                            }
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-asterisk"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div
                      className="field-label is-normal"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <label className="label">Sistemas de separação</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input is-rounded is-small"
                            type="number"
                            value={nSSS}
                            onChange={(e) =>
                              handleMacroInputChange(e, '03', '01')
                            }
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-asterisk"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card has-table">
                <header className="card-header">
                  <p
                    className="card-header-title"
                    style={{
                      padding: '12px 0px 12px 12px',
                    }}
                  >
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                  </p>
                </header>

                <div className="card-content" style={{ padding: '1.5rem' }}>
                  <div className="b-table has-pagination">
                    <div className="table-wrapper has-mobile-cards">
                      <TableContainer
                        component={Paper}
                        style={{
                          border: '1px solid lightgrey',
                          borderRadius: '5px',
                        }}
                      >
                        <Table aria-label="collapsible table">
                          <TableHead>
                            <TableRow
                              style={{
                                display: 'flex',
                                width: '100%',
                                backgroundColor: '#2e323a',
                              }}
                            >
                              <TableCell
                                style={{
                                  width: '60px',
                                }}
                              />
                              <TableCell
                                style={{
                                  flex: '1',
                                  fontSize: '20px',
                                  color: '#ffffff',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                TAG
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  fontSize: '20px',
                                  color: '#ffffff',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                                align="right"
                              >
                                AREA
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  fontSize: '20px',
                                  color: '#ffffff',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                                align="right"
                              >
                                PREÇO
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  fontSize: '20px',
                                  color: '#ffffff',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                                align="right"
                              >
                                MIS
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  fontSize: '20px',
                                  color: '#ffffff',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                                align="right"
                              >
                                AÇÃO
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {areasESubareas.map((area, index) => (
                              <React.Fragment key={index}>
                                <TableRow
                                  style={{
                                    display: 'flex',
                                    width: '100%',
                                    backgroundColor: '#f7f7f7',
                                  }}
                                  key={area.area[1]}
                                >
                                  <TableCell
                                    style={{
                                      width: '60px',
                                      padding: '10px 15px',
                                      border: '0px',
                                    }}
                                  >
                                    <IconButton
                                      aria-label="expand row"
                                      size="small"
                                      onClick={() =>
                                        handleRowClick(area.area[0])
                                      }
                                    >
                                      {openRows[area.area[0]] ? (
                                        <KeyboardArrowUpIcon />
                                      ) : (
                                        <KeyboardArrowDownIcon />
                                      )}
                                    </IconButton>
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      fontSize: '20px',
                                      fontWeight: '400',
                                      border: '0px',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                    align="right"
                                  >
                                    {area.area[1]}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      fontSize: '20px',
                                      fontWeight: '400',
                                      border: '0px',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                    align="right"
                                  >
                                    {area.area[0]}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      fontSize: '20px',
                                      fontWeight: '400',
                                      border: '0px',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                    align="right"
                                  >
                                    {formatPreco(
                                      area.data.subareas.reduce(
                                        (totalCumpreco, subarea) =>
                                          totalCumpreco + subarea.cumpreco,
                                        0
                                      )
                                    )}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      fontSize: '20px',
                                      fontWeight: '400',
                                      border: '0px',
                                      fontFamily: '"Nunito", sans-serif',
                                    }}
                                    align="right"
                                  >
                                    {area.data.subareas.map((subarea) => {
                                      return subarea.associatedMis.map(
                                        (mis, index) => (
                                          <React.Fragment key={index}>
                                            <p
                                              key={index}
                                              className="tag"
                                              style={{
                                                margin: '1px',
                                              }}
                                            >
                                              {mis.quantidade_mult}x
                                              {mis.display}
                                            </p>
                                          </React.Fragment>
                                        )
                                      );
                                    })}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      flex: '1',
                                      border: '0px',
                                    }}
                                    align="right"
                                  >
                                    <span
                                      className="icon"
                                      style={{
                                        margin: '0px 20px',
                                      }}
                                    >
                                      <i
                                        className="fa-solid fa-circle-plus"
                                        style={{
                                          cursor: 'pointer',
                                          fontSize: '20px',
                                          color: '#2B3BED',
                                        }}
                                        onClick={() =>
                                          handleOpenModal(
                                            'ADICIONAR MIS',
                                            `${area.area[1]}`,
                                            'ADICIONAR MIS'
                                          )
                                        }
                                      ></i>
                                    </span>
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell
                                    style={{
                                      padding: 0,
                                    }}
                                    colSpan={6}
                                  >
                                    <Collapse
                                      in={openRows[area.area[0]]}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <Box
                                        style={{
                                          margin: 0,
                                        }}
                                      >
                                        <Table aria-label="purchases">
                                          <TableHead>
                                            <TableRow
                                              style={{
                                                display: 'flex',
                                                width: '100%',
                                              }}
                                            >
                                              <TableCell
                                                style={{
                                                  width: '60px',
                                                }}
                                              />
                                              <TableCell
                                                style={{
                                                  flex: '1',
                                                  fontWeight: 'bold',
                                                  fontFamily:
                                                    '"Nunito", sans-serif',
                                                  fontSize: '16px',
                                                }}
                                              >
                                                TAG
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  flex: '1',
                                                  fontWeight: 'bold',
                                                  fontFamily:
                                                    '"Nunito", sans-serif',
                                                  fontSize: '16px',
                                                }}
                                              >
                                                SUBAREA
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  flex: '1',
                                                  fontWeight: 'bold',
                                                  fontFamily:
                                                    '"Nunito", sans-serif',
                                                  fontSize: '16px',
                                                }}
                                                align="right"
                                              >
                                                PREÇO
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  flex: '1',
                                                  fontWeight: 'bold',
                                                  fontFamily:
                                                    '"Nunito", sans-serif',
                                                  fontSize: '16px',
                                                }}
                                                align="right"
                                              >
                                                MIS
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  flex: '1',
                                                }}
                                                align="right"
                                              ></TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {area.data.subareas.map(
                                              (subarea, subareaIndex) => (
                                                <TableRow
                                                  style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                  }}
                                                  key={subarea.cod}
                                                >
                                                  <TableCell
                                                    style={{
                                                      width: '60px',
                                                    }}
                                                  />
                                                  <TableCell
                                                    sx={{
                                                      flex: '1',
                                                      fontWeight: '400',
                                                      fontFamily:
                                                        '"Nunito", sans-serif',
                                                      fontSize: '16px',
                                                    }}
                                                  >
                                                    {subarea.cod}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      flex: '1',
                                                      fontWeight: '400',
                                                      fontFamily:
                                                        '"Nunito", sans-serif',
                                                      fontSize: '16px',
                                                    }}
                                                  >
                                                    {subarea.nome}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      flex: '1',
                                                      fontWeight: '400',
                                                      fontFamily:
                                                        '"Nunito", sans-serif',
                                                      fontSize: '16px',
                                                    }}
                                                  >
                                                    {formatPreco(
                                                      subarea.cumpreco
                                                    )}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      flex: '1',
                                                      fontWeight: '400',
                                                      fontFamily:
                                                        '"Nunito", sans-serif',
                                                      fontSize: '16px',
                                                    }}
                                                  >
                                                    {subarea.associatedMis &&
                                                      subarea.associatedMis.map(
                                                        (mis, misIndex) => (
                                                          <p
                                                            key={misIndex}
                                                            className="tag"
                                                            // onClick={() => handleRemoveMis(subareaIndex, misIndex, subarea)}
                                                            style={{
                                                              cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                              setMisObject(
                                                                requestMis.find(
                                                                  (misObj) =>
                                                                    misObj.display ===
                                                                    mis.display
                                                                )
                                                              );
                                                              setSubareaObj(
                                                                subarea
                                                              );
                                                              handleOpenModal(
                                                                mis.display,
                                                                `${area.area[1]}`,
                                                                'DETALHES MIS'
                                                              );
                                                            }}
                                                          >
                                                            {
                                                              mis.quantidade_mult
                                                            }
                                                            x{mis.display}
                                                          </p>
                                                        )
                                                      )}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      flex: '1',
                                                    }}
                                                  ></TableCell>
                                                </TableRow>
                                              )
                                            )}
                                          </TableBody>
                                        </Table>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      {/* <button type="submit">Popular</button> */}
                      <div
                        style={{
                          margin: '40px 0px 20px 0px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <button
                          className="button is-primary"
                          disabled={
                            !allowedRoles.some((role) =>
                              user.grupos.includes(role)
                            )
                          }
                          type="submit"
                          style={{ width: '160px' }}
                        >
                          <span className="icon">
                            <i className="fa-solid fa-compass-drafting"></i>
                          </span>
                          <span
                            style={{
                              fontSize: '20px',
                            }}
                          >
                            Popular
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <FormModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            modalTitle={modalContent}
          >
            {renderModalContent(info)}
          </FormModal>
        </div>
      </MainLayout>
    </>
  );
};

export default CapexPopulateView;
