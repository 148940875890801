import React from 'react';
import { Link } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';

const MEICHome = () => {
  return (
    <>
      <MainLayout>
        <section className="main-content">
          <div className="container">
            <div className="container has-text-centered">
              <button className="button is-secondary m-1">
                <Link to={'/meic/materiais'}>Materiais</Link>
              </button>
              <button className="button is-secondary m-1">
                <Link to={'/meic/equipamentos'}>Equipamentos</Link>
              </button>
              <button className="button is-secondary m-1">
                <Link to={'/meic/servicos'}>Serviços</Link>
              </button>
              <button className="button is-secondary m-1">
                <Link to={'/meic/composicoes'}>Composições</Link>
              </button>
              <button className="button is-secondary m-1">
                <Link to={'/meic/mis'}>MIS</Link>
              </button>
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
};

export default MEICHome;
