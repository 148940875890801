import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { ButtonGroup } from '@mui/material';
import AlertMessage from '../../layouts/AlertMessage';

const AdminGrupoEdit = ({ grupoId, info }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [targetGrupo, setTargetGrupo] = useState(null);
  const [newRoles, setNewRoles] = useState([]);
  const [allRoles, setAllRoles] = useState([]);

  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const headers = {
    'Content-Type': 'application/json',
  };

  const getGrupo = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/grupos/q/`,
        {
          params: { grupo_id: grupoId },
          headers,
        }
      );
      const data = await response.data;
      if (response.status === 200) {
        setTargetGrupo(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar detalhes do grupo.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getRoles = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/roles`,
        { headers }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAllRoles(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar roles.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleChangeGrupoDescricao = (val) => {
    setTargetGrupo({ ...targetGrupo, descricao: val });
  };

  const handleRoleChange = (index, val) => {
    const updatedRoles = newRoles.map((role, i) => {
      if (i === index) {
        const selectedRole = allRoles.find((r) => r.nome === val);
        return {
          ...role,
          nome: val,
          descricao: selectedRole ? selectedRole.descricao : '',
          id: selectedRole ? selectedRole.id : '',
          politicas: selectedRole ? selectedRole.politicas : '',
        };
      }
      return role;
    });
    setNewRoles(updatedRoles);
  };

  const addRow = () => {
    setNewRoles([
      ...newRoles,
      { nome: '', descricao: '', id: '', politicas: '' },
    ]);
  };

  const removeRow = (index, isNew) => {
    if (isNew) {
      setNewRoles(newRoles.filter((_, i) => i !== index));
    } else {
      setTargetGrupo({
        ...targetGrupo,
        roles: targetGrupo.roles.filter((_, i) => i !== index),
      });
    }
  };

  const putGrupo = async (e) => {
    e.preventDefault();
    const allRoles = [...targetGrupo.roles, ...newRoles];
    const filteredRoles = allRoles.filter(
      (role) =>
        role.id !== '' &&
        role.nome !== '' &&
        role.descricao !== '' &&
        role.roles !== ''
    );

    const rolesStrings = filteredRoles.map((role) => {
      return `${role.id}`;
    });

    const updatedGrupo = {
      ...targetGrupo,
      roles: rolesStrings.join(' '),
    };

    try {
      const response = await axios.put(
        `${REACT_APP_BASE_API_URL}/admin/grupos/`,
        updatedGrupo,
        { headers }
      );
      if (response.status === 200) {
        setAlertMessage(`Grupo ${updatedGrupo.nome} atualizado com sucesso.`);
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao atualizar grupo.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const deleteGrupo = async () => {
    try {
      const response = await axios.delete(
        `${REACT_APP_BASE_API_URL}/admin/grupos/`,
        {
          params: { grupo_id: targetGrupo.id },
        }
      );
      if (response.status === 200) {
        setAlertMessage(`Grupo ${targetGrupo.nome} excluido com sucesso.`);
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao excluir grupo.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getRoles();
    getGrupo();
  }, [grupoId]);

  if (!targetGrupo) {
    return <div>Loading...</div>; // or any other loading indicator
  }

  return (
    <>
      <div className="container" style={{ width: '900px' }}>
        <form name="updateGrupo" className="modal-form" onSubmit={putGrupo}>
          <div className="field-modal">
            <label className="label-modal">Nome</label>
            <input
              className="input-modal"
              style={{ color: '#7c8187' }}
              type="text"
              value={targetGrupo.nome}
              disabled
            />
          </div>

          <div className="field-modal">
            <label className="label-modal">
              Descrição
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
            </label>
            <input
              className="input-modal"
              type="text"
              value={targetGrupo.descricao}
              onChange={(e) => handleChangeGrupoDescricao(e.target.value)}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <table
              className="table is-fullwidth"
              style={{
                padding: '20px 20px',
                borderRadius: '5px',
                width: 'calc(100% - 40px)',
              }}
            >
              <thead>
                <tr key={'thead'}>
                  <th
                    style={{
                      borderBottom: '1px solid gray',
                    }}
                  >
                    Role
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid gray',
                    }}
                  >
                    Descrição
                  </th>
                  <th
                    style={{
                      borderBottom: '1px solid gray',
                    }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {targetGrupo.roles.map((role, idx) => (
                  <tr key={idx}>
                    <td>
                      <select value={role.nome} disabled>
                        <option value={''}>Selecione</option>
                        {allRoles.map((role) => (
                          <option key={role.id} value={role.nome}>
                            {role.nome}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <span className="">{role.descricao}</span>
                    </td>
                    {/* <td>
                                    <span className="button" onClick={() => removeRow(idx, false)}>X</span>
                                </td> */}
                    <td style={{ paddingLeft: '24px' }}>
                      <button
                        type="button"
                        className="button"
                        style={{
                          color: 'white',
                          backgroundColor: 'hsl(348deg 100% 50%)',
                          fontWeight: '600',
                          height: '27px',
                          fontWeight: 'bold',
                          padding: '0px 8px 2px',
                        }}
                        onClick={() => removeRow(idx, false)}
                      >
                        x
                      </button>
                    </td>
                  </tr>
                ))}
                {newRoles.map((role, idx) => (
                  <tr key={idx + targetGrupo.roles.length}>
                    <td>
                      <select
                        value={role.nome}
                        onChange={(e) => handleRoleChange(idx, e.target.value)}
                      >
                        <option value={''}>Selecione</option>
                        {allRoles.map((role) => (
                          <option key={role.id} value={role.nome}>
                            {role.nome}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <span className="">{role.descricao}</span>
                    </td>
                    {/* <td>
                                    <span className="button" onClick={() => removeRow(idx, true)}>X</span>
                                </td> */}

                    <td style={{ paddingLeft: '27px' }}>
                      <button
                        type="button"
                        className="button is-primary"
                        style={{
                          color: 'white',
                          backgroundColor: 'hsl(348deg 100% 50%)',
                          fontWeight: '600',
                          height: '27px',
                          fontWeight: 'bold',
                          padding: '0px 8px 2px',
                        }}
                        onClick={() => removeRow(idx, true)}
                      >
                        x
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <hr></hr>
          <div
            style={{
              display: 'flex',
              margin: '20px',
              justifyContent: 'space-between',
            }}
          >
            <ButtonGroup aria-label="outlined is-primary button group">
              <Button
                style={{
                  color: '#2B3BED',
                  backgroundColor: 'white',
                  padding: '5px 30px',
                }}
                onClick={addRow}
                type="button"
              >
                Novo Role
              </Button>

              <Button
                style={{
                  color: '#2B3BED',
                  backgroundColor: 'white',
                  padding: '5px 30px',
                }}
                type="submit"
              >
                Atualizar
              </Button>
            </ButtonGroup>
            <button
              type="button"
              className="button is-danger"
              style={{
                backgroundColor: 'hsl(348deg 100% 50%)',
                fontWeight: '600',
              }}
              onClick={deleteGrupo}
            >
              Excluir
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AdminGrupoEdit;
