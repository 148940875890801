import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

import FormModal from '../../layouts/FormModal';
import ConsultarRC from './ConsultarRC';
import AlertMessage from '../../layouts/AlertMessage';
import { useUser } from '../../../context/UserContext';

const SolicitacaoRCHomePage2 = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const [soliciatacoesUser, setSolicitacaoUser] = useState([]);
  const [rcData, setRcData] = useState(null);
  const { idUsuario } = useUser();

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRc, setSelectedRc] = useState(null);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const handleOpenModal = (rcId) => {
    setSelectedRc(rcId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedRc(null);
    setModalOpen(false);
  };

  const getSolicitacoesUser = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/solicitacao-rc/user/`,
        {
          params: { user_id: idUsuario },
          headers,
        }
      );
      const data = await response.data;
      if (response.status === 200) {
        setSolicitacaoUser(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar solicitações'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleRCCheck = async (rcId) => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/solicitacao-rc/consultar/`,
        {
          params: { nrc: parseInt(rcId) },
          headers,
        }
      );
      if (response.status === 200) {
        setRcData(response.data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar RC'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const columns = [
    { field: 'sap_rc', headerName: 'RC ID', flex: 1 },
    { field: 'titulo', headerName: 'TITULO', flex: 2 },
    { field: 'tipo', headerName: 'TIPO', flex: 1 },
    { field: 'prioridade', headerName: 'PRIORIDADE', flex: 1 },
    {
      field: 'status',
      headerName: 'STATUS',
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.status.includes('PREDECESSORA') ? (
            <p className="tag is-warning">{params.row.status}</p>
          ) : params.row.status.includes('CONC') ? (
            <p className="tag is-success">{params.row.status}</p>
          ) : params.row.status.includes('ANDAM') ? (
            <p className="tag is-success is-light">{params.row.status}</p>
          ) : params.row.status === 'PENDENTE' ? (
            <p className="tag is-warning is-light">{params.row.status}</p>
          ) : params.row.status.includes('RECUSA') ? (
            <p className="tag is-danger">{params.row.status}</p>
          ) : params.row.status.includes('ROTA') ? (
            <p className="tag is-link is-light">{params.row.status}</p>
          ) : (
            <p className="tag is-primary is-light">{params.row.status}</p>
          )}
        </>
      ),
    },
    {
      field: 'custo',
      headerName: 'CUSTO',
      flex: 1,
      renderCell: (params) => formatPreco(params.value),
    },
    { field: 'criado_em', headerName: 'DATA', flex: 1 },
    {
      field: 'acao',
      headerName: 'Ação',
      flex: 1,
      renderCell: (params) => (
        <div className="has-text-centered">
          <span
            className="icon is-medium"
            style={{ cursor: 'pointer' }}
            onClick={() => handleOpenModal(params.row.sap_rc)}
          >
            <i className="fas fa-binoculars"></i>
          </span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getSolicitacoesUser();
  }, []);

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Planejamento</li>
                    <li>Minhas solicitações de RC</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Solicitações</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <button
                      className="button is-primary"
                      type="button"
                      onClick={() => {
                        navigate('/n-srch');
                      }}
                    >
                      Nova Solicitação de RC
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <div className="card-content">
                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="FilaTarefas">
                      <div
                        style={{
                          height: 600,
                          width: '100%',
                        }}
                      >
                        <DataGrid
                          rows={soliciatacoesUser}
                          columns={columns}
                          pageSize={5}
                          disableSelectionOnClick
                          getRowHeight={() => 'auto'}
                          sx={{
                            fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
                            '& .MuiDataGrid-cell': {
                              display: 'flex',
                              alignItems: 'flex-start', // Alinha o conteúdo no topo
                              paddingTop: '4px', // Ajuste conforme necessário
                              paddingBottom: '4px',
                              border: '0px',
                              // lineHeight: '2.5',
                              fontSize: '16px',
                            },
                            '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                              {
                                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                                fontSize: '16px',
                                color: '#171717',
                                // fontFamily: '"Nunito", sans-serif',
                                lineHeight: '2.5',
                                borderLeft: '5px solid transparent',
                              },
                            '& .MuiDataGrid-virtualScroller': {
                              overflowX: 'hidden',
                            },
                          }}
                          getRowClassName={(params) => {
                            // if (params.row.status === 'CONCLUÍDO' || params.row.responsavel_controladoria === null) {
                            //   return "ocultar";
                            // }
                            if (
                              params.row.prioridade_material ===
                                '1 - Processo parado' ||
                              params.row.prioridade_material ===
                                '2 - Máquina parada'
                            ) {
                              return 'urgente'; // Retorna a classe 'importante' quando 'responsavel' for null
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FormModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              modalTitle={`Consultar RC: ${selectedRc}`}
              backgroundColorBody={'hsl(0, 0%, 96%)'}
              backgroundColorTitle={'#212529'}
            >
              {isModalOpen && selectedRc && (
                <ConsultarRC rcId={selectedRc} info={info} />
              )}
            </FormModal>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default SolicitacaoRCHomePage2;
