import './Kanban.css';

const TarefasConcluidas = ({
  tarefas,
  setModalOpen,
  setCardObj,
  setModalCardBackgroundColor,
}) => {
  const disciplinas = [
    'automacao',
    'civil',
    'eletrica',
    'externo',
    'instrumentacao',
    'mecanica',
    'planejamento',
    'processos',
  ];

  // Group tarefas by disciplina
  const tarefasConcluidasByDisciplina = tarefas.reduce((acc, tarefa) => {
    const { disciplina } = JSON.parse(tarefa.conteudo);
    if (disciplina) {
      if (!acc[disciplina]) {
        acc[disciplina] = [];
      }
      acc[disciplina].push(tarefa);
    }
    return acc;
  }, {});

  return (
    <div className="tarefas-concluidas">
      <div className="tarefas-concluidas-content">
        <div className="tarefas-concluidas-columns">
          {disciplinas.map((disciplina, index) => (
            <div key={index} className="tarefas-concluidas-disciplina">
              <div className="disciplina-cell">{disciplina}</div>
              <div className="tarefas-concluidas-list">
                {tarefasConcluidasByDisciplina[disciplina]?.length > 0 ? (
                  tarefasConcluidasByDisciplina[disciplina].map(
                    (tarefa, index) => {
                      const { tag, nome_documento } = JSON.parse(
                        tarefa.conteudo
                      );
                      return (
                        <div
                          key={index}
                          className="tarefa-concluida"
                          style={{
                            backgroundColor: 'rgba(0, 155, 100, 0.56)',
                          }}
                          onClick={() => {
                            setModalOpen(true);
                            setCardObj({
                              tarefa: tarefa,
                              conteudo: JSON.parse(tarefa.conteudo),
                            });
                            setModalCardBackgroundColor(
                              'rgba(0, 155, 100, 0.56)'
                            );
                          }}
                        >
                          <div className="tarefa-concluida-tag">{tag}</div>
                          <div className="tarefa-concluida-nome_documento">
                            {nome_documento}
                          </div>
                        </div>
                      );
                    }
                  )
                ) : (
                  <div className="tarefa-concluida-empty"></div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TarefasConcluidas;
