import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainLayout from '../../../layouts/MainLayout';
import AlertMessage from '../../../layouts/AlertMessage';

const ListasOrcamentoForm = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [orcamentoTitulo, setOrcamentoTitulo] = useState('');
  const [orcamentoCapex, setOrcamentoCapex] = useState('');
  const [orcamentoCapexId, setOrcamentoCapexId] = useState('');
  const [orcamentoDocRef, setOrcamentoDocRef] = useState('');
  const [orcamentoCusto, setOrcamentoCusto] = useState(0);
  const [orcamentoLm, setOrcamentoLm] = useState('');
  const [orcamentoFornecedor, setOrcamentoFornecedor] = useState('');
  const [orcamentoFornecedorId, setOrcamentoFornecedorId] = useState('');

  const [listasMateriais, setListasMateriais] = useState([]);
  const [allFornecedores, setAllFornecedores] = useState([]);
  const [listaItems, setListaItems] = useState([]);
  const [listaMDetais, setListaMDetais] = useState({});

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const headers = {
    'Content-Type': 'application/json',
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const getAllLM = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/listas/lmv/`,
        { headers }
      );
      const data = await response.data;
      setListasMateriais(data);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar listas de materiais'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getFornecedores = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/fornecedor/`,
        { headers }
      );
      const data = await response.data.fornecedores;
      setAllFornecedores(data);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar fornecedores'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleLMChange = async (val) => {
    const obj = listasMateriais.find((item) => item.id === val);
    setOrcamentoTitulo(obj.titulo);
    setOrcamentoCapex(obj.capex_name);
    setOrcamentoCapexId(obj.capex);
    setOrcamentoDocRef(obj.doc_ref);
    setOrcamentoCusto(obj.custo);
    setOrcamentoLm(obj.id);
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/listas/lmid/`,
        {
          params: {
            lista_id: obj.id,
          },
          headers: headers,
        }
      );
      const data = await response.data;
      setListaItems(data.items);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar itens da lista'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleFornecedorChange = (val) => {
    const fornecedor = allFornecedores.find((item) => item.id === val);
    setOrcamentoFornecedorId(fornecedor.id);
    setOrcamentoFornecedor(fornecedor.nome_fantasia);
  };

  const handleLOCad = async (e) => {
    e.preventDefault();
    const requestItems = listaItems.map((item) => ({
      lm_id: orcamentoLm,
      capex_linha_ref: item.capex_linha_ref,
      item_id: item.id,
      sap_id: item.sap_id,
      quantidade: item.quantidade,
      preco_und: item.preco,
    }));

    const lista_orcamento = {
      titulo: orcamentoTitulo,
      capex: orcamentoCapexId,
      doc_ref: orcamentoDocRef,
      custo: orcamentoCusto,
      custo_ref: orcamentoCusto,
      lm_id: orcamentoLm,
      fornecedor: orcamentoFornecedorId,
    };

    const resquestObj = {
      lista_orcamento: lista_orcamento,
      items: {
        items: requestItems, // MUDAR
      },
    };

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/listas/orcamento/`,
        resquestObj,
        { headers }
      );
      const data = await response.data;
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao criar lista de orçamento'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getAllLM();
    getFornecedores();
  }, []);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <section className="main-content">
          <div className="container">
            <form name="CadastroListaOrcamento" onSubmit={handleLOCad}>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Lista de Materiais:</label>
                    <div className="select">
                      <select
                        className="select"
                        onChange={(e) => handleLMChange(e.target.value)}
                      >
                        <option value={''} key={'selecione'}>
                          Selecione.
                        </option>
                        {listasMateriais &&
                          listasMateriais.map((lista, i) => (
                            <option key={i} value={lista.id}>
                              {lista.titulo}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label">Título:</label>
                    <input
                      className="input"
                      value={`${orcamentoTitulo}-Orcamento`}
                      type="text"
                      onChange={(e) => setOrcamentoTitulo(e.target.value)}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Documento Referência:</label>
                <input
                  className="input"
                  value={orcamentoDocRef}
                  type="text"
                  onChange={(e) => setOrcamentoDocRef(e.target.value)}
                  disabled
                />
              </div>

              <div className="field">
                <label className="label">Custo REF:</label>
                <input
                  className="input"
                  value={formatPreco(orcamentoCusto)}
                  type="text"
                  onChange={(e) => setOrcamentoCusto(e.target.value)}
                  disabled
                />
              </div>

              <div className="field">
                <label className="label">Fornecedor:</label>
                <div className="select">
                  <select
                    className="select"
                    onChange={(e) => handleFornecedorChange(e.target.value)}
                  >
                    <option value={''} key={'selecione'}>
                      Selecione.
                    </option>
                    {allFornecedores &&
                      allFornecedores.map((fornecedor, i) => (
                        <option key={i} value={fornecedor.id}>
                          {fornecedor.razao_social}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="field">
                <div className="label">Fornecedor ID:</div>
                <input
                  className="input"
                  type="text"
                  value={orcamentoFornecedorId}
                  disabled
                />
              </div>

              <div className="divider">ITENS</div>

              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>REF</th>
                    <th>ITEM</th>
                    <th>UND.</th>
                    <th>QTD.</th>
                    <th>PREÇO REF.</th>
                    <th>TOTAL REF</th>
                  </tr>
                </thead>
                <tbody>
                  {listaItems &&
                    listaItems.map((item, i) => (
                      <tr key={i}>
                        <td>{item.capex_linha_ref}</td>
                        <td>{item.descl}</td>
                        <td>{item.unidade}</td>
                        <td>{item.quantidade}</td>
                        <td>{formatPreco(item.preco)}</td>
                        <td>{formatPreco(item.preco * item.quantidade)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <button className="button">Gerar</button>
            </form>
          </div>
        </section>
      </MainLayout>
    </>
  );
};

export default ListasOrcamentoForm;
