import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import axios from 'axios';
import { LineChart, BarChart } from '@mui/x-charts';
import { format } from 'date-fns';
import AlertMessage from '../../layouts/AlertMessage';

const RelatorioPorTag = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [tag, setTag] = useState('all');
  const [setor, setSetor] = useState('all');
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 30); // 30 days ago
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  });
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    return date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  });
  const [macroData, setMacroData] = useState(null);
  const [chartData, setChartData] = useState(null);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const setDateTimes = (startDate, endDate) => {
    // const start = new Date(startDate);
    // start.setHours(0, 0, 0, 0);  // Set time to 00:00:00 for startDate

    // const end = new Date(endDate);
    // end.setHours(23, 59, 59, 999);  // Set time to 23:59:59.999 for endDate
    const start = new Date(`${startDate}T00:00:00`); // Explicitly set time to 00:00:00
    const end = new Date(`${endDate}T23:59:59`); // Explicitly set time to 23:59:59

    return {
      startDateTime: start.toISOString(), // Convert to ISO string
      endDateTime: end.toISOString(),
    };
  };

  const initialReport = async () => {
    const { startDateTime, endDateTime } = setDateTimes(startDate, endDate);
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/tarefas/relatorio-gestao/`,
        {
          params: {
            tag: tag,
            sd: startDateTime,
            ed: endDateTime,
            setor: setor,
          },
        }
      );
      if (response.status === 200) {
        setMacroData(response.data[0]);
        const x = Object.keys(response.data[1]).map((date) => new Date(date));
        const y = Object.values(response.data[1]).map((value) => Number(value));
        setChartData({
          x: x,
          y: y,
        });
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar relatório'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { startDateTime, endDateTime } = setDateTimes(startDate, endDate);
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/tarefas/relatorio-gestao/`,
        {
          params: {
            tag: tag,
            sd: startDateTime,
            ed: endDateTime,
            setor: setor,
          },
        }
      );
      if (response.status === 200) {
        setMacroData(response.data[0]);
        const x = Object.keys(response.data[1]).map((date) => new Date(date));
        const y = Object.values(response.data[1]).map((value) => Number(value));
        setChartData({
          x: x,
          y: y,
        });
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar relatório'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };
  useEffect(() => {
    initialReport();
  }, []);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Geral</li>
                    <li>Relatórios</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Relatórios</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="section is-main-section">
          <div className="card">
            <div className="card-content">
              <div className="container">
                <form onSubmit={handleSubmit}>
                  <div className="field is-horizontal">
                    <div className="field-body">
                      <div className="field">
                        <label className="label">Tag</label>
                        <div className="control">
                          <div className="select">
                            <select
                              name="tag"
                              value={tag}
                              onChange={(e) => setTag(e.target.value)}
                            >
                              <option value="">Selecione.</option>
                              <option value="fornecedor">Fornecedor</option>
                              <option value="cliente">Cliente</option>
                              <option value="material">Material</option>
                              <option value="solicitacao_rc">
                                Solitações de RC
                              </option>
                              <option value="expancao">
                                Expansão de Materiais
                              </option>
                              <option value="all">Todas (-frete)</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="field">
                        <label className="label">Setor</label>
                        <div className="control">
                          <div className="select">
                            <select
                              name="setor"
                              value={setor}
                              onChange={(e) => setSetor(e.target.value)}
                            >
                              <option value="all">Todos</option>
                              <option value="controladoria">
                                Controladoria
                              </option>
                              <option value="planejamento">Planejamento</option>
                              <option value="suprimentos">Suprimentos</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="field">
                        <label className="label">Data Início</label>
                        <div className="control">
                          <input
                            className="input"
                            type="date"
                            name="startDate"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="field">
                        <label className="label">Data Fim</label>
                        <div className="control">
                          <input
                            className="input"
                            type="date"
                            name="endDate"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="field">
                        <div className="control">
                          <button
                            className="button is-primary"
                            type="submit"
                            style={{
                              marginTop: '2rem',
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="">
            {macroData && (
              <div className="card">
                <div className="card-content">
                  <div className="columns is-multiline">
                    {/* Metric Boxes */}
                    <div className="column is-one-third">
                      <div className="box">
                        <p className="subtitle">Quantidade Total</p>
                        <p className="title">{macroData.quantidade_total}</p>
                      </div>
                    </div>

                    <div className="column is-one-third">
                      <div className="box">
                        <p className="subtitle">Tarefas concluídas</p>
                        <p className="title">
                          {macroData.quantidade_concluida}
                        </p>
                      </div>
                    </div>

                    <div className="column is-one-third">
                      <div className="box">
                        <p className="subtitle">Tarefas recusadas</p>
                        <p className="title">{macroData.quantidade_recusada}</p>
                      </div>
                    </div>

                    <div className="column is-one-third">
                      <div className="box">
                        <p className="subtitle">Tarefas pendentes</p>
                        <p className="title">{macroData.quantidade_pendente}</p>
                      </div>
                    </div>

                    <div className="column is-one-third">
                      <div className="box">
                        <p className="subtitle">Tarefas em andamento</p>
                        <p className="title">
                          {macroData.quantidade_andamento}
                        </p>
                      </div>
                    </div>

                    <div className="column is-one-third">
                      <div className="box">
                        <p className="subtitle">TMC</p>
                        <p className="title">
                          {macroData.tempo_medio.toFixed(4)}{' '}
                          <span className="subtitle">horas</span>
                        </p>
                      </div>
                    </div>

                    {/* Line Chart Placeholders */}
                    <div className="column is-two-thirds">
                      <div className="box">
                        <p className="subtitle">Tarefas iniciadas no período</p>
                        <LineChart
                          height={200}
                          width={1000}
                          xAxis={[
                            {
                              data: chartData.x,
                              type: 'category',
                              valueFormatter: (value) =>
                                format(new Date(value), 'dd/MM/yyyy'),
                              tickInterval: chartData.x,
                            },
                          ]}
                          series={[
                            {
                              data: chartData.y,
                              showMark: false,
                            },
                          ]}
                          sx={{
                            '& .MuiChartsAxis-directionX': {
                              '& .MuiChartsAxis-tickLabel': {
                                rotate: '30deg',
                                textAnchor: 'start',
                              },
                            },
                          }}
                          grid={{ horizontal: true }}
                        />
                      </div>
                    </div>

                    <div className="column is-one-third">
                      <div
                        className="box"
                        style={{
                          paddingBottom: '20px',
                        }}
                      >
                        <p className="subtitle">SLA</p>
                        <BarChart
                          width={500}
                          height={200}
                          series={[
                            {
                              data: [
                                macroData.quantidade_sla_ok,
                                macroData.quantidade_sla_nok,
                              ],
                            },
                          ]}
                          xAxis={[
                            {
                              data: ['IN SLA', 'OUT SLA'],
                              scaleType: 'band',
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </MainLayout>
    </>
  );
};

export default RelatorioPorTag;
