import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/pt-br';

import Checkbox from '@mui/material/Checkbox';
import Select from 'react-select';
import AlertMessage from '../../layouts/AlertMessage';

dayjs.extend(localizedFormat);
dayjs.locale('pt-br'); // Define o idioma para português

const CadastroCardKanban = () => {
  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;
  const [titulo, setTitulo] = useState('');
  const [nomeDocumento, setNomeDocumento] = useState('');
  const [tag, setTag] = useState('');
  const [permalink, setPermalink] = useState('');
  const [versaoCliente, setVersaoCliente] = useState(false);
  const [dataPrevista, setDataPrevista] = useState(null);
  const [aprovadoresSelecionados, setAprovadoresSelecionados] = useState([]);
  const [empreendimentos, setEmpreendimentos] = useState([]);
  const [selectedEmpreendimento, setSelectedEmpreendimento] = useState([]);
  const [selectedEmissor, setSelectedEmissor] = useState([]);
  const [tempoAprov, setTempoAprov] = useState(0);
  const [tempoEmissao, setTempoEmissao] = useState(0);
  const [options, setOptions] = useState([]);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleSubmitDocEntregavel = async (e) => {
    e.preventDefault();

    const fileInput = document.getElementById('doc-entregavel');
    const file = fileInput.files[0];

    if (!file) {
      // alert("Por favor, selecione um arquivo CSV.");
      setAlertMessage('Por favor, selecione um arquivo CSV.');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    // Atualizando os headers para o envio do arquivo
    const headers_new = {};

    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/kanban/upload-doc-entregaveis`,
        formData,
        {
          headers: headers_new,
        }
      );

      if (response.status === 201) {
        // alert("Arquivo enviado e processado com sucesso!");
        setAlertMessage('Arquivo enviado e processado com sucesso!');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao enviar arquivo'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = {
        tarefa: {
          titulo: titulo,
          nome_documento: nomeDocumento,
          tag: tag,
          permalink: permalink,
          emissor: selectedEmissor,
          projeto: selectedEmpreendimento.id,
          data_prevista: dataPrevista
            ? dayjs(dataPrevista).format('YYYY-MM-DDTHH:mm:ssZ')
            : null,
          tempo_aprov: tempoAprov,
          versao_cliente: versaoCliente,
          tempo_emissao: tempoEmissao,
        },
        usuarios: { users: aprovadoresSelecionados },
      };

      let params = {
        tarefa: formData.tarefa,
        usuarios: formData.usuarios,
      };

      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/kanban/`,
        params,
        { headers }
      );

      if (response.status === 201) {
        // alert("Cards enviados!")
        setAlertMessage('Cards enviados com sucesso!');
        setAlertSeverity('success');
        setAlertOpen(true);
        setDataPrevista(null);
        setTempoAprov(0);
        setNomeDocumento('');
        setPermalink('');
        setTag('');
        setTitulo('');
        setSelectedEmpreendimento([]);
        setSelectedEmissor([]);
        setTempoEmissao(0);
        setOptions([]);
        setAprovadoresSelecionados([]);
        setEmpreendimentos([]);

        setReload(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao enviar cards'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        let params = { setor: 'Engenharia' };
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/users/setor/`,
          {
            params,
            headers,
          }
        );

        if (response.data) {
          const opcoes = response.data.users.map((usuario, index) => ({
            value: usuario,
            label: `${usuario.nome} ${usuario.sobrenome}`,
          }));

          // Sort opcoes by label
          opcoes.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });

          setOptions(opcoes);
        }
      } catch (error) {
        console.error('Erro ao buscar usuarios:', error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao buscar usuários'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    const getEmpreendimentos = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/adm/empreendimento/`,
          { headers }
        );

        if (response.status === 200) {
          const emps = response.data.empreendimentos.map((emp, index) => ({
            value: emp,
            label: emp.tag,
          }));

          setEmpreendimentos(emps);
        }
      } catch (error) {
        console.error(error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao buscar empreendimentos'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    getUsers();
    getEmpreendimentos();
    // getDisciplinas();
  }, [REACT_APP_BASE_API_URL]);

  const handleClose = () => {
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        open={alertOpen}
        handleClose={handleClose}
        severity={alertSeverity}
        message={alertMessage}
      />
      <MainLayout>
        <div className="main-section">
          <form name="cadastrarFornecedor" onSubmit={handleSubmit}>
            <section className="section is-title-bar">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <ul>
                      {/* <li>Setores</li> */}
                      <li>Planejamento</li>
                      <li>Card Kanban</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <section className="hero is-hero-bar">
              <div className="hero-body">
                <div className="level">
                  <div className="level-left">
                    <div className="level-item">
                      <h1 className="title">Card Kanban</h1>
                    </div>
                  </div>
                  <div className="level-right" style={{ display: 'none' }}>
                    <div className="level-item"></div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section is-main-section">
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Informações gerais
                  </p>
                </header>
                <div className="card-content">
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Projeto
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <Select
                            className="selectf"
                            classNamePrefix="select"
                            defaultValue={empreendimentos[0]}
                            isClearable={true}
                            isSearchable={true}
                            name="empre"
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 1000,
                              }),
                            }}
                            options={empreendimentos}
                            required
                            placeholder={
                              <div>
                                <i className="fa-solid fa-magnifying-glass"></i>
                                <span> Selecione o empreendimento</span>
                              </div>
                            }
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                // const selectedValue = ;
                                setSelectedEmpreendimento(selectedOption.value);
                              } else {
                                setSelectedEmpreendimento([]);
                              }
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Tag
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={tag}
                            onChange={(e) => {
                              setTag(e.target.value);
                              setTitulo(`${e.target.value} - ${nomeDocumento}`);
                            }}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-hashtag"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Nome do Documento
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={nomeDocumento}
                            onChange={(e) => {
                              setNomeDocumento(e.target.value);
                              setTitulo(`${tag} - ${e.target.value}`);
                            }}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-file-signature"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Documento alfabético
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <Checkbox
                            checked={versaoCliente}
                            onChange={(e) => setVersaoCliente(e.target.checked)}
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fill: '#2B3BED',
                              },
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Título
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            disabled
                            value={titulo}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-heading"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Emissor
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <Select
                            className="selectf"
                            classNamePrefix="select"
                            defaultValue={options[0]}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 1000,
                              }),
                            }}
                            required
                            options={options}
                            placeholder={
                              <div>
                                <i className="fa-solid fa-magnifying-glass"></i>
                                <span> Selecione o emissor</span>
                              </div>
                            }
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                setSelectedEmissor(selectedOption.value);
                              } else {
                                setSelectedEmissor([]);
                              }
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Permalink
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            required
                            value={permalink}
                            onChange={(e) => setPermalink(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-link"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Tempo para emissão (em horas - analista)
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="number"
                            placeholder=""
                            required
                            value={tempoEmissao}
                            onChange={(e) => {
                              setTempoEmissao(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              // Impede a entrada de caracteres especiais como "." ou ","
                              if (e.key === '.' || e.key === ',') {
                                e.preventDefault();
                              }
                            }}
                            min="1" // Aceita apenas números positivos
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-regular fa-clock"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Tempo para aprovação (em horas)
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="number"
                            placeholder=""
                            required
                            value={tempoAprov}
                            onChange={(e) => {
                              setTempoAprov(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              // Impede a entrada de caracteres especiais como "." ou ","
                              if (e.key === '.' || e.key === ',') {
                                e.preventDefault();
                              }
                            }}
                            min="1" // Aceita apenas números positivos
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-regular fa-clock"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Data Prevista emissão
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control">
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="pt-br"
                          >
                            <DemoContainer
                              sx={{
                                padding: '0px',
                                height: '40px',
                              }}
                              components={['DatePicker']}
                            >
                              <DatePicker
                                label=""
                                required
                                sx={{
                                  '& .MuiInputBase-input': {
                                    padding: '8px',
                                    fontSize: '1rem',
                                  },
                                }}
                                value={dataPrevista}
                                onChange={(newValue) =>
                                  setDataPrevista(newValue ? newValue : null)
                                }
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section is-main-section">
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Aprovadores
                  </p>
                </header>
                <div className="card-content">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={options[0]}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={true} // Adicione esta linha para permitir múltiplas seleções
                    name="color"
                    required
                    options={options}
                    placeholder={
                      <div>
                        <i className="fa-solid fa-magnifying-glass"></i>
                        <span>Selecione os aprovadores</span>
                      </div>
                    }
                    onChange={(selectedOptions) => {
                      if (selectedOptions) {
                        // Se estiver usando múltiplas seleções, selectedOptions será um array
                        const selectedValues = selectedOptions.map(
                          (option) => option.value
                        );
                        setAprovadoresSelecionados(selectedValues);
                      } else {
                        setAprovadoresSelecionados([]);
                      }
                    }}
                  />

                  <div
                    style={{
                      margin: '40px 0px 20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button type="submit" className="button is-primary">
                      Cadastrar
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </form>

          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Anexar arquivo
                </p>

                <div style={{ margin: '10px' }}>
                  <a href="/Template_documentos_entregaveis.csv" download>
                    <button
                      className="button is-primary"
                      style={{
                        padding: '10px 20px',
                        backgroundColor: '#2B3BED',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                      }}
                    >
                      Baixar Template CSV
                    </button>
                  </a>
                </div>
              </header>
              <div className="card-content">
                <form
                  name="cadastrarDoocumentoEntregavel"
                  onSubmit={handleSubmitDocEntregavel}
                >
                  <div>
                    <div className="input_upload">
                      <input id="doc-entregavel" type="file" />
                    </div>

                    <div
                      style={{
                        margin: '40px 0px 20px 0px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <button type="submit" className="button is-primary">
                        Carregar documento
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default CadastroCardKanban;
