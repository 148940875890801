import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import AlertMessage from '../../layouts/AlertMessage';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CheckBoxAceHorizontal from '../../functional/checkBox';

import AdminUserGrupoManager from './AdminUserGrupoManager';
import FormModal from '../../layouts/FormModal';

const AdminUserEdit = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const { user_id } = useParams();
  const [userAtivo, setUserAtivo] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');
  const [userSetor, setUserSetor] = useState('');
  const [userSetorId, setUserSetorId] = useState('');
  const [userNome, setUserNome] = useState('');
  const [userSobrenome, setUserSobrenome] = useState('');
  const [userCargo, setUserCargo] = useState('');
  const [userGrupos, setUserGrupos] = useState('');
  const [userTel1, setUserTel1] = useState('');
  const [userTel2, setUserTel2] = useState('');
  const [userData, setUserData] = useState('');
  const [userEmpresa, setUserEmpresa] = useState('');
  const [userDisciplinasId, setUserDisciplinasId] = useState('');

  const [userObj, setUserObj] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const [allSetores, setAllSetores] = useState([]);
  const [allDisciplinas, setAllDisciplinas] = useState([]);
  const [allEmpresas, setAllEmpresas] = useState([]);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const listaPerms = [
    { nome: 'P0', val: 'analista' },
    { nome: 'P1', val: 'supervisor' },
    { nome: 'P2', val: 'coordenador' },
    { nome: 'P3', val: 'gerente' },
    { nome: 'P4', val: 'diretor' },
    { nome: 'P5', val: 'admin' },
  ];

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleSetorChange = (val) => {
    const targetSetor = allSetores.find((setor) => setor.id === val);
    setUserSetor(targetSetor.setor);
    setUserSetorId(targetSetor.id);
  };

  const handleDisciplinaChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions).map(
      (option) => option.value
    );
    setUserDisciplinasId(selectedOptions);
  };

  const getEmpresas = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/fornecedor/`,
        { headers }
      );
      if (response.status === 200) {
        setAllEmpresas(response.data.fornecedores);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar empresas.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getSetores = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/setores/`,
        { headers }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAllSetores(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar setores.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getDisciplinas = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/disciplinas/`,
        { headers }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAllDisciplinas(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar disciplinas.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const ativoChange = (e) => {
    const ativoVal = e.target.value;
    setUserAtivo(ativoVal);
  };

  const getUserDetail = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/users/detail/com/`,
        {
          params: {
            user_id: user_id,
          },
          headers: headers,
        }
      );
      const data = await response.data;
      if (response.status === 200) {
        setUserAtivo(data.user.ativo);
        setUserNome(data.user.nome);
        setUserSobrenome(data.user.sobrenome);
        setUserEmail(data.user.email);
        setUserRole(data.user.role);
        setUserSetor(data.user.setor);
        setUserCargo(data.user.cargo);
        setUserGrupos(data.grupos);
        setUserTel1(data.user.telefone1);
        setUserTel2(data.user.telefone2);
        setUserData(data.user.criado_em);
        setUserEmpresa(data.user.empresa);
        setUserSetorId(data.user.setor_id);
        setUserObj(data);

        let disciplinas_temp = [];
        if (data.user.disciplinas_id) {
          disciplinas_temp = data.user.disciplinas_id.map(
            (disciplina) => disciplina.disciplina_id
          );
        }
        setUserDisciplinasId(disciplinas_temp);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail ||
              'Erro ao buscar detalhes do usuário.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let formData = {
      id: user_id,
      nome: userNome,
      sobrenome: userSobrenome,
      email: userEmail,
      cargo: userCargo.toLowerCase(),
      setor: userSetor.toLowerCase(),
      role: userRole.toLowerCase(),
      telefone1: userTel1,
      telefone2: userTel2,
      ativo: userAtivo,
      criado_em: userData,
      setor_id: userSetorId,
    };

    try {
      const response = await axios.put(
        `${REACT_APP_BASE_API_URL}/admin/update-user/`,
        formData,
        {
          params: {
            disciplinas_id:
              userDisciplinasId.length > 0 ? userDisciplinasId.join(',') : '',
          },
          headers,
        }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAlertMessage('Usuário atualizado com sucesso.');
        setAlertSeverity('success');
        setAlertOpen(true);
        setNavigateTo('/admin/');
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao atualizar usuário.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  useEffect(() => {
    getEmpresas();
    getDisciplinas();
    getSetores();
    getUserDetail();
  }, []);

  useEffect(() => {
    const select_disciplinas = document.querySelector(
      'select[name="disciplina"]'
    );
    if (select_disciplinas && userDisciplinasId) {
      userDisciplinasId.forEach((disciplina_id) => {
        const option = select_disciplinas.querySelector(
          `option[value="${disciplina_id}"]`
        );
        if (option) option.selected = true;
      });
    }
  }, [userDisciplinasId]);

  return (
    <>
      <AlertMessage
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
        loading={loading}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Configurações</li>
                    <li>Meu perfil</li>
                    <li>Painel ADM</li>
                    <li>Editar usuário</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Editar usuário</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="section is-main-section"
            style={{ marginBottom: '50px' }}
          >
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Editar usuário: {`${userNome} ${userSobrenome}`}
                </p>
              </header>
              <div className="card-content">
                <form name="adminuseredit" onSubmit={handleSubmit}>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">ID</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            disabled
                            value={user_id || ''}
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-hashtag"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Nome</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            disabled
                            value={userNome || ''}
                            onChange={(e) => setUserNome(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-person"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Sobrenome</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            disabled
                            value={userSobrenome || ''}
                            onChange={(e) => setUserSobrenome(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-person"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Empresa</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            disabled
                            value={userEmpresa || ''}
                            onChange={(e) => setUserEmpresa(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-at"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Email</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            value={userEmail || ''}
                            onChange={(e) => setUserEmail(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-at"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Telefone 1</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            value={userTel1 || ''}
                            onChange={(e) => setUserTel1(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-phone"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Telefone 2</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            value={userTel2 || ''}
                            onChange={(e) => setUserTel2(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-phone"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Cargo</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            value={userCargo || ''}
                            onChange={(e) => setUserCargo(e.target.value)}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-flag"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Setor</label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <select
                              name="setor"
                              value={userSetorId}
                              onChange={(e) =>
                                handleSetorChange(e.target.value)
                              }
                            >
                              <option value="" disabled></option>
                              {allSetores.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.setor}
                                </option>
                              ))}
                            </select>
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-flag"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Disciplina</label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-multiple is-fullwidth">
                            <select
                              name="disciplina"
                              multiple
                              onChange={handleDisciplinaChange}
                            >
                              <option value="" disabled></option>
                              {allDisciplinas.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.disciplina}
                                </option>
                              ))}
                            </select>
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-flag"></i>
                            </span>
                            <p
                              className="help"
                              style={{
                                fontSize: '0.80rem',
                                marginTop: '10px',
                              }}
                            >
                              <i>
                                Segure <strong>CTRL</strong> para selecionar
                                mais de uma Disciplina.
                              </i>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Role</label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control has-icons-left">
                          <div className="select is-fullwidth">
                            <select
                              name="role"
                              value={userRole}
                              onChange={(e) => setUserRole(e.target.value)}
                            >
                              <option value="" disabled></option>
                              {listaPerms.map((item) => (
                                <option key={item.val} value={item.val}>
                                  {item.nome}
                                </option>
                              ))}
                            </select>
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-lock"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CheckBoxAceHorizontal
                    isChecked={userAtivo}
                    onChange={ativoChange}
                  />
                  <br />

                  <div className="card" style={{ marginTop: '40px' }}>
                    <header className="card-header">
                      <p
                        className="card-header-title"
                        style={{
                          backgroundColor: '#2e323a',
                          color: 'white',
                          borderTopLeftRadius: '7px',
                        }}
                      >
                        GRUPOS
                      </p>
                      <p
                        className="card-header-icon"
                        style={{
                          backgroundColor: '#2e323a',
                          color: 'white',
                          borderTopRightRadius: '7px',
                        }}
                        onClick={handleOpenModal}
                      >
                        <span className="icon">
                          <i className="fa-solid fa-people-roof"></i>
                        </span>
                      </p>
                    </header>
                    <div className="card-content" style={{ marginTop: '10px' }}>
                      <div
                        className="grid"
                        style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(3, 1fr)',
                          gap: '20px',
                        }}
                      >
                        {userGrupos &&
                          userGrupos.map((grupo) => (
                            <div className="card" key={grupo.id}>
                              <header className="card-header">
                                <p
                                  className="card-header-title"
                                  style={{
                                    backgroundColor: '#2e323a',
                                    color: 'white',
                                    borderTopLeftRadius: '7px',
                                    borderTopRightRadius: '7px',
                                  }}
                                >
                                  {grupo.grupo.nome}
                                </p>
                              </header>
                              <div className="card-content">
                                <p className="subtitle is-6">
                                  {grupo.grupo.descricao}
                                </p>
                                {grupo.roles.map((role) => (
                                  <span
                                    className="tag"
                                    style={{
                                      margin: 1,
                                    }}
                                  >
                                    {role.nome}
                                  </span>
                                ))}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button className="button is-primary" type="submit">
                      Salvar Alterações
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <FormModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              modalTitle={'Gerenciar grupos'}
            >
              {isModalOpen && (
                <AdminUserGrupoManager userData={userObj} info={info} />
              )}
            </FormModal>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default AdminUserEdit;
