import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import AlertMessage from '../../layouts/AlertMessage';

const SolicitacaoRCFormV = () => {
  const { REACT_APP_BASE_API_URL } = process.env;

  const [titulo, setTitulo] = useState('');
  const [listaMateriais, setListaMateriais] = useState('');
  const [empreendimento, setEmpreendimento] = useState('');
  const [prioridade, setPrioridade] = useState('');
  const [tipo, setTipo] = useState('');
  const [dataNecessidadeObra, setDataNecessidadeObra] = useState('');
  const [area, setArea] = useState('');
  const [subarea, setSubarea] = useState('');
  const [orcamento, setOrcamento] = useState('');
  const [escopo, setEscopo] = useState('');
  const [localEntrega, setLocalEntrega] = useState('');
  const [fornecedorEntrega, setFornecedorEntrega] = useState('');
  const [docRef, setDocRef] = useState('');
  const [previstoCpx, setPrevistoCpx] = useState('');
  const [anexoPrevisaoCpx, setAnexoPrevisaoCpx] = useState('');
  const [tipoCompra, setTipoCompra] = useState('');
  const [fornecimentoExclusivo, setFornecimentoExclusivo] = useState('');
  const [justFornecimentoExc, setJustFornecimentoExc] = useState('');
  const [despesaPrevista, setDespesaPrevista] = useState('');
  const [freteIncluso, setFreteIncluso] = useState('');
  const [compraPacote, setCompraPacote] = useState('');
  const [proposta, setProposta] = useState('');
  const [observacoes, setObservacoes] = useState('');
  const [capex, setCapex] = useState('');
  const [allCapex, setAllCapex] = useState([]);
  const [capexObjRef, setCapexObjRef] = useState({});

  const [allProjetos, setAllProjetos] = useState([]);
  const [allListas, setAllListas] = useState([]);
  const [showHiddenFields, setShowHiddenFields] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [allItemsRef, setAllItemsRef] = useState([]);
  const [allItemsOrigin, setAllItemsOrigin] = useState([]);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);

  const headers = {
    'Content-Type': 'application/json',
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const handleSelectChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    setCapexObjRef(allCapex[selectedIndex - 1]);
    setCapex(e.target.value);
    setShowHiddenFields(capex !== '');
  };

  const getCapex = async () => {
    try {
      const capexResponse = await axios.get(
        `${REACT_APP_BASE_API_URL}/capex/lastcapex`,
        { headers }
      );
      const data = await capexResponse.data;
      setAllCapex(data.capex);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar capex'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getListas = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/listas/orcamento/capex`,
        {
          params: {
            capex_id: capex,
          },
          headers: headers,
        }
      );
      const data = await response.data;
      setAllListas(data);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar listas'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const readLista = async (e) => {
    setListaMateriais(e.target.value);

    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/listas/orcamento/t`,
        {
          params: {
            lista_id: e.target.value,
          },
          headers: headers,
        }
      );
      if (response.status === 200) {
        const data = await response.data;
        setAllItems(data.items);
        const newRefs = data.items.map((item) => item.capex_linha_ref);
        setAllItemsRef(newRefs);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar listas orçamento'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }

    try {
      const ref_response = await axios.post(
        `${REACT_APP_BASE_API_URL}/capex/linhas`,
        allItemsRef,
        {
          params: {
            capex_id: capex,
          },
          headers: headers,
        }
      );
      if (ref_response.status === 200) {
        const ref_data = await ref_response.data;
        setAllItemsOrigin(ref_data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar capex linhas'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleSRCSubmit = async (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    getCapex();
  }, []);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <section className="main-content">
          <div className="container">
            <form name="SRCForm">
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label">Capex:</label>
                    <div className="select">
                      <select
                        name="capex"
                        value={capex}
                        onChange={handleSelectChange}
                      >
                        <option value="">Selecione.</option>
                        {allCapex.map((cpx, i) => (
                          <option key={cpx.id} value={cpx.id}>
                            {cpx.empreendimento} | {cpx.projeto} | {cpx.fase}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <p className="button is-primary" onClick={getListas}>
                    Listas
                  </p>
                </div>
              </div>

              <div className="field">
                <label className="label">Lista de materiais:</label>
                <div className="select">
                  <select
                    name="listas"
                    value={listaMateriais}
                    onChange={(e) => readLista(e)}
                  >
                    <option value="">Selecione.</option>
                    {allListas &&
                      allListas.map((lista, i) => (
                        <option key={i} value={lista.id}>
                          {lista.titulo}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="field">
                <label className="label">Título:</label>
                <div className="control">
                  <input
                    type="text"
                    placeholder=""
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                    className="input"
                    required
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Empreendimento:</label>
                <div className="control">
                  <div className="select">
                    <select disabled>
                      <option
                        key="empreendimentoSelecionado"
                        value={capexObjRef?.empreendimento_id}
                      >
                        {capexObjRef?.empreendimento}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Prioridade:</label>
                <div className="control">
                  <input
                    type="text"
                    placeholder=""
                    value={prioridade}
                    onChange={(e) => setPrioridade(e.target.value)}
                    className="input"
                    required
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Tipo:</label>
                <div className="control">
                  <input
                    type="text"
                    placeholder=""
                    value={tipo}
                    onChange={(e) => setTipo(e.target.value)}
                    className="input"
                    required
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Escopo:</label>
                <div className="control">
                  <div className="select">
                    <select
                      name="escopo"
                      value={escopo}
                      onChange={(e) => setEscopo(e.target.value)}
                    >
                      <option value={'GEO'} key={'GEO'}>
                        GEO
                      </option>
                      <option value={'FORNECEDOR'} key={'FORNECEDOR'}>
                        FORNECEDOR
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Local de entrega:</label>
                <div className="control">
                  <input
                    type="text"
                    placeholder=""
                    value={localEntrega}
                    onChange={(e) => setLocalEntrega(e.target.value)}
                    className="input"
                    required
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Documento referência:</label>
                <div className="control">
                  <input
                    type="text"
                    placeholder=""
                    value={docRef}
                    onChange={(e) => setDocRef(e.target.value)}
                    className="input"
                    required
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Tipo de compra:</label>
                <div className="control">
                  <div className="select">
                    <select
                      name="tipo_compra"
                      value={tipoCompra}
                      onChange={(e) => setTipoCompra(e.target.value)}
                    >
                      <option value={'TOTAL'} key={'cTOTAL'}>
                        TOTAL
                      </option>
                      <option value={'PARCIAL'} key={'cPARCIAL'}>
                        PARCIAL
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Frete incluso:</label>
                <div className="control">
                  <input
                    type="text"
                    placeholder=""
                    value={freteIncluso}
                    onChange={(e) => setFreteIncluso(e.target.value)}
                    className="input"
                    required
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Link da proposta:</label>
                <div className="control">
                  <input
                    type="text"
                    placeholder=""
                    value={proposta}
                    onChange={(e) => setProposta(e.target.value)}
                    className="input"
                    required
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Observações:</label>
                <div className="control">
                  <textarea className="textarea" rows={3}></textarea>
                </div>
              </div>

              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>REF</th>
                    <th>ITEM</th>
                    <th>QUANTIDADE</th>
                    <th>PREÇO</th>
                  </tr>
                </thead>
                <tbody>
                  {allItems &&
                    allItems.map((item, i) => (
                      <tr key={i}>
                        <td>{item.capex_linha_ref}</td>
                        <td>{item.item_desc}</td>
                        <td>{item.quantidade}</td>
                        <td>{formatPreco(item.preco_und)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <p>ORIGINAL</p>

              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>REF</th>
                    <th>ITEM</th>
                    <th>QUANTIDADE</th>
                    <th>PREÇO</th>
                  </tr>
                </thead>
                <tbody>
                  {allItemsOrigin &&
                    allItemsOrigin.map((item, i) => (
                      <tr key={i}>
                        <td>{item.capex_linha_ref}</td>
                        <td>{item.item_desc}</td>
                        <td>{item.quantidade}</td>
                        <td>{formatPreco(item.preco_und)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <button className="button is-primary">Solicitar</button>
            </form>
          </div>
        </section>
      </MainLayout>
    </>
  );
};

export default SolicitacaoRCFormV;
