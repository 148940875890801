import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MainLayout from '../layouts/MainLayout';
import './css/Formularios.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import ComentarioComponent from '../functional/comentarios/Comentario';
import AlertMessage from '../layouts/AlertMessage';
import { useUser } from '../../context/UserContext';

const AprovarExpansaoMaterial = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;
  const { tarefa, ex_material, route } = location.state;

  const [status, setStatus] = useState(tarefa.status);
  const [respostaSolicitante, setRespostaSolicitante] = useState(
    tarefa.observacoes
  );
  const [responsavel, setResponsavel] = useState([]);
  const [commentsData, setCommentsData] = useState([]);
  const [userComment, setUserComment] = useState('');
  const { idUsuario, setIdUsuario } = useUser();

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const statusMiddleList = ['ANDAMENTO', 'PENDENTE'];

  const headers = {
    'Content-Type': 'application/json',
  };

  useEffect(() => {
    const getResponsavel = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/tarefas/responsavel`,
          {
            params: {
              tarefa_id: tarefa.id,
            },
            headers,
          }
        );
        if (response.data) {
          setResponsavel(response.data);
        }
      } catch (error) {
        console.error('Erro ao buscar responsavel:', error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao buscar responsavel'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    getResponsavel();
    if (
      respostaSolicitante === 'null' ||
      respostaSolicitante === null ||
      !respostaSolicitante
    ) {
      setRespostaSolicitante('');
    }
  }, [REACT_APP_BASE_API_URL]);

  useEffect(() => {
    if (idUsuario === null || idUsuario === 'null' || idUsuario === undefined) {
      setIdUsuario(idUsuario);
    }
  }, [idUsuario]);

  const handleFlux = async (status_val) => {
    const mensagem = `
    A sua solicitação de cadastro para expandir o material foi atualizada para ${status_val}
    
    Detalhes: ${respostaSolicitante}
    `;
    let schema = {
      comentario: mensagem,
      tarefa_id: tarefa.id,
      email: tarefa.email_relator,
    };
    if (statusMiddleList.includes(status_val)) {
      try {
        const response = await axios.put(
          `${REACT_APP_BASE_API_URL}/tarefas/status/`,
          schema,
          {
            params: { status: status_val },
            headers,
          }
        );
        if (response.status === 200) {
          // alert('O status da solicitação foi alterado com sucesso.');
          setAlertMessage('O status da solicitação foi alterado com sucesso.');
          setAlertSeverity('success');
          setAlertOpen(true);
          setStatus(status_val);
          // navigate('/suprimentos/fila-tarefas', { state: { defaultButton: 'Expansao' } });
          setNavigateTo('/suprimentos/fila-tarefas');
        }
      } catch (error) {
        // alert(error.response.data.detail)
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail ||
                'Erro ao alterar status da solicitação'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
        console.error(error);
      }
    } else if (status_val === 'RECUSADO') {
      if (respostaSolicitante === '' || respostaSolicitante === null) {
        // alert("É necessário explicar o motivo da recusa.")
        setAlertMessage('É necessário explicar o motivo da recusa.');
        setAlertSeverity('error');
        setAlertOpen(true);
        return;
      }
      try {
        const refuseResponse = await axios.put(
          `${REACT_APP_BASE_API_URL}/tarefas/done/`,
          schema,
          {
            params: { status: status_val },
            headers,
          }
        );
        if (refuseResponse.status === 200) {
          // alert("Solicitação recusada com sucesso.");
          setAlertMessage('Solicitação recusada com sucesso.');
          setAlertSeverity('success');
          setAlertOpen(true);
          setStatus(status_val);
          // navigate('/suprimentos/fila-tarefas', { state: { defaultButton: 'Expansao' } });
          setNavigateTo('/suprimentos/fila-tarefas');
        }
      } catch (error) {
        // alert(error.response.data.detail)
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao recusar solicitação'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
        console.error(error);
      }
    } else if (status_val === 'CONCLUIDO') {
      try {
        const cadStatusResponse = await axios.put(
          `${REACT_APP_BASE_API_URL}/tarefas/done/`,
          schema,
          {
            params: { status: status_val },
            headers,
          }
        );
        if (cadStatusResponse.status === 200) {
          // alert("Solicitação concluida com sucesso.");
          setAlertMessage('Solicitação concluida com sucesso.');
          setAlertSeverity('success');
          setAlertOpen(true);
          setStatus(status_val);
          // navigate('/suprimentos/fila-tarefas', { state: { defaultButton: 'Expansao' } });
          setNavigateTo('/suprimentos/fila-tarefas');
        }
      } catch (error) {
        console.error(error);
        // alert(error.response.data.detail)
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao concluir solicitação'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    }
  };

  const postRootComment = async (e) => {
    e.preventDefault();
    let obj = {
      text: userComment,
      user_id: idUsuario,
      objeto: tarefa.id,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/comments/`,
        obj,
        { headers }
      );
      if (response.status === 201) {
        setUserComment('');
        getComments();
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || "Ocorreu um erro.")
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao postar comentário'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getComments = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_API_URL}/comments/`, {
        params: { objeto_id: tarefa.id },
        headers,
      });
      if (response.status === 200) {
        setCommentsData(response.data);
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || "Ocoreu um erro.")
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar comentários'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getComments();
  }, []);

  useEffect(() => {
    return () => {
      setAlertOpen(false);
      if (navigateTo) {
        navigate(navigateTo, { state: { defaultButton: 'Expansao' } });
      }
    };
  }, []);

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo, { state: { defaultButton: 'Expansao' } });
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    {/* <li>Setores</li> */}
                    <li>Suprimentos</li>
                    <li>Fila de Tarefas</li>
                    <li>Analisar Expansão de Material</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Analisar Expansão de Material</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <div className="buttons is-right">
                      <div className="div-select-box-status">
                        <Select
                          style={{
                            height: '42px',
                            marginBottom: '8px',
                          }}
                          className="select-status"
                          value={status}
                          onChange={(e) => {
                            handleFlux(e.target.value);
                          }}
                        >
                          <MenuItem value={'PENDENTE'}>PENDENTE</MenuItem>
                          <MenuItem value={'ANDAMENTO'}>EM ANDAMENTO</MenuItem>
                          <MenuItem value={'CONCLUIDO'}>CONCLUÍDO</MenuItem>
                          <MenuItem value={'RECUSADO'}>RECUSADO</MenuItem>
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Detalhes da solicitação
                </p>
              </header>
              <div
                className="card-content"
                style={{
                  display: 'flex',
                  margin: '0px 0px 30px 0px',
                }}
              >
                <div className="atributos-form">
                  <div className="div-quadro-main-form">
                    <div className="div-quadro-material">
                      <div
                        className="field"
                        style={{
                          paddingBottom: '12px',
                          borderBottom: '1px solid #dbdbdb',
                        }}
                      >
                        <div className="row-field" style={{ fontSize: '20px' }}>
                          <label className="label-field">
                            {tarefa.nome_relator} criou esta solicitação
                          </label>
                        </div>
                      </div>

                      <div className="field">
                        <div className="row-field">
                          <label className="label-field">id</label>
                          <span className="cell-value">{tarefa.id}</span>
                        </div>
                      </div>

                      {tarefa.titulo && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Resumo</label>
                            <span className="cell-value">{tarefa.titulo}</span>
                          </div>
                        </div>
                      )}

                      {ex_material.sap_id && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">SAP ID</label>
                            <span className="cell-value">
                              {ex_material.sap_id}
                            </span>
                          </div>
                        </div>
                      )}

                      {ex_material.centros && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Centro(s)</label>
                            <span className="cell-value">
                              {ex_material.centros}
                            </span>
                          </div>
                        </div>
                      )}

                      {ex_material.centro_novo && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Expandir para</label>
                            <span className="cell-value">
                              {ex_material.centro_novo}
                            </span>
                          </div>
                        </div>
                      )}

                      {ex_material.texto_breve && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Texto breve</label>
                            <span className="cell-value">
                              {ex_material.texto_breve}
                            </span>
                          </div>
                        </div>
                      )}

                      {ex_material.texto_longo && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Texto longo</label>
                            <span className="cell-value">
                              {ex_material.texto_longo}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* ---------------------------------  Quadros laterais ---------------------------------*/}
                  <div className="div-quadros-secundarios">
                    {/* ----------------------- RESPONDER AO SOLICITANTE ----------------------- */}
                    <div className="div-quadro-SLAs">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">
                          Adicionar detalhes ao status
                        </label>
                      </div>
                      <div className="div-quadro-content">
                        <div
                          className="row-field-message"
                          style={{ width: '100%' }}
                        >
                          <label
                            className="text"
                            style={{
                              color: '#171717',
                            }}
                          >
                            Informe ao solicitante detalhes sobre o status da
                            tarefa:
                          </label>
                          <div
                            className="cell-value"
                            style={{
                              paddingLeft: '0px',
                              paddingTop: '15px',
                            }}
                          >
                            <textarea
                              rows="4"
                              className="input"
                              value={respostaSolicitante}
                              style={{
                                height: '100px',
                                backgroundColor: '#f9f9f9',
                              }}
                              onChange={(e) =>
                                setRespostaSolicitante(e.target.value)
                              }
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="div-quadro-SLAs">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">SLAs</label>
                      </div>
                      <div className="div-quadro-content">
                        <div className="row-field">
                          <label className="label-field">Criado em</label>
                          <span className="cell-value">
                            {new Date(tarefa.criado_em).toLocaleString()}
                          </span>
                        </div>

                        <div className="row-field">
                          <label className="label-field">
                            Tempo para resolução
                          </label>
                          <span className="cell-value">
                            {new Date(tarefa.data_due).toLocaleString()}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="div-quadro-informacoes">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">
                          Informações
                        </label>
                      </div>
                      <div className="div-quadro-content">
                        <div className="row-field">
                          <label className="label-field">
                            Responsável Suprimentos
                          </label>
                          <span className="cell-value">
                            {responsavel.nome &&
                              responsavel.nome + ' ' + responsavel.sobrenome}
                          </span>
                        </div>

                        <div className="row-field">
                          <label className="label-field">Relator</label>
                          <span className="cell-value">
                            {tarefa.nome_relator} {tarefa.sobrenome_relator}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Comentários
                </p>
              </header>
              <div className="card-content" style={{ width: '100%' }}>
                <form name="comment" onSubmit={postRootComment}>
                  <div className="field">
                    <div className="control">
                      <textarea
                        className="textarea"
                        placeholder="Comentário"
                        value={userComment}
                        onChange={(e) => setUserComment(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div
                    className="div"
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <button className="button is-primary" type="submit">
                      Comentar
                    </button>
                  </div>
                </form>
                <br />
                {commentsData &&
                  commentsData.map((comment) => (
                    <ComentarioComponent
                      key={comment.id}
                      comment={comment}
                      userId={idUsuario}
                      objeto={tarefa}
                      getCommentFunction={getComments}
                    />
                  ))}
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default AprovarExpansaoMaterial;
