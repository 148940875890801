import React, { useEffect } from 'react';

const GeoCompDetail = ({ compObj, compItems }) => {
  const dateString = compObj.data;
  const date = new Date(dateString);
  const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  return (
    <>
      <div className="container has-background-light">
        <div className="card">
          <header className="card-header"></header>
          <div className="card-content">
            <div className="content">
              <div className="divider is-size-5 mt-1">
                DETALHES DA COMPOSIÇÃO
              </div>
              <div className="content">
                <table className="table is-fullwidth">
                  <thead>
                    <tr>
                      <th>TÍTULO</th>
                      <th>UN</th>
                      <th>PREÇO</th>
                      <th>DATA</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="is-size-7">{compObj.titulo}</td>
                      <td className="is-size-7">{compObj.unidade}</td>
                      <td className="is-size-7">
                        {formatPreco(compObj.preco)}
                      </td>
                      <td className="is-size-7">{formattedDate}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <div className="divider is-size-5">Itens</div>
              <div className="table">
                <table className="table is-hoverable is-fullwidth is-stripped">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>DESCRIÇÃO</th>
                      <th>UN</th>
                      <th>PRECO</th>
                      <th>COEFICIENTE</th>
                      <th>TIPO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {compItems.map((item) => (
                      <tr key={item.did}>
                        <td>
                          <p className="is-size-7">{item.did}</p>
                        </td>
                        <td>
                          <p className="is-size-7">{item.descricao}</p>
                        </td>
                        <td>
                          <p className="is-size-7">{item.unidade}</p>
                        </td>
                        <td>
                          <p className="is-size-7">{formatPreco(item.preco)}</p>
                        </td>
                        <td>
                          <p className="is-size-7">{item.coeficiente}</p>
                        </td>
                        <td>
                          <p
                            className={`is-size-7 ${item.tipo === 'C' ? 'tag is-link' : 'tag is-warning'}`}
                          >
                            {item.tipo}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeoCompDetail;
