import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import AlertMessage from '../../layouts/AlertMessage';
import axios from 'axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useUser } from '../../../context/UserContext';

const CapexDetailView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { capex_id } = useParams();
  const { REACT_APP_BASE_API_URL } = process.env;
  const [capexDetails, setCapexDetails] = useState([]);
  const [capexItems, setCapexItems] = useState([]);
  const [targetCol, setTargetCol] = useState('rev');
  const [selectedPriceType, setSelectedPriceType] = useState('ref');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const allowedRoles = ['ADMINISTRADOR'];
  const { gruposUsuario } = useUser();
  const [user, setUser] = useState({
    grupos: gruposUsuario,
  });

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };
  const headers = {
    'Content-Type': 'application/json',
  };

  const getCapex = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/capex/detail/`,
        {
          params: {
            capex_id: capex_id,
          },
          headers,
        }
      );
      const data = await response.data;
      setCapexDetails(data.capex);
      setCapexItems(data.linhas);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar detalhes do Capex'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleFilterLinhas = async (e) => {
    e.preventDefault();
    try {
    } catch (error) {
      console.error(error);
    }
  };

  const handleAdvanceFase = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/capex/advf/`,
        {},
        {
          params: {
            capex_id: capex_id,
          },
          headers,
        }
      );
      const data = await response.data;
      if (response.status == 200) {
        // alert('Fase avançada.')
        setAlertMessage('Fase avançada.');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao avançar fase do Capex'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };
  useEffect(() => {
    getCapex();
  }, []);

  const capexCusto = capexItems.reduce(
    (total, item) => total + item.preco_total,
    0
  );

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    last_orcado_preco: false,
    last_orcado_preco_total: false,
    last_orcado_origem: false,
    last_comprado_preco: false,
    last_comprado_preco_total: false,
    last_comprado_origem: false,
  });

  const toggleComprado = () => {
    setColumnVisibilityModel((prev) => ({
      ...prev,
      preco_und: false,
      preco_total: false,
      last_orcado_preco: false,
      last_orcado_preco_total: false,
      last_orcado_origem: false,
      last_comprado_preco: true,
      last_comprado_preco_total: true,
      last_comprado_origem: true,
    }));
  };

  const toggleOrcado = () => {
    setColumnVisibilityModel((prev) => ({
      ...prev,
      preco_und: false,
      preco_total: false,
      last_comprado_preco: false,
      last_comprado_preco_total: false,
      last_comprado_origem: false,
      last_orcado_preco: true,
      last_orcado_preco_total: true,
      last_orcado_origem: true,
    }));
  };

  const toggleRef = () => {
    setColumnVisibilityModel((prev) => ({
      ...prev,
      preco_und: true,
      preco_total: true,
      last_comprado_preco: false,
      last_comprado_preco_total: false,
      last_comprado_origem: false,
      last_orcado_preco: false,
      last_orcado_preco_total: false,
      last_orcado_origem: false,
    }));
  };

  const columns = [
    { field: 'ref', headerName: 'REF', flex: 1 },
    { field: 'rev', headerName: 'REV', flex: 1 },
    { field: 'grupo', headerName: 'GRUPO', flex: 1 },
    { field: 'disciplina', headerName: 'DISCIPLINA', flex: 2 },
    { field: 'mis', headerName: 'MIS', flex: 2 },
    { field: 'descricao', headerName: 'ITEM', flex: 6 },
    { field: 'unidade', headerName: 'UND', flex: 1 },
    { field: 'quantidade', headerName: 'QTD', flex: 1 },
    {
      field: 'preco_und',
      headerName: 'PRECO REF',
      flex: 2,
      renderCell: (params) => `R$ ${parseFloat(params.value).toFixed(2)}`,
    },
    {
      field: 'preco_total',
      headerName: 'PRECO TOTAL REF',
      flex: 2,
      renderCell: (params) => `R$ ${parseFloat(params.value).toFixed(2)}`,
    },
    {
      field: 'last_orcado_preco',
      headerName: 'PRECO ORCADO',
      flex: 2,
      renderCell: (params) => `R$ ${parseFloat(params.value).toFixed(2)}`,
    },
    {
      field: 'last_orcado_preco_total',
      headerName: 'PRECO TOTAL ORCADO',
      flex: 2,
      renderCell: (params) => `R$ ${parseFloat(params.value).toFixed(2)}`,
      hide: true,
    },
    { field: 'last_orcado_origem', headerName: 'ORIGEM ORCADO', flex: 2 },
    {
      field: 'last_comprado_preco',
      headerName: 'PRECO HISTORICO',
      flex: 2,
      renderCell: (params) => `R$ ${parseFloat(params.value).toFixed(2)}`,
      hide: true,
    },
    {
      field: 'last_comprado_preco_total',
      headerName: 'PRECO TOTAL HISTORICO',
      flex: 2,
      renderCell: (params) => `R$ ${parseFloat(params.value).toFixed(2)}`,
      hide: true,
    },
    { field: 'last_comprado_origem', headerName: 'ORIGEM ORCADO', flex: 2 },
  ];

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Geral</li>
                    <li>Tabela de empreendimentos</li>
                    <li>{location.state.page1}</li>
                    <li>{location.state.page2}</li>
                    <li>Detalhes capex </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Detalhes capex</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <div className="buttons is-right">
                      {capexDetails.fase &&
                        capexDetails.fase.charAt(0) === 'C' &&
                        capexDetails.rev === 0 &&
                        capexDetails.ver === 0 && (
                          <button
                            className="button is-primary"
                            onClick={() =>
                              navigate(
                                `/adm/capex/populate/${capexDetails.id}`,
                                {
                                  state: {
                                    empreendimento: location.state.page1,
                                    fluxo: 'Detalhes capex',
                                  },
                                }
                              )
                            }
                          >
                            <span className="icon">
                              <i className="fa-regular fa-pen-to-square"></i>
                            </span>
                            <span>Popular</span>
                          </button>
                        )}
                      {capexDetails.fase &&
                        (capexDetails.fase.charAt(0) === 'C' ||
                          capexDetails.fase.charAt(0) === 'B') &&
                        capexDetails.ver !== 0 && (
                          <button
                            className="button is-primary"
                            onClick={() =>
                              navigate(`/adm/capex/edit/${capexDetails.id}`, {
                                state: {
                                  empreendimento: location.state.page1,
                                },
                              })
                            }
                          >
                            <span className="icon">
                              <i className="fa-regular fa-pen-to-square"></i>
                            </span>
                            <span>Editar Linhas</span>
                          </button>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card has-table">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Informações gerais do capex
                </p>
              </header>
              <div style={{ padding: '1.5rem' }}>
                <table className="table is-fullwidth">
                  <thead style={{ backgroundColor: '#2e323a' }}>
                    <th style={{ color: 'white' }}>ID</th>
                    <th style={{ color: 'white' }}>EMPREENDIMENTO</th>
                    <th style={{ color: 'white' }}>PROJETO</th>
                    <th style={{ color: 'white' }}>FASE</th>
                    <th style={{ color: 'white' }}>REV</th>
                    <th style={{ color: 'white' }}>VER</th>
                    <th style={{ color: 'white' }}>CUSTO</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{capexDetails.did}</td>
                      <td>{capexDetails.empreendimento}</td>
                      <td>{capexDetails.projeto}</td>
                      <td>
                        {capexDetails.fase && (
                          <p
                            className={`${capexDetails.fase.charAt(0) === 'C' ? 'tag is-danger' : capexDetails.fase.charAt(0) === 'B' ? 'tag is-warning' : 'tag is-success'}`}
                          >
                            {capexDetails.fase}
                          </p>
                        )}
                      </td>
                      <td>{capexDetails.rev}</td>
                      <td>{capexDetails.ver}</td>
                      <td>{formatPreco(capexCusto)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card has-table">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Linhas cadastradas no capex
                </p>
              </header>
              <div className="card-content" style={{ padding: '1.5rem' }}>
                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="TabelaLinhas">
                      <div
                        style={{
                          height: 660,
                          width: '100%',
                        }}
                      >
                        <button onClick={toggleComprado}>Preço comprado</button>
                        <button onClick={toggleOrcado}>Preço orcado</button>
                        <button onClick={toggleRef}>Preço ref</button>
                        <DataGrid
                          rows={capexItems}
                          columns={columns}
                          columnVisibilityModel={columnVisibilityModel}
                          pageSize={5}
                          disableSelectionOnClick
                          getRowHeight={() => 'auto'}
                          getColumnHeight={() => 'auto'}
                          sx={{
                            fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
                            '& .MuiDataGrid-cell': {
                              display: 'flex',
                              alignItems: 'flex-start', // Alinha o conteúdo no topo
                              paddingTop: '4px', // Ajuste conforme necessário
                              paddingBottom: '4px',
                              border: '0px',
                              fontFamily: '"Nunito", sans-serif',
                              lineHeight: '1.5',
                              fontSize: '12px',
                              textAlign: 'justify',
                            },
                            '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                              {
                                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                                fontSize: '12px',
                                color: '#171717',
                                fontFamily: '"Nunito", sans-serif',
                                lineHeight: '1.5',
                              },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    margin: '40px 0px 20px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <button
                    className="button is-primary"
                    disabled={
                      !allowedRoles.some((role) => user.grupos.includes(role))
                    }
                    onClick={handleAdvanceFase}
                  >
                    Avançar fase
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default CapexDetailView;
