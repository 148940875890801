import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CheckIcon from '@mui/icons-material/Check';
import AlertMessage from '../../layouts/AlertMessage';

const AdminUserRegister = ({ info }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [allEmpresas, setAllEmpresas] = useState([]);
  const [allGrupos, setAllGrupos] = useState([]);
  const [allSetores, setAllSetores] = useState([]);
  const [allDisciplinas, setAllDisciplinas] = useState([]);

  const [userEmail, setUserEmail] = useState('');
  const [userCargo, setUserCargo] = useState('');
  const [userEmpresa, setUserEmpresa] = useState('');
  const [userGrupos, setUserGrupos] = useState('');
  const [userSetor, setUserSetor] = useState('');
  const [userSetorId, setUserSetorId] = useState('');
  const [userDisciplina, setUserDisciplina] = useState('');
  const [userDisciplinasId, setUserDisciplinasId] = useState('');
  const [userRecebeEmail, setUserRecebeEmail] = useState(false);

  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const [selectedDisciplinas, setSelectedDisciplinas] = useState([]);
  const [selectedGrupos, setSelectedGrupos] = useState([]);

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleSetorChange = (val) => {
    const targetSetor = allSetores.find((setor) => setor.id === val);
    setUserSetor(targetSetor.setor);
    setUserSetorId(targetSetor.id);
  };

  // const handleDisciplinaChange = (event) => {
  //     const selectedOptions = Array.from(event.target.value).map(option => option);
  //     setSelectedDisciplinas(event.target.value);
  //     setUserDisciplinasId(selectedOptions);
  // };

  const handleGrupoChange = (event) => {
    const selectedOptions = Array.from(event.target.value).map(
      (option) => option
    );
    setSelectedGrupos(event.target.value);
    setUserGrupos(selectedOptions);
  };

  const getEmpresas = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/fornecedor/`,
        { headers }
      );
      if (response.status === 200) {
        setAllEmpresas(response.data.fornecedores);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar empresas.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getSetores = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/setores/`,
        { headers }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAllSetores(data);
      }
    } catch (error) {
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || "Ocorreu um erro.");
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar setores.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getDisciplinas = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/disciplinas/`,
        { headers }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAllDisciplinas(data);
      }
    } catch (error) {
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || "Ocorreu um erro.");
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar disciplinas.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getGrupos = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/admin/grupos/`,
        {
          headers,
        }
      );
      const data = await response.data;
      if (response.status === 200) {
        setAllGrupos(data);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar grupos.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleCad = async (e) => {
    e.preventDefault();

    let userGrupos = selectedGrupos.join(' ');

    let requestObj = {
      email: userEmail.toLowerCase(),
      cargo: userCargo.toLowerCase(),
      empresa: userEmpresa,
      setor: userSetor,
      role: 'analista',
      grupos: userGrupos,
      setor_id: userSetorId,
      recebe_email: userRecebeEmail,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/admin/email/preautorizar/`,
        requestObj,
        {
          // params: {
          //     disciplinas_id: userDisciplinasIdStr
          // },
          headers,
        }
      );
      if (response.status === 201) {
        // alert(`Email ${userEmail} pré-aprovado!`)
        setAlertMessage(`Email ${userEmail} pré-aprovado!`);
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao pré autorizar email.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
      // alert(error.response.data.detail)
    }
  };

  useEffect(() => {
    getEmpresas();
    getGrupos();
    getSetores();
    getDisciplinas();
  }, []);

  return (
    <>
      <div className="container">
        <br />
        <form name="cadEmail" onSubmit={handleCad} className="modal-form">
          <div className="field-modal">
            <label className="label-modal">
              Email
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
            </label>
            <input
              className="input-modal"
              type="email"
              required
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
          </div>

          <div className="field-modal">
            <label className="label-modal">
              Cargo
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
            </label>
            <input
              className="input-modal"
              type="text"
              required
              value={userCargo}
              onChange={(e) => setUserCargo(e.target.value)}
            />
          </div>

          <div className="field-modal">
            <label className="label-modal">
              Setor
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
            </label>
            <Select
              labelId="demo-simple-select-label"
              id="setor"
              className="select-modal"
              required
              value={userSetorId}
              onChange={(e) => handleSetorChange(e.target.value)}
            >
              {allSetores.map((setor) => (
                <MenuItem key={setor.id} value={setor.id}>
                  {setor.setor.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </div>

          {/* <div className="field-modal">
                    <label className="label-modal"> 
                        Disciplina 
                      <span className="required-symbol" style={{fontSize: '1.2rem'}}> *</span>
                    </label>
                    <Select
                        labelId="demo-simple-select-label"
                        id="disciplina"
                        className="select-modal"
                        required
                        multiple
                        value={selectedDisciplinas}
                        onChange={handleDisciplinaChange}
                    >   
                    {allDisciplinas.map((disciplina) => (
                      <MenuItem key={disciplina.id} value={disciplina.id}>{disciplina.disciplina.toUpperCase()}</MenuItem>
                    ))}
                    </Select>
                </div> */}

          <div className="field-modal">
            <label className="label-modal">
              Empresa
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
            </label>
            <Select
              labelId="demo-simple-select-label"
              id="empresa"
              className="select-modal"
              required
              value={userEmpresa}
              onChange={(e) => setUserEmpresa(e.target.value)}
            >
              {allEmpresas.map((empresa) => (
                <MenuItem key={empresa.id} value={empresa.id}>
                  {empresa.razao_social}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="field-modal">
            <label className="label-modal">
              Grupo
              <span className="required-symbol" style={{ fontSize: '1.2rem' }}>
                {' '}
                *
              </span>
            </label>
            <Select
              labelId="demo-simple-select-label"
              id="grupo"
              className="select-modal"
              required
              multiple
              value={selectedGrupos}
              onChange={handleGrupoChange}
            >
              {allGrupos.map((grupo) => (
                <MenuItem key={grupo.id} value={grupo.id}>
                  {grupo.nome}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="field-modal">
            <label className="label-modal">Recebe Emails?</label>
            <input
              type="checkbox"
              checked={userRecebeEmail}
              onChange={(e) => setUserRecebeEmail(e.target.checked)}
            />
          </div>

          <div className="modal-btn-footer">
            <button type="submit" className="button-modal btn-submit">
              Cadastrar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AdminUserRegister;
