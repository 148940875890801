import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MainLayout from '../layouts/MainLayout';
import './css/Formularios.css';
import classeAvaliacaoData from './resources/classe_avaliacao.json';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import ComentarioComponent from '../functional/comentarios/Comentario';
import AlertMessage from '../layouts/AlertMessage';
import { useUser } from '../../context/UserContext';

const EditarTarefaMaterialForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;
  const { tarefa, material, route } = location.state;
  const [id_sap, setIdSAP] = useState(material.id_sap);
  const [comentarioSuprimentos, setComentarioSuprimentos] = useState('');
  const [descTipoMaterial, setDescTipoMaterial] = useState(
    material.desc_tipo_material
  );
  const [descricaoBreveSuprimentos, setDescricaoBreveSuprimentos] = useState(
    material.descricao_breve_suprimentos
  );
  const [status, setStatus] = useState(tarefa.status);
  const [tipoMaterial, setTipoMaterial] = useState(material.tipo_material);
  const [responsavelCont, setResponsavelCont] = useState([]);
  const [origemMaterial, setOrigemMaterial] = useState(
    material.origem_material
  );
  const [classeAvaliacao, setClasseAvaliacao] = useState(
    material.classe_avaliacao
  );
  const [commentsData, setCommentsData] = useState([]);
  const [userComment, setUserComment] = useState('');
  const { idUsuario, setIdUsuario } = useUser();
  const statusMiddleList = ['ANDAMENTO', 'PENDENTE', 'ROTA', 'COTACAO'];

  const [validSap, setValidSap] = useState(material.id_sap);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  useEffect(() => {
    const getResponsavelControladoria = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_BASE_API_URL}/tarefas/responsavel`,
          {
            params: {
              tarefa_id: tarefa.link_bw,
            },
            headers: headers,
          }
        );

        if (response.data) {
          setResponsavelCont(response.data);
        }
      } catch (error) {
        console.error('Erro ao buscar responsavel controladoria:', error);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail ||
                'Erro ao buscar responsável de controladoria.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    };

    if (tarefa.link_bw) {
      getResponsavelControladoria();
    } else {
      setResponsavelCont({
        nome: 'Sem',
        sobrenome: 'Responsável',
      });
    }
    if (
      comentarioSuprimentos === 'null' ||
      comentarioSuprimentos === null ||
      comentarioSuprimentos === undefined
    ) {
      setComentarioSuprimentos('');
    }
  }, [REACT_APP_BASE_API_URL]);

  useEffect(() => {
    if (idUsuario === null || idUsuario === 'null' || idUsuario === undefined) {
      setIdUsuario(idUsuario);
    }
  }, [idUsuario]);

  const handleFlux = async (status_val) => {
    const mensagem = `
    Sua solicitação para o cadastro do material ${material.resumo} foi atualizada para ${status_val}
    

    Detalhes: ${comentarioSuprimentos}
    `;
    let schema = {
      comentario: mensagem,
      tarefa_id: tarefa.id,
      email: tarefa.email_relator,
    };
    if (statusMiddleList.includes(status_val)) {
      try {
        const response = await axios.put(
          `${REACT_APP_BASE_API_URL}/tarefas/status/`,
          schema,
          {
            params: { status: status_val, send_mail: false },
            headers,
          }
        );
        if (response.status === 200) {
          // alert('O status da solicitação foi alterado com sucesso.');
          setAlertMessage('O status da solicitação foi alterado com sucesso.');
          setAlertSeverity('success');
          setAlertOpen(true);
          setStatus(status_val);
          // navigate('/suprimentos/fila-tarefas/');
          setNavigateTo('/suprimentos/fila-tarefas/');
        }
      } catch (error) {
        // alert(error.response.data.detail);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail ||
                'Erro ao alterar status da solicitação.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
        console.error(error);
      }
    } else if (status_val === 'RECUSADO') {
      if (comentarioSuprimentos === '' || comentarioSuprimentos === null) {
        // alert("É necessário explicar o motivo da recusa.")
        setAlertMessage('É necessário explicar o motivo da recusa.');
        setAlertSeverity('error');
        setAlertOpen(true);
        return;
      }
      try {
        const delResponse = await axios.delete(
          `${REACT_APP_BASE_API_URL}/mats/insumos/`,
          {
            params: { insumo_id: material.id },
            headers,
          }
        );
        if (delResponse.status === 200) {
          try {
            const refuseResponse = await axios.put(
              `${REACT_APP_BASE_API_URL}/tarefas/done/`,
              schema,
              {
                params: { status: status_val },
                headers,
              }
            );
            if (refuseResponse.status === 200) {
              // alert("Solicitação recusada com sucesso.");
              setAlertMessage('Solicitação recusada com sucesso.');
              setAlertSeverity('success');
              setAlertOpen(true);
              setStatus(status_val);
              // navigate('/suprimentos/fila-tarefas/');
              setNavigateTo('/suprimentos/fila-tarefas/');
            }
          } catch (error) {
            // alert(error.response.data.detail);
            setAlertMessage(
              Array.isArray(error.response?.data?.detail)
                ? JSON.stringify(error.response?.data?.detail, null, 2)
                : error.response?.data?.detail || 'Erro ao recusar solicitação.'
            );
            setAlertSeverity('error');
            setAlertOpen(true);
            console.error(error);
          }
        }
      } catch (error) {
        console.error(error);
        // alert(error.response.data.detail);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao deletar material.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    } else if (status_val === 'CONCLUIDO') {
      if (id_sap === '' || id_sap === null) {
        // alert("É necessário informar o ID SAP")
        setAlertMessage('É necessário informar o ID SAP.');
        setAlertSeverity('error');
        setAlertOpen(true);
        return;
      }
      try {
        const cadResponse = await axios.put(
          `${REACT_APP_BASE_API_URL}/tarefas/editar-status-insumo/`,
          {},
          {
            params: {
              material_id: material.id,
              sap_id: id_sap,
              descc: descricaoBreveSuprimentos,
            },
            headers,
          }
        );
        if (cadResponse.status === 200) {
          let okMessage = `
          Sua solicitação para o cadastro do material ${material.resumo} foi aceita! \n
          O ID do material no SAP é ${id_sap} \n
    

          Detalhes: ${comentarioSuprimentos}
          `;
          let okSchema = {
            comentario: okMessage,
            tarefa_id: tarefa.id,
            email: tarefa.email_relator,
          };
          try {
            const cadStatusResponse = await axios.put(
              `${REACT_APP_BASE_API_URL}/tarefas/done/`,
              okSchema,
              {
                params: { status: status_val },
                headers,
              }
            );
            if (cadStatusResponse.status === 200) {
              // alert("Solicitação concluida com sucesso.");
              setAlertMessage('Solicitação concluída com sucesso.');
              setAlertSeverity('success');
              setAlertOpen(true);
              setStatus(status_val);
              // navigate('/suprimentos/fila-tarefas/');
              setNavigateTo('/suprimentos/fila-tarefas/');
            }
          } catch (error) {
            console.error(error);
            // alert(error.response.data.detail);
            setAlertMessage(
              Array.isArray(error.response?.data?.detail)
                ? JSON.stringify(error.response?.data?.detail, null, 2)
                : error.response?.data?.detail ||
                    'Erro ao concluir solicitação.'
            );
            setAlertSeverity('error');
            setAlertOpen(true);
          }
        }
      } catch (error) {
        console.error(error);
        // alert(error.response.data.detail);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao concluir solicitação.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedMaterial = {
      ...material,
      id_sap: id_sap,
      comentario_suprimentos: comentarioSuprimentos,
      descricao_breve_suprimentos: descricaoBreveSuprimentos,
      origem_material: origemMaterial
        ? origemMaterial
        : '0 - Nacional - exceto indicado para códigos 3, 4, 5 ou 8',
      classe_avaliacao: classeAvaliacao,
      status: status,
    };

    try {
      const conteudo = JSON.stringify(updatedMaterial);
      const params = new URLSearchParams({ conteudo: conteudo });
      try {
        const response = await axios.put(
          `${REACT_APP_BASE_API_URL}/tarefas/editar-conteudo-tarefa/?tarefa_id=${tarefa.id}&${params.toString()}`,
          {}, // Corpo vazio, já que estamos enviando status como parâmetro de query
          { headers }
        );

        if (response.status === 200) {
          // alert("Material atualizado.");
          setAlertMessage('Material atualizado.');
          setAlertSeverity('success');
          setAlertOpen(true);
          setValidSap(id_sap);
        }
      } catch (error) {
        console.error(error);
        // alert(error.response.data.detail);
        setAlertMessage(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao atualizar material.'
        );
        setAlertSeverity('error');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao atualizar material.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const postRootComment = async (e) => {
    e.preventDefault();
    let obj = {
      text: userComment,
      user_id: idUsuario,
      objeto: tarefa.id,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/comments/`,
        obj,
        { headers }
      );
      if (response.status === 201) {
        setUserComment('');
        getComments();
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || "Ocorreu um erro.")
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao postar comentário.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const getComments = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_API_URL}/comments/`, {
        params: { objeto_id: tarefa.id },
        headers,
      });
      if (response.status === 200) {
        setCommentsData(response.data);
      }
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || "Ocoreu um erro.")
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar comentários.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    getComments();
  }, []);

  useEffect(() => {
    return () => {
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  const handleClose = () => {
    if (navigateTo) {
      navigate('/suprimentos/fila-tarefas/');
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    {/* <li>Setores</li> */}
                    <li>Suprimentos</li>
                    <li>Fila de materiais</li>
                    <li>Analisar material</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Analisar material</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    {tarefa.status !== 'PREDECESSORA PENDENTE' && (
                      <div className="buttons is-right">
                        <div className="div-select-box-status">
                          <Select
                            style={{
                              height: '42px',
                              marginBottom: '8px',
                            }}
                            className="select-status"
                            value={status}
                            onChange={(e) => {
                              handleFlux(e.target.value);
                            }}
                          >
                            <MenuItem value={'PENDENTE'}>PENDENTE</MenuItem>
                            <MenuItem value={'ANDAMENTO'}>
                              EM ANDAMENTO
                            </MenuItem>
                            <MenuItem
                              value={'CONCLUIDO'}
                              disabled={
                                (validSap === null) | (validSap === 'null')
                              }
                            >
                              CONCLUÍDO
                            </MenuItem>
                            <MenuItem value={'RECUSADO'}>RECUSADO</MenuItem>
                          </Select>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Detalhes da solicitação
                </p>
              </header>
              <div
                className="card-content"
                style={{
                  display: 'flex',
                  margin: '0px 0px 30px 0px',
                }}
              >
                <div className="atributos-form">
                  <div className="div-quadro-main-form">
                    <div className="div-quadro-material">
                      <div
                        className="field"
                        style={{
                          paddingBottom: '12px',
                          borderBottom: '1px solid #dbdbdb',
                        }}
                      >
                        <div className="row-field" style={{ fontSize: '20px' }}>
                          <label className="label-field">
                            {tarefa.nome_relator +
                              ' ' +
                              tarefa.sobrenome_relator}{' '}
                            criou esta solicitação
                          </label>
                        </div>
                      </div>

                      <div className="field">
                        <div className="row-field">
                          <label className="label-field">Id</label>
                          <span className="cell-value">{tarefa.id}</span>
                        </div>
                      </div>

                      {material.id_sap && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">ID SAP</label>
                            <span className="cell-value">
                              {material.id_sap}
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="field">
                        <div className="row-field">
                          <label className="label-field">Resumo</label>
                          <span className="cell-value">{tarefa.titulo}</span>
                        </div>
                      </div>

                      <div className="field">
                        <div className="row-field">
                          <label className="label-field">Prioridade</label>
                          <span className="cell-value">
                            {material.prioridade_material}
                          </span>
                        </div>
                      </div>

                      <div className="field">
                        <div className="row-field">
                          <label className="label-field">Centro</label>
                          <span className="cell-value">{material.centro}</span>
                        </div>
                      </div>

                      {material.tipo_material && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Tipo de material
                            </label>
                            <span className="cell-value">
                              {material.tipo_material}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.descricao_breve && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Descrição breve
                            </label>
                            <span className="cell-value">
                              {material.descricao_breve}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.cfop && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">CFOP</label>
                            <span className="cell-value">{material.cfop}</span>
                          </div>
                        </div>
                      )}

                      {material.unidade_medida && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Unidade de medida
                            </label>
                            <span className="cell-value">
                              {material.unidade_medida}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.ncm && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">NCM</label>
                            <span className="cell-value">{material.ncm}</span>
                          </div>
                        </div>
                      )}

                      {material.norma && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Norma</label>
                            <span className="cell-value">{material.norma}</span>
                          </div>
                        </div>
                      )}

                      {material.fabricante && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Fabricante</label>
                            <span className="cell-value">
                              {material.fabricante}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.preco_medio !== 0 && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Preço médio</label>
                            <span className="cell-value">
                              R$ {material.preco_medio}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.finalidade && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Finalidade</label>
                            <span className="cell-value">
                              {material.finalidade}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.quantidade_itens && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">Quantidade</label>
                            <span className="cell-value">
                              {material.quantidade_itens}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.descricao_longa && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Descrição longa
                            </label>
                            <span className="cell-value">
                              {material.descricao_longa}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.observacoes_solicitante && (
                        <div className="field">
                          <div className="row-field">
                            <label className="label-field">
                              Observações do solicitante
                            </label>
                            <span className="cell-value">
                              {material.observacoes_solicitante}
                            </span>
                          </div>
                        </div>
                      )}

                      {material.observacoes && (
                        <div className="field">
                          <label className="label-field">Observações</label>
                          <div className="row-field">
                            <span className="cell-value-full">
                              {material.observacoes}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* ----------------------- QUADRO DE CONTROLADORIA ----------------------- */}

                    {responsavelCont.nome && (
                      <div className="div-quadro-material-oculto">
                        <div className="div-quadro-material">
                          <div
                            className="field"
                            style={{
                              paddingBottom: '12px',
                              borderBottom: '1px solid #dbdbdb',
                            }}
                          >
                            <div
                              className="row-field"
                              style={{
                                fontSize: '20px',
                              }}
                            >
                              <label className="label-field">
                                {responsavelCont.nome +
                                  ' ' +
                                  responsavelCont.sobrenome}{' '}
                                analisou esta solicitação
                              </label>
                            </div>
                          </div>

                          {material.tipo_material && (
                            <div className="field">
                              <div className="row-field">
                                <label className="label-field">
                                  Tipo de material
                                </label>
                                <span className="cell-value">
                                  {material.tipo_material}
                                </span>
                              </div>
                            </div>
                          )}

                          {material.classe_avaliacao && (
                            <div className="field">
                              <div className="row-field">
                                <label className="label-field">
                                  Classe avaliação
                                </label>
                                <span className="cell-value">
                                  {material.classe_avaliacao}
                                </span>
                              </div>
                            </div>
                          )}

                          {material.origem_material && (
                            <div className="field">
                              <div className="row-field">
                                <label className="label-field">
                                  Origem do material
                                </label>
                                <span className="cell-value">
                                  {material.origem_material}
                                </span>
                              </div>
                            </div>
                          )}

                          {material.utilizacao_material && (
                            <div className="field">
                              <div className="row-field">
                                <label className="label-field">
                                  Utilizacao do material
                                </label>
                                <span className="cell-value">
                                  {material.utilizacao_material}
                                </span>
                              </div>
                            </div>
                          )}

                          {material.comentario_controladoria && (
                            <div className="field">
                              <div className="row-field">
                                <label className="label-field">
                                  Comentarios controladoria
                                </label>
                                <span className="cell-value">
                                  {material.comentario_controladoria}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {/* ---------------------------------  Quadros laterais ---------------------------------*/}

                  <div className="div-quadros-secundarios">
                    <div className="div-quadro-SLAs">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">
                          Adicionar comentários
                        </label>
                      </div>
                      <div className="div-quadro-content">
                        <div
                          className="row-field-message"
                          style={{ width: '100%' }}
                        >
                          <label
                            className="text"
                            style={{
                              color: 'hsl(0, 0%, 21%)',
                            }}
                          >
                            Informe ao solicitante detalhes sobre o status da
                            solicitação:
                          </label>
                          <div
                            className="cell-value"
                            style={{
                              paddingLeft: '0%',
                              paddingTop: '15px',
                            }}
                          >
                            <textarea
                              rows={'4'}
                              className="input"
                              value={comentarioSuprimentos}
                              style={{
                                height: '100px',
                                backgroundColor: '#f9f9f9',
                              }}
                              onChange={(e) =>
                                setComentarioSuprimentos(e.target.value)
                              }
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="div-quadro-SLAs">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">SLAs</label>
                      </div>
                      <div className="div-quadro-content">
                        <div className="row-field">
                          <label className="label-field">Criado em</label>
                          <span className="cell-value">
                            {new Date(tarefa.criado_em).toLocaleString()}
                          </span>
                        </div>

                        <div className="row-field">
                          <label className="label-field">
                            Tempo para resolução
                          </label>
                          <span className="cell-value">
                            {new Date(tarefa.data_due).toLocaleString()}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="div-quadro-informacoes">
                      <div className="div-quadro-title">
                        <label className="label-content-quadro">
                          Informações
                        </label>
                      </div>
                      <div className="div-quadro-content">
                        <div className="row-field">
                          <label className="label-field">
                            Responsável Suprimentos
                          </label>
                          <span className="cell-value">
                            {tarefa.responsavel}
                          </span>
                        </div>

                        <div className="row-field">
                          <label className="label-field">
                            Responsável Controladoria
                          </label>
                          <span className="cell-value">
                            {responsavelCont.nome &&
                              responsavelCont.nome +
                                ' ' +
                                responsavelCont.sobrenome}
                          </span>
                        </div>

                        <div className="row-field">
                          <label className="label-field">Relator</label>
                          <span className="cell-value">
                            {tarefa.nome_relator +
                              ' ' +
                              tarefa.sobrenome_relator}
                          </span>
                        </div>

                        <div className="row-field">
                          <label className="label-field">Prioridade</label>
                          <span className="cell-value">
                            {tarefa.prioridade}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ----------------------- ADICIONAR INFORMACOES DO MATERIAL ----------------------- */}

            {tarefa.status !== 'PREDECESSORA PENDENTE' && (
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Editar informações do material
                  </p>
                </header>

                <div className="card-content">
                  <form name="EditarMaterialForm" onSubmit={handleSubmit}>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          ID SAP
                          <span className="required-symbol">*</span>
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <p className="control is-expanded has-icons-left">
                            <input
                              className="input"
                              type="text"
                              value={id_sap}
                              required
                              onChange={(e) => setIdSAP(e.target.value)}
                            />
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-gears"></i>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          Origem do material
                          <span className="required-symbol">*</span>
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <p className="control is-expanded has-icons-left">
                            <div className="select is-fullwidth">
                              <select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                className="custom-select-style"
                                value={origemMaterial}
                                required
                                onChange={(e) =>
                                  setOrigemMaterial(e.target.value)
                                }
                              >
                                <option
                                  value={
                                    '0 - Nacional - exceto indicado para códigos 3, 4, 5 ou 8'
                                  }
                                >
                                  0 - Nacional - exceto indicado para códigos 3,
                                  4, 5 ou 8
                                </option>
                                <option
                                  value={'1 - Estrangeiro - import.direta'}
                                >
                                  1 - Estrangeiro - import.direta
                                </option>
                                <option
                                  value={'2 - Estrang.- aquis.merc.interno'}
                                >
                                  2 - Estrang.- aquis.merc.internoo
                                </option>
                                <option
                                  value={
                                    '3 - Nacional - com quota de importação entre 40 e 70%, inclusive'
                                  }
                                >
                                  3 - Nacional - com quota de importação entre
                                  40 e 70%, inclusive
                                </option>
                                <option
                                  value={
                                    '4 - Nacional - produção com incentivo de imposto'
                                  }
                                >
                                  4 - Nacional - produção com incentivo de
                                  imposto
                                </option>
                                <option
                                  value={
                                    '5 - Nacional - c/conteúdo de importação inferior ou igual a 40%'
                                  }
                                >
                                  5 - Nacional - c/conteúdo de importação
                                  inferior ou igual a 40%
                                </option>
                                <option
                                  value={
                                    '6 - Estrng.- imp.dir., nen.prod.nac.semlh., res.CAMEX e gás nat.'
                                  }
                                >
                                  6 - Estrng.- imp.dir., nen.prod.nac.semlh.,
                                  res.CAMEX e gás nat.
                                </option>
                                <option
                                  value={
                                    '7 - Estrng.- aquis.int., nenh.prd.nac.sem., res.CAMEX e gás nat.'
                                  }
                                >
                                  7 - Estrng.- aquis.int., nenh.prd.nac.sem.,
                                  res.CAMEX e gás nat.
                                </option>
                                <option
                                  value={
                                    '8 - Nacional - com quota de importação acima de 70%'
                                  }
                                >
                                  8 - Nacional - com quota de importação acima
                                  de 70%
                                </option>
                              </select>
                              <span className="icon is-small is-left">
                                <i className="fa-solid fa-location-dot"></i>
                              </span>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          Classe avaliação
                          <span className="required-symbol">*</span>
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <p className="control is-expanded has-icons-left">
                            <div className="select is-fullwidth">
                              <select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                className="custom-select-style"
                                value={classeAvaliacao}
                                required
                                onChange={(e) =>
                                  setClasseAvaliacao(e.target.value)
                                }
                              >
                                <option value="" disabled></option>
                                {classeAvaliacaoData[tipoMaterial]?.map(
                                  (opcao, index) => (
                                    <option key={index} value={opcao}>
                                      {opcao}
                                    </option>
                                  )
                                )}
                              </select>
                              <span className="icon is-small is-left">
                                <i className="fa-solid fa-pen-nib"></i>
                              </span>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          Descrição breve
                          <span className="required-symbol">*</span>
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <p className="control is-expanded has-icons-left">
                            <input
                              className="input"
                              type="text"
                              required
                              value={descricaoBreveSuprimentos}
                              onChange={(e) =>
                                setDescricaoBreveSuprimentos(e.target.value)
                              }
                            />
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-file"></i>
                            </span>
                          </p>
                          <label className="label-descricao">
                            Descrição breve com no máximo 40 caracteres
                          </label>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        margin: '40px 0px 20px 0px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <button className="button is-primary">
                        <span className="icon">
                          <i className="fa-solid fa-floppy-disk"></i>
                        </span>
                        <span>Salvar</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}

            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Comentários
                </p>
              </header>
              <div className="card-content" style={{ width: '100%' }}>
                <form name="comment" onSubmit={postRootComment}>
                  <div className="field">
                    <div className="control">
                      <textarea
                        className="textarea"
                        placeholder="Comentário"
                        value={userComment}
                        onChange={(e) => setUserComment(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div
                    className="div"
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <button className="button is-primary" type="submit">
                      Comentar
                    </button>
                  </div>
                </form>
                <br />
                {commentsData &&
                  commentsData.map((comment) => (
                    <ComentarioComponent
                      key={comment.id}
                      comment={comment}
                      userId={idUsuario}
                      objeto={tarefa}
                      getCommentFunction={getComments}
                    />
                  ))}
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default EditarTarefaMaterialForm;
