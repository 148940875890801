import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './components/functional/PrivateRoute';
import HomePage from './components/HomePage';
import LoginForm from './components/apps/usuarios/LoginForm';
import Register from './components/apps/usuarios/Register';
import FornecedoresHome from './components/apps/fornecedores/FornecedoresHome';
import FornecedoresForm from './components/apps/fornecedores/FornecedoresForm';
import FormularioCadEquipamento from './components/meic/MEICRegister';
import CadastroEmpreendimentos from './components/apps/empreendimentos/Empreendimentos';
import EmpreendimentoDetailView from './components/apps/empreendimentos/EmpreendimentosDetailView';
import ProjetoDetailView from './components/apps/projetos/ProjetosDetailView';
import Equipamentos from './components/meic/Equipamentos/Equipamentos';
import Materiais from './components/meic/Materiais/Materiais';
import CompHome from './components/meic/Composicoes/ComposicoesHome';
import ComposicoesForm from './components/meic/Composicoes/Composicoes';
import MISForm from './components/meic//Mis/MIS';
import MISHome from './components/meic/Mis/MISHome';
import MEICHome from './components/meic/HomeMeic';
import SolicitacaoRCFormV from './components/apps/solicitacao_rc/SolicitacaoRCFormV';
import SolicitacaoRCFormA from './components/apps/solicitacao_rc/SolicitacaoRCFormA';
import SolicitacaoRCHomePage from './components/apps/solicitacao_rc/SolicitacaoRCHome';
import MOForm from './components/meic/Servicos/MOForm';
import LocacaoForm from './components/meic/Servicos/LocacaoForm';
import ServicoHomePage from './components/meic/Servicos/ServicoHome';
import SinapiHome from './components/meic/Sinapi/SinapiHome';
import CapexDetailView from './components/apps/capex/CapexDetailView';
import CapexPopulateView from './components/apps/capex/CapexPopulateView';
import CapexEditView from './components/apps/capex/CapexEditView';
import ListaOrcamentoHome from './components/apps/listas/ListaOrcamento/ListasOrcamentoHome';
import ListasOrcamentoForm from './components/apps/listas/ListaOrcamento/ListasOrcamentoForm';
import ListaOrcamentoFornecedor from './components/apps/listas/ListaOrcamento/ListaOrcamentoFornecedor';
import ListasMateriaisHome from './components/apps/listas/ListasMateriaisHome';
import ListasMateriaisHomed from './components/apps/listas/ListasMateriaisHomed';
import ListaMateriaisAvulsaForm from './components/apps/listas/ListaMateriais/ListaMateriaisAvulsaForm';
import ListaMateriaisAvulsaView from './components/apps/listas/ListaMateriais/ListaMateriaisAvulsaView';
import ListaMateriaisAvulsaEdit from './components/apps/listas/ListaMateriais/ListaMateriaisAvulsaEdit';
import ListasMateriaisVinculadasForm from './components/apps/listas/ListaMateriais/ListasMateriaisVinculadasForm';
import FormCriarPDM from './components/suprimentos/CriarPDM';
import AprovarPdmForm from './components/suprimentos/AprovarPdm';
import EmpreendimentosTable from './components/apps/geral/TabelaEmpreendimentos';
import Cadastros from './components/apps/geral/Cadastros';
import ProfilePageNew from './components/apps/usuarios/ProfilePageNew';
import PasswordChange from './components/apps/usuarios/PasswordChange';
import PasswordForget from './components/apps/usuarios/PasswordForget';
import AdminPanel from './components/apps/admin/AdminPanel';
import AdminUserEdit from './components/apps/admin/AdminUserEdit';
import FormEditarPDM from './components/suprimentos/EditarPdm';
import AprovarEdicaoPdm from './components/suprimentos/AprovarEdicaoPdm';
import ClientesFormJuridico from './components/apps/clientes/ClientePJForm';
import FilaTarefas from './components/suprimentos/filaTarefas';
import EditarTarefaMaterialForm from './components/suprimentos/EditarTarefaMaterial';
import FilaTarefasControladoria from './components/controladoria/FilaTarefas';
import AnalisarMaterialControladoriaForm from './components/controladoria/AnalisarMaterial';
import AprovarFornecedor from './components/suprimentos/AprovarFornecedor';
import Solicitacoes from './components/apps/usuarios/Solicitacoes';
import AprovarCliente from './components/suprimentos/AprovarCliente';
import FreteForm from './components/apps/fretes/FreteForm';
import FreteHome from './components/apps/fretes/FreteHome';
import OrcamentoFreteDetail from './components/apps/fretes/OrcamentoFreteDetail';
import NotAuthorizedPage from './components/layouts/NotAuthorized';
import TabelaFornecedores from './components/apps/fornecedores/TabelaFornecedores';
import AprovarExpansaoMaterial from './components/suprimentos/AprovarExpansaoMaterial';
import AprovarFrete from './components/suprimentos/AprovarFrete';
import SolicitacaoRCHomePage2 from './components/apps/solicitacao_rc/SolicitacaoRCHome2';
import VisualizarSolicitacaoRC from './components/apps/solicitacao_rc/VisualizarSolicitacaoRC';
import FilaTarefasRC from './components/apps/solicitacao_rc/FilaTarefasRC';
import AdminRoleForm from './components/apps/admin/AdminRoleForm';
import AdminRoleEdit from './components/apps/admin/AdminRoleEdit';
import AdminGrupoForm from './components/apps/admin/AdminGrupoForm';
import FilaServico from './components/meic/PedidosServico/FilaPedidos';
import FilaServicoItems from './components/meic/PedidosServico/FilaPedidosItems';
import KanbanPlanejamento from './components/apps/kanban/KanbanPlanejamento';
import CadastroCardKanban from './components/apps/kanban/CadastrarCardKanban';
import RelatorioPorTag from './components/apps/relatorios/RelatorioTag';
import UserDashboards from './components/apps/dashboards/UserDashboards';

import SupervisorHomePage from './components/layouts/SupervisorHomePage';

import HomePagePF from './components/portalfornecedores/HomePagePF';
import PedidosFornecedores from './components/portalfornecedores/PedidosFornecedores';
import FreteHomeFornecedor from './components/portalfornecedores/fretes/FreteHomeFornecedor';
import FreteFormFornecedor from './components/portalfornecedores/fretes/FreteFormFornecedor';
import TabelaDocsEntregaveis from './components/apps/kanban/TabelaDocsEntregaveis';
import TabelaVersoesDocsEntregaveis from './components/apps/kanban/TabelaVersoesDocsEntregaveis';
import FreteHome2 from './components/apps/fretes/FreteHome2';
import VisualizarTarefaUser from './components/apps/usuarios/tarefas/VisualizarTarefaUser';
import FluxoDocsEntregaveis from './components/apps/kanban/FluxoDocsEntregaveis';

const App = () => {
  return (
    // <>
    <Routes>
      <Route
        exact
        path="/"
        element={
          <PrivateRoute
            allowedRoles={[
              'ADMINISTRADOR',
              'USUARIOS ATIVOS',
              'USUARIOS NAO ATIVOS',
            ]}
          >
            <HomePage />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/me/profileN/"
        element={
          <PrivateRoute
            allowedRoles={[
              'ADMINISTRADOR',
              'USUARIOS ATIVOS',
              'USUARIOS NAO ATIVOS',
            ]}
          >
            <ProfilePageNew />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/me/forgetpass/"
        element={
          <PrivateRoute>
            <PasswordForget />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/me/changepass/"
        element={
          <PrivateRoute
            allowedRoles={[
              'ADMINISTRADOR',
              'USUARIOS ATIVOS',
              'USUARIOS NAO ATIVOS',
            ]}
          >
            <PasswordChange />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/admin/"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR']}>
            <AdminPanel />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/supervisor/"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SUPERVISOR']}>
            <SupervisorHomePage />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/admin/rolesform/"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR']}>
            <AdminRoleForm />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/admin/roles/edit/:roleId"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR']}>
            <AdminRoleEdit />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/admin/gruposform/"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR']}>
            <AdminGrupoForm />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/admin/user/edit/:user_id"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR']}>
            <AdminUserEdit />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/geral/tabela_fornecedores/"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SUPRIMENTOS - FILAS']}>
            <TabelaFornecedores />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/not-authorized"
        element={
          // <PrivateRoute>
          <NotAuthorizedPage />
          // </PrivateRoute>
        }
      />

      <Route
        exact
        path="/fretes/form"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'FRETE - SOLICITAÇÃO']}>
            <FreteForm />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/fretes"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'FRETE - SOLICITAÇÃO']}>
            <FreteHome2 />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/loh/"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR']}>
            <ListaOrcamentoHome />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/lof/"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR']}>
            <ListasOrcamentoForm />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/lof/:lista_id"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR']}>
            <ListaOrcamentoFornecedor />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/lmh/"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SOLICITA RC']}>
            <ListasMateriaisHome />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/lista-materiais/"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SOLICITA RC']}>
            <ListasMateriaisHomed />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/lista-materiais/edit/:lista_id"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SOLICITA RC']}>
            <ListaMateriaisAvulsaEdit />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/lma-form/"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SOLICITA RC']}>
            <ListaMateriaisAvulsaForm />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/lmv-form/"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR']}>
            <ListasMateriaisVinculadasForm />
          </PrivateRoute>
        }
      />

      <Route
        path="/meic/mo"
        element={
          <PrivateRoute
            allowedRoles={['ADMINISTRADOR', 'CADASTRO COMPOSIÇÕES']}
          >
            <MOForm />
          </PrivateRoute>
        }
      />

      <Route
        path="/meic/serv/locacao"
        element={
          <PrivateRoute
            allowedRoles={['ADMINISTRADOR', 'CADASTRO COMPOSIÇÕES']}
          >
            <LocacaoForm />
          </PrivateRoute>
        }
      />

      <Route
        path="/meic/serv"
        element={
          <PrivateRoute
            allowedRoles={['ADMINISTRADOR', 'CADASTRO COMPOSIÇÕES']}
          >
            <ServicoHomePage />
          </PrivateRoute>
        }
      />

      <Route
        path="/meic/composicoes"
        element={
          <PrivateRoute
            allowedRoles={['ADMINISTRADOR', 'CADASTRO COMPOSIÇÕES']}
          >
            <CompHome />
          </PrivateRoute>
        }
      />

      <Route
        path="/meic/composicoes/geo"
        element={
          <PrivateRoute
            allowedRoles={['ADMINISTRADOR', 'CADASTRO COMPOSIÇÕES']}
          >
            <ComposicoesForm />
          </PrivateRoute>
        }
      />

      <Route
        path="/meic/sinapi"
        element={
          <PrivateRoute
            allowedRoles={['ADMINISTRADOR', 'CADASTRO COMPOSIÇÕES']}
          >
            <SinapiHome />
          </PrivateRoute>
        }
      />

      <Route
        path="/meic/materiais"
        element={
          <PrivateRoute
            allowedRoles={[
              'ADMINISTRADOR',
              'CADASTRO MATERIAIS',
              'USUARIOS ATIVOS',
            ]}
          >
            <Materiais />
          </PrivateRoute>
        }
      />

      <Route
        path="/meic/equipamentos"
        element={
          <PrivateRoute
            allowedRoles={[
              'ADMINISTRADOR',
              'CADASTRO MATERIAIS',
              'USUARIOS ATIVOS',
            ]}
          >
            <Equipamentos />
          </PrivateRoute>
        }
      />

      <Route
        path="/cadmat-form"
        element={
          <PrivateRoute
            allowedRoles={[
              'ADMINISTRADOR',
              'CADASTRO MATERIAIS',
              'USUARIOS ATIVOS',
            ]}
          >
            <FormularioCadEquipamento />
          </PrivateRoute>
        }
      />

      <Route
        path="/adm/empreendimentos"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR']}>
            <CadastroEmpreendimentos />
          </PrivateRoute>
        }
      />

      <Route
        path="/adm/empreendimentos/:empreendimento_id"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR']}>
            <EmpreendimentoDetailView />
          </PrivateRoute>
        }
      />

      <Route
        path="/geral/tabela_empreendimentos/"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'USUARIOS ATIVOS']}>
            <EmpreendimentosTable />
          </PrivateRoute>
        }
      />

      <Route
        path="/geral/minhas-solicitacoes/"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'USUARIOS ATIVOS']}>
            <Solicitacoes />
          </PrivateRoute>
        }
      />

      <Route
        path="/geral/cadastros"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'USUARIOS ATIVOS']}>
            <Cadastros />
          </PrivateRoute>
        }
      />

      <Route
        path="/adm/projetos/:projeto_id"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR']}>
            <ProjetoDetailView />
          </PrivateRoute>
        }
      />

      <Route
        path="/suprimentos/aprovar-expansao"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SUPRIMENTOS - FILAS']}>
            <AprovarExpansaoMaterial />
          </PrivateRoute>
        }
      />

      <Route
        path="/suprimentos/analisar-frete"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SUPRIMENTOS - FILAS']}>
            <AprovarFrete />
          </PrivateRoute>
        }
      />

      <Route
        path="/adm/capex/:capex_id"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR']}>
            <CapexDetailView />
          </PrivateRoute>
        }
      />

      <Route
        path="/adm/capex/populate/:capex_id"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR']}>
            <CapexPopulateView />
          </PrivateRoute>
        }
      />

      <Route
        path="/adm/capex/edit/:capex_id"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR']}>
            <CapexEditView />
          </PrivateRoute>
        }
      />

      <Route
        path="/meic/mis/form"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'CADASTRO MIS']}>
            <MISForm />
          </PrivateRoute>
        }
      />

      <Route
        path="/meic/mis"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'CADASTRO MIS']}>
            <MISHome />
          </PrivateRoute>
        }
      />

      <Route
        path="/suprimentos/criar-pdm-form"
        element={
          <PrivateRoute
            allowedRoles={[
              'ADMINISTRADOR',
              'USUARIOS ATIVOS',
              'CADASTRO MATERIAIS',
            ]}
          >
            <FormCriarPDM />
          </PrivateRoute>
        }
      />

      <Route
        path="/suprimentos/editar-pdm"
        element={
          <PrivateRoute
            allowedRoles={[
              'ADMINISTRADOR',
              'USUARIOS ATIVOS',
              'CADASTRO MATERIAIS',
            ]}
          >
            <FormEditarPDM />
          </PrivateRoute>
        }
      />

      <Route
        path="/suprimentos/fila-tarefas"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SUPRIMENTOS - FILAS']}>
            <FilaTarefas defaultButton={'Material'} />
          </PrivateRoute>
        }
      />

      <Route
        path="/controladoria/fila-tarefas"
        element={
          <PrivateRoute
            allowedRoles={['ADMINISTRADOR', 'CONTROLADORIA - FILAS']}
          >
            <FilaTarefasControladoria />
          </PrivateRoute>
        }
      />

      <Route
        path="/suprimentos/aprovar-pdm"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SUPRIMENTOS - FILAS']}>
            <AprovarPdmForm />
          </PrivateRoute>
        }
      />

      <Route
        path="/suprimentos/aprovar-edicao-pdm"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SUPRIMENTOS - FILAS']}>
            <AprovarEdicaoPdm />
          </PrivateRoute>
        }
      />

      <Route
        path="/suprimentos/cadastrar-fornecedor"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SUPRIMENTOS - FILAS']}>
            <AprovarFornecedor />
          </PrivateRoute>
        }
      />

      <Route
        path="/suprimentos/cadastrar-cliente"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SUPRIMENTOS - FILAS']}>
            <AprovarCliente />
          </PrivateRoute>
        }
      />

      <Route
        path="/suprimentos/editar-tarefa-material"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SUPRIMENTOS - FILAS']}>
            <EditarTarefaMaterialForm />
          </PrivateRoute>
        }
      />

      <Route
        path="/controladoria/analisar-tarefa"
        element={
          <PrivateRoute
            allowedRoles={['ADMINISTRADOR', 'CONTROLADORIA - FILAS']}
          >
            <AnalisarMaterialControladoriaForm />
          </PrivateRoute>
        }
      />

      <Route
        path="/fornecedores/"
        element={
          <PrivateRoute
            allowedRoles={[
              'ADMINISTRADOR',
              'CADASTRO DE FORNECEDORES E CLIENTES',
            ]}
          >
            <FornecedoresHome />
          </PrivateRoute>
        }
      />

      <Route
        path="/fornecedores/registrar"
        element={
          <PrivateRoute
            allowedRoles={[
              'ADMINISTRADOR',
              'CADASTRO DE FORNECEDORES E CLIENTES',
            ]}
          >
            <FornecedoresForm />
          </PrivateRoute>
        }
      />

      <Route
        path="/clientes/registrar"
        element={
          <PrivateRoute
            allowedRoles={[
              'ADMINISTRADOR',
              'CADASTRO DE FORNECEDORES E CLIENTES',
            ]}
          >
            <ClientesFormJuridico />
          </PrivateRoute>
        }
      />

      <Route
        path="/srch"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SOLICITA RC']}>
            <SolicitacaoRCHomePage2 />
          </PrivateRoute>
        }
      />

      <Route
        path="/user/visualizar-rc"
        element={
          <PrivateRoute
            allowedRoles={['ADMINISTRADOR', 'PLANEJAMENTO - FILAS']}
          >
            <VisualizarSolicitacaoRC />
          </PrivateRoute>
        }
      />

      <Route
        path="/plan/fila-tarefas-rc"
        element={
          <PrivateRoute
            allowedRoles={['ADMINISTRADOR', 'PLANEJAMENTO - FILAS']}
          >
            <FilaTarefasRC />
          </PrivateRoute>
        }
      />

      <Route
        path="/n-srch"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SOLICITA RC']}>
            <SolicitacaoRCHomePage />
          </PrivateRoute>
        }
      />

      <Route
        path="/srcv"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SOLICITA RC']}>
            <SolicitacaoRCFormV />
          </PrivateRoute>
        }
      />

      <Route
        path="/srca"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'SOLICITA RC']}>
            <SolicitacaoRCFormA />
          </PrivateRoute>
        }
      />

      <Route
        path="/pedidos-servico"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'FORNECEDOR EXTERNO']}>
            <FilaServico />
          </PrivateRoute>
        }
      />

      <Route
        path="/pedidos-servico-items"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'FORNECEDOR EXTERNO']}>
            <FilaServicoItems />
          </PrivateRoute>
        }
      />

      <Route
        path="/kanban-planejamento"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'KANBAN']}>
            <KanbanPlanejamento />
          </PrivateRoute>
        }
      />

      <Route
        path="/cadastro-card-kanban-pl"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'KANBAN']}>
            <CadastroCardKanban />
          </PrivateRoute>
        }
      />

      <Route
        path="/relatorios/tag/"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR']}>
            <RelatorioPorTag />
          </PrivateRoute>
        }
      />

      <Route
        path="/user/dashboard/"
        element={
          <PrivateRoute allowedRoles={['USUARIOS ATIVOS']}>
            <UserDashboards />
          </PrivateRoute>
        }
      />

      <Route
        path="/ext/home/"
        element={
          <PrivateRoute
            allowedRoles={['USUARIOS ATIVOS', 'FORNECEDOR EXTERNO']}
          >
            <HomePagePF />
          </PrivateRoute>
        }
      />

      <Route
        path="/ext/pedidos/"
        element={
          <PrivateRoute
            allowedRoles={['USUARIOS ATIVOS', 'FORNECEDOR EXTERNO']}
          >
            <PedidosFornecedores />
          </PrivateRoute>
        }
      />

      <Route
        path="/ext/frete-home/"
        element={
          <PrivateRoute
            allowedRoles={[
              'USUARIOS ATIVOS',
              'ADMINISTRADOR',
              'FORNECEDOR EXTERNO',
            ]}
          >
            <FreteHomeFornecedor />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/ext/frete-orc/detail/:orc_id"
        element={
          <PrivateRoute
            allowedRoles={[
              'ADMINISTRADOR',
              'FRETE - SOLICITAÇÃO',
              'FORNECEDOR EXTERNO',
            ]}
          >
            <OrcamentoFreteDetail />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="ext/fretes/fornecedor/:orc_id"
        element={
          <PrivateRoute
            allowedRoles={[
              'ADMINISTRADOR',
              'FRETE - SOLICITAÇÃO',
              'FORNECEDOR EXTERNO',
            ]}
          >
            <FreteFormFornecedor />
          </PrivateRoute>
        }
      />

      <Route
        path="/user/tarefa/view/"
        element={
          <PrivateRoute allowedRoles={['USUARIOS ATIVOS']}>
            <VisualizarTarefaUser />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="visualizar-doc-entregaveis"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'FILA ENTREGAVEIS']}>
            <TabelaDocsEntregaveis />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="doc-entregavel-versao"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'FILA ENTREGAVEIS']}>
            <TabelaVersoesDocsEntregaveis />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="fluxo-doc-entregavel"
        element={
          <PrivateRoute allowedRoles={['ADMINISTRADOR', 'FILA ENTREGAVEIS']}>
            <FluxoDocsEntregaveis />
          </PrivateRoute>
        }
      />

      <Route path="/registrar" element={<Register />} />
      <Route path="/login" element={<LoginForm />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
    // </>
  );
};

export default App;
