import React, { useState } from 'react';
import axios from 'axios';
import { Avatar, Box, Link, Typography, Paper } from '@mui/material';
import AlertMessage from '../../layouts/AlertMessage';

const ComentarioComponent = ({
  comment,
  isChild = false,
  userId,
  objeto,
  getCommentFunction,
  reply = true,
}) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  const postComment = async (e) => {
    e.preventDefault();
    let obj = {
      text: replyText,
      user_id: userId,
      objeto: objeto.id,
      parent_comment_id: comment.id,
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/comments/`,
        obj,
        { headers }
      );
      if (response.status === 201) {
        getCommentFunction();
        setReplyText('');
        setShowReplyForm(false);
      }
    } catch (error) {
      console.error(error);
      //   alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || "Ocorreu um erro.")
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao postar comentário.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          mt: isChild ? 1 : 3,
          ml: isChild ? 0 : 0,
        }}
      >
        <div className="div" style={{ paddingRight: '16px' }}>
          <Avatar
            alt={comment.user}
            style={{ ml: 1 }}
            src={comment.user_data.avatar}
          />
        </div>
        <Box sx={{ ml: 0, width: '100%' }}>
          <Paper elevation={3} sx={{ p: 1, maxWidth: '100%' }}>
            <Typography variant="body1">
              <strong>{comment.user_data.nome}</strong>{' '}
              <small>{comment.atualizado_em}</small>
            </Typography>
            <Typography variant="body2">{comment.text}</Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {reply && (
                <Link onClick={() => setShowReplyForm(!showReplyForm)}>
                  {showReplyForm ? 'Cancelar' : 'Responder'}
                </Link>
              )}
              {/* <span className="is-link is-light" onClick={() => setShowReplyForm(!showReplyForm)}>
                            {showReplyForm ? "Cancelar" : "Responder"}
                        {/* <span className="is-link is-light" onClick={() => setShowReplyForm(!showReplyForm)}>
                            {showReplyForm ? "Cancelar" : "Responder"}
                        </span> */}
            </div>
            {showReplyForm && (
              <form onSubmit={postComment} style={{ marginTop: '10px' }}>
                <textarea
                  className="textarea"
                  placeholder="Escreva sua resposta"
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '10px',
                  }}
                >
                  <button className="button is-primary" type="submit">
                    Enviar Resposta
                  </button>
                </div>
              </form>
            )}
          </Paper>
          {comment.children && comment.children.length > 0 && (
            <Box sx={{ mt: 2, ml: 0 }}>
              {comment.children.map((child) => (
                <ComentarioComponent
                  key={child.id}
                  comment={child}
                  isChild={true}
                  userId={userId}
                  objeto={objeto}
                  getCommentFunction={getCommentFunction}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ComentarioComponent;
