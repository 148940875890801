import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AlertMessage from '../../layouts/AlertMessage';
import {
  StackedBarChart,
  TypeBarChart,
  SLABarChart,
  EmptyStackedBarChart,
} from './chartsComponents/baseCharts';
import { useUser } from '../../../context/UserContext';

const SupervisorDashboardResponsavel = () => {
  const { REACT_APP_BASE_API_URL } = process.env;

  const { idUsuario, setIdUsuario } = useUser();

  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 15);
    return date.toISOString().split('T')[0];
  });
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    return date.toISOString().split('T')[0];
  });
  const [otherUsers, setOtherUsers] = useState();
  const [targetUser, setTargetUser] = useState('all');
  const [macroData, setMacroData] = useState(null);
  const [barData, setBarData] = useState(null);
  const [stackedBarData, setStackedBarData] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  const setDateTimes = (startDate, endDate) => {
    const start = new Date(`${startDate}T00:00:00`);
    const end = new Date(`${endDate}T18:00:00`);
    console.log(start, end);
    return {
      startDateTime: start.toISOString(),
      endDateTime: end.toISOString(),
    };
  };

  const initialReport = async () => {
    const labelKeys = {
      pdm: 'PDM',
      fornecedor: 'Fornecedor',
      frete: 'Frete',
      material: 'Material',
      solicitacao_rc: 'SRC',
    };
    const { startDateTime, endDateTime } = setDateTimes(startDate, endDate);
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/tarefas/relatorio-supervisor/`,
        {
          params: {
            start_date: startDateTime,
            end_date: endDateTime,
            user_id: idUsuario,
            solicitante: false,
          },
          headers: headers,
        }
      );
      if (response.status === 200) {
        const responseData0 = response.data[0] || {};
        const stackedBarSource = response.data[2] || {};
        const barSource = response.data[1] || {};

        setMacroData(response.data[0]);
        const otherUsersObj = responseData0['_usuarios'];
        setOtherUsers(otherUsersObj);
        const stackedBarData = Object.keys(stackedBarSource).map((date) => {
          const dayData = stackedBarSource[date];
          return {
            date,
            ...Object.fromEntries(
              Object.entries(dayData || {}).filter(([key]) => key !== 'total')
            ),
          };
        });
        setStackedBarData(stackedBarData.length > 0 ? stackedBarData : []);
        console.log(stackedBarData);

        const barX = Object.keys(barSource).map(
          (item) => labelKeys[item] || item
        );
        const barY = Object.values(barSource).map((item) => Number(item));
        setBarData({
          x: barX.length > 0 ? barX : [],
          y: barY.length > 0 ? barY : [],
        });
      }
    } catch (error) {
      console.error(error);
      // alert(error.response.data.detail);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail ||
              'Não há atividades para esse período.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const labelKeys = {
      pdm: 'PDM',
      fornecedor: 'Fornecedor',
      frete: 'Frete',
      material: 'Material',
      solicitacao_rc: 'SRC',
    };
    const { startDateTime, endDateTime } = setDateTimes(startDate, endDate);
    try {
      let response;
      if (targetUser == 'all') {
        response = await axios.get(
          `${REACT_APP_BASE_API_URL}/tarefas/relatorio-supervisor/`,
          {
            params: {
              start_date: startDateTime,
              end_date: endDateTime,
              user_id: idUsuario,
              solicitante: false,
            },
            headers: headers,
          }
        );
      } else {
        response = await axios.get(
          `${REACT_APP_BASE_API_URL}/tarefas/relatorio-user/`,
          {
            params: {
              sd: startDateTime,
              ed: endDateTime,
              user_id: targetUser,
              solicitante: false,
            },
            headers: headers,
          }
        );
      }
      if (response.status === 200) {
        const responseData0 = response.data[0] || {};
        const stackedBarSource = response.data[2] || {};
        const barSource = response.data[1] || {};

        setMacroData(responseData0);

        const stackedBarData = Object.keys(stackedBarSource).map((date) => {
          const dayData = stackedBarSource[date];
          return {
            date,
            ...Object.fromEntries(
              Object.entries(dayData || {}).filter(([key]) => key !== 'total') // Exclude "total"
            ),
          };
        });
        console.log('stacked -> ', stackedBarData);
        let today = new Date().getDate();
        setStackedBarData(stackedBarData.length > 0 ? stackedBarData : []);

        const barX = Object.keys(barSource).map(
          (item) => labelKeys[item] || item
        );
        const barY = Object.values(barSource).map((item) => Number(item));
        setBarData({
          x: barX.length > 0 ? barX : [],
          y: barY.length > 0 ? barY : [],
        });
        console.log(barData);
      }
    } catch (error) {
      console.error(error);
      // alert(error.response.data.detail);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail ||
              'Não há atividades para esse período.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleDownload = async () => {
    try {
      const { startDateTime, endDateTime } = setDateTimes(startDate, endDate);
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/tarefas/exportar-relatorio-supervisor/`,
        {
          params: {
            user_id: idUsuario,
            start_date: startDateTime,
            end_date: endDateTime,
            solicitante: false,
          },
          headers: {
            'Content-Type': 'text/csv; charset=utf-8',
          },
          responseType: 'blob', // Importante para tratar o retorno como um arquivo binário
        }
      );
      if (response.status === 200) {
        // Cria uma URL para o blob retornado
        if ('showSaveFilePicker' in window) {
          const fileHandle = await window.showSaveFilePicker({
            suggestedName: 'relatorio-tarefas-supervisor-responsaveis.csv',
            types: [
              {
                description: 'CSV Files',
                accept: { 'text/csv': ['.csv'] },
              },
            ],
          });
          const writableStream = await fileHandle.createWritable();
          await writableStream.write(response.data);
          await writableStream.close();
        } else {
          // Traditional download
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'text/csv; charset=utf-8' })
          );
          const a = document.createElement('a');
          a.href = url;
          a.download = 'relatorio-tarefas-supervisor-responsaveis.csv'; // Default name
          document.body.appendChild(a);
          a.click();
          a.remove();
        }

        setAlertMessage('Relatório criado e baixado com sucesso!');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao baixar relatório.'
      );
    }
  };

  useEffect(() => {
    let taretId = idUsuario;
    if (taretId) {
      setIdUsuario(taretId);
      initialReport();
    }
  }, []);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <div className="card">
        <div className="card-content">
          <div
            className="container"
            style={{ margin: '0px', maxWidth: '100%' }}
          >
            <form onSubmit={handleSubmit}>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <label className="label">Data Início</label>
                    <div className="control">
                      <input
                        className="input"
                        type="date"
                        name="startDate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Data Fim</label>
                    <div className="control">
                      <input
                        className="input"
                        type="date"
                        name="endDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Usuário</label>
                    <div className="control">
                      <div className="select">
                        <select
                          className="select"
                          value={targetUser}
                          onChange={(e) => setTargetUser(e.target.value)}
                        >
                          <option value={'all'}>Todos</option>
                          {otherUsers &&
                            otherUsers.map((item, i) => (
                              <option key={i} value={item.user_id}>
                                {item.user_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <button
                        className="button is-primary"
                        type="submit"
                        style={{ marginTop: '2rem' }}
                      >
                        Gerar
                      </button>
                    </div>
                  </div>

                  <div className="field">
                    <div
                      className="control"
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <button
                        className="button is-secondary"
                        type="button"
                        style={{ marginTop: '2rem' }}
                        onClick={() => handleDownload()}
                      >
                        Exportar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="">
        {macroData && (
          <div className="card">
            <div className="card-content">
              <div className="columns is-multiline">
                <div className="column is-one-third">
                  <div className="box">
                    <p className="subtitle">Quantidade Total</p>
                    <p className="title">{macroData.quantidade_total}</p>
                  </div>
                </div>

                <div className="column is-one-third">
                  <div className="box">
                    <p className="subtitle">Tarefas concluídas</p>
                    <p className="title">{macroData.quantidade_concluida}</p>
                  </div>
                </div>

                <div className="column is-one-third">
                  <div className="box">
                    <p className="subtitle">Tarefas recusadas</p>
                    <p className="title">{macroData.quantidade_recusada}</p>
                  </div>
                </div>

                <div className="column is-one-third">
                  <div className="box">
                    <p className="subtitle">Tarefas pendentes</p>
                    <p className="title">{macroData.quantidade_pendente}</p>
                  </div>
                </div>

                <div className="column is-one-third">
                  <div className="box">
                    <p className="subtitle">Tarefas em andamento</p>
                    <p className="title">{macroData.quantidade_andamento}</p>
                  </div>
                </div>

                <div className="column is-one-third">
                  <div className="box">
                    <p className="subtitle">TMC</p>
                    <p className="title">
                      {(() => {
                        const totalMinutes = Math.floor(
                          macroData.tempo_medio * 60
                        ); // Convert hours to minutes
                        const hours = Math.floor(totalMinutes / 60); // Extract hours
                        const minutes = totalMinutes % 60; // Extract remaining minutes
                        return `${hours}h ${minutes.toString().padStart(2, '0')}min`; // Format as '1h 04min'
                      })()}
                    </p>
                  </div>
                </div>

                <div className="column is-two-thirds">
                  {stackedBarData && stackedBarData.length > 0 ? (
                    <div className="box">
                      <p className="subtitle">Tarefas iniciadas no período</p>
                      <StackedBarChart stackedBarData={stackedBarData} />
                    </div>
                  ) : (
                    <div className="box">
                      <p className="subtitle">Tarefas iniciadas no período</p>
                      <EmptyStackedBarChart />
                    </div>
                  )}
                </div>

                {barData && (
                  <div className="column is-one-third">
                    <div className="box" style={{ paddingBottom: '20px' }}>
                      <p className="subtitle">Por tipo</p>
                      <TypeBarChart barData={barData} />
                    </div>
                    <div className="box" style={{ paddingBottom: '20px' }}>
                      <p className="subtitle">SLA</p>
                      <SLABarChart SLAData={macroData} />
                    </div>
                  </div>
                )}

                <div className="column is-one-third"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SupervisorDashboardResponsavel;
