import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainLayout from '../layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AlertMessage from '../layouts/AlertMessage';
import { useUser } from '../../context/UserContext';

const PedidosFornecedores = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const { idUsuario, setIdUsuario } = useUser();
  const navigate = useNavigate();
  const [filaPedidos, setFilaPedidos] = useState([]); // Estado para armazenar os dados da fila
  const [listaPedidos, setListaPedidos] = useState([]); // Estado para armazenar os dados da fila
  const [open, setOpen] = useState(false);
  const [userEmpSapId, setUserEmpSapId] = useState('');

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const headers = {
    'Content-Type': 'application/json',
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const getFornecedorId = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/fornecedor/user/sap-id/`,
        {
          params: {
            user_id: idUsuario,
          },
          headers,
        }
      );
      if (response.status === 200) {
        setUserEmpSapId(response.data);
      }
      return response.data;
    } catch (error) {
      console.error(error);
      // alert(Array.isArray(error.response?.data?.detail) ? JSON.stringify(error.response?.data?.detail, null, 2) : error.response?.data?.detail || 'Erro ao buscar o SAP ID')
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar o SAP ID'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const fetchFilaPedidos = async (fornecedorId) => {
    try {
      const response = await axios.get(
        `http://172.30.16.35:8082/data/pedidos/`,
        {
          params: { fornecedor_id: parseInt(fornecedorId) },
        }
      );
      if (response.data) {
        const data = response.data.data;
        const pedidos = data.map((dataP, index) => ({
          nRC: ' - ',
          id: dataP.pedido_compra,
          total_itens: dataP.itens_pedido.length,
          statusAprovacao: dataP.status_aprovacao,
          fornecedor: dataP.fornecedor,
          valor: dataP.valor_total,
          destinatario: dataP.nome_centro,
        }));
        setFilaPedidos(pedidos);
        setListaPedidos(data);
      }
    } catch (error) {
      //   console.error("Erro ao buscar fila de pedidos:", error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar a fila de pedidos'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      if (!idUsuario || idUsuario === 'null') {
        setIdUsuario(idUsuario);
      }
      const fornecedorId = await getFornecedorId(); // Wait for the SAP ID to be fetched
      if (fornecedorId) {
        await fetchFilaPedidos(fornecedorId); // Fetch the orders after SAP ID is set
      }
    };
    initialize();
  }, [REACT_APP_BASE_API_URL]);

  const columns = [
    { field: 'nRC', headerName: 'Numero RC', flex: 1 },
    { field: 'id', headerName: 'Pedido de compra', flex: 2 },
    { field: 'total_itens', headerName: 'Total itens', flex: 1 },
    { field: 'statusAprovacao', headerName: 'Status', flex: 2 },
    { field: 'fornecedor', headerName: 'Fornecedor', flex: 3 },
    {
      field: 'valor',
      headerName: 'Valor',
      flex: 1.5,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>{formatPreco(params.row.valor)}</div>
      ),
    },
    {
      field: 'destinatario',
      headerName: 'Destinatário',
      editable: true,
      flex: 3,
    },
    {
      field: 'acao',
      headerName: 'Ação',
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>
          <IconButton
            onClick={() => {
              editPedido(params.row.id);
            }}
            color="black"
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  const editPedido = async (pedido_compra) => {
    const pedidoObj = listaPedidos.find(
      (pedido) => pedido.pedido_compra === pedido_compra
    );

    if (pedidoObj) {
      navigate('/pedidos-servico-items', {
        state: { pedidoObj, route: '/pedidos-servico' },
      });
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleAlertClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Comercial</li>
                    <li>Fila de Pedidos</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Fila de Pedidos</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Lista dos pedidos cadastrados no SAP
                </p>
              </header>
              <div className="card-content">
                {/* <div className="div-select-search">
                                <div className="search-input" >
                                    <div className="info-search">
                                        <label className="label">Filtrar por empreendimento</label>                                                        
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            defaultValue={options[0]}    
                                            isClearable={true}
                                            isSearchable= {true}                                                            
                                            name="color"
                                            options={options}
                                            placeholder= {<div><i className="fa-solid fa-magnifying-glass"></i><span> Selecione</span></div>}
                                            onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                    setActiveButton(selectedOption.value);
                                                } else {
                                                    setActiveButton("");
                                                }
                                            }}
                                        />                                       
                                    </div>                                                                              
                                </div>  
                            </div> */}

                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="FilaTarefas">
                      <div
                        style={{
                          height: 600,
                          width: '100%',
                        }}
                      >
                        <DataGrid
                          rows={filaPedidos}
                          columns={columns}
                          pageSize={5}
                          disableSelectionOnClick
                          getRowHeight={() => 'auto'}
                          sx={{
                            fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
                            '& .MuiDataGrid-cell': {
                              display: 'flex',
                              alignItems: 'flex-start', // Alinha o conteúdo no topo
                              paddingTop: '4px', // Ajuste conforme necessário
                              paddingBottom: '4px',
                              border: '0px',
                              // lineHeight: '2.5',
                              fontSize: '16px',
                            },
                            '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                              {
                                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                                fontSize: '16px',
                                color: '#363636',
                                // fontFamily: '"Nunito", sans-serif',
                                lineHeight: '2.5',
                                borderLeft: '5px solid transparent',
                              },
                            '& .MuiDataGrid-virtualScroller': {
                              overflowX: 'hidden',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Backdrop
              sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={!(filaPedidos.length > 0)}
              onClick={handleClose}
            >
              <CircularProgress color="inherit" size="5rem" />
            </Backdrop>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default PedidosFornecedores;
