import React from 'react';

const LaunchModalButton = ({ onOpenModal, pText }) => {
  const handleButtonClick = () => {
    onOpenModal(pText);
  };
  return (
    <>
      <p onClick={handleButtonClick}>{pText}</p>
    </>
  );
};

export default LaunchModalButton;
