import React, { useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import axios from 'axios';
import AlertMessage from '../../layouts/AlertMessage';

const ModalPasswordForget = ({ info }) => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [targetEmail, setTargetEmail] = useState('');
  // const [alertMessage, setAlertMessage] = useState(null);
  // const [alertSeverity, setAlertSeverity] = useState('error');
  // const [alertOpen, setAlertOpen] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [navigateTo, setNavigateTo] = useState("");
  // const [reload, setReload] = useState(false);

  const {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  } = info;

  const handleSubmit = async (e) => {
    e.preventDefault();
    let requestData = {
      email: [targetEmail],
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/users/forgot_pass`,
        requestData
      );
      const data = await response.data;
      if (response.status === 200) {
        setAlertMessage(data.detail);
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(
          Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail
        )
          ? JSON.stringify(
              Array.isArray(error.response?.data?.detail)
                ? JSON.stringify(error.response?.data?.detail, null, 2)
                : error.response?.data?.detail,
              null,
              2
            )
          : Array.isArray(error.response?.data?.detail)
            ? JSON.stringify(error.response?.data?.detail, null, 2)
            : error.response?.data?.detail || 'Erro ao recuperar senha'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  return (
    <>
      <div className="container" style={{ width: '800px' }}>
        <form onSubmit={handleSubmit} className="modal-form">
          <div className="field-modal">
            <label className="label-modal">E-mail</label>
            <input
              className="input-modal"
              type="text"
              value={targetEmail}
              onChange={(e) => setTargetEmail(e.target.value)}
              required
            />
          </div>

          <hr></hr>

          <div className="modal-btn-footer">
            <button type="submit" className="button-modal btn-submit">
              Recuperar senha
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ModalPasswordForget;
