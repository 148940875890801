import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import EmpreendimentoDetailView from './EmpreendimentosDetailView';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AlertMessage from '../../layouts/AlertMessage';

const CadastroEmpreendimentos = () => {
  const navigate = useNavigate();
  const [nome, setNome] = useState('');
  const [tag, setTag] = useState('');
  const [endereco, setEndereco] = useState('');
  const [empreendimentos, setEmpreendimentos] = useState([]);
  const { REACT_APP_BASE_API_URL } = process.env;
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      nome: nome,
      tag: tag,
      endereco: endereco,
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/adm/empreendimento/`,
        formData,
        { headers }
      );

      if (response.status === 201) {
        // alert("Empreendimento cadastrado com sucesso.")
        setEmpreendimentos([...empreendimentos, response.data]);
        setAlertMessage('Empreendimento cadastrado com sucesso.');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      // alert(error.response.data.detail)
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao cadastrar empreendimento.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleInspect = (index) => {
    const empreendimentoRef = empreendimentos[index];
    const empreendimentoId = empreendimentoRef.id;
    navigate(`/adm/empreendimentos/${empreendimentoId}`);
  };

  const getEmpreendimentos = async () => {
    const headers = {
      'Content-Type': 'application/json',
    };
    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/adm/empreendimento/`,
        {
          params: {
            skip: 0,
            limit: 15,
          },
          headers: headers,
        }
      );
      const data = await response.data;
      setEmpreendimentos(data.empreendimentos);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar empreendimentos.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };
  useEffect(() => {
    getEmpreendimentos();
  }, []);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <section className="section is-title-bar">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <ul>
                  <li>Geral</li>
                  <li>Empreendimentos</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="hero is-hero-bar">
          <div className="hero-body">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h1 className="title">Empreendimentos</h1>
                </div>
              </div>
              <div className="level-right" style={{ display: 'none' }}>
                <div className="level-item"></div>
              </div>
            </div>
          </div>
        </section>
        <section className="section is-main-section">
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-ballot"></i>
                </span>
                Informações gerais
              </p>
            </header>
            <div className="card-content">
              {/* <div className="is-flex is-justify-content-center"> */}
              <form name="cadastrarEmpreendimento" onSubmit={handleSubmit}>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Nome</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="text"
                          placeholder=""
                          required
                          value={nome}
                          onChange={(e) => setNome(e.target.value)}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-account"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Tag</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="text"
                          placeholder=""
                          required
                          value={tag}
                          onChange={(e) => setTag(e.target.value)}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-account"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Endereço</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input"
                          type="text"
                          placeholder=""
                          required
                          value={endereco}
                          onChange={(e) => setEndereco(e.target.value)}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-account"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <br />
                <div
                  style={{
                    margin: '40px 0px 20px 0px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <button className="button is-primary" type="submit">
                    Cadastrar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </MainLayout>
    </>
  );
};

export default CadastroEmpreendimentos;
