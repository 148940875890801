import React, { useState, useEffect } from 'react';
import MainLayout from '../../layouts/MainLayout';
import LaunchModalButton from '../../functional/LaunchModal';
import Modal from '../../layouts/InsumosModal';
import ItemInclusionView from '../../functional/ItemInclusionView';
import FormModal from '../../layouts/FormModal';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';

import './Composicoes.css';
import axios from 'axios';
import AlertMessage from '../../layouts/AlertMessage';

const ComposicoesForm = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const [compTitulo, setCompTitulo] = useState('');
  const [compDescicao, setCompDescricao] = useState('');
  const [compUnidade, setCompUnidade] = useState('');
  const [compPreco, setCompPreco] = useState(0);
  const [compItemsList, setCompItemsList] = useState([]);
  const [requestCompItems, setRequestCompItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');
  const [reload, setReload] = useState(false);
  const info = {
    alertMessage,
    setAlertMessage,
    alertSeverity,
    setAlertSeverity,
    alertOpen,
    setAlertOpen,
    loading,
    setLoading,
    navigateTo,
    setNavigateTo,
    reload,
    setReload,
  };

  const tagObjectByType = {
    M: 'is-danger is-dark',
    E: 'is-info is-dark',
    S: 'is-warning is-dark',
    O: 'is-success is-dark',
  };

  const onAddHandler = (obj) => {
    setCompItemsList((prevItems) => [...prevItems, obj]);
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setModalOpen(false);
  };

  //Fecha modal apertando "ESC"
  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      handleCloseModal();
    }
  });

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const renderModalContent = (info) => {
    switch (modalContent) {
      case 'Materiais':
        return (
          <ItemInclusionView
            mSchema={'material'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'Equipamentos':
        return (
          <ItemInclusionView
            mSchema={'equip'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'Mão de obra':
        return (
          <ItemInclusionView
            mSchema={'servico'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'Composições':
        return (
          <ItemInclusionView
            mSchema={'composicao'}
            mTarget={'composicao'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'SINAPI: Insumos':
        return (
          <ItemInclusionView
            mSchema={'sinapi'}
            mTarget={'insumo'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      case 'SINAPI: Composições':
        return (
          <ItemInclusionView
            mSchema={'sinapi'}
            mTarget={'composicao'}
            onAddHandler={onAddHandler}
            info={info}
          />
        );
      default:
        return null;
    }
  };

  const handleRemoveItem = (indexToRemove) => {
    const itemToRemove = compItemsList[indexToRemove];
    const removedItemIndex = requestCompItems.findIndex(
      (item) => item.item_id === itemToRemove.id
    );

    if (removedItemIndex !== -1) {
      const removedItem = requestCompItems[removedItemIndex];
      const removedItemPreco = removedItem.preco_und * removedItem.coeficiente;

      const updatedList = [...compItemsList];
      updatedList.splice(indexToRemove, 1);
      setCompItemsList(updatedList);

      setSelectedRows((prevRows) =>
        prevRows.filter((index) => index !== indexToRemove)
      );

      const newRequestCompItems = [...requestCompItems];
      newRequestCompItems.splice(removedItemIndex, 1);
      setRequestCompItems(newRequestCompItems);

      setCompPreco((prevPreco) => prevPreco - removedItemPreco);
    }
  };

  function isObjEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length != keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  }

  const handleCheckItem = (indexToAdd) => {
    const itemToAdd = compItemsList[indexToAdd];
    const coefInput = document.querySelector(`input[name="Q${indexToAdd}"]`);
    const coeficiente = parseFloat(coefInput.value);

    if (coefInput && coeficiente > 0) {
      const newItem = {
        item_id: itemToAdd.id,
        preco_und: itemToAdd.preco,
        coeficiente: coeficiente,
      };
      if (!selectedRows.includes(indexToAdd)) {
        const itemPreco = itemToAdd.preco * coeficiente;
        setCompPreco((prevPreco) => prevPreco + itemPreco);

        setRequestCompItems((prevItems) => [...prevItems, newItem]);
        setSelectedRows((prevRows) => [...prevRows, indexToAdd]);
      }
    } else {
      // alert("Não é possível confirmar um item com coeficiente nulo.")
      setAlertMessage('Não é possível confirmar um item com coeficiente nulo.');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const isCadButDisabled = () => {
    return (
      compDescicao === '' || compUnidade === '' || requestCompItems.length === 0
    );
  };

  const handleCompCad = async (e) => {
    e.preventDefault();
    let requestBody = {
      composicao: {
        titulo: compTitulo,
        descricao: compDescicao,
        preco: compPreco,
        unidade: compUnidade,
        origem: 'GEO',
      },
      items: {
        items: requestCompItems,
      },
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/composicoes/`,
        requestBody,
        { headers }
      );
      if (response.status == 201) {
        // alert("Composição cadastrada com sucesso.")
        setAlertMessage('Composição cadastrada com sucesso.');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao cadastrar composição.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    if (reload) {
      window.location.reload();
    }
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  useEffect(() => {
    return () => {
      if (reload) {
        window.location.reload();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    };
  }, []);

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Engenharia</li>
                    <li>Composições</li>
                    <li>Cadastrar composição</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Cadastrar composição</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="section is-main-section"
            style={{ marginBottom: '50px' }}
          >
            <form name="ComposicoesForm" onSubmit={handleCompCad}>
              <div className="card">
                <header className="card-header">
                  <p
                    className="card-header-title"
                    style={{
                      padding: '12px 0px 12px 12px',
                    }}
                  >
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Formulário para a criação de composição
                  </p>
                </header>
                <div className="card-content" style={{ padding: '1.5rem' }}>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Título
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            value={compTitulo}
                            onChange={(e) => setCompTitulo(e.target.value)}
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-font"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Unidade
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            value={compUnidade}
                            onChange={(e) => setCompUnidade(e.target.value)}
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-list-ol"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Preço</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input className="input" value={compPreco} disabled />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-circle-dollar-to-slot"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        Descrição
                        <span className="required-symbol"> *</span>
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            className="input"
                            value={compDescicao}
                            onChange={(e) => setCompDescricao(e.target.value)}
                            required
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-file-lines"></i>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      margin: '40px 0px 20px 0px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button
                      type="submit"
                      className="button is-primary"
                      disabled={isCadButDisabled()}
                    >
                      Cadastrar
                    </button>
                  </div>
                </div>
              </div>

              <div className="card">
                <header className="card-header">
                  <p
                    className="card-header-title"
                    style={{
                      padding: '12px 0px 12px 12px',
                    }}
                  >
                    <span className="icon">
                      <i className="mdi mdi-ballot"></i>
                    </span>
                    Tabela de items
                  </p>
                </header>
                <div className="card-content" style={{ padding: '1.5rem' }}>
                  <div className="b-table has-pagination">
                    <div className="table-wrapper has-mobile-cards">
                      <ButtonGroup
                        aria-label="outlined is-primary button group"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: '20px 0px',
                        }}
                      >
                        <Button
                          onClick={() => handleOpenModal('Materiais')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          Materiais
                        </Button>
                        <Button
                          onClick={() => handleOpenModal('Equipamentos')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          Equipamentos
                        </Button>
                        <Button
                          onClick={() => handleOpenModal('Composições')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          Composições
                        </Button>
                        <Button
                          onClick={() => handleOpenModal('Mão de obra')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          Mão de obra
                        </Button>
                        <Button
                          onClick={() => handleOpenModal('SINAPI: Composições')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          SINAPI: Composições
                        </Button>
                        <Button
                          onClick={() => handleOpenModal('SINAPI: Insumos')}
                          style={{
                            color: '#2B3BED',
                            padding: '5px 30px',
                          }}
                        >
                          SINAPI: Insumos
                        </Button>
                      </ButtonGroup>

                      {/* <table className="table is-fullwidth hast-text-centered">
                                                <thead>
                                                    <th className="has-text-centered">ID</th>
                                                    <th className="has-text-centered">DESCRICAO</th>
                                                    <th className="has-text-centered">UNID.</th>
                                                    <th className="has-text-centered">PREÇO</th>
                                                    <th className="has-text-centered">COEFICIENTE</th>
                                                    <th className="has-text-centered">TIPO</th>
                                                    <th></th>
                                                </thead>
                                                <tbody>
                                                    {compItemsList.map((item, i) => (
                                                        <tr key={`K${i}`} className={selectedRows.includes(i) ? 'row-in-request' : ''}>
                                                            <td><p className="is-size-7">{item.did}</p></td>
                                                            <td><p className="is-size-7">{item.descl}</p></td>
                                                            <td><p className="is-size-7">{item.unidade}</p></td>
                                                            <td><p className="is-size-7">{formatPreco(item.preco)}</p></td>
                                                            <td>
                                                                <input className="input is-small"
                                                                    name={`Q${i}`}
                                                                    step={"0.001"}
                                                                    type="number"
                                                                    style={{ maxWidth: 100 }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <p className={`is-size-7 tag ${Object.keys(tagObjectByType).includes(item.did.charAt(0)) ? tagObjectByType[item.did.charAt(0)] : ''}`}>
                                                                    {item.did.charAt(0)}
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <div className="columns">
                                                                    <div className="column">
                                                                        <span onClick={() => handleCheckItem(i)}>
                                                                            <i className={`fa-solid fa-circle-check ${selectedRows.includes(i) ? 'green-icon' : ''}`}></i>
                                                                        </span>
                                                                    </div>
                                                                    <div className="column">
                                                                        <span onClick={() => handleRemoveItem(i)}>
                                                                            <i className="fa-solid fa-trash"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table> */}

                      <TableContainer
                        component={Paper}
                        style={{
                          border: '1px solid lightgrey',
                          borderRadius: '5px',
                          marginBottom: '20px',
                        }}
                      >
                        <Table aria-label="Component items table">
                          <TableHead>
                            <TableRow
                              style={{
                                display: 'flex',
                                width: '100%',
                              }}
                            >
                              <TableCell
                                style={{
                                  flex: '1',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                ID
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '3',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                DESCRIÇÃO
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                UNID.
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                PREÇO
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                COEFICIENTE
                              </TableCell>
                              <TableCell
                                style={{
                                  flex: '1',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                TIPO
                              </TableCell>
                              <TableCell
                                style={{
                                  width: '80px',
                                  color: '#171717',
                                  fontWeight: 'bold',
                                  fontFamily: '"Nunito", sans-serif',
                                }}
                              >
                                AÇÃO
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {compItemsList.map((item, i) => (
                              <TableRow
                                key={`K${i}`}
                                className={
                                  selectedRows.includes(i)
                                    ? 'row-in-request'
                                    : ''
                                }
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <TableCell
                                  style={{
                                    flex: '1',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  {item.did}
                                </TableCell>
                                <TableCell
                                  style={{
                                    flex: '3',
                                    fontFamily: '"Nunito", sans-serif',
                                    textAlign: 'justify',
                                  }}
                                >
                                  {item.descl}
                                </TableCell>
                                <TableCell
                                  style={{
                                    flex: '1',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  {item.unidade}
                                </TableCell>
                                <TableCell
                                  style={{
                                    flex: '1',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  {formatPreco(item.preco)}
                                </TableCell>
                                <TableCell
                                  style={{
                                    flex: '1',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  <input
                                    className="input is-small"
                                    name={`Q${i}`}
                                    step={'0.001'}
                                    type="number"
                                    style={{
                                      maxWidth: 100,
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  style={{
                                    flex: '1',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  <p
                                    className={`is-size-7 tag ${Object.keys(tagObjectByType).includes(item.did.charAt(0)) ? tagObjectByType[item.did.charAt(0)] : ''}`}
                                  >
                                    {item.did.charAt(0)}
                                  </p>
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: '80px',
                                    fontFamily: '"Nunito", sans-serif',
                                  }}
                                >
                                  <div
                                    className="columns"
                                    style={{
                                      fontSize: '18px',
                                    }}
                                  >
                                    <div className="column is-1">
                                      <span
                                        className="icon"
                                        onClick={() => handleCheckItem(i)}
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <i
                                          className={`fa-solid fa-circle-check ${selectedRows.includes(i) ? 'green-icon' : ''}`}
                                        ></i>
                                      </span>
                                    </div>
                                    <div className="column is-1">
                                      <span
                                        className="icon"
                                        onClick={() => handleRemoveItem(i)}
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <i className="fa-solid fa-trash"></i>
                                      </span>
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <FormModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              modalTitle={modalContent}
            >
              {renderModalContent(info)}
            </FormModal>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default ComposicoesForm;
