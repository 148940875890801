import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import AlertMessage from '../../layouts/AlertMessage';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

const EmpreendimentosTable = () => {
  const navigate = useNavigate();
  const [empreendimentos, setEmpreendimentos] = useState([]);
  const { REACT_APP_BASE_API_URL } = process.env;
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const handleInspect = (empreendimentoId) => {
    navigate(`/adm/empreendimentos/${empreendimentoId}`);
  };

  const getEmpreendimentos = async () => {
    let headers = {
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.get(
        `${REACT_APP_BASE_API_URL}/adm/empreendimento/`,
        { headers }
      );
      const data = await response.data;
      setEmpreendimentos(data.empreendimentos);
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Erro ao buscar empreendimentos'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };
  useEffect(() => {
    getEmpreendimentos();
  }, []);

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 7 },
    { field: 'nome', headerName: 'Nome', flex: 7 },
    { field: 'tag', headerName: 'Tag', flex: 7 },
    {
      field: 'acao',
      headerName: 'Acao',
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: '100%', paddingLeft: '10px' }}>
          <span onClick={() => handleInspect(params.row.id)}>
            <i className="fa-solid fa-binoculars pr-1"></i>
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Geral</li>
                    <li>Tabela de empreendimentos</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Empreendimentos</h1>
                  </div>
                </div>
                <div className="level-right" style={{ display: 'none' }}>
                  <div className="level-item"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card has-table">
              <div className="card-content">
                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="TabelaEmpreendimento">
                      <div
                        style={{
                          height: 660,
                          width: '100%',
                        }}
                      >
                        <DataGrid
                          rows={empreendimentos}
                          columns={columns}
                          pageSize={5}
                          disableSelectionOnClick
                          getRowHeight={() => 'auto'}
                          getColumnHeight={() => 'auto'}
                          sx={{
                            fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
                            '& .MuiDataGrid-cell': {
                              display: 'flex',
                              alignItems: 'flex-start', // Alinha o conteúdo no topo
                              paddingTop: '4px', // Ajuste conforme necessário
                              paddingBottom: '4px',
                              border: '0px',
                              fontFamily: '"Nunito", sans-serif',
                              lineHeight: '1.5',
                              fontSize: '16px',
                            },
                            '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                              {
                                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                                fontSize: '16px',
                                color: '#171717',
                                fontFamily: '"Nunito", sans-serif',
                                lineHeight: '1.5',
                              },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default EmpreendimentosTable;
