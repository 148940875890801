import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainLayout from '../../layouts/MainLayout';
import { useNavigate, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import FormModal from '../../layouts/FormModal';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Select from 'react-select';
import ModalPedidos from './ModalPedidos';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Badge from '@mui/material/Badge';

const FilaServico = () => {
  const location = useLocation();
  const { pedidoObj, route } = location.state;
  const { REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();
  const [soliciatacoesUser, setSolicitacaoUser] = useState([]);
  const [filaItemsPed, setItemsPed] = useState([]); // Estado para armazenar os dados da fila
  const [listaItemsPed, setListaItemsPed] = useState([]); // Estado para armazenar os dados da fila
  const [options, setOptions] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [itemPed, setItemPed] = useState([]);
  const [activeButton, setActiveButton] = useState('Serviço');

  const headers = {
    'Content-Type': 'application/json',
  };

  const formatPreco = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const handleOpenModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setModalOpen(false);
  };

  useEffect(() => {
    const fetchFilaItemPed = async () => {
      if (pedidoObj) {
        // Filtrar itens que têm tipo_pedido "serviço"
        const filteredItems = pedidoObj.itens_pedido.filter(
          (item) => item.tipo_item === activeButton
        );

        // Transformar os dados recebidos para se adequar à estrutura do DataGrid
        const items_ped = filteredItems.map((item, index) => ({
          id: item.item_pedido_compra,
          destinatario: pedidoObj.nome_centro,
          fornecedor: pedidoObj.fornecedor,
          descricao: item.texto_breve,
          data_entrega: item.data_entrega,
          data_alinhada: item.data_remessa,
          status_aprovacao: item.status_aprovacao,
          valor_item: item.valor_item,
          tipo_item: item.tipo_item ? item.tipo_item : '--',
        }));

        setItemsPed(items_ped);
        setListaItemsPed(filteredItems);
      }
    };

    fetchFilaItemPed();
  }, [REACT_APP_BASE_API_URL]);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);

    // Filtrar itens que têm tipo_pedido "serviço"
    const filteredItems = pedidoObj.itens_pedido.filter(
      (item) => item.tipo_item === buttonName
    );

    // Transformar os dados recebidos para se adequar à estrutura do DataGrid
    const items_ped = filteredItems.map((item, index) => ({
      id: item.item_pedido_compra,
      destinatario: pedidoObj.nome_centro,
      fornecedor: pedidoObj.fornecedor,
      descricao: item.texto_breve,
      data_entrega: item.data_entrega,
      data_alinhada: item.data_remessa,
      status_aprovacao: item.status_aprovacao,
      valor_item: item.valor_item,
      tipo_item: item.tipo_item ? item.tipo_item : '--',
    }));

    setItemsPed(items_ped);
    setListaItemsPed(filteredItems);
  };

  const columns = [
    { field: 'id', headerName: 'Item', flex: 1 },
    { field: 'destinatario', headerName: 'Destinatário', flex: 2 },
    { field: 'fornecedor', headerName: 'Fornecedor', flex: 2 },
    {
      field: 'descricao',
      headerName: 'Descrição',
      editable: true,
      flex: 3,
    },
    { field: 'tipo_item', headerName: 'Tipo', flex: 1 },
    { field: 'data_entrega', headerName: 'Data entrega', flex: 1.5 },
    { field: 'data_alinhada', headerName: 'Data alinhada', flex: 1.5 },
    {
      field: 'valor_item',
      headerName: 'Valor',
      flex: 1.5,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>
          {formatPreco(params.row.valor_item)}
        </div>
      ),
    },
    {
      field: 'status_aprovacao',
      headerName: 'Status aprovação',
      editable: true,
      flex: 1.5,
    },
    {
      field: 'acao',
      headerName: 'Ação',
      flex: 1,
      renderCell: (params) => (
        <div style={{ width: '100%' }}>
          <IconButton
            onClick={() => {
              editItemsPed(params.row.id);
            }}
            color="black"
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  const editItemsPed = async (id) => {
    const itemObj = listaItemsPed.find(
      (item) => item.item_pedido_compra === id
    );

    if (itemObj) {
      setItemPed(itemObj);
      handleOpenModal('Informações do item pedido');
    }
  };

  const renderModalContent = () => {
    return (
      <ModalPedidos
        mPedido={pedidoObj}
        mItemPedido={itemPed}
        mRoute={'/pedidos-servico-items'}
      />
    );
  };

  return (
    <>
      <MainLayout>
        <div className="main-section">
          <section className="section is-title-bar">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <ul>
                    <li>Comercial</li>
                    <li>Fila de Pedidos</li>
                    <li>Pedido {pedidoObj.pedido_compra}</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="hero is-hero-bar">
            <div className="hero-body">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title">Pedido {pedidoObj.pedido_compra}</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <ButtonGroup aria-label="outlined is-primary button group">
                      <Button
                        style={{
                          color:
                            activeButton === 'Material' ? 'white' : '#2B3BED',
                          backgroundColor:
                            activeButton === 'Material'
                              ? '#2B3BED'
                              : 'transparent',
                          padding: '5px 30px',
                        }}
                        onClick={() => handleButtonClick('Material')}
                      >
                        Material
                      </Button>

                      <Button
                        style={{
                          color:
                            activeButton === 'Serviço' ? 'white' : '#2B3BED',
                          backgroundColor:
                            activeButton === 'Serviço'
                              ? '#2B3BED'
                              : 'transparent',
                          padding: '5px 30px',
                        }}
                        onClick={() => handleButtonClick('Serviço')}
                      >
                        Serviço
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section is-main-section">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-ballot"></i>
                  </span>
                  Items do pedido
                </p>
              </header>
              <div className="card-content">
                {/* <div className="div-select-search">
                                <div className="search-input" >
                                    <div className="info-search">
                                        <label className="label">Filtrar por empreendimento</label>                                                        
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            defaultValue={options[0]}    
                                            isClearable={true}
                                            isSearchable= {true}                                                            
                                            name="color"
                                            options={options}
                                            placeholder= {<div><i className="fa-solid fa-magnifying-glass"></i><span> Selecione</span></div>}
                                            onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                    setActiveButton(selectedOption.value);
                                                } else {
                                                    setActiveButton("");
                                                }
                                            }}
                                        />                                       
                                    </div>                                                                              
                                </div>  
                            </div> */}

                <div className="b-table has-pagination">
                  <div className="table-wrapper has-mobile-cards">
                    <div className="FilaTarefas">
                      <div
                        style={{
                          height: 600,
                          width: '100%',
                        }}
                      >
                        <DataGrid
                          rows={filaItemsPed}
                          columns={columns}
                          pageSize={5}
                          disableSelectionOnClick
                          getRowHeight={() => 'auto'}
                          sx={{
                            fontFamily: '"Nunito", sans-serif', // Aplica a fonte Nunito a todo o DataGrid
                            '& .MuiDataGrid-cell': {
                              display: 'flex',
                              alignItems: 'flex-start', // Alinha o conteúdo no topo
                              paddingTop: '4px', // Ajuste conforme necessário
                              paddingBottom: '4px',
                              border: '0px',
                              // lineHeight: '2.5',
                              fontSize: '16px',
                            },
                            '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle':
                              {
                                fontWeight: '600', // Deixa o texto do cabeçalho em negrito
                                fontSize: '16px',
                                color: '#171717',
                                // fontFamily: '"Nunito", sans-serif',
                                lineHeight: '2.5',
                                borderLeft: '5px solid transparent',
                              },
                            '& .MuiDataGrid-virtualScroller': {
                              overflowX: 'hidden',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FormModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              modalTitle={'Informações do Item do Pedido'}
            >
              {renderModalContent()}
            </FormModal>
          </section>
        </div>
      </MainLayout>
    </>
  );
};

export default FilaServico;
