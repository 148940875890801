import React, { useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import UserDashboardSolicitante from './UserDashboardSolicitante';
import UserDashboardResponsavel from './UserDashboardResponsavel';

const MemoizedUserSolicitante = React.memo(UserDashboardSolicitante);
const MemoizedUserResponsavel = React.memo(UserDashboardResponsavel);

const UserDashboards = () => {
  const [tipoDash, setTipoDash] = useState('solicitante');
  const [selectedDash, setSelectedDash] = useState('solicitante');

  const handleSubmit = (e) => {
    e.preventDefault();
    setSelectedDash(tipoDash);
  };

  const renderDash = () => {
    if (selectedDash === 'solicitante') {
      return <MemoizedUserSolicitante key="user-solicitante" />;
    } else if (selectedDash === 'responsavel') {
      return <MemoizedUserResponsavel key="user-responsavel" />;
    }
  };

  return (
    <MainLayout>
      <div className="main-section">
        <section className="section is-title-bar">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <ul>
                  <li>Usuário</li>
                  <li>Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="hero is-hero-bar">
          <div className="hero-body">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h1 className="title">Dashboard</h1>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <div className="select-lista">
                    <form
                      name="selecionarDashboard"
                      className="is-inline"
                      onSubmit={handleSubmit}
                    >
                      <div style={{ display: 'flex' }}>
                        <div
                          className="div-select-search"
                          style={{
                            paddingRight: '15px',
                            width: '400px',
                          }}
                        >
                          <label className="label"></label>

                          <div className="field-body">
                            <div
                              className="field is-narrow"
                              style={{
                                padding: '0px',
                              }}
                            >
                              <div className="control has-icons-left">
                                <div className="select is-fullwidth">
                                  <select
                                    value={tipoDash}
                                    className="custom-select-style"
                                    onChange={(e) =>
                                      setTipoDash(e.target.value)
                                    }
                                  >
                                    <option value="solicitante">
                                      Solicitante
                                    </option>
                                    <option value="responsavel">
                                      Responsável
                                    </option>
                                  </select>
                                  <span className="icon is-small is-left">
                                    <i className="fa-solid fa-list"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                          }}
                        >
                          <button className="button is-primary" type="submit">
                            <span className="icon">
                              <i className="fa-solid fa-arrow-right"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="section is-main-section">{renderDash()}</section>
    </MainLayout>
  );
};

export default UserDashboards;
