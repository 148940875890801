import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import axios from 'axios';

const AceNavbar = () => {
  const { nomeUsuario, setGruposUsuario } = useUser();
  const navigate = useNavigate();
  const { REACT_APP_BASE_API_URL } = process.env;

  const logoutHandler = async () => {
    const response = await axios.post(
      `${REACT_APP_BASE_API_URL}/auth/logout`,
      {},
      {}
    );

    setGruposUsuario(null);

    navigate('/login');
  };

  return (
    <nav id="navbar-main" className="navbar is-fixed-top">
      <div className="navbar-brand">
        <a className="navbar-item is-hidden-desktop jb-aside-mobile-toggle">
          <span className="icon">
            <i className="mdi mdi-forwardburger mdi-24px"></i>
          </span>
        </a>
      </div>
      <div className="navbar-brand is-right">
        <a
          className="navbar-item is-hidden-desktop jb-navbar-menu-toggle"
          data-target="navbar-menu"
        >
          <span className="icon">
            <i className="mdi mdi-dots-vertical"></i>
          </span>
        </a>
      </div>
      <div className="navbar-menu fadeIn animated faster" id="navbar-menu">
        <div className="navbar-end">
          <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
            <a className="navbar-link is-arrowless">
              <div className="is-user-avatar">
                <img
                  src="https://avatars.dicebear.com/v2/initials/john-doe.svg"
                  alt="John Doe"
                />
              </div>
              <div className="is-user-name">
                {/* <span>{user}</span> */}
                <span>{nomeUsuario}</span>
              </div>
              <span className="icon">
                <i className="mdi mdi-chevron-down"></i>
              </span>
            </a>
            <div className="navbar-dropdown">
              <Link to={'/me/profileN'} className="navbar-item">
                <span className="icon">
                  <i className="mdi mdi-account"></i>
                </span>
                <span>Meu perfil</span>
              </Link>
              <a className="navbar-item">
                <span className="icon">
                  <i className="mdi mdi-settings"></i>
                </span>
                <span>Configurações</span>
              </a>
              <a className="navbar-item">
                <span className="icon">
                  <i className="mdi mdi-email"></i>
                </span>
                <span>Mensagens</span>
              </a>
              <hr className="navbar-divider" />
              <a className="navbar-item" onClick={logoutHandler}>
                <span className="icon">
                  <i className="mdi mdi-logout"></i>
                </span>
                <span>Sair</span>
              </a>
            </div>
          </div>
          <a
            href="https://geoenerlondrina.sharepoint.com/:u:/r/SitePages/ACES-os-processos-da-Geo-em-um-so-lugar.aspx?csf=1&web=1&share=EVRLvN3_Ky1Ns4wTHEvJxOsB7fNYDfk9fhW2Ilir57ph_g&e=toE3Sz"
            title="Sobre o ACES"
            className="navbar-item has-divider is-desktop-icon-only"
          >
            <span className="icon">
              <i className="mdi mdi-help-circle-outline"></i>
            </span>
            <span>Sobre</span>
          </a>
          <a
            title="Sair"
            className="navbar-item is-desktop-icon-only"
            onClick={logoutHandler}
          >
            <span className="icon">
              <i className="mdi mdi-logout"></i>
            </span>
            <span>Sair</span>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default AceNavbar;
