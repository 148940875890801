import React, { useState } from 'react';
import MainLayout from '../../layouts/MainLayout';
import AlertMessage from '../../layouts/AlertMessage';

import axios from 'axios';

const PasswordForget = () => {
  const { REACT_APP_BASE_API_URL } = process.env;
  const [targetEmail, setTargetEmail] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navigateTo, setNavigateTo] = useState('');

  const headers = {
    'Content-Type': 'application/json',
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let requestData = {
      email: [targetEmail],
    };
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_API_URL}/users/forgot_pass`,
        requestData
      );
      const data = await response.data;
      if (response.status === 200) {
        setAlertMessage('E-mail enviado com sucesso');
        setAlertSeverity('success');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage(
        Array.isArray(error.response?.data?.detail)
          ? JSON.stringify(error.response?.data?.detail, null, 2)
          : error.response?.data?.detail || 'Ocorreu um erro.'
      );
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    setAlertOpen(false);
    setAlertSeverity('error');
    setAlertMessage(null);
    setNavigateTo('');
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        severity={alertSeverity}
        open={alertOpen}
        handleClose={handleClose}
      />
      <section className="section main-content">
        <div className="container">
          <div className="card">
            <div className="card-content">
              <form name="forgetpass" onSubmit={handleSubmit}>
                <div className="field is-horizontal">
                  <div
                    className="field-label is-normal"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <label className="label">E-mail</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          className="input is-rounded"
                          type="text"
                          value={targetEmail}
                          onChange={(e) => setTargetEmail(e.target.value)}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-mail"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <button className="button is-primary" type="submit">
                  Recuperar senha
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PasswordForget;
